<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <simple-equity-search
      :autofocus="shouldAutoFocus"
      class="mt"
      clearable
      :error-messages="localErrorMsg || errorMessages"
      label="add security"
      :value="equity"
      @change="onEquityChange($event)"
      @input="onEquityChange($event)"
    />

    <div class="table-container mt-4">
      <div>
        <v-data-table
          v-if="equities.length"
          disable-filtering
          disable-pagination
          disable-sort
          fixed-header
          hide-default-footer
          item-key="key"
          :items="equities"
          width="100%"
        >
          <template #item="{ item }">
            <tr>
              <td>
                {{ item.ticker }}
              </td>
              <td>
                {{ item.cusip }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td class="text-right">
                <v-btn class="icon-action" icon @click="removeEquity(item)">
                  <v-icon size="24">mdi-close-circle</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import { Equity } from '@/modules/common/types/api';
import { PropType } from 'vue';

@Component({
  props: {
    equities: {
      type: Array as PropType<Equity[]>,
      required: true,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SimpleEquitySearch,
  },
})
export default class MultipleEquitySelector extends Vue {
  protected readonly equities!: Equity[];
  protected readonly errorMessages!: string[];
  protected readonly autofocus!: boolean;

  protected equity: Equity | null = null;
  protected localErrorMsg: string | null = null;

  protected get shouldAutoFocus(): boolean {
    return this.autofocus && this.equities.length === 0;
  }

  protected onEquityChange(equity: Equity | null): void {
    this.localErrorMsg = null;

    if (equity === null) {
      // user cleared the search field
      return;
    }

    this.equity = equity;

    if (this.equity.cannotTradeMessage) {
      this.localErrorMsg = this.equity.cannotTradeMessage;
      return;
    }

    if (this.equities.map((i) => i.cusip).includes(equity?.cusip)) {
      this.localErrorMsg = 'security already in list.';
      return;
    }

    this.$emit('update:equities', [...this.equities, equity]);
    // get model and validator ready for the next equity
    this.$nextTick(() => {
      this.equity = null;
    });
  }

  protected removeEquity(equity: Equity): void {
    this.$emit(
      'update:equities',
      this.equities.filter((e) => e.id !== equity.id)
    );
  }
}
</script>

<style lang="scss" scoped>
.icon-action {
  opacity: 0.4;
  transition: opacity linear 0.15s;
}

.icon-action:hover {
  opacity: 1;
}

.equities {
  list-style: none;
  padding: 0;
  margin: 0;
}

.equities li {
  padding: 5px;
  border-bottom: 1px solid #444;
}

.equities li + li:last-child {
  border-bottom: none;
}

.equities li:hover .v-icon {
  display: inline-block;
  cursor: pointer;
}

::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 40vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
