<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <open-loans-action-manager ref="actionManager" />
    <v-card elevation="0">
      <v-row
        v-if="fetchStatus === 'fetching'"
        class="justify-center pt-16 flex-column align-center"
      >
        <fulfilling-bouncing-circle-spinner :animation-duration="1500" color="#4caf50" :size="50" />
        <h3 class="mt-2">Fetching loan details...</h3>
        <h4 v-if="retryCount > 0">(This may take a bit longer)</h4>
      </v-row>

      <v-row
        v-else-if="fetchStatus === 'failed'"
        class="justify-center pt-16 flex-column align-center"
      >
        <v-alert dense type="error">
          {{ fetchError }}
        </v-alert>
      </v-row>

      <template v-else-if="fetchStatus === 'completed' && loan">
        <!-- Loan summary -->
        <v-card-title class="mb-0">
          <div class="d-flex align-center">
            <span class="headline">
              {{ loan.sponsorshipSide !== null ? 'Sponsored' : '' }}
              {{ loan.side === 'borrower' ? 'Borrow' : 'Loan' }} Details
            </span>
            <loan-status-chip class="ml-4" :loan="loan" />
            <v-chip
              v-if="clientConfig.bilateralLoansEnabled"
              class="ml-4"
              :color="settlementTypeColor[loan.settlementType]"
              small
            >
              {{ settlementTypeDisplayText[loan.settlementType] }}
            </v-chip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-chip
                  v-if="loan.termContractDisplayId"
                  class="ml-4 text-lowercase"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  term loan:&nbsp;<span class="text-uppercase">{{
                    loan.termContractDisplayId
                  }}</span>
                </v-chip>
              </template>
              <span>this loan is part of a term loan contract</span>
            </v-tooltip>
          </div>
          <v-spacer />
          <div v-if="asBroker" class="text--primary text-right headline-2">
            <span v-if="loan.pendingDtccRefId && loan.activeDtccRefId">
              DTCC {{ loan.activeDtccRefId }} / {{ loan.pendingDtccRefId }} (pending)
            </span>
            <span v-else-if="loan.pendingDtccRefId">
              DTCC {{ loan.pendingDtccRefId }} (pending)
            </span>
            <span v-else-if="loan.activeDtccRefId">DTCC {{ loan.activeDtccRefId }}</span>
            <span v-if="loan.pendingDtccRefId || loan.activeDtccRefId">
              / {{ loan.displayId }}
            </span>
            <span v-else>{{ loan.displayId }}</span>
          </div>
          <span v-else class="text--primary headline-2">{{ loan.displayId }}</span>
        </v-card-title>
        <v-card-text>
          <div class="pr-4 pl-2">
            <v-row class="d-flex align-center">
              <v-col class="col-12">
                <h4 class="summary">
                  <strong>
                    {{ loan.side === 'lender' ? 'Lending' : 'Borrowing' }}
                    <pretty-number :value="loan.openQuantity" />
                  </strong>
                  {{ loan.equity.name }} / <strong>{{ loan.equity.ticker }}</strong> ({{
                    loan.equity.cusip
                  }}) @
                  <strong>
                    <rate-output :rate="loan.rate" :rate-modifier="loan.rateModifier" />
                  </strong>
                  {{ loan.side === 'lender' ? 'to' : 'from' }}
                  <strong>{{ loan.counterpartyDisplay }}</strong>
                </h4>
                <h4 v-if="loan.independentAmountRate.isZero()">
                  Contract Amount: ${{ prettyPrice(loan.contractAmount) }}
                  <small v-if="loan.roundingRule && loan.roundingRule !== RoundingRule.NoRounding">
                    (Rounding: {{ formatRoundingRule(loan.roundingRule) }})
                  </small>
                </h4>
                <h4 v-else>
                  Settlement Amount:
                  <span title="Contract Amount">${{ prettyPrice(loan.contractAmount) }}</span> +
                  <span title="Independent Amount Rate">
                    {{ loan.independentAmountRate }}% IA
                  </span>
                  =
                  <span title="Settlement Amount">${{ prettyPrice(loan.settlementAmount) }}</span>
                  <small v-if="loan.roundingRule && loan.roundingRule !== RoundingRule.NoRounding">
                    (Rounding: {{ formatRoundingRule(loan.roundingRule) }})
                  </small>
                </h4>
              </v-col>

              <!-- Pending actions notification alerts -->
              <v-col class="col-12">
                <template v-if="loan.status === 'Canceled' && loan.canceledReason">
                  <v-alert class="mb-6 font-weight-light" color="blue-grey" dense type="error">
                    This loan was canceled because of: {{ loan.canceledReason }}
                  </v-alert>
                </template>
                <template v-if="loan.renegotiation">
                  <v-alert
                    v-if="loan.renegotiation.side === loan.side"
                    class="mb-6 font-weight-light"
                    color="blue-grey"
                    dense
                    type="error"
                  >
                    You suggested a rerate from
                    <strong>
                      <rate-output :rate="loan.rate" :rate-modifier="loan.rateModifier" />
                    </strong>
                    to
                    <strong>
                      <rate-output
                        :rate="loan.renegotiation.rate"
                        :rate-modifier="loan.renegotiation.rateModifier"
                      />
                    </strong>
                    <template v-if="loanDetails.loan?.renegotiatePendingSince">
                      on
                      <strong>
                        {{ formatTimestamp(loanDetails.loan.renegotiatePendingSince) }}
                      </strong>
                    </template>
                  </v-alert>
                  <v-alert
                    v-if="loan.renegotiation.side !== loan.side"
                    class="mb-6 font-weight-light"
                    color="blue-grey"
                    dense
                    type="error"
                  >
                    Counterparty suggested a rerate from
                    <strong>
                      <rate-output :rate="loan.rate" :rate-modifier="loan.rateModifier" />
                    </strong>
                    to
                    <strong>
                      <rate-output
                        :rate="loan.renegotiation.rate"
                        :rate-modifier="loan.renegotiation.rateModifier"
                      />
                    </strong>
                    <template v-if="loanDetails.loan?.renegotiatePendingSince">
                      on
                      <strong>
                        {{ formatTimestamp(loanDetails.loan.renegotiatePendingSince) }}
                      </strong>
                    </template>
                  </v-alert>
                </template>
                <v-alert
                  v-if="loan.recalledQuantity > 0"
                  class="mb-6 font-weight-light"
                  color="blue-grey"
                  dense
                  type="error"
                >
                  {{ loan.side === 'lender' ? 'You' : 'Counterparty' }} recalled
                  <strong>{{ loan.recalledQuantity }}</strong> shares
                </v-alert>
                <v-alert
                  v-if="loan.pendingReturnQuantity > 0"
                  class="mb-6 font-weight-light"
                  color="blue-grey"
                  dense
                  type="error"
                >
                  {{ loan.side === 'lender' ? 'Counterparty' : 'You' }} initiated a return of
                  <strong>{{ loan.pendingReturnQuantity }}</strong> shares
                  <template v-if="loanDetails.loan?.returnPendingSince">
                    on
                    <strong> {{ formatTimestamp(loanDetails.loan.returnPendingSince) }} </strong>
                  </template>
                </v-alert>

                <!-- Actions buttons -->
                <div v-if="!asBroker && !isTerminalLoanStatus(loan.status)" class="actions d-flex">
                  <!-- If PENDING the only available action is Cancel -->
                  <aurora-btn
                    v-if="loan.availableActions.cancelPending"
                    :disabled="!hasOpsUserRole"
                    timeframe="cancelLoans"
                    @click="$refs.actionManager.trigger({ name: 'cancelPendingLoan', loan })"
                  >
                    Cancel pending loan
                  </aurora-btn>

                  <!-- Lender actions -->
                  <aurora-btn
                    v-if="loan.availableActions.recall || loan.availableActions.updateRecall"
                    color="orange darken-2"
                    data-test="update-recall-button"
                    :disabled="!hasOpsUserRole || loan.openQuantityToRecall === 0"
                    timeframe="recallLoans"
                    @click="$refs.actionManager.trigger({ name: 'recall', loan })"
                  >
                    Add recall
                  </aurora-btn>

                  <aurora-btn
                    v-if="loan.availableActions.buyIn"
                    color="red darken-2"
                    data-test="buy-in-button"
                    :disabled="!hasOpsUserRole"
                    timeframe="buyInLoans"
                    @click="$refs.actionManager.trigger({ name: 'buyIn', loan })"
                  >
                    Buy-in
                  </aurora-btn>

                  <!-- Borrower actions -->
                  <aurora-btn
                    v-if="loan.availableActions.return"
                    :color="`${loan.recalledQuantity > 0 ? 'red' : 'orange'} darken-2`"
                    data-test="return-button"
                    :disabled="!hasOpsUserRole"
                    timeframe="settleLoans"
                    @click="$refs.actionManager.trigger({ name: 'return', loan })"
                  >
                    Return
                  </aurora-btn>

                  <aurora-btn
                    v-if="loan.availableActions.cancelReturn"
                    color="red darken-2"
                    data-test="cancel-return-button"
                    :disabled="!hasOpsUserRole"
                    timeframe="settleLoans"
                    @click="$refs.actionManager.trigger({ name: 'cancelPendingReturns', loan })"
                  >
                    Cancel All Pending Returns
                  </aurora-btn>

                  <!-- Renegotiation actions -->
                  <aurora-btn
                    v-if="loan.availableActions.acceptRenegotiate"
                    color="green darken-2"
                    data-test="accept-rate-button"
                    :disabled="!hasTraderUserRole"
                    timeframe="rateModLoans"
                    @click="$refs.actionManager.trigger({ name: 'acceptRenegotiate', loan })"
                  >
                    Accept rerate
                  </aurora-btn>

                  <aurora-btn
                    v-if="loan.availableActions.rejectRenegotiate"
                    color="red darken-2"
                    data-test="reject-rate-button"
                    :disabled="!hasTraderUserRole"
                    timeframe="rateModLoans"
                    @click="$refs.actionManager.trigger({ name: 'rejectRenegotiate', loan })"
                  >
                    Reject rerate
                  </aurora-btn>

                  <aurora-btn
                    v-if="loan.availableActions.rejectRenegotiate"
                    color="red darken-2"
                    data-test="reject-rate-button"
                    :disabled="!hasTraderUserRole"
                    timeframe="rateModLoans"
                    @click="
                      $refs.actionManager.trigger({
                        name: 'rejectRenegotiateAndCounterOffer',
                        loan,
                      })
                    "
                  >
                    Reject & Counter Rerate
                  </aurora-btn>

                  <v-btn
                    v-if="loan.availableActions.cancelRenegotiate"
                    data-test="cancel-rate-button"
                    :disabled="!hasTraderUserRole"
                    @click="$refs.actionManager.trigger({ name: 'cancelRenegotiate', loan })"
                  >
                    Cancel rerate
                  </v-btn>

                  <aurora-btn
                    v-if="
                      loan.availableActions.renegotiateFixed ||
                      loan.availableActions.renegotiateFloating
                    "
                    color="purple"
                    data-test="renegotiate-button"
                    :disabled="!hasTraderUserRole"
                    timeframe="rateModLoans"
                    @click="$refs.actionManager.trigger({ name: 'renegotiate', loan })"
                  >
                    Rerate
                  </aurora-btn>
                  <confirm-dialog-wrapper
                    v-if="selectedCancelRecallCount"
                    :options="{
                      message: 'You are about to cancel all selected recalls.',
                      title: 'Recall cancellation',
                      rejectText: 'Keep recalls',
                      acceptText: 'Cancel recalls',
                    }"
                    v-on="$listeners"
                  >
                    <template #default="{ confirm }">
                      <aurora-btn
                        data-test="cancel-recall-button"
                        :disabled="!hasOpsUserRole"
                        :loading="isBatchCancellingRecalls"
                        timeframe="recallLoans"
                        @click="confirm(() => $refs.openRecalls.batchCancelSelected())"
                      >
                        Cancel recalls ({{ selectedCancelRecallCount }})
                      </aurora-btn>
                    </template>
                  </confirm-dialog-wrapper>
                </div>
              </v-col>

              <v-col class="col-12 d-flex">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row class="mt-n2 mb-4" no-gutters>
              <v-tabs
                ref="tabs"
                v-model="selectedTabIndex"
                background-color="transparent"
                @change="selectedCancelRecallCount = 0"
              >
                <v-tab> History </v-tab>
                <v-tab> Recalls ({{ loan?.openRecalls?.length || 0 }})</v-tab>
                <v-tab v-if="clientConfig.corpActionsEnabled" data-test="announcements-tab">
                  Announcements ({{ totalCorpActions }})
                </v-tab>
              </v-tabs>
            </v-row>

            <!-- history tab -->
            <div v-if="selectedTabIndex === 0">
              <!-- Event history table -->
              <div class="table-container mt-4">
                <div>
                  <v-data-table
                    disable-filtering
                    disable-pagination
                    disable-sort
                    fixed-header
                    :headers="tableHeaders"
                    hide-default-footer
                    item-key="key"
                    :items="filteredHistory"
                    width="100%"
                  >
                    <template #item="{ item, index }">
                      <tr>
                        <td class="highlight">
                          {{ formatTimestamp(item.eventTimestamp) }}
                        </td>
                        <td>
                          <v-chip
                            class="text-uppercase mr-2"
                            :color="loanEventColors[item.eventType]"
                            x-small
                          >
                            {{ $t(`loanHistory.eventType.${item.eventType}`) }}
                            <span v-if="item.eventType === 'RECALLED'" class="ml-1">
                              / {{ item.recalledQuantity }}
                            </span>
                            <span v-else-if="item.eventType === 'RETURNED'" class="ml-1">
                              / {{ item.returnedQuantity }}
                            </span>
                            <span v-else-if="item.eventType === 'RETURN_CANCELED'" class="ml-1">
                              / {{ item.quantity }}
                            </span>
                            <span v-else-if="item.eventType === 'RETURN_REJECTED'" class="ml-1">
                              / {{ item.quantity }}
                            </span>
                            <span v-else-if="item.eventType === 'RETURN_FAILED'" class="ml-1">
                              / {{ item.quantity }}
                            </span>
                            <span v-else-if="item.eventType === 'BUY-IN'" class="ml-1">
                              / {{ item.buyInQuantity }}
                            </span>
                            <span v-else-if="item.eventType === 'BUY-IN_CANCELED'" class="ml-1">
                              / {{ item.quantity }}
                            </span>
                            <span v-else-if="item.eventType === 'BUY-IN_REJECTED'" class="ml-1">
                              / {{ item.quantity }}
                            </span>
                            <span v-else-if="item.eventType === 'RECALL_CANCELED'" class="ml-1">
                              / {{ item.canceledRecalledQuantity }}
                            </span>
                            <span v-else-if="item.eventType === 'RECALL_REJECTED'" class="ml-1">
                              / {{ item.rejectedRecalledQuantity }}
                            </span>
                            <span
                              v-else-if="
                                item.eventType === 'RENEGOTIATION_REJECTED' ||
                                item.eventType === 'RENEGOTIATION_CANCELED' ||
                                item.eventType === 'RENEGOTIATION_EXPIRED'
                              "
                              class="ml-1"
                            >
                              / <rate-output :rate="item.newRate" />
                            </span>
                            <span
                              v-else-if="
                                item.eventType === 'ROLL_PD_PENDING' ||
                                item.eventType === 'ROLL_PD_MADE' ||
                                item.eventType === 'BUY_IN_PO_FAILED' ||
                                item.eventType === 'BUY_IN_PO_MADE' ||
                                item.eventType === 'DAILY_INTEREST_PO_FAILED' ||
                                item.eventType === 'DAILY_INTEREST_PO_MADE' ||
                                item.eventType === 'MARK_TO_MARKET_PO_FAILED' ||
                                item.eventType === 'MARK_TO_MARKET_PO_MADE'
                              "
                              class="ml-1"
                            >
                              {{ prettyPriceWithSymbol(item.amount) }}
                            </span>
                            <span
                              v-else-if="
                                item.eventType === 'CORP-ACTION' && item.instrumentChange !== null
                              "
                              class="ml-1"
                            >
                              / to {{ item.instrumentChange.newInstrument.cusip }}
                            </span>
                          </v-chip>
                        </td>
                        <td :class="cellHighlight('loanState.openQuantity', index)">
                          <pretty-number :value="item.loanState.openQuantity" />
                        </td>
                        <td :class="cellHighlight('loanState.unitPrice', index)">
                          ${{ prettyPrice(item.loanState.unitPrice) }}
                        </td>
                        <td :class="[cellHighlight('loanState.rate', index), 'text-end']">
                          <rate-output :rate="item.loanState.rate" />
                        </td>
                        <td :class="cellHighlight('loanState.settlementValue', index)">
                          ${{ prettyPrice(item.loanState.settlementValue) }}
                        </td>
                        <td :class="cellHighlight('loanState.dailyInterestAmount', index)">
                          ${{ prettyPrice(item.loanState.dailyInterestAmount) }}
                        </td>
                        <td>
                          {{
                            item.loanState.activeDtccRefId
                              ? item.loanState.activeDtccRefId
                              : item.loanState.pendingDtccRefId
                                ? item.loanState.pendingDtccRefId
                                : '–'
                          }}
                        </td>
                        <td>
                          <format-event-initiator :initiator="item.initiator" />
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <!-- Filter by eventType -->
              <v-row class="mt-4 filter-by">
                <v-col class="col-12 d-flex align-center justify-end">
                  <span v-if="filterableEvents.length" class="text--primary mr-2">Filter by</span>
                  <v-chip-group v-model="selectedEvents" column multiple>
                    <v-chip
                      v-for="eventType in filterableEvents"
                      :key="eventType"
                      :color="
                        selectedEvents.includes(eventType)
                          ? loanEventColors[eventType]
                          : 'grey darken-3'
                      "
                      filter
                      outlined
                      small
                      :value="eventType"
                    >
                      {{ $t(`loanHistory.eventType.${eventType}`) }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </div>
            <!-- recalls tab -->
            <div v-if="selectedTabIndex === 1">
              <div class="table-container mt-4">
                <loan-recalls
                  ref="openRecalls"
                  :loan="loan"
                  @end-batch-cancel="isBatchCancellingRecalls = false"
                  @selected-count="selectedCancelRecallCount = $event"
                  @start-batch-cancel="isBatchCancellingRecalls = true"
                />
              </div>
            </div>
            <!-- corpactions tab; render component but hide it (so mounted/fetch happens) -->
            <!-- this way we get the count number inside the tab before user actually clicks the tab -->
            <div
              v-if="clientConfig.corpActionsEnabled"
              class="table-container"
              :class="{ 'd-none': selectedTabIndex !== 2 }"
              data-test="corp-actions-card"
            >
              <div class="overflow-y-auto">
                <corp-actions-card
                  :cusip="loan.equity.cusip"
                  @fetch-corp-actions="totalCorpActions = $event.length"
                />
              </div>
            </div>
          </div>
        </v-card-text>
      </template>
    </v-card>
  </span>
</template>

<script lang="ts">
import FormatEventInitiator from '@/modules/common/components/FormatEventInitiator.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import CorpActionsCard from '@/modules/corp-actions/components/CorpActionsCard.vue';
import {
  settlementTypeColor,
  settlementTypeDisplayText,
} from '@/modules/marketplace/helpers/marketplace';
import LoanRecalls from '@/modules/open-loans/components/LoanRecalls.vue';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import OpenLoansActionManager from '@/modules/open-loans/components/OpenLoansActionManager.vue';
import { RoundingRule, roundingRuleToString } from '@/modules/sec-lending/helpers/contract-details';
import { SocketEvents } from '@/store/store';
import {
  LoanDetails,
  LoanDetailsLoan,
  LoanEvent,
  LoanEventType,
  LoanStatus,
  isTerminalLoanStatus,
  loanEventColors,
} from '@/utils/api/loans';
import { formatDecimalAsCurrencyString, getPriceAsString } from '@/utils/helpers/auction-numbers';
import { formatDate } from '@/utils/helpers/dates';
import { ClientConfig } from '@/utils/helpers/rest';
import Decimal from 'decimal.js';
import { get as getPropValue } from 'lodash';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { mapGetters, mapState } from 'vuex';

const rollPdEvents: LoanEventType[] = ['ROLL_PD_PENDING', 'ROLL_PD_MADE'];

const tableHeaders: DataTableHeader[] = [
  { text: 'When', value: 'eventTimestamp', width: '11rem' },
  { text: 'Event', value: 'eventType', width: '12rem' },
  { text: 'Quantity', value: 'openQuantity' },
  { text: 'Unit Price', value: 'unitPrice' },
  { text: 'Rate', value: 'rate', align: 'end', width: '10rem' },
  { text: 'Settlement Value', value: 'settlementValue' },
  { text: 'Rate Amount', value: 'dailyInterestAmount' },
  { text: 'DTCC Ref ID', value: 'dtccRefId' },
  { text: 'Initiator', value: 'initiator' },
];

const FETCH_RETRY_DELAYS = [1 * 1000, 2 * 1000, 4 * 1000, 4 * 1000];

@Component({
  components: {
    LoanStatusChip,
    LoanRecalls,
    OpenLoansActionManager,
    FormatEventInitiator,
    CorpActionsCard,
  },
  props: {
    loanId: Number,
    asBroker: Boolean,
    initialTab: {
      type: String as PropType<'history' | 'recalls' | 'corporateActions'>,
      default: 'history',
    },
    shouldRetryFetch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['socketEvents', 'clientConfig']),
    ...mapGetters(['hasOpsUserRole', 'hasTraderUserRole']),
  },
})
export default class LoanDetailsCard extends Vue {
  public $refs!: {
    actionManager: OpenLoansActionManager;
    openRecalls: LoanRecalls;
  };

  // props
  protected readonly loanId!: number;
  protected readonly asBroker!: boolean;
  protected readonly initialTab!: 'history' | 'recalls' | 'corporateActions';
  // useful when order has just been matched
  // and the loan events are not available yet (but will be soon)
  protected readonly shouldRetryFetch!: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly RoundingRule = RoundingRule;
  protected readonly settlementTypeDisplayText = settlementTypeDisplayText;
  protected readonly settlementTypeColor = settlementTypeColor;

  // store state
  protected socketEvents!: SocketEvents;
  protected hasOpsUserRole!: boolean;
  protected hasTraderUserRole!: boolean;
  protected readonly clientConfig!: ClientConfig;

  // internals
  protected tableHeaders = tableHeaders;
  protected selectedTabIndex = 0;
  protected selectedCancelRecallCount = 0;
  protected isBatchCancellingRecalls = false;
  protected totalCorpActions = 0;
  protected retryCount = 0;
  protected fetchRetryDelays = FETCH_RETRY_DELAYS;

  protected loanDetails: LoanDetails = { loan: null, history: [] };

  protected fetchStatus: 'fetching' | 'completed' | 'failed' = 'fetching';
  protected defaultSelectedEvents: LoanEventType[] = [
    'CREATED',
    'DO_PENDING',
    'PENDING',
    'MADE',
    'DROPPED',
    'REJECTED',
    'CANCEL_PENDING',
    'CANCEL_REJECTED',
    'CANCELED',
    'ROLL_PENDING',
    'ROLL_PD_PENDING',
    'ROLL_PD_MADE',
    'ROLLED',
    'MARKED_TO_MARKET',
    'RENEGOTIATED',
    'RENEGOTIATION_CANCELED',
    'RENEGOTIATION_REJECTED',
    'RENEGOTIATION_EXPIRED',
    'RETURNED',
    'CANCEL_RETURN_PENDING',
    'RETURN_CANCELED',
    'RETURN_PENDING',
    'RETURN_FAILED',
    'RETURN_REJECTED',
    'TERMINATED',
    'RECALLED',
    'RECALL_REJECTED',
    'RECALL_CANCELED',
    'BUY-IN',
    'BUY-IN_PENDING',
    'BUY-IN_CANCELED',
    'BUY-IN_REJECTED',
    'CORP-ACTION',
    'FLOATING_RATE_RECALCULATED',
  ];
  protected selectedEvents = this.defaultSelectedEvents;

  protected readonly unfilterableEventTypes: LoanEventType[] = [
    'CREATED',
    'DROPPED',
    'REJECTED',
    'CANCELED',
    'TERMINATED',
    'CORP-ACTION',
  ];
  protected hasCurrentlyPendingRoll: boolean | null = null;
  protected loanEventColors = loanEventColors;
  protected fetchError = '';
  protected abortController: AbortController | null = null;

  protected get loan(): LoanDetailsLoan | null {
    return this.loanDetails.loan;
  }

  protected get filteredHistory(): LoanEvent[] {
    return this.loanDetails.history.filter((i) => {
      return this.selectedEvents.includes(i.eventType);
    });
  }

  /*
   * UI only displays eventType that can be filtered
   * Return a list of unique eventType based on the fetched history data
   * (CREATED eventType is excluded)
   */
  protected get filterableEvents(): LoanEventType[] {
    return this.loanDetails.history.reduce<LoanEventType[]>((filterable, event) => {
      if (
        !filterable.includes(event.eventType) &&
        !this.unfilterableEventTypes.includes(event.eventType)
      ) {
        filterable.push(event.eventType);
      }
      return filterable;
    }, []);
  }

  @Watch('loanId')
  protected onLoanIdChange(): void {
    this.retryCount = 0;
    this.fetchStatus = 'fetching';
    this.selectedEvents = this.defaultSelectedEvents;
    void this.onLoanEvent({ loanId: this.loanId });
    // default to history tab when a new loan is displayed
    this.selectedTabIndex = 0;
  }

  @Watch('initialTab')
  protected onLoanInitialTabChange(): void {
    this.updateTab();
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  @Watch('socketEvents.openLoans.brokerLoan')
  protected async onLoanEvent(payload: { loanId: number }): Promise<void> {
    if (payload.loanId === this.loanId) {
      const eventCountBefore = this.loanDetails.history.length;

      // we don't need to throttle because we are interested in getting
      // all updates ASAP; token cancellation makes sure UI is in sync
      await this.fetch();

      // if we didn't get any new events added then we fetch again after a short delay
      // to account for the race condition between the event being pushed to the websocket
      // and the backend processing the event
      if (this.loanDetails.history.length == eventCountBefore) {
        await this.$wait(300);
        await this.fetch();
      }
    }
  }

  @Watch('loanDetails.history')
  protected onLoanHistoryChange(): void {
    const hasCurrentlyPendingRoll = this.loanDetails.history.some((ev) => {
      return ev.eventType == 'ROLL_PENDING';
    });

    // we only want to change the selected events if hasCurrentlyPendingRoll changes.
    // that way when a user has manually selected or deselected events,
    // we don't mess with that unless a pending roll is added or disappears.
    if (hasCurrentlyPendingRoll !== this.hasCurrentlyPendingRoll) {
      this.hasCurrentlyPendingRoll = hasCurrentlyPendingRoll;

      if (hasCurrentlyPendingRoll) {
        // add the Roll PD events to the selected events
        rollPdEvents.forEach((ev) => {
          if (!this.selectedEvents.includes(ev)) {
            this.selectedEvents.push(ev);
          }
        });
      } else {
        // remove the Roll PD events from the selected events
        this.selectedEvents = this.selectedEvents.filter((event) => {
          return !rollPdEvents.includes(event);
        });
      }
    }
  }

  protected mounted(): void {
    void this.fetch();
    this.updateTab();
  }

  protected updateTab(): void {
    this.selectedTabIndex =
      this.initialTab === 'recalls' ? 1 : this.initialTab === 'corporateActions' ? 2 : 0;
  }

  /*
   * 1) Always highlight all cells of the first event (length - 1)
   * 2) Highlight if current event cell value is different from previous event
   */
  protected cellHighlight(path: string, index: number): 'highlight' | '' {
    if (index === this.filteredHistory.length - 1) {
      return 'highlight';
    }
    const currentEventCellValue = getPropValue(this.filteredHistory[index], path);
    // previous event is index + 1 (list is reversed)
    const previousEventCellValue = getPropValue(this.filteredHistory[index + 1], path);

    return `${currentEventCellValue}` === `${previousEventCellValue}` ? '' : 'highlight';
  }

  protected prettyPrice(value: Decimal): string {
    return getPriceAsString(value, PRICE_PRECISION);
  }

  protected prettyPriceWithSymbol(value: Decimal): string {
    return formatDecimalAsCurrencyString('USD', value, PRICE_PRECISION);
  }

  protected formatTimestamp(value: Date): string {
    return formatDate(value, 'MMM d h:mm a');
  }

  protected isTerminalLoanStatus(s: LoanStatus): boolean {
    return isTerminalLoanStatus(s);
  }

  protected formatRoundingRule(roundingRule: RoundingRule): string {
    return roundingRuleToString(roundingRule);
  }

  private async fetch(): Promise<void> {
    if (this.abortController) {
      // we want to initiate a new request, but the previous one is still pending
      // cancel to avoid stale responses arriving late (and potentially in the wrong order)
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    try {
      const res = this.asBroker
        ? await this.$api.openLoans.fetchBrokerLoanDetails(this.loanId, this.abortController.signal)
        : await this.$api.openLoans.fetchLoanDetails(this.loanId, this.abortController.signal);
      if (!res) {
        // request was cancelled, there's another response arriving
        return;
      }
      this.fetchStatus = 'completed';
      this.loanDetails = res;
    } catch (e) {
      if (this.shouldRetryFetch) {
        await this.retryFetch(e);
      } else {
        this.fetchStatus = 'failed';
        this.fetchError = e as string;
      }
    }
  }

  private async retryFetch(lastError: unknown): Promise<void> {
    if (this.retryCount >= this.fetchRetryDelays.length) {
      this.fetchStatus = 'failed';
      this.fetchError = lastError as string;
    } else {
      this.retryCount += 1;
      const delay = this.fetchRetryDelays[this.retryCount - 1];
      await this.$wait(delay);
      await this.fetch();
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-by {
  background-color: #1e1e1e;
  position: sticky;
  bottom: 0;
  .theme--light & {
    background: white;
  }
}

.actions {
  gap: 1rem;
}

.table-container tr,
.summary {
  /* Tone down grey to increase contrast between bold and non-bold text */
  color: #888;
}

.highlight,
.summary strong {
  color: white;
  font-weight: bold;
}

.theme--light .table-container tr,
.theme--light .summary {
  color: #777;
}

.theme--light .highlight,
.theme--light .summary strong {
  color: black;
}

::v-deep {
  .v-card {
    min-height: 60vh;
  }

  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
