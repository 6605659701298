import { CustomRule } from 'vuelidate/lib/validators';
import { Equity } from '@/modules/common/types/api';

/**
 * Vuelidate custom validator:
 * An instrument must be tradeable before we can submit an order
 */

// canTradeEquity - an instrument is tradeable if it does NOT have a 'cannotTradeMessage'
export const canTradeEquity = (): CustomRule => (equity: Equity | null) => {
  return equity !== null && !equity.cannotTradeMessage;
};
