// @generated by protoc-gen-es v1.8.0 with parameter "target=ts"
// @generated from file consts/benchmarkconsts.proto (package aurora.core.consts.benchmark, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * possible rate benchmarks
 *
 * @generated from enum aurora.core.consts.benchmark.Benchmark
 */
export enum Benchmark {
  /**
   * @generated from enum value: Unspecified = 0;
   */
  Unspecified = 0,

  /**
   * @generated from enum value: OBFR = 1;
   */
  OBFR = 1,

  /**
   * @generated from enum value: EFFR = 2;
   */
  EFFR = 2,

  /**
   * @generated from enum value: SOFR = 3;
   */
  SOFR = 3,

  /**
   * @generated from enum value: BGCR = 4;
   */
  BGCR = 4,

  /**
   * @generated from enum value: TGCR = 5;
   */
  TGCR = 5,

  /**
   * @generated from enum value: IORB = 6;
   */
  IORB = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(Benchmark)
proto3.util.setEnumType(Benchmark, "aurora.core.consts.benchmark.Benchmark", [
  { no: 0, name: "Unspecified" },
  { no: 1, name: "OBFR" },
  { no: 2, name: "EFFR" },
  { no: 3, name: "SOFR" },
  { no: 4, name: "BGCR" },
  { no: 5, name: "TGCR" },
  { no: 6, name: "IORB" },
]);

