<template>
  <v-card class="flex d-flex flex-column">
    <v-card-title class="mb-4">
      <span class="headline">{{ $t('adminCounterpartyCredits.title') }}</span>
    </v-card-title>
    <v-card-subtitle>
      <span class="text--secondary headline-2">{{
        $t('adminCounterpartyCredits.description')
      }}</span>
    </v-card-subtitle>
    <v-card-text v-if="isLoading" class="text--primary">
      <v-container class="flex-column justify-center fill-height">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
        <h3 class="mt-4">Loading credits ...</h3>
      </v-container>
    </v-card-text>
    <v-card-text v-else>
      <v-data-table
        class="elevation-0 pb-8"
        dense
        disable-pagination
        fixed-header
        :headers="tableColumns"
        hide-default-footer
        :items="counterpartyCredits"
        sort-by="totalInSFT"
        sort-desc
      >
        <template #[`item.companyDisplay`]="{ item }">
          {{ item.companyDisplay }}
        </template>

        <template #[`item.totalBilateral`]="{ item }">
          ${{ prettyPrice(item.totalBilateral) }}
        </template>

        <template #[`item.totalInSFT`]="{ item }"> ${{ prettyPrice(item.totalInSFT) }}</template>

        <template #[`item.grandTotal`]="{ item }"> ${{ prettyPrice(item.grandTotal) }}</template>

        <template #[`body.append`]>
          <tr v-if="counterpartyCredits && counterpartyCredits.length > 0" class="last-row">
            <td>
              {{ $i18n.tc('adminCounterpartyCredits.grand-total') }}
            </td>
            <td class="text-end is-numeric d-none">${{ prettyPrice(totalBilateral) }}</td>
            <td class="text-end is-numeric">${{ prettyPrice(totalSFTs) }}</td>
            <td class="text-end is-numeric d-none">${{ prettyPrice(grandTotal) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { AppState } from '@/store/store';
import { DataTableHeader } from 'vuetify';
import i18n from '@/localisation/i18n';
import Decimal from 'decimal.js';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Watch } from 'vue-property-decorator';
import { SocketEvents } from '@/store/store';
import { throttle } from 'lodash';

const tableColumns: DataTableHeader[] = [
  {
    text: i18n.tc('adminCounterpartyCredits.name'),
    value: 'companyDisplay',
    class: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('adminCounterpartyCredits.Bilateral'),
    value: 'totalBilateral',
    class: 'text-end d-none',
    cellClass: 'is-numeric text-end d-none',
  },
  {
    text: i18n.tc('adminCounterpartyCredits.NSCC'),
    value: 'totalInSFT',
    class: 'text-end',
    cellClass: 'is-numeric text-end',
  },
  {
    text: i18n.tc('adminCounterpartyCredits.grand-total'),
    value: 'grandTotal',
    class: 'text-end d-none',
    cellClass: 'is-numeric text-end d-none',
  },
];

const zero = new Decimal(0);

interface Totals {
  bilateral: Decimal;
  sfts: Decimal;
}

export function newTotals(): Totals {
  return {
    bilateral: zero,
    sfts: zero,
  };
}

@Component({
  data: () => ({}),
  methods: {
    ...mapActions(['fetchTraderAdminCounterpartyCredits']),
  },
  computed: {
    ...mapState(['counterpartyCredits', 'socketEvents']),
  },
})
export default class TraderAdminCounterpartyCredit extends Vue {
  // store state
  protected readonly counterpartyCredits!: AppState['counterpartyCredits'];
  protected readonly socketEvents!: SocketEvents;

  // store actions
  protected fetchTraderAdminCounterpartyCredits!: () => Promise<void>;

  protected isLoading = true;
  protected tableColumns = tableColumns;
  protected throttledFetch = throttle(this.fetch, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  protected get totalBilateral(): Decimal {
    return this.totals.bilateral;
  }

  protected get totalSFTs(): Decimal {
    return this.totals.sfts;
  }

  protected get grandTotal(): Decimal {
    return this.totals.bilateral.add(this.totals.sfts);
  }

  protected get totals(): Totals {
    const t = newTotals();

    this.counterpartyCredits.forEach((counterparty) => {
      t.bilateral = t.bilateral.add(counterparty.totalBilateral);
      t.sfts = t.sfts.add(counterparty.totalInSFT);
    });

    return t;
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  @Watch('socketEvents.openLoans.brokerLoan')
  protected onSocketEvents(): void {
    void this.throttledFetch();
  }

  protected async fetch(): Promise<void> {
    this.isLoading = true;
    try {
      await this.fetchTraderAdminCounterpartyCredits();
    } catch (e) {
      this.$log.warn(e);
    } finally {
      this.isLoading = false;
    }
  }

  protected mounted(): void {
    void this.fetch();
  }

  protected prettyPrice(value: Decimal): string {
    return getPriceAsString(value, PRICE_PRECISION);
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  .aggregate {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }

  .last-row {
    td {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      position: sticky;
      bottom: 0;
      border-top: solid #393939 1px;
      color: rgba(255, 255, 255, 0.7);
      background-color: #1e1e1e;
      font-family: 'Roboto', sans-serif;
    }
  }
}
</style>
