<template>
  <v-card class="flex d-flex flex-column">
    <v-data-table
      v-model="selectedItems"
      class="d-flex flex flex-column"
      :custom-filter="searchableTableFilter"
      dense
      fixed-header
      :footer-props="{ itemsPerPageOptions: [10, 50, 100] }"
      :headers="tableHeaders"
      item-key="id"
      :items="formattedBorrowerOrders"
      :items-per-page="50"
      :search="search"
      sort-asc
      sort-by="equity.ticker"
    >
      <!-- Top bar above table  -->
      <template #top>
        <div class="d-flex flex-column align-start ml-3 mb-3 mr-3">
          <div class="d-flex justify-space-between container container--fluid px-0 py-0">
            <div class="d-flex">
              <v-select
                v-model="selectedBorrower"
                class="counterparties"
                clearable
                :items="counterparties"
                placeholder="All Borrowers"
              />
              <simple-equity-search
                v-model="selectedEquity"
                class="ml-8"
                clearable
                label="security"
              />
            </div>
            <!-- filter box -->
            <div class="d-flex col-md-3 ml-auto search">
              <v-text-field
                v-model="search"
                clearable
                dense
                label="Filter list"
                placeholder="filter..."
                prepend-inner-icon="mdi-magnify"
              />
            </div>
          </div>
        </div>
      </template>

      <!-- Table rows -->
      <template #[`item.isBorrowableDisplay`]="{ item }">
        <v-chip v-if="item.expiredAt" class="justify-center" color="warning" small>
          expired
        </v-chip>
        <v-chip v-else :class="item.isBorrowable ? 'status-enabled' : 'status-disabled'" small>
          {{ item.isBorrowableDisplay }}
        </v-chip>
      </template>

      <template #[`item.quantity`]="{ item }">
        <pretty-number :value="item.quantity" />
      </template>

      <template #[`item.rate`]="{ item }">
        <rate-output :rate="item.rate" />
      </template>

      <template #[`item.timeInForce`]="{ item }">
        <span>{{ $tc(`orders.timeInForce.options.${item.timeInForce}`) }}</span>
      </template>

      <template #[`item.isLendableDisplay`]="{ item }">
        <v-chip :class="item.isLendable ? 'status-enabled' : 'status-disabled'" small>
          {{ item.isLendableDisplay }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import {
  BrokerBorrowerOrderListDisplayItem,
  BrokerBorrowerOrderListItem,
} from '@/modules/broker-admin/types/borrower-orders';
import { Equity } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import { AppState } from '@/store/store';
import { searchableTableFilter } from '@/utils/helpers/searchable-fields';
import Vue from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapState } from 'vuex';

const tableHeaders: DataTableHeader[] = [
  {
    text: i18n.tc('Status'),
    value: 'isBorrowableDisplay',
    class: 'text-truncate row-icon status',
    cellClass: 'text-truncate row-icon status',
    align: 'center',
    width: '7rem',
  },
  {
    text: i18n.tc('Borrower'),
    value: 'borrowerDisplay',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: 200,
  },
  {
    text: i18n.tc('Ticker'),
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('CUSIP'),
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Available'),
    value: 'quantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('Limit'),
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('orders.timeInForce.title'),
    value: 'timeInForce',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
  },
  // empty column to control space and alignment
  {
    text: '',
    value: '',
    sortable: false,
    width: 100,
  },
  {
    text: i18n.tc('Order ID'),
    value: 'displayId',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: 200,
  },
];

@Component({
  components: {
    SimpleEquitySearch,
  },
  methods: {
    ...mapActions(['fetchBrokerBorrowerOrders']),
  },
  computed: {
    ...mapState(['brokerBorrowerOrders']),
  },
})
export default class BrokerBorrowOrdersList extends Vue {
  // store methods
  protected fetchBrokerBorrowerOrders!: () => Promise<void>;

  // store state
  protected brokerBorrowerOrders!: AppState['brokerBorrowerOrders'];

  protected tableHeaders = tableHeaders;

  protected selectedBorrower: null | string = null;
  protected selectedEquity: Equity | null = null;

  protected searchableTableFilter = searchableTableFilter;
  protected selectedItems: BrokerBorrowerOrderListItem[] = [];
  protected search = '';

  /**
   * Get a formatted version of borrower orders list
   */
  protected get formattedBorrowerOrders(): BrokerBorrowerOrderListDisplayItem[] {
    let filteredOrders =
      this.selectedBorrower === null
        ? this.brokerBorrowerOrders
        : this.brokerBorrowerOrders.filter((i) => i.borrowerDisplay === this.selectedBorrower);

    if (this.selectedEquity) {
      filteredOrders = filteredOrders.filter((i) => i.equity.id === this.selectedEquity?.id);
    }

    return filteredOrders.map((item) => {
      const isBorrowableDisplay = item.isBorrowable ? 'active' : 'disabled';

      return {
        ...item,
        isBorrowableDisplay,
      };
    });
  }

  protected get counterparties(): string[] {
    const counterparties = this.brokerBorrowerOrders
      .map((order) => order.borrowerDisplay)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => (a == b ? 0 : a > b ? 1 : -1));

    return counterparties;
  }

  protected mounted(): void {
    void this.fetchBrokerBorrowerOrders();
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  .selected-items-count {
    min-height: 2rem;

    small {
      font-size: 0.75rem;
    }
  }

  // remove extra margin on file input icon
  .v-btn .v-file-input .v-input__prepend-outer {
    margin: 0;
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }

    tr {
      .row-actions {
        opacity: 0.4;
        pointer-events: none;
        transition: opacity linear 0.15s;
      }

      &:hover .row-actions {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }

  // enhance status column
  th.row-icon.status {
    padding-left: 29px !important;
  }

  // @TODO use sass variables to store these colours
  .v-chip {
    &.status-enabled {
      color: #fff;
      background: #4caf50;
    }

    &.status-disabled {
      color: #fff;
      background: #7a7a7a;
    }
  }
}
</style>
