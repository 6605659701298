import { AuctionEquity } from '@/utils/helpers/rest';

const DEFAULT_EQUITY_DESCRIPTION_LIMIT = 60;

export function formatEquityDescription(
  equity: AuctionEquity,
  eqDescriptionLimit?: number
): string {
  eqDescriptionLimit = eqDescriptionLimit ?? DEFAULT_EQUITY_DESCRIPTION_LIMIT;
  const description = equity.name
    ? `${equity.name} ${equity.ticker} [${equity.cusip}]`
    : `${equity.ticker} [${equity.cusip}]`;
  const nTooLong = description.length - eqDescriptionLimit;
  return nTooLong > 0
    ? `${equity.name.slice(0, equity.name.length - nTooLong)}... ${equity.ticker} [${equity.cusip}]`
    : description;
}
