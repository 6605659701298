<template>
  <div>
    <sponsorship-position-dialog
      v-if="dialogSide"
      :side="dialogSide"
      @close-modal="dialogSide = null"
    />

    <sponsorship-upload-dialog
      v-if="uploadDialog !== null"
      :side="uploadDialog"
      @close-modal="uploadDialog = null"
    />

    <dashboard-panel no-collapse :title="panelTitle">
      <snapshot-manager
        :drivers="['url', 'sessionStorage']"
        :get-state-mutators="getStateMutators"
        :snapshot="snapshot"
        storage-key="snapshot-sponsorship-loans-list"
      >
        <open-loans-list
          :counterparty.sync="counterparty"
          :detail-loan.sync="detailLoan"
          :detail-loan-initial-tab.sync="detailLoanInitialTab"
          :equity.sync="equity"
          :is-sponsored="true"
          :options.sync="options"
          :show-all.sync="showAll"
          :side.sync="side"
          :tab.sync="tab"
        >
          <template #header>
            <aurora-btn-dropdown
              v-if="canBorrow"
              color="secondary"
              data-test="borrow-button"
              :disabled="!hasTraderUserRole"
              main-text="Sponsored Borrow"
              split
              timeframe="createLoans"
              @click="dialogSide = 'borrower'"
            >
              <v-list dense>
                <v-list-item @click="uploadDialog = 'borrower'">
                  <v-list-item-content>
                    <v-list-item-title>Upload Borrow Positions</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon dense small> mdi-upload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </aurora-btn-dropdown>

            <aurora-btn-dropdown
              v-if="canLend"
              color="secondary"
              data-test="lend-button"
              :disabled="!hasTraderUserRole"
              main-text="Sponsored Lend"
              split
              timeframe="createLoans"
              @click="dialogSide = 'lender'"
            >
              <v-list dense>
                <v-list-item @click="uploadDialog = 'lender'">
                  <v-list-item-content>
                    <v-list-item-title>Upload Lend Positions</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon dense small> mdi-upload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </aurora-btn-dropdown>
          </template>
        </open-loans-list>
      </snapshot-manager>
      <loan-details-dialog
        v-if="detailLoan"
        :initial-tab="detailLoanInitialTab"
        :loan-id="detailLoan.id"
        @close-modal="detailLoan = null"
      />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import OpenLoansList from '@/modules/open-loans/components/OpenLoansList.vue';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import SponsorshipPositionDialog from '@/modules/sponsorship/components/SponsorshipPositionDialog.vue';
import SponsorshipUploadDialog from '@/modules/sponsorship/components/SponsorshipUploadDialog.vue';
import { mapGetters, mapState } from 'vuex';
import i18n from '@/localisation/i18n';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { Equity } from '@/modules/common/types/api';
import { DataOptions } from 'vuetify';
import { OpenLoan } from '@/utils/api/loans';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';

const MAX_ITEMS_PER_PAGE = 50;

@Component({
  components: {
    DashboardPanel,
    OpenLoansList,
    SnapshotManager,
    LoanDetailsDialog,
    SponsorshipPositionDialog,
    SponsorshipUploadDialog,
  },
  computed: {
    ...mapState(['uxConfig']),
    ...mapGetters(['hasTraderUserRole', 'canBorrow', 'canLend']),
  },
})
export default class SponsorshipLoans extends Vue {
  protected tab: 'all' | 'recalled' | 'rerate' = 'all';
  protected counterparty: null | CompanyInfo = null;
  protected equity: Equity | null = null;
  protected side: 'lender' | 'borrower' | null = null;
  protected showAll = false;
  protected detailLoan: OpenLoan | null = null;
  protected detailLoanInitialTab = 'history';
  protected dialogSide: 'lender' | 'borrower' | null = null;
  protected uploadDialog: 'borrower' | 'lender' | null = null;

  // store state
  protected hasTraderUserRole!: boolean;
  protected canBorrow!: boolean;
  protected canLend!: boolean;

  protected readonly options: Pick<DataOptions, 'sortBy' | 'sortDesc' | 'page' | 'itemsPerPage'> = {
    sortBy: ['createdAt'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: MAX_ITEMS_PER_PAGE,
  };

  protected dialog = false;

  protected get panelTitle(): string {
    return i18n.t('sidebar.items.sponsoredLoans') as string;
  }

  protected get snapshot(): Record<string, string> {
    const snapshot: Record<string, string> = {
      tab: this.tab,
      showAll: this.showAll ? 'true' : 'false',
      sortDesc: this.options.sortDesc[0] ? 'true' : 'false',
      sortBy: this.options.sortBy[0],
      page: this.options.page.toString(),
      itemsPerPage: this.options.itemsPerPage.toString(),
    };
    if (this.side) {
      snapshot.side = this.side;
    }
    if (this.counterparty) {
      snapshot.counterpartyDisplayBoxId = this.counterparty.displayBoxId as string;
    }
    if (this.equity) {
      snapshot.cusip = this.equity.cusip;
    }

    return snapshot;
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      tab: (value) => {
        this.tab = value as 'all' | 'recalled' | 'rerate';
      },
      side: (value) => {
        this.side = value === 'lender' || value === 'borrower' ? value : null;
      },
      showAll: (value) => {
        this.showAll = value === 'true';
      },
      counterpartyDisplayBoxId: async (value) => {
        try {
          const res = await this.$api.userAccounts.fetchCompanyByDisplayBoxId(value);
          if (res) {
            this.counterparty = res;
          } else {
            throw new Error('Failed to fetch selected counterparty');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      cusip: async (value) => {
        try {
          const res = await this.$api.manualLoans.fetchEquityByCusip(value);
          if (res) {
            this.equity = res;
          } else {
            throw new Error('Failed to fetch selected equity');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      sortBy: (value) => {
        this.options.sortBy = [value];
      },
      sortDesc: (value) => {
        this.options.sortDesc = [value === 'true'];
      },
      page: (value) => {
        this.options.page = Math.max(parseInt(value), 1);
      },
      itemsPerPage: (value) => {
        const parsedValue = parseInt(value);
        this.options.itemsPerPage = parsedValue > 0 ? parsedValue : MAX_ITEMS_PER_PAGE;
      },
    };
  }
}
</script>

<style lang="scss"></style>
