import { ApiService } from '@/modules/common/services/api.service';
import Vue from 'vue';
import { Api } from '@/modules/common/types/api';
import { omit } from 'lodash-es';
import { ApiError } from '@/utils/errors';

export class SponsorshipApiService extends ApiService {
  private featureUrl = '/sponsorship';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.sponsorship = singleton;
    } else {
      v.prototype.$api = {
        sponsorship: singleton,
      };
    }
  }

  public async createLoans(
    basketResponse: Api.Sponsorship.BasketResponse
  ): Promise<Api.Sponsorship.NewLoanResponse> {
    const payload: Api.Sponsorship.NewLoanRequest = {
      side: basketResponse.side,
      items: basketResponse.entries.map((item) => {
        return {
          ...omit(item, ['equity', 'counterparty']),
          equityId: item.equity.id,
          counterpartyId: item.counterparty.companyId,
        };
      }),
    };
    const url = this.baseUrl + this.featureUrl;
    const { data } = await this.axios.post<Api.Sponsorship.NewLoanResponse>(url, payload);
    return data;
  }

  public async uploadLoans(
    request: Api.Sponsorship.UploadLoansRequest
  ): Promise<Api.Sponsorship.UploadLoansResult> {
    const url = this.baseUrl + this.featureUrl + '/basket-parse';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', request.file);
    formData.append('side', request.side);

    try {
      const { data } = await this.axios.post<Api.Sponsorship.UploadLoansResponse>(url, formData, {
        headers,
      });
      return {
        side: request.side,
        entries: data.items,
      };
    } catch (e) {
      const apiError = e as ApiError;
      const data = apiError.responseData;
      if (data) {
        data.side = request.side;
      }
      throw e;
    }
  }
}
