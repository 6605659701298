<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
         with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <v-card-text>
      <div class="pr-4 pl-2">
        <term-loans-contract-body-summary :contract="contract" />
        <v-divider class="mt-3" />
        <div class="table-container mt-4">
          <div>
            <v-data-table
              disable-filtering
              disable-pagination
              fixed-header
              :headers="tableHeaders"
              hide-default-footer
              item-key="id"
              :items="itemsWithChanges"
              must-sort
              :show-select="false"
              width="100%"
              v-on="$listeners"
            >
              <template #item="{ item }">
                <tr>
                  <td>
                    {{ item.ticker }}
                  </td>
                  <td>
                    {{ item.cusip }}
                  </td>
                  <td class="text-end">
                    {{ prettyNumber(item.newQuantity) }}
                  </td>
                  <td class="text-end">
                    <rate-output
                      :class="{ 'text-red': item.hasExceeded }"
                      :precision="2"
                      :rate="item.position"
                    />
                  </td>
                  <td class="text-end">
                    ${{ formatPrice(item.newQuantity * item.lastClosePrice) }}
                  </td>
                  <td class="text-end">
                    {{ prettyNumber(item.newQuantity - item.currentQuantity) }}
                  </td>
                  <td class="text-end">
                    <rate-output
                      :precision="2"
                      :rate="
                        item.position.minus(
                          getItemPosition(
                            item.lastClosePrice,
                            item.currentQuantity,
                            contract.currentValue
                          )
                        )
                      "
                    />
                  </td>
                  <td class="text-end">
                    {{ item.newQuantity - item.currentQuantity < 0 ? '-$' : '$'
                    }}{{
                      formatPrice(
                        item.lastClosePrice.times(item.newQuantity - item.currentQuantity).abs()
                      )
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>

        <term-loans-manage-contract-totals
          class="mt-8"
          :contract="contract"
          :new-total-value="newTotalValue"
        />
      </div>
      <v-row v-if="apiError">
        <v-col class="pa-0 px-1 col-6 offset-3 mt-8">
          <div class="error v-alert v-alert--dense text--primary text-body-2 text-center">
            {{ apiError }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="d-flex">
      <div class="d-flex flex-grow-1 justify-space-between align-end">
        <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="$emit('back')">
          Back
        </v-btn>
        <!-- @TODO: boolean to be replaced by some sort of reconciliation mechanism
        allowing users to keep editing the contract without having to wait for loans to settle -->
        <v-btn
          v-if="!contract.hasPendingLoans"
          class="mb-2"
          color="primary"
          :disabled="formStatus === 'submitting'"
          :loading="formStatus === 'submitting'"
          type="submit"
          @click="submitForm"
        >
          Update
        </v-btn>
        <v-tooltip v-else top>
          <template #activator="{ on }">
            <v-btn class="mb-2" color="grey darken-3" style="cursor: auto" v-on="on">
              Update
            </v-btn>
          </template>
          <button>Waiting for pending loans to settle</button>
        </v-tooltip>
      </div>
    </v-card-actions>
  </span>
</template>

<script lang="ts">
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Api, Equity } from '@/modules/common/types/api';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import TermLoansContractBodySummary from '@/modules/termloans/components/TermLoansContractBodySummary.vue';
import TermLoansContractFullSummary from '@/modules/termloans/components/TermLoansContractFullSummary.vue';
import TermLoansManageContractTotals from '@/modules/termloans/components/TermLoansManageContractTotals.vue';
import { getItemPosition } from '@/modules/termloans/helpers/termloans';
import { ApiError } from '@/utils/errors';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import Decimal from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';

const allTableHeaders: DataTableHeader[] = [
  {
    text: 'Ticker',
    value: 'equity.ticker',
    align: 'start',
    sortable: false,
  },
  {
    text: 'CUSIP',
    value: 'equity.cusip',
    align: 'start',
    sortable: false,
  },
  {
    text: 'New Quantity',
    value: 'newQuantity',
    align: 'end',
    sortable: false,
  },
  {
    text: 'New Position%',
    value: 'newPosition',
    align: 'end',
    sortable: false,
  },
  {
    text: 'New Value',
    value: 'newValue',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Quantity Change',
    value: 'quantityChange',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Position% Change',
    value: 'positionChange',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Value Change',
    value: 'valueChange',
    align: 'end',
    sortable: false,
  },
];

@Component({
  props: {
    items: {
      type: Array as PropType<Api.TermLoans.UpdatedItem[]>,
      required: true,
    },
    contract: {
      type: Object as PropType<Api.TermLoans.ContractSummary>,
      required: true,
    },
    newTotalValue: {
      type: Decimal,
      required: true,
    },
  },
  components: {
    TermLoansContractBodySummary,
    TermLoansContractFullSummary,
    TermLoansManageContractTotals,
  },
})
export default class TermLoansManageContractConfirmation extends Vue {
  // props
  protected readonly items!: Api.TermLoans.UpdatedItem[];
  protected readonly contract!: Api.TermLoans.ContractSummary;
  protected readonly newTotalValue!: Decimal;

  protected formStatus: DialogFormStatus = 'idle';
  protected apiError: string | null = null;
  protected getItemPosition = getItemPosition;

  protected get tableHeaders(): DataTableHeader[] {
    return allTableHeaders;
  }

  protected get itemsWithChanges(): Api.TermLoans.UpdatedItem[] {
    return this.items.filter((item) => item.newQuantity !== item.currentQuantity);
  }

  protected get remainingEquities(): Equity[] {
    if (!this.contract) {
      return [];
    }
    const existingCusips = this.items.map((item) => item.cusip);
    return this.contract.equities.filter((equity) => !existingCusips.includes(equity.cusip));
  }

  protected async submitForm(): Promise<void> {
    this.formStatus = 'submitting';

    try {
      const payload: Api.TermLoans.ContractManageLoansRequest = {
        loans: this.items.map<Api.TermLoans.ContractManageLoansRequestItem>((item) => {
          return {
            cusip: item.cusip,
            quantity: item.newQuantity,
          };
        }),
      };

      await this.$api.termLoans.manageLoans(this.contract.displayId, payload);

      this.$snackbar.show({
        color: 'primary',
        message: `The contract has been updated.`,
        timeout: 3000,
      });

      this.$emit('close-modal');
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiError = `Operation Failed: ${errorMessage}`;
    } finally {
      this.formStatus = 'idle';
    }
  }

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }

  protected prettyNumber(value: number): string {
    return formatPrettyNumber(value);
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  max-height: 30vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}

.text-red {
  color: #ff5252;
}
</style>
