<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <form class="form" novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('join-auction') }}</span>
          <v-spacer></v-spacer>
          <span class="text--primary headline-2">{{
            formatEquityDescription(auction.equity)
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- order details -->
            <v-row align="center">
              <v-col class="py-3" cols="4">
                {{ $t('direction') }}
              </v-col>
              <v-col class="py-3" cols="3">
                <v-radio-group v-model="ticket.direction" dense hide-details row>
                  <v-radio color="form-field" :label="$t('borrow')" :value="Borrow"></v-radio>
                  <v-radio color="form-field" :label="$t('lend')" :value="Lend"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="py-0" cols="5">
                <v-chip
                  v-if="rateToolTip"
                  class="ma-0"
                  color="form-tooltip"
                  label
                  text-color="white"
                >
                  <v-icon left> mdi-information</v-icon>
                  {{ rateToolTip }}
                </v-chip>
              </v-col>
            </v-row>

            <v-row align="end">
              <v-col class="py-3" cols="4"></v-col>
              <v-col class="py-3">
                <template v-for="(row, lineNo) in ticket.orders">
                  <v-row :key="row.rowID" align="center" dense>
                    <v-col cols="5">
                      <volume-input
                        :ref="`quantity-${row.rowID}`"
                        :label="$t('quantity')"
                        :step="1"
                        tabindex
                        :ticket="row"
                        :volume="row.quantity"
                        @changed-error="onQuantityError"
                        @changed-volume="onQuantityChange"
                      ></volume-input>
                    </v-col>
                    <v-col cols="5">
                      <rate-input
                        :ref="`rate-${row.rowID}`"
                        :label="$t('rate')"
                        :max-rate="clientConfig.maxRate"
                        :precision="RATE_PRECISION"
                        :rate="row.rate"
                        tabindex
                        :ticket="row"
                        @changed-error="onRateError"
                        @changed-rate="onRateChange"
                      ></rate-input>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip color="form-tooltip" top>
                        <template #activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-btn color="primary" icon x-small @click="onCopyOrder(row)">
                              <v-icon> mdi-plus-circle </v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>{{ $t('add') }}</span>
                      </v-tooltip>

                      <v-tooltip v-if="ticket.orders.length > 1" color="form-tooltip" top>
                        <template #activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-btn color="primary" icon x-small @click="onDeleteOrder(row)">
                              <v-icon> mdi-trash-can-outline </v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>{{ $t('delete') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        v-if="ticket.orders.length > 1 && lineNo === 0"
                        class="pl-10"
                        color="form-tooltip"
                        top
                      >
                        <template #activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-btn
                              :class="['ml-2', { 'is-disabled': stackIsSorted }]"
                              color="primary"
                              :disabled="stackIsSorted"
                              icon
                              x-small
                              @click="onSortOrders"
                            >
                              <v-icon>
                                {{ isLend() ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}
                              </v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>{{ $t('sortOrders.tip') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>

                <!-- order errors -->
                <v-row v-if="quantityError !== '' || rateError !== ''" align="center">
                  <v-col class="pa-0 pr-1" cols="5">
                    <v-alert v-if="quantityError !== ''" class="py-1" color="error" dense text
                      >{{ quantityError }}
                    </v-alert>
                  </v-col>
                  <v-col class="pa-0 pl-1" cols="5">
                    <v-alert v-if="rateError !== ''" class="py-1" color="error" dense text
                      >{{ rateError }}
                    </v-alert>
                  </v-col>
                </v-row>

                <!-- order summary -->
                <v-row v-if="ticket.orders.length > 1">
                  <v-col>
                    {{ $t('total-quantity') }}: <pretty-number :value="totalQuantity" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- order notes, free text -->
            <v-row align="start">
              <v-col class="py-4" cols="4">
                <v-tooltip color="form-tooltip" max-width="600px" right>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <span>{{ $t('notes') }}</span>
                      <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>{{ $t('privateNotes.tip') }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="py-0">
                <v-textarea
                  v-model="ticket.notes"
                  color="form-field"
                  counter
                  dense
                  :hint="$t('privateNotes.label')"
                  persistent-hint
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>

          <auction-summary :auction="auction"></auction-summary>

          <countdown
            class="mt-8 px-8"
            expired-message="joinAuctionExpired"
            info-message="joinAuctionBefore"
            :when="auction.endsAt"
            @expired-countdown="auctionExpired"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="closeModalDialog">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="clearForm">
            {{ $t('clearOrderButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="isExpired"
            min-width="120"
            type="submit"
            @click="onSubmit"
          >
            <v-icon left>mdi-arrow-right-bold</v-icon>
            {{ $t('verifyOrderButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { formatDate } from '@/utils/helpers/dates';
import {
  formatDecimalAsCurrencyString,
  getCurrencySymbol,
  getVolumeAsInteger,
} from '@/utils/helpers/auction-numbers';

import {
  AuctionEquity,
  BespokeAuction,
  ClientConfig,
  Direction,
  Order,
  OrderTicket,
  copyOrderTicket,
  createBlankOrder,
} from '@/utils/helpers/rest';
import VolumeInput from '@/modules/common/components/format-volume/VolumeInput.vue';
import PriceInput from '@/modules/common/components/format-price/PriceInput.vue';
import AuctionSummary from '@/modules/auction/components/AuctionSummary.vue';
import Countdown from '@/modules/common/components/Countdown.vue';
import { FormValidator } from '@/utils/helpers/input-form';
import i18n from '@/localisation/i18n';
import Decimal from 'decimal.js';
import {
  PRICE_PRECISION,
  RATE_PRECISION,
  RATE_TICKSIZE,
} from '@/modules/common/constants/precision';
import RateInput from '@/modules/common/components/format-rate/RateInput.vue';
import { formatEquityDescription } from '@/utils/helpers/equities';

@Component({
  components: {
    RateInput,
    AuctionSummary,
    VolumeInput,
    PriceInput,
    Countdown,
  },
  filters: {
    formatDate,
  },
  data: () => {
    return {
      RATE_PRECISION: RATE_PRECISION,
      Borrow: Direction.Borrow,
      Lend: Direction.Lend,
      totalQuantity: 0,
      quantityError: '',
      rateError: '',
      stackIsSorted: false,
    };
  },
  props: {
    auction: Object,
    ticket: Object,
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class AuctionOrderDialog extends Vue {
  public $refs!: {
    // dynamically build with quantity and price ref for every row
    [key: `quantity-${number}`]: VolumeInput;
  };

  // store state refs
  private clientConfig!: ClientConfig;

  private processing = false;

  private auction!: BespokeAuction;
  private equity!: AuctionEquity;
  private ticket!: OrderTicket;
  private priceCurrencySymbol = '';
  private rateToolTip = '';
  private privateNotesToolTip = '';
  private isExpired = false;

  // stacked order
  private lastRowId = 0;
  private totalQuantity!: number;
  private totalValue!: Decimal;
  private quantityError!: string;
  private rateError!: string;
  private stackIsSorted!: boolean;

  private eqDescriptionLimit = 60;

  @Watch('order.direction')
  private onChangedDirection(): void {
    this.onSortOrders();
  }

  private beforeMount() {
    this.equity = this.auction.equity;
    if (this.equity) {
      this.priceCurrencySymbol = getCurrencySymbol(this.equity.currencyCode);
      this.rateToolTip = `Last Price: ${formatDecimalAsCurrencyString(
        this.equity.currencyCode,
        this.equity.closePrice,
        PRICE_PRECISION
      )}`;
    }

    this.updateTotals();
  }

  private mounted() {
    this.privateNotesToolTip = i18n.tc('privateNotes.tip');

    this.lastRowId = this.ticket.orders.reduce(
      (max, order) => (order.rowID > max ? order.rowID : max),
      0
    );
    this.stackIsSorted = this.isOrdersSorted();
    this.updateTotals();

    // don't focus until the whole view has been rendered, quantity is a child component
    this.$nextTick(function () {
      this.$refs['quantity-0'][0].$el.focus();
    });
  }

  // clone current row
  private onCopyOrder(row: Order) {
    const newRow = createBlankOrder(0, row.rate);
    newRow.rowID = ++this.lastRowId;

    // use splice to insert the new row as this will not disturb the sort order
    const idx = this.ticket.orders.indexOf(row) + 1; // append after current
    this.ticket.orders.splice(idx, 0, newRow);
    this.updateTotals();

    // set focus on the new row (after it has been rendered)
    Vue.nextTick(() => {
      this.$refs[`quantity-${this.lastRowId}`][0].$el.focus();
    });
  }

  private onDeleteOrder(row: Order) {
    this.ticket.orders = this.ticket.orders.filter((e) => {
      return e.rowID !== row.rowID;
    });
    this.stackIsSorted = this.isOrdersSorted();
    this.updateTotals();
    this.quantityError = this.reduceQuantityErrors();
    this.rateError = this.reduceRateErrors();
  }

  private onSortOrders(): void {
    this.ticket.orders = this.sortOrders(this.ticket.orders);
    this.stackIsSorted = true;
  }

  private sortOrders(orders: Order[]): Order[] {
    return orders.sort((o1, o2) =>
      this.isLend() ? o1.rate.comparedTo(o2.rate) : o2.rate.comparedTo(o1.rate)
    );
  }

  private isOrdersSorted(): boolean {
    // sort a clone of the ticket.orders and compare with the original
    return this.sortOrders(this.ticket.orders.slice(0)).every(
      (el, i) => el === this.ticket.orders[i]
    );
  }

  private isLend(): boolean {
    return this.ticket.direction === Direction.Lend;
  }

  private auctionExpired(): void {
    // auction cut-off expired, can't join anymore
    this.isExpired = true;
  }

  private onQuantityChange(quantity: number, row: Order): void {
    row.quantity = quantity;
    row.quantityError = '';

    this.updateTotals();
    this.quantityError = this.reduceQuantityErrors();
  }

  private onQuantityError(errorStr: string, row: Order): void {
    row.quantityError = errorStr;
    this.quantityError = errorStr;
  }

  private updateTotals(): void {
    this.totalQuantity = this.ticket.orders.reduce((sum, r) => sum + r.quantity, 0);
    this.totalValue = this.ticket.orders.reduce(
      (sum, r) => sum.add(r.rate.mul(r.quantity)),
      new Decimal(0)
    );
  }

  private reduceQuantityErrors(): string {
    return this.ticket.orders.reduce((err, r) => {
      if (r.quantityError && r.quantityError !== '') {
        return r.quantityError;
      }
      return err;
    }, '');
  }

  // the order that is published is the most attractive one (price-wise)
  private onRateChange(rate: Decimal, row: Order): void {
    row.rate = rate;
    row.rateError = '';

    this.stackIsSorted = this.isOrdersSorted();
    this.rateError = this.reduceRateErrors();
  }

  private onRateError(errorStr: string, row: Order): void {
    row.rateError = errorStr;
    this.rateError = errorStr;
  }

  private reduceRateErrors(): string {
    return this.ticket.orders.reduce((err, r) => {
      if (r.rateError && r.rateError !== '') {
        return r.rateError;
      }
      return err;
    }, '');
  }

  private formatEquityDescription(equity: AuctionEquity): string {
    return formatEquityDescription(equity, this.eqDescriptionLimit);
  }

  private onSubmit(): void {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      if (this.ticket.orders.length === 0) {
        throw new Error('Submit at least 1 order!');
      }

      // check all orders before submitting anything
      this.quantityError = '';
      this.rateError = '';
      const canSubmit = this.ticket.orders.every((row) => {
        // check if quantity and price are entered
        const errors = new FormValidator(row, ['quantity', 'rate']).check();

        // report quantity error
        this.quantityError = errors.quantity || row.quantityError;
        if (this.quantityError) {
          this.$refs[`quantity-${row.rowID}`][0].$el.focus();
          return false;
        }

        // report price error
        this.rateError = errors.rate || row.rateError;

        // also check that the price is a multiple of the ticksize
        if (!this.rateError) {
          if (row.rate.greaterThan(this.clientConfig.maxRate)) {
            errors.rate = i18n.t('max.rate', {
              max: new Decimal(this.clientConfig.maxRate).toFixed(RATE_PRECISION),
            }) as string;
          } else if (this.auction.equity) {
            // check that the price is a multiple of the ticksize
            if (!row.rate.mod(RATE_TICKSIZE).eq(0)) {
              errors.rate = this.$tc('ticksize.rate');
            }
          }
        }

        if (this.rateError) {
          this.$refs[`rate-${row.rowID}`][0].$el.focus();
          return false;
        }

        // this order row is fine
        return true;
      });

      // bail out on hard errors
      if (!canSubmit) {
        return;
      }

      // check for suspicious limit prices
      //   if found: confirm or review order
      this.$emit('toggle-can-cancel', false);

      this.submitOrder();
    } finally {
      this.processing = false;
    }
  }

  private submitOrder(): void {
    this.$emit(
      'stage-ticket',
      copyOrderTicket(
        this.ticket,
        this.ticket.orders.map(
          (order) =>
            ({
              quantity: getVolumeAsInteger(order.quantity),
              rate: order.rate,
            }) as Order
        )
      )
    );
  }

  private clearForm(): void {
    this.$emit('clear-ticket');
  }

  private closeModalDialog(): void {
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss"></style>
