<template>
  <v-text-field
    ref="rate"
    v-model="rateStr"
    :append-icon="appendIcon"
    :class="['auction-number-input', { 'text-danger': rateErr }]"
    color="form-field"
    :disabled="disabled"
    :error-messages="errorMessages"
    :label="label"
    :suffix="'%'"
    type="text"
    @blur="
      formatInput();
      $emit('blur', $event);
    "
    @focus="handleFocus"
    @keyup.exact="handleKeyup"
  />
</template>

<script lang="ts">
/**
 * RateInput is a small component for editing and formatting rates
 */
import { Component, Vue, Watch } from 'vue-property-decorator';
import i18n from '@/localisation/i18n';
import { OrderTicket } from '@/utils/helpers/rest';
import Decimal from 'decimal.js';

@Component({
  props: {
    disabled: Boolean,
    label: String,
    ticket: Object,
    rate: Decimal,
    precision: Number,
    maxRate: Number,
    appendIcon: String,
    errorMessages: Array,
  },
})
export default class RateInput extends Vue {
  protected readonly errorMessages?: string[];

  private reUnformattedRate = /,/gi;
  private reBeautifiedRate = /[^\-0-9,.]/gi;

  private ticket!: OrderTicket;
  private rate!: number;
  private maxRate?: number;
  private rateStr!: string;
  private rateErr!: string;

  private precision!: number;

  // rate changed by parent component; reformat display string
  @Watch('rate')
  protected onRate(newRate: Decimal): void {
    this.rateStr = newRate.toFixed(this.precision);
  }

  @Watch('rateErr')
  protected onError(newError: string): void {
    this.$emit('changed-error', newError, this.ticket);
  }

  protected data(): { rateStr: string; rateErr: string } {
    return {
      rateStr: this.rate.toFixed(this.precision),
      rateErr: '',
    };
  }

  protected handleFocus(): void {
    // remove thousand separators from the input
    this.rateStr = this.rateStr.replace(this.reUnformattedRate, '');
  }

  protected handleKeyup(): void {
    this.rateErr = '';
    const tmpRate = this.rateStr.replace(this.reBeautifiedRate, '');
    if (tmpRate !== this.rateStr) {
      this.rateErr = i18n.tc('invalid.rate');
      return;
    }
    if (this.rateStr === '') {
      this.rateErr = i18n.t('empty.rate') as string;
      return;
    }

    try {
      const newRate = new Decimal(this.rateStr);
      this.$emit('changed-rate', newRate, this.ticket);
    } catch (e) {
      // ignore
    }
  }

  protected formatInput(): void {
    // don't format the rate if there is an error, giving the user the change to correct
    if (this.rateErr !== '') {
      return;
    }

    let newRate: Decimal;
    try {
      newRate = new Decimal(this.rateStr);
    } catch (e) {
      this.rateErr = i18n.t('invalid.rate') as string;
      return;
    }

    if (this.maxRate !== undefined) {
      if (newRate.abs().greaterThan(this.maxRate)) {
        this.rateErr = i18n.t('max.rate', {
          max: new Decimal(this.maxRate).toFixed(this.precision),
        }) as string;
      }
    }

    this.rateStr = newRate.toFixed(this.precision);
    this.$emit('changed-rate', newRate, this.ticket);
    this.$emit('input', this.rate);
  }
}
</script>
