<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    data-test-watchlist-form="dialog"
    max-width="600"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <uploader
      v-if="step === 'upload'"
      :execute="(file) => $api.marketplace.uploadWatchlistSecurities(file)"
      :upload-method="uploadMethod"
      @close-modal="step = 'form'"
      @parse-errors="goToParseErrors($event)"
      @upload="goToForm($event.instruments)"
      @upload-method="uploadMethod = $event"
    >
      <template #title>Upload Watchlist Securities</template>
      <template #description>
        <div>Expected column:</div>
        <div>Ticker or CUSIP</div>
      </template>
    </uploader>

    <upload-parse-errors
      v-if="step === 'parse-errors'"
      :errors="errors"
      :headers="[
        { text: 'Row', value: 'row' },
        { text: 'Error', value: 'errMsg' },
      ]"
      :upload-method="uploadMethod"
      @back="step = 'upload'"
      @parse-errors="goToForm($event)"
    >
      <template #title>Failed</template>
      <template #item="{ item }">
        <tr>
          <td>{{ item.row }}</td>
          <td>{{ item.errormsg }}</td>
        </tr>
      </template>
    </upload-parse-errors>

    <v-form v-if="step === 'form'" novalidate @submit.prevent>
      <v-card class="d-flex flex-column">
        <v-card-title>
          <span class="headline">{{ displayId ? 'Edit' : 'Create' }} Watchlist</span>
          <v-btn class="close-icon" icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field
              v-model="name"
              :autofocus="displayId == null"
              class="mt-n4"
              :error-messages="errorMsgs['name']"
              label="Name"
            />

            <multiple-equity-selector :equities.sync="equities" />
          </v-container>

          <v-row v-if="apiError">
            <v-col class="pa-0 px-1 col-6 offset-3 mt-4">
              <div class="error v-alert v-alert--dense text--primary text-body-2 text-center">
                {{ apiError }}
              </div>
            </v-col>
          </v-row>

          <v-container v-else class="mt-4 text-right">
            <div v-if="equities.length">
              {{ equities.length }} {{ equities.length === 1 ? 'equity' : 'equities' }} in list
            </div>
            <div v-else>No securities have been added yet.</div>
          </v-container>
        </v-card-text>

        <v-card-actions v-shortkey="['enter']" class="d-flex py-4 mt-n6" @shortkey="submitForm()">
          <div class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="closeDialog()">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              data-test-watchlist-form="submit"
              :disabled="formStatus !== 'idle'"
              :loading="formStatus === 'submitting'"
              @click="submitForm()"
            >
              {{ displayId ? 'Save' : 'Create' }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { CustomRule, minLength, required } from 'vuelidate/lib/validators';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import { Api, Equity } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors';
import { PropType } from 'vue';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import MultipleEquitySelector from '@/modules/common/components/MultipleEquitySelector.vue';
import Uploader from '@/modules/common/components/Uploader.vue';
import UploadParseErrors from '@/modules/common/components/UploadParseErrors.vue';
import type { UploadMethod } from '@/modules/common/types/upload';

const isEquityUnique =
  (): CustomRule => (equity: Equity | null, vm: MarketplaceTopOfBookWatchlistForm) => {
    if (equity == null) {
      return true;
    }
    return !vm.equities.map((i) => i.cusip).includes(equity?.cusip);
  };

interface FormErrors {
  name: string[];
}

@Component({
  props: {
    // determins if form is in edit or create mode
    displayId: String as PropType<string | null>,
    initialStep: String as PropType<'form' | 'upload'>,
  },
  components: {
    SimpleEquitySearch,
    MultipleEquitySelector,
    Uploader,
    UploadParseErrors,
  },
  mixins: [validationMixin],
  validations: function (this: MarketplaceTopOfBookWatchlistForm) {
    return {
      name: {
        required,
        minLength: minLength(2),
      },
      equity: { required, isUnique: isEquityUnique() },
    };
  },
})
export default class MarketplaceTopOfBookWatchlistForm extends mixins(validationMixin) {
  // "public" because "isEquityUnique" needs to access it
  public equities: Equity[] = [];

  // props
  protected readonly displayId!: string | null;
  protected readonly initialStep!: 'form' | 'upload';

  protected name: string | null = null;
  protected formStatus: DialogFormStatus = 'idle';
  protected apiError: string | null = null;
  protected step: 'form' | 'upload' | 'parse-errors' = 'form';
  protected uploadMethod: UploadMethod | null = null;
  protected errors: Api.Marketplace.TopOfBookWatchlistSecuritiesUploadErrorItem[] = [];

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      name: [],
    };

    if (this.$v.name.$dirty) {
      if (!this.$v.name.required) errors.name.push('please enter a name.');
      if (!this.$v.name.minLength) errors.name.push('must have at least 2 characters.');
    }

    return errors;
  }

  protected async created(): Promise<void> {
    if (this.displayId === null) {
      this.step = this.initialStep;
      return;
    }

    // edit mode: fetch details and populate form
    try {
      const res = await this.$api.marketplace.fetchWatchlistWithInstruments(this.displayId);
      this.name = res.name;
      this.equities = res.instruments;
    } catch (err) {
      this.apiError = (err as ApiError).message;
    }
  }

  protected async submitForm(): Promise<void> {
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }

    this.formStatus = 'submitting';

    try {
      const payload = {
        name: this.name as string,
        instruments: this.equities.map((e) => e.cusip),
        shared: false,
      };

      if (this.displayId) {
        await this.$api.marketplace.updateWatchlist({ displayId: this.displayId, payload });
      } else {
        await this.$api.marketplace.createWatchlist(payload);
      }

      this.$snackbar.confirm(`Watchlist successfully ${this.displayId ? 'updated' : 'created'}.`);
      this.$emit('refresh-watchlists');
      this.closeDialog();
    } catch (err) {
      this.apiError = i18nServerMessage(err as ApiError);
      this.formStatus = 'idle';
    }
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.apiError = null;
    // we only need to validate the name field here
    // equity validation is handled as the user adds equities
    this.$v.name.$touch();
    return !this.$v.$anyError;
  }

  protected goToForm(equities: Equity[]): void {
    this.equities = equities;
    this.step = 'form';
  }

  protected goToParseErrors(
    errors: Api.Marketplace.TopOfBookWatchlistSecuritiesUploadErrorItem[]
  ): void {
    this.errors = errors;
    this.step = 'parse-errors';
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}
</style>
