<template>
  <v-card class="flex d-flex flex-column">
    <!-- edit user popup -->
    <trader-user-dialog
      v-if="dialogOptions.isActive"
      :admin-count="dialogOptions.adminCount"
      :crud-action="dialogOptions.crudAction"
      :title="dialogOptions.title"
      :user="dialogBuffer"
      @close-modal="closeModal"
    ></trader-user-dialog>

    <v-card-title>
      <span class="headline">{{ $t('adminSelectUsers.title') }}</span>
    </v-card-title>
    <v-card-subtitle>
      <span class="text--secondary headline-2">{{ $t('adminSelectUsers.description') }}</span>
    </v-card-subtitle>
    <v-card-text>
      <!-- add user account -->
      <v-btn class="ma-6" color="primary" @click="createUser">
        <v-icon left> mdi-plus</v-icon>
        {{ $t('adminAddUser') }}
      </v-btn>

      <!-- search bar -->
      <v-text-field
        v-model="tableSearch"
        clearable
        :label="$t('adminSearchUser')"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>

      <v-data-table
        v-if="companyUsers"
        class="elevation-0 pb-8"
        dense
        disable-pagination
        fixed-header
        :headers="tableColumns"
        height="100%"
        hide-default-footer
        :items="companyUsers"
        :no-data-text="$tc('no users', companyUsers.length, { count: companyUsers.length })"
        :search="tableSearch"
        sort-by="name"
      >
        <!-- trading allowed for this user -->
        <template #[`item.tradingPermissions`]="{ item }">
          <template v-if="item.tradingPermissions != null"
            >{{ $t(`tradingPermissions.options.${item.tradingPermissions}`) }}
          </template>
          <template v-else>{{ $t(`tradingPermissions.inherit`) }}</template>
        </template>

        <template #[`item.userRole`]="{ item }">
          <template v-if="item.accountStatus === 'pending-approval-for-roles'">
            {{ $t(item.roles[0]) }} &#8674; {{ $t(item.rolesPendingApproval[0]) }}
          </template>
          <template v-else>
            {{ $t(item.roles[0]) }}
          </template>
        </template>

        <!-- trading allowed for this user -->
        <template #[`item.accountStatus`]="{ item }">
          {{ $t(`userAccounts.status.${item.accountStatus}`) }}
        </template>

        <template #[`item.tfaIsEnabled`]="{ item }">
          <v-icon v-if="item.tfaIsEnabled"> mdi-two-factor-authentication</v-icon>
        </template>

        <!-- risk limit icons -->
        <template #[`item.riskLimits`]="{ item }">
          <v-tooltip color="secondary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon small>{{ orderSoftLimitIcon(item) }}</v-icon>
              </span>
            </template>
            <span>{{ orderSoftLimitTooltip(item) }}</span>
          </v-tooltip>
          <span>&nbsp;</span>
          <v-tooltip color="secondary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon small>{{ orderHardLimitIcon(item) }} </v-icon>
              </span>
            </template>
            <span>{{ orderHardLimitTooltip(item) }}</span>
          </v-tooltip>
        </template>

        <!-- action buttons -->
        <template #[`item.actions`]="{ item }">
          <v-tooltip color="primary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="ma-1" color="primary" icon x-small @click="editUser(item)">
                  <v-icon> mdi-account-edit </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('adminEditUser') }}</span>
          </v-tooltip>

          <v-tooltip v-if="item.disabledAt === null" color="primary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="ma-1" color="primary" icon x-small @click="disableUser(item)">
                  <v-icon> mdi-account-off </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('adminDisableUser') }}</span>
          </v-tooltip>
          <v-tooltip v-else color="primary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="ma-1" color="primary" icon x-small @click="enableUser(item)">
                  <v-icon> mdi-account-check </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('adminEnableUser') }}</span>
          </v-tooltip>

          <v-tooltip color="primary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="ma-1" color="primary" icon x-small @click="deleteUser(item)">
                  <v-icon> mdi-account-remove </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('adminDeleteUser') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import i18n from '@/localisation/i18n';
import TraderUserDialog from '@/modules/trader-admin/components/TraderUserDialog.vue';
import PermissionValidator, { Role } from '@/utils/helpers/permissions';
import { RestOperation } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { AppState } from '@/store/store';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';
import Decimal from 'decimal.js';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { DataTableHeader } from 'vuetify';

const defaultUser = {
  id: 0,
  companyID: 0,
  name: '',
  emailAddress: '',
  roles: [] as string[],
  tradingPermissions: null, // inherit from company
  orderSoftLimit: null,
  orderHardLimit: null,
  companyTradingPermissions: null,
};

const tableColumns: DataTableHeader[] = [
  { text: i18n.tc('userName'), value: 'name', class: 'text-no-wrap text-truncate' },
  { text: i18n.tc('companyName'), value: 'companyName', class: 'text-no-wrap text-truncate' },
  { text: i18n.tc('userEmail'), value: 'emailAddress', class: 'text-no-wrap text-truncate' },
  {
    text: i18n.tc('userRole'),
    value: 'userRole',
    class: 'text-no-wrap text-truncate',
    align: 'start',
    sortable: false,
  },
  {
    text: i18n.tc('tradingPermissions.title'),
    value: 'tradingPermissions',
    class: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('userAccounts.status.title'),
    value: 'accountStatus',
    class: 'text-no-wrap text-truncate',
    align: 'start',
    sortable: false,
  },
  {
    text: i18n.tc('2FA'),
    value: 'tfaIsEnabled',
    align: 'end',
    class: 'text-no-wrap text-truncate',
    sortable: false,
  },
  {
    text: i18n.tc('userAccounts.riskLimits.title'),
    value: 'riskLimits',
    align: 'center',
    sortable: false,
  },
  { text: '', value: 'actions', sortable: false },
];

@Component({
  components: {
    TraderUserDialog: TraderUserDialog,
  },
  props: {
    userFilter: String,
  },
  methods: {
    ...mapActions([
      'fetchTraderAdminUsers',
      'deleteTraderAdminUser',
      'disableTraderAdminUser',
      'enableTraderAdminUser',
    ]),
  },
  computed: {
    ...mapState(['loginState', 'companyUsers']),
  },
})
export default class TraderUsers extends Vue {
  // Props
  protected readonly userFilter?: string;

  // store state
  protected readonly loginState!: NonNullableAll<AppState['loginState']>;
  protected readonly companyUsers!: AppState['companyUsers'];
  // store actions
  protected readonly fetchTraderAdminUsers!: () => void;
  protected readonly deleteTraderAdminUser!: (user: UserAccount) => void;
  protected readonly disableTraderAdminUser!: (user: UserAccount) => void;
  protected readonly enableTraderAdminUser!: (user: UserAccount) => void;

  protected tableSearch = '';
  protected tableColumns = tableColumns;

  protected addBuffer: Partial<NullableAll<UserAccount>> | null = null;
  protected dialogBuffer: Partial<NullableAll<UserAccount>> | null = null;
  protected dialogOptions = {
    isActive: false,
    crudAction: RestOperation.Noop,
    title: 'User',
    adminCount: 0,
    cancelOptions: ['escape', 'outside'], // no 'X' for real FORMs
  };

  @Watch('userFilter')
  protected onChangeFilter(newFilter: string): void {
    this.tableSearch = newFilter;
  }

  protected async mounted(): Promise<void> {
    try {
      await this.fetchTraderAdminUsers();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected orderSoftLimitIcon(user: UserAccount): string {
    return user.orderSoftLimit
      ? 'mdi-account'
      : user.defaultOrderSoftLimit
        ? 'mdi-domain'
        : 'mdi-set-none';
  }

  protected orderSoftLimitTooltip(user: UserAccount): string {
    const riskLimit = user.orderSoftLimit
      ? user.orderSoftLimit
      : user.defaultOrderSoftLimit
        ? user.defaultOrderSoftLimit
        : new Decimal(0);
    const riskChoice = user.orderSoftLimit ? 1 : user.defaultOrderSoftLimit ? 2 : 0;

    return i18n.tc('userAccounts.riskLimits.softLimit', riskChoice, {
      name: user.name,
      limit: getPriceAsString(riskLimit, 0),
    });
  }

  protected orderHardLimitIcon(user: UserAccount): string {
    return user.orderHardLimit
      ? 'mdi-account'
      : user.defaultOrderHardLimit
        ? 'mdi-domain'
        : 'mdi-set-none';
  }

  protected orderHardLimitTooltip(user: UserAccount): string {
    const riskLimit = user.orderHardLimit
      ? user.orderHardLimit
      : user.defaultOrderHardLimit
        ? user.defaultOrderHardLimit
        : new Decimal(0);
    const riskChoice = user.orderHardLimit ? 1 : user.defaultOrderHardLimit ? 2 : 0;

    return i18n.tc('userAccounts.riskLimits.hardLimit', riskChoice, {
      name: user.name,
      limit: getPriceAsString(riskLimit, 0),
    });
  }

  protected createUser(): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Create;
    this.dialogOptions.title = 'Add Company User';
    this.dialogOptions.adminCount = this.countAdministrators();

    // addBuffer is a 2nd buffer behind the dialog so that the admin
    // can escape from `Create New User` and return with partially filled record
    // browsing the user list will not destroy the partially filled record
    if (this.addBuffer === null) {
      this.addBuffer = cloneDeep(defaultUser);
    }
    this.dialogBuffer = this.addBuffer;

    // managing users of my company only!
    this.addBuffer.companyID = this.loginState.user.companyID;
    this.addBuffer.companyTradingPermissions = this.loginState.user.companyTradingPermissions;
  }

  protected editUser(rowUser: UserAccount): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Update;
    this.dialogOptions.title = 'Update User Profile';
    this.dialogOptions.adminCount = this.countAdministrators();
    // do not modify the row while typing
    this.dialogBuffer = cloneDeep(rowUser);
  }

  protected closeModal(): void {
    // wipe user data
    this.dialogOptions.isActive = false;
    this.dialogOptions.crudAction = RestOperation.Noop;
    this.dialogOptions.title = '';
    this.addBuffer = null;
    this.dialogBuffer = null;
  }

  protected deleteUser(rowUser: UserAccount): void {
    // can't delete own account if you are the last admin
    if (rowUser.id === this.loginState.user.id && this.countAdministrators() === 1) {
      this.$dialog.alert({
        title: i18n.t('adminDeleteUser'),
        icon: 'mdi-alert',
        message: i18n.t('adminDeleteLastAdminAccount', {
          userName: rowUser.name,
          companyName: rowUser.companyName,
          emailAddress: rowUser.emailAddress,
        }),
      });
      return;
    }

    this.$dialog.ask({
      title: i18n.t('adminDeleteUser'),
      color: 'error',
      icon: 'mdi-alert',
      message: i18n.t('adminDeleteUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminDeleteUser'),
      onAccept: () => this.doDeleteUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected disableUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminDisableUser'),
      color: 'warning',
      icon: 'mdi-account-off',
      message: i18n.t('adminDisableUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminDisableUser'),
      onAccept: () => this.doDisableUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected enableUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminEnableUser'),
      color: 'info',
      icon: 'mdi-account-check',
      message: i18n.t('adminEnableUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminEnableUser'),
      onAccept: () => this.doEnableUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  private async doDeleteUser(rowUser: UserAccount) {
    try {
      await this.deleteTraderAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminDeletedUserMessage'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doDisableUser(rowUser: UserAccount) {
    try {
      await this.disableTraderAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminDisabledUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doEnableUser(rowUser: UserAccount) {
    try {
      await this.enableTraderAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminEnabledUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private countAdministrators(): number {
    return this.companyUsers.reduce((count: number, user: UserAccount) => {
      const validator = new PermissionValidator(user.roles);
      const hasPermission = validator.hasPermission(Role.TraderAdmin);
      return hasPermission ? count + 1 : count;
    }, 0);
  }
}
</script>

<style lang="scss" scoped></style>
