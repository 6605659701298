import Vue from 'vue';
import { Api } from '@/modules/common/types/api';
import { ApiService } from '@/modules/common/services/api.service';
import { Decimal } from 'decimal.js';
import { TimeInForce } from '@/utils/helpers/tif_options';

export class BrokerAdminApiService extends ApiService {
  private featureUrl = '/broker-user/monitor';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.brokerAdmin = singleton;
    } else {
      v.prototype.$api = {
        brokerAdmin: singleton,
      };
    }
  }

  /**
   * Get a paginated list of all Lender Orders
   */
  public async getBrokerLenderOrdersList(
    offset = 0,
    limit = 100,
    dir: 'asc' | 'desc' = 'asc',
    sort?: string,
    filter?: string
  ): Promise<Api.BrokerLenderOrders.BrokerLenderOrderListResponse> {
    const url = this.baseUrl + this.featureUrl + `/lender/orders`;
    const params = { filter, sort, dir, limit, offset };

    const { data } = await this.axios.get<Api.BrokerLenderOrders.Raw.BrokerOrderListResponse>(url, {
      params,
    });

    // convert various values from raw API response to appropriate types
    const result: Api.BrokerLenderOrders.BrokerLenderOrderListResponse = {
      ...data,
      data: data.data.map((item) => ({
        ...item,
        rate: new Decimal(item.rate),
        timeInForce: TimeInForce[item.timeInForce],
        expiredAt: item.expiredAt ? new Date(item.expiredAt) : null,
      })),
    };

    return result;
  }

  /**
   * Get a paginated list of all Borrower Orders
   */
  public async getBrokerBorrowerOrdersList(
    offset = 0,
    limit = 100,
    dir: 'asc' | 'desc' = 'asc',
    sort?: string,
    filter?: string
  ): Promise<Api.BrokerBorrowerOrders.BrokerBorrowerOrderListResponse> {
    const url = this.baseUrl + this.featureUrl + `/borrower/orders`;
    const params = { filter, sort, dir, limit, offset };
    const { data } = await this.axios.get<Api.BrokerBorrowerOrders.Raw.BrokerOrderListResponse>(
      url,
      {
        params,
      }
    );

    // convert various values from raw API response to appropriate types
    const result: Api.BrokerBorrowerOrders.BrokerBorrowerOrderListResponse = {
      ...data,
      data: data.data.map((item) => ({
        ...item,
        rate: new Decimal(item.rate),
        timeInForce: TimeInForce[item.timeInForce],
        expiredAt: item.expiredAt ? new Date(item.expiredAt) : null,
      })),
    };

    return result;
  }
}
