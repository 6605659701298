<template>
  <div class="d-flex flex-column justify-between pa-4 grey darken-4">
    <div class="d-flex align-end gap-1 fill-height">
      <div class="d-flex flex-column justify-between fill-height w-100">
        <div>
          <h3>{{ title }}</h3>
          <p>
            <slot />
          </p>
        </div>
        <div class="d-flex gap-1 mt-auto">
          <div class="d-flex gap-1 w-100 mb-n4">
            <text-date-picker
              v-if="withDateFilter"
              v-model="dateFilter"
              :allowed-dates="allowedDates"
              class="date-filter mt-0"
              data-test-id="date-filter"
              label=""
              :max="dateMax"
              :min="dateMin"
            />

            <v-select
              v-if="withCompanyFilter"
              v-model="company"
              class="mt-0"
              clearable
              dense
              :error-messages="showCompanyRequiredError ? ['Please select a company'] : []"
              item-text="name"
              item-value="id"
              :items="companyOptions"
              placeholder="Select a company"
              @change="showCompanyRequiredError = false"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-column gap-1">
        <v-btn v-for="source in sources" :key="source.url" small @click="download(source)">
          {{ source.defaultName.split('.').pop() }}
          <v-icon small> mdi-download </v-icon>
        </v-btn>
        <v-checkbox v-if="withPrettyOption" v-model="pretty" class="d-inline-block" dense />
      </div>
    </div>
    <v-alert v-if="downloadErr" class="mt-4 mb-0" dense type="error">
      {{ downloadErr }}
    </v-alert>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Vue from 'vue';
import axios from 'axios';
import { errorString } from '@/utils/helpers/rest-response';
import { fileNameFromHeaders } from '@/utils/api/helpers';
import { Api } from '@/modules/broker-admin/types/statistics';
import { add as dateAdd, format, parse } from 'date-fns';
import { mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';
import TextDatePicker from '@/modules/common/components/TextDatePicker.vue';
import { parseDate } from '@/utils/helpers/dates';

const dateFilterInclTodayFromFormat = 'HH:mm';

interface Source {
  url: string;
  defaultName: string;
}

@Component({
  components: { TextDatePicker },
  props: {
    title: String,
    sources: Array as () => Source[],
    withCompanyFilter: Boolean,
    withDateFilter: Boolean,
    dateFilterInclTodayFrom: String,
    dateFilterInclNonWorkdays: Boolean,
    withPrettyOption: Boolean,
  },
  computed: {
    ...mapState(['clientConfig', 'currentTimeUTC']),
  },
})
export default class Report extends Vue {
  private readonly title!: string;
  private readonly sources!: Source[];
  private readonly withCompanyFilter!: boolean;
  private readonly withDateFilter!: boolean;
  private readonly dateFilterInclTodayFrom!: string;
  private readonly dateFilterInclNonWorkdays!: boolean;
  private readonly withPrettyOption!: boolean;

  private readonly currentTimeUTC!: Date;
  private readonly clientConfig!: ClientConfig;

  private downloadErr: string | null = null;
  private company: number | null = null;
  private showCompanyRequiredError = false;
  private companyOptions: Api.StatsFilters['companies'] = [];
  private selectedDateFilter = '';

  private pretty = false;

  protected get dateFilter(): Date {
    if (this.selectedDateFilter != '') {
      return parseDate(this.selectedDateFilter);
    }
    return this.dateMax;
  }

  protected set dateFilter(date: Date | null) {
    this.selectedDateFilter = format(date ?? this.dateMax, 'yyyy-MM-dd');
  }

  protected get shouldDateFilterInclToday(): boolean {
    if (!this.dateFilterInclTodayFrom) {
      return false;
    }

    try {
      // calculate the cutOff based on the provided prop,
      // but with the timezone appended to ensure it's not in the user's timezone
      const cutOff = parse(
        `${this.dateFilterInclTodayFrom} ${this.clientConfig.timezoneOffset}`,
        `${dateFilterInclTodayFromFormat} X`,
        this.currentTimeUTC
      );

      return this.currentTimeUTC >= cutOff;
    } catch {
      return false;
    }
  }

  protected get dateMin(): Date {
    return dateAdd(this.currentTimeUTC, { years: -1 });
  }

  protected get dateMax(): Date {
    let dateMax = dateAdd(this.currentTimeUTC, { days: this.shouldDateFilterInclToday ? 0 : -1 });
    while (!this.allowedDates(dateMax)) {
      dateMax = dateAdd(dateMax, { days: -1 });
    }
    return dateMax;
  }

  protected allowedDates(date: string | Date): boolean {
    if (this.dateFilterInclNonWorkdays) {
      return true;
    }
    const isWeekend = [0, 6].includes((date instanceof Date ? date : parseDate(date)).getDay());
    const isHoliday = this.clientConfig.holidaysLast12Months.includes(
      typeof date === 'string' ? date : format(date, 'yyyy-MM-dd')
    );
    return !isWeekend && !isHoliday;
  }

  protected async mounted(): Promise<void> {
    if (this.withDateFilter) {
      this.dateFilter = this.dateMax;
    }
    if (this.withCompanyFilter) {
      // load the available filters from the backend
      await this.loadFilters();
    }
  }

  protected async loadFilters(): Promise<void> {
    try {
      const { data } = await axios.get<Api.StatsFilters>(`/api/1/broker-user/stats/filters`);
      this.companyOptions = data.companies;
    } catch (e) {
      this.companyOptions = [];
    }
  }

  protected async download(source: Source): Promise<void> {
    this.showCompanyRequiredError = false;
    this.downloadErr = null;

    if (this.withCompanyFilter && this.company === null) {
      this.showCompanyRequiredError = true;
      return;
    }

    try {
      const response = await axios.get<BlobPart>(source.url, {
        params: {
          company: this.withCompanyFilter ? this.company : undefined,
          date: this.withDateFilter ? this.selectedDateFilter : undefined,
          pretty: this.pretty ? 'true' : undefined,
        },
        responseType: 'blob',
      });

      const fileName =
        fileNameFromHeaders(response.headers as Record<string, string>) || source.defaultName;
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (e) {
      this.downloadErr = errorString(e as Error);
    }
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.w-100 {
  width: 100%;
}

::v-deep .date-filter {
  width: 8rem;
  flex-shrink: 0;
  flex-grow: 0;

  input {
    cursor: pointer;
  }
}
</style>
