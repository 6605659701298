<template>
  <v-dialog
    v-model="dialog"
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    :max-width="1300"
    origin="center left"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    scrollable
    @click:outside="!showSummary && securities.length <= 1 && closeDialog()"
    @keydown.esc="showSummary ? goBack() : securities.length <= 1 && closeDialog()"
    @shortkey.native="showSummary ? goBack() : securities.length <= 1 && closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <h2 class="headline aurora-keep-case">
            {{
              isPreEstablished
                ? side === 'borrower'
                  ? $t('preEstablishedLoans.newBorrow.title')
                  : $t('preEstablishedLoans.newLoan.title')
                : side === 'borrower'
                  ? $t('manualLoans.newBorrow.title')
                  : $t('manualLoans.newLoan.title')
            }}
          </h2>
        </v-card-title>

        <v-card-text class="text--primary">
          <!-- Input fields -->
          <v-container v-show="!showSummary" class="d-flex form-input" fluid>
            <v-row no-gutters>
              <v-col class="col-3 col-md-3 col-lg-3 pr-4 form-part-1">
                <div class="counterparty-input">
                  <v-list>
                    <v-list-item v-if="nonSftLoansEnabled" class="pa-0">
                      <v-list-item-content class="pb-0">
                        <v-list-item-title class="text-capitalize">
                          Settlement Type
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <settlement-type-select
                            v-model="settlementType"
                            autofocus
                            @change="onChangeSettlementType()"
                          />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-content class="pb-0">
                        <v-list-item-title class="text-capitalize">
                          Counterparty
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <counterparty-search
                            v-model="counterparty"
                            :autofocus="!nonSftLoansEnabled"
                            :bilateral="settlementType == 'Bilateral'"
                            class="mt-n2"
                            data-test="counterparty-search"
                            :error-messages="errorMsgs.counterparty"
                            :side="counterpartySide"
                            @blur="$v.counterparty.$touch()"
                            @input="$v.counterparty.$touch()"
                          />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-content class="pb-0">
                        <v-list-item-title class="text-capitalize"> Rate Type</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-select
                            v-model="rateType"
                            class="mt-n2"
                            :items="['Fixed', 'Floating']"
                            @change="onChangeRateType($event)"
                          />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="rateType === 'Floating'" class="pa-0">
                      <v-list-item-content class="pb-0">
                        <v-list-item-title class="text-capitalize"> Benchmark</v-list-item-title>
                        <v-list-item-subtitle>
                          <benchmark-select
                            v-model="benchmark"
                            class="mt-n2"
                            :error-messages="errorMsgs.benchmark"
                            @blur="$v.benchmark.$touch()"
                            @change="onChangeBenchmark($event)"
                            @input="$v.benchmark.$touch()"
                          />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="side === 'lender' && !independentAmount.isZero()"
                      class="pa-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                          Independent Amount
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <rate-output :precision="2" :rate="independentAmount" />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="side === 'lender' && roundingRule" class="pa-0">
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                          Rounding Rule
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ roundingRuleToString(roundingRule) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>

              <v-col class="form-part-2 pl-4">
                <v-row class="justify-space-between mb-4" no-gutters>
                  <div>
                    <h2 class="font-weight-regular">Securities</h2>
                    <div class="gross-notional text-body-2 text--secondary">
                      Gross Notional: ${{ formatPrice(grossNotional) }}
                    </div>
                  </div>
                  <v-btn
                    class="add-item text-capitalize"
                    color="tertiary"
                    tabindex="-1"
                    @click="addListItem()"
                  >
                    <v-icon left> mdi-plus</v-icon>
                    {{ $t('manualLoans.addListItemBtn') }}
                  </v-btn>
                </v-row>

                <v-row no-gutters>
                  <v-col
                    class="securities-list"
                    :class="{ 'is-large-order': securities.length > 5 }"
                  >
                    <v-row
                      v-for="(item, idx) in securities"
                      :key="item.id"
                      class="securities-item"
                      no-gutters
                    >
                      <v-col class="d-flex col-auto align-center row-delete">
                        <v-btn
                          class="remove-item"
                          icon
                          tabindex="-1"
                          title="remove item"
                          x-small
                          @click="removeListItem(idx)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col class="col-2 col-md-3 col-lg-2">
                        <simple-equity-search
                          v-model="item.equity"
                          :error-messages="errorMsgs.securities[idx]['equity']"
                          label="security"
                          @blur="$v.securities.$each.$iter[idx].equity.$touch()"
                          @change="onChangeItemEquity(item, $event)"
                          @input="$v.securities.$each.$iter[idx].equity.$touch()"
                        />
                      </v-col>
                      <v-col class="col-lg-2">
                        <numeric-input
                          v-model="item.quantity"
                          :error-messages="errorMsgs.securities[idx]['quantity']"
                          label="quantity"
                          :min="0"
                          placeholder="0"
                          :step="100"
                          type="integer"
                          @blur="$v.securities.$each.$iter[idx].quantity.$touch()"
                          @change="onChangeItemQuantity(item, $event)"
                          @input="$v.securities.$each.$iter[idx].quantity.$touch()"
                        />
                      </v-col>
                      <v-col>
                        <numeric-input
                          v-model="item.rate"
                          :error-messages="errorMsgs.securities[idx]['rate']"
                          :label="rateType === 'Fixed' ? 'rate' : 'offset'"
                          placeholder="0.0000"
                          :precision="ratePrecision"
                          :step="0.25"
                          suffix="%"
                          type="decimal"
                          @blur="$v.securities.$each.$iter[idx].rate.$touch()"
                          @change="onChangeItemRate(item, $event)"
                          @input="$v.securities.$each.$iter[idx].rate.$touch()"
                        />
                      </v-col>
                      <v-col class="col-lg-2">
                        <numeric-input
                          disabled
                          label="price"
                          :min="0"
                          :precision="pricePrecision"
                          prefix="$"
                          type="decimal"
                          :value="item.equity && item.equity.lastClosePrice"
                        />
                      </v-col>
                      <v-col>
                        <numeric-input
                          v-model="item.notional"
                          :append-icon="
                            item.isGreaterThanOrderSoftLimit && !item.isGreaterThanOrderHardLimit
                              ? 'mdi-alert'
                              : null
                          "
                          hint="we will calculate the quantity based on price"
                          label="notional"
                          :min="0"
                          :precision="pricePrecision"
                          prefix="$"
                          type="decimal"
                          @change="onChangeItemNotional(item, $event)"
                        />
                      </v-col>
                      <div v-if="securities.length > 1" class="row-number">
                        <small>{{ `${idx + 1} of ${securities.length}` }}</small>
                      </div>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>

          <!-- Form summary  -->
          <v-container
            v-if="showSummary"
            v-shortkey="['enter']"
            class="form-summary d-flex flex-column"
            fluxid
            @shortkey="submitForm()"
          >
            <v-row no-gutters>
              <v-col class="message text-body-1 mb-4" cols="12">
                Are you sure you wish to send this request?
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="col-3 pr-4 form-part-1">
                <div class="counterparty-input">
                  <v-list>
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                          Counterparty
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ formatCompanyName(counterparty) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="nonSftLoansEnabled" class="pa-0">
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                          Settlement Type
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ settlementTypeDisplayText[settlementType] }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize"> Rate Type</v-list-item-title>
                        <v-list-item-subtitle>{{ rateType }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="isPreEstablished" class="pa-0">
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                          Pre-Established Loans
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="side === 'lender' && !independentAmount.isZero()"
                      class="pa-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                          Independent Amount
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <rate-output :precision="2" :rate="independentAmount" />
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="side === 'lender' && roundingRule" class="pa-0">
                      <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                          Rounding Rule
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >{{ roundingRuleToString(roundingRule) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>

              <v-col class="form-part-2 pl-4">
                <v-row class="justify-space-between mb-4" no-gutters>
                  <div>
                    <h2 class="font-weight-regular">Securities</h2>
                  </div>
                  <div class="gross-notional text--secondary">
                    Gross Notional: ${{ formatPrice(grossNotional) }}
                  </div>
                </v-row>

                <v-row no-gutters>
                  <v-col class="securities-list">
                    <v-row
                      v-for="(item, idx) in securities"
                      :key="item.id"
                      class="securities-item flex-nowrap pb-2 mb-2 mr-1"
                      no-gutters
                    >
                      <v-col cols="6">
                        <div class="security text-body-1">
                          {{ item.equity.ticker }} ({{ item.equity.cusip }})
                        </div>
                        <div class="d-flex">
                          <div class="price pr-6 text--secondary">
                            Price: ${{ formatPrice(item.equity.lastClosePrice) }}
                          </div>
                          <div class="notional pr-6 text--secondary">
                            Notional: ${{ formatPrice(item.notional) }}
                          </div>
                        </div>
                      </v-col>

                      <v-col class="ml-auto" cols="5">
                        <v-row no-gutters>
                          <v-col class="quantity pr-6">
                            <div class="label text--secondary">Quantity</div>
                            <div class="value text-body-2">
                              {{ formatNumber(item.quantity) }}
                            </div>
                          </v-col>
                          <v-col class="rate">
                            <div class="label text--secondary">Rate</div>
                            <div class="value text-body-2">
                              <rate-output
                                v-if="rateType === 'Floating'"
                                :rate="item.rate"
                                :rate-modifier="benchmark"
                              />
                              <rate-output v-else :rate="item.rate" />
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        v-if="securities.length > 1"
                        class="row-number text--secondary"
                        cols="1"
                      >
                        <small>{{ `${idx + 1} of ${securities.length}` }}</small>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Dialog box actions -->
        <v-card-actions class="d-flex">
          <div v-if="!showSummary" class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" @click="closeDialog">
              {{ backButtonText }}
            </v-btn>
            <div
              v-if="showValidationError && errorMsgs.wholeForm.length"
              class="error v-alert v-alert--dense text-center"
            >
              {{ errorMsgs.wholeForm.join('\n') }}
            </div>
            <div v-else-if="fatFingerMsgs" class="v-alert v-alert--dense text-center pa-0">
              <v-icon>mdi-alert</v-icon>
              <span class="text--secondary">{{ fatFingerMsgs }}</span>
            </div>
            <aurora-btn
              color="primary"
              timeframe="createLoans"
              type="submit"
              @click="goToSummary()"
            >
              {{ $t('dialogs.nextButton') }}
            </aurora-btn>
          </div>
          <div v-if="showSummary" class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="goBack()">
              {{ backButtonText }}
            </v-btn>
            <div
              class="v-alert v-alert--dense text-center"
              :class="{ error: errorMsgs.apiErrors.length, success: showSuccess }"
            >
              <div v-if="errorMsgs.apiErrors.length">
                <div>Something went wrong. Please check your input and try again.</div>
                <small>{{ errorMsgs.apiErrors.join('\n') }}</small>
              </div>
              <div v-if="showSuccess">
                <div>Success!</div>
                <small>Your manual loan request has been created.</small>
              </div>
            </div>
            <aurora-btn
              color="primary"
              :disabled="formStatus !== 'idle'"
              :loading="formStatus === 'submitting'"
              timeframe="createLoans"
              type="submit"
              @click="submitForm()"
            >
              {{ $t('dialogs.submitButton') }}
            </aurora-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Decimal } from 'decimal.js';
import { MAX_RATE, PRICE_PRECISION, RATE_PRECISION } from '@/modules/common/constants/precision';
import { CompanyInfo, Side } from '@/modules/user-accounts/types/user-accounts';
import wait from '@/modules/common/services/wait';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import {
  formatDecimalAsString,
  getPriceAsString,
  getVolumeAsString,
} from '@/utils/helpers/auction-numbers';
import { Watch } from 'vue-property-decorator';
import {
  decimal,
  maxValue,
  minLength,
  minValue,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { BadRequestError } from '@/utils/errors';
import { Api, Equity } from '@/modules/common/types/api';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import { mapState } from 'vuex';
import { LoginState } from '@/store/store';
import {
  NotionalDetails,
  RoundingRule,
  calculateNotional,
  roundingRuleToString,
  zeroNotionalDetails,
} from '@/modules/sec-lending/helpers/contract-details';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import { joinSentence } from '@/utils/helpers/helpers';
import BenchmarkSelect from '@/modules/marketdata/components/BenchmarkSelect.vue';
import { RateType } from '@/modules/marketdata/types/marketdata';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { formatCompanyName } from '@/modules/user-accounts/helpers/user-accounts';
import { canTradeEquity } from '@/utils/helpers/equity-validators';
import { PropType } from 'vue';
import { notGreaterThanPrecision } from '@/utils/helpers/custom-validators';
import { SettlementType } from '@/modules/marketplace/types/marketplace';
import SettlementTypeSelect from '@/modules/common/components/SettlementTypeSelect.vue';
import { settlementTypeDisplayText } from '@/modules/marketplace/helpers/marketplace';
import { ClientConfig } from '@/utils/helpers/rest';
import i18n from '@/localisation/i18n';

interface SecurityListItem {
  id: number;
  equity: Equity;
  quantity: number;
  rate: Decimal;
  notional: Decimal;
  isGreaterThanOrderSoftLimit?: boolean;
  isGreaterThanOrderHardLimit?: boolean;
}

interface FormErrors {
  apiErrors: string[];
  wholeForm: string[];
  counterparty: string[];
  benchmark: string[];
  securities: Array<Record<keyof Omit<SecurityListItem, 'id'>, string[]>>;
}

@Component({
  methods: { roundingRuleToString },
  components: {
    SettlementTypeSelect,
    CounterpartySearch,
    BenchmarkSelect,
    SimpleEquitySearch,
  },
  props: {
    side: String as PropType<Side>,
    showDialog: Boolean,
    fileUpload: Object,
    isPreEstablished: Boolean,
  },
  filters: {
    formatCompanyName,
  },
  mixins: [validationMixin],
  validations: function (this: NewManualLoanDialog) {
    return {
      counterparty: { required },
      benchmark: {
        required: requiredIf((vm: NewManualLoanDialog) => vm.rateType === 'Floating'),
      },
      securities: {
        required,
        minLength: minLength(1),
        $each: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          $trackBy: 'id' as any, // typings are incorrect
          equity: { required, canTrade: canTradeEquity() },
          quantity: { required, decimal, minValue: minValue(1) },
          rate: {
            decimal,
            required,
            notGreaterThanPrecision: notGreaterThanPrecision(RATE_PRECISION),
            minValue: minValue(-MAX_RATE),
            maxValue: maxValue(MAX_RATE),
          },
          notional: {
            required,
            maxOrderSize: new RiskLimitValidator(
              this.$dialog,
              this.loginState.user
            ).maxOrderHardLimitRule(),
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['loginState', 'clientConfig']),
  },
})
export default class NewManualLoanDialog extends mixins(validationMixin) {
  protected readonly side!: Side;
  protected readonly isPreEstablished!: boolean;

  protected readonly loginState!: NonNullableAll<LoginState>;
  protected readonly clientConfig!: ClientConfig;

  protected largestOrder!: NullableAll<SecurityListItem> | null;
  protected independentAmount: Decimal = new Decimal(0);
  protected roundingRule!: RoundingRule | null;
  protected readonly showDialog!: boolean;
  protected readonly fileUpload!: Api.ManualLoans.ManualLoanBasketUploadResponse | null;
  protected showSummary = false;
  protected showValidationError = false;
  protected showSuccess = false;
  protected formStatus: DialogFormStatus = 'idle';
  protected apiErrors: string[] = [];
  protected ratePrecision = RATE_PRECISION;
  protected pricePrecision = PRICE_PRECISION;
  protected isLoadingCounterparties = false;
  protected counterparties: CompanyInfo[] = [];
  protected counterparty: CompanyInfo | null = null;
  protected securities: Array<NullableAll<SecurityListItem>> = [];
  protected settlementTypeDisplayText = settlementTypeDisplayText;

  protected rateType: RateType = 'Fixed';
  protected benchmark: string | null = null;
  protected settlementType: SettlementType = 'NSCC';

  protected get nonSftLoansEnabled(): boolean {
    return (
      !this.isPreEstablished &&
      (this.clientConfig.bilateralLoansEnabled || this.clientConfig.occBilateralLoansEnabled)
    );
  }

  protected get dialog(): boolean {
    return this.showDialog;
  }

  protected set dialog(value: boolean) {
    this.$emit('update:showDialog', value);
  }

  protected get counterpartySide(): Side {
    return this.side === 'lender' ? 'borrower' : 'lender';
  }

  protected get grossNotional(): Decimal {
    this.largestOrder = null;
    return this.securities.reduce<Decimal>((prevItem, currItem) => {
      try {
        if (
          !this.largestOrder ||
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (currItem.notional && currItem.notional > this.largestOrder.notional!)
        ) {
          this.largestOrder = currItem;
        }
        return prevItem.add(currItem.notional as Decimal);
      } catch (e) {
        return prevItem;
      }
    }, new Decimal(0));
  }

  protected get fatFingerMsgs(): string {
    // check soft-limit only for order-rows where an instrument was selected and the hard-limit was not exceeded
    const tickerList = this.securities
      .filter((s) => s.equity && s.isGreaterThanOrderSoftLimit && !s.isGreaterThanOrderHardLimit)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map((item) => item.equity!.ticker)
      .filter((item, pos, ar) => ar.indexOf(item) === pos);

    const count = tickerList.length;
    if (count === 0) {
      return '';
    }

    return `The notional value for ${joinSentence(tickerList, ', ', ' and ')} ${
      count === 1 ? 'is' : 'are'
    } quite high. Please double-check the size of ${count === 1 ? 'this order' : 'those orders'}.`;
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      wholeForm: [],
      counterparty: [],
      benchmark: [],
      securities: [],
    };

    // validate overall form
    if (this.$v.$dirty && this.$v.$error) {
      errors.wholeForm.push('Please check your input. There are some errors.');
    }
    // validate counterparty input
    if (this.$v.counterparty.$dirty) {
      if (!this.$v.counterparty.required) errors.counterparty.push('Counterparty is required.');
    }

    // validate benchmark
    if (this.$v.benchmark.$dirty) {
      if (!this.$v.benchmark.required) errors.benchmark.push('Benchmark is required.');
    }

    // validate each security item
    for (const idx in this.$v.securities.$each?.$iter) {
      const securityItem = this.securities[idx];
      const vResult = this.$v.securities.$each?.$iter[idx];
      const errorObj = {
        equity: [],
        quantity: [],
        rate: [],
        notional: [],
        isGreaterThanOrderSoftLimit: [],
        isGreaterThanOrderHardLimit: [],
      } as FormErrors['securities'][0];

      // equity
      if (vResult?.equity.$dirty) {
        if (!vResult.equity.required) errorObj.equity.push('Security is required.');
        if (securityItem.equity && securityItem.equity.cannotTradeMessage) {
          errorObj.equity.push(i18n.t(securityItem.equity.cannotTradeMessage) as string);
        }
      }

      // quantity
      if (vResult?.quantity.$dirty) {
        if (!vResult.quantity.required) errorObj.quantity.push('Quantity is required.');
        if (!vResult.quantity.minValue) errorObj.quantity.push('must be greater than 0');
        if (!vResult.notional.maxOrderSize)
          errorObj.quantity.push('risk limit exceeded, reduce the size of this order');
      }

      // rate
      if (vResult?.rate.$dirty) {
        if (!vResult.rate.required) {
          errorObj.rate.push(
            `${securityItem.rateType === 'Floating' ? 'offset' : 'rate'} is required.`
          );
        }
        if (!vResult.rate.notGreaterThanPrecision) {
          errorObj.rate.push(
            `${
              securityItem.rateType === 'Floating' ? 'offset' : 'rate'
            } must not be more than ${RATE_PRECISION} decimal places.`
          );
        }
        if (!vResult.rate.minValue) {
          errorObj.rate.push(
            `${
              securityItem.rateType === 'Floating' ? 'offset' : 'rate'
            } cannot be lower than -${this.formatRate(MAX_RATE)}.`
          );
        }
        if (!vResult.rate.maxValue) {
          errorObj.rate.push(
            `${
              securityItem.rateType === 'Floating' ? 'offset' : 'rate'
            } cannot be more than ${this.formatRate(MAX_RATE)}.`
          );
        }
      }

      errors.securities[idx] = errorObj;
    }
    return errors;
  }

  /**
   * get the text to display in the back button
   */
  protected get backButtonText(): string {
    if (this.showSummary && this.fileUpload) {
      // show Edit text when displaying file upload results
      return this.$t('dialogs.editButton') as string;
    } else if (this.showSummary) {
      return this.$t('dialogs.goBackButton') as string;
    } else {
      return this.$t('dialogs.cancelButton') as string;
    }
  }

  @Watch('fileUpload')
  protected onFileUpload(
    newFileUpload: Api.ManualLoans.ManualLoanBasketUploadResponse | null
  ): void {
    if (newFileUpload) {
      this.setFormFromFileUpload({
        counterparty: newFileUpload.counterparty,
        benchmark: newFileUpload.benchmark,
        securities: newFileUpload.requestItems.map((item) => {
          if (item.equity) {
            item.equity.lastClosePrice = new Decimal(item.equity.lastClosePrice);
          }

          return item;
        }),
      });
    }
  }

  protected created(): void {
    if (this.side === 'lender') {
      this.independentAmount = this.loginState.user.companyPreferredIndependentAmountRate;
      this.roundingRule = this.loginState.user.companyPreferredRoundingRule;
    } else {
      this.independentAmount = new Decimal(0);
      this.roundingRule = null;
    }
  }

  protected mounted(): void {
    if (this.fileUpload) {
      // load any existing fileUpload data passed in from the props
      this.setFormFromFileUpload({
        counterparty: this.fileUpload.counterparty,
        benchmark: this.fileUpload.benchmark,
        securities: this.fileUpload.requestItems,
      });
    } else {
      // add the first empty item
      this.addListItem();
    }
  }

  protected closeDialog(): void {
    // @TODO if there is form data entered, should we confirm before discarding?
    this.resetForm();
    this.dialog = false;

    // close risk-validator popups
    this.$dialog.close();
  }

  protected goToSummary(): void {
    if (!this.largestOrder) {
      this.showSummary = this.validateForm();
      return;
    }

    if (this.validateForm()) {
      // check for suspicious quantities
      //   if found: confirm or review order
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const notional = this.largestOrder.notional!;
      new RiskLimitValidator(this.$dialog, this.loginState.user).checkAndConfirmRiskLimits(
        notional,
        () => {
          this.$emit('toggle-can-cancel', false);
          this.showSummary = true;
        }
      );
    }
  }

  protected goBack(): void {
    this.showValidationError = false;
    this.showSummary = false;
  }

  protected addListItem(): void {
    if (!this.$v.$error) {
      this.$v.$reset();
    }

    let lastId = this.securities[this.securities.length - 1]?.id || 0;
    this.securities.push({
      equity: null,
      id: ++lastId,
      quantity: null,
      rate: null,
      notional: null,
      isGreaterThanOrderSoftLimit: null,
      isGreaterThanOrderHardLimit: null,
    });
  }

  protected removeListItem(idx: number): void {
    this.securities.splice(idx, 1);

    if (this.securities.length == 0) {
      this.addListItem();
    }
  }

  protected onChangeItemEquity(item: NullableAll<SecurityListItem>, equity: Equity | null): void {
    item.equity = equity;

    if (item.equity && item.quantity) {
      // calculate notional from quantity
      const notional = this.calcNotionalFromQty(item.quantity, item.equity.lastClosePrice);
      item.notional = notional.contractAmount;
    } else if (item.equity && item.notional) {
      // calculate quantity from notional, then recalculate correct notional
      item.quantity = this.calcQtyFromNotional(item.notional, item.equity.lastClosePrice);

      const notional = this.calcNotionalFromQty(item.quantity, item.equity.lastClosePrice);
      item.notional = notional.contractAmount;
    }
    this.checkRiskLimits(item);
  }

  protected onChangeItemQuantity(item: NullableAll<SecurityListItem>, newQty: number | null): void {
    item.quantity = newQty;
    if (item.equity && item.quantity) {
      const notional = this.calcNotionalFromQty(item.quantity, item.equity.lastClosePrice);
      item.notional = notional.contractAmount;
    }
    this.checkRiskLimits(item);
  }

  protected onChangeRateType(rateType: RateType): void {
    this.rateType = rateType;
    if (this.rateType != 'Floating') {
      this.benchmark = '';
    }
  }

  protected onChangeBenchmark(benchmark: string): void {
    this.benchmark = benchmark;
  }

  protected onChangeItemRate(item: NullableAll<SecurityListItem>, newRate: Decimal | null): void {
    item.rate = newRate;
  }

  protected onChangeSettlementType(): void {
    this.counterparty = null;
  }

  protected onChangeItemNotional(
    item: NullableAll<SecurityListItem>,
    newNotional: Decimal | null
  ): void {
    item.notional = newNotional;
    if (item.equity && item.notional) {
      item.quantity = this.calcQtyFromNotional(item.notional, item.equity.lastClosePrice);

      // recalculate notional, to reflect the new quantity
      const notional = this.calcNotionalFromQty(item.quantity, item.equity.lastClosePrice);
      item.notional = notional.contractAmount;
    }
    this.checkRiskLimits(item);
  }

  /**
   * Calculates the max quantity possible given a notional value and an equity's price
   * @TODO maybe move into the helper file: auction-number.ts
   */
  protected calcQtyFromNotional(notional: number | Decimal, price: Decimal): number {
    if (new Decimal(price).isZero()) {
      return 0;
    }
    let qty = new Decimal(notional).dividedToIntegerBy(price).toNumber();
    if (qty > 100) {
      // round down to nearest 100
      qty = new Decimal(qty).dividedToIntegerBy(100).mul(100).toNumber();
    }

    return qty;
  }

  /**
   * Calculates the notional value given a quantity and an equity's last price
   * @TODO maybe move into the helper file: auction-number.ts
   */
  protected calcNotionalFromQty(quantity: number, price: Decimal): NotionalDetails {
    if (quantity == null) {
      return zeroNotionalDetails();
    }

    return calculateNotional(quantity, price, this.independentAmount, this.roundingRule);
  }

  /**
   * Use the notional value to determine if the fat finger limit has been exceeded
   */
  protected checkRiskLimits(item: NullableAll<SecurityListItem>): void {
    const riskValidator = new RiskLimitValidator(this.$dialog, this.loginState.user);
    item.isGreaterThanOrderSoftLimit = riskValidator.isGreaterThanOrderSoftLimit(item.notional);
    item.isGreaterThanOrderHardLimit = riskValidator.isGreaterThanOrderHardLimit(item.notional);
  }

  protected formatPrice(price: Decimal): string {
    return getPriceAsString(price, this.pricePrecision);
  }

  protected formatRate(rate: number): string {
    return formatDecimalAsString(new Decimal(rate), this.ratePrecision);
  }

  protected formatNumber(number: number): string {
    return getVolumeAsString(number);
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];

    // validate the whole form, and display the overall error message if present
    this.$v.$touch();
    this.showValidationError = true;

    return !this.$v.$anyError;
  }

  protected formatCompanyName(counterparty: CompanyInfo): string {
    return formatCompanyName(counterparty);
  }

  protected async submitForm(): Promise<void> {
    if (this.formStatus !== 'idle') {
      return;
    }

    this.apiErrors = [];
    this.formStatus = 'submitting';

    // validation has already occurred
    const counterpartyId = this.counterparty?.companyId as number;
    const securities = this.securities as unknown as SecurityListItem[];

    const data: Api.ManualLoans.NewManualLoanItem[] = securities.map((item) => {
      return {
        counterpartyId,
        equityId: item.equity.id,
        quantity: item.quantity,
        benchmark: this.benchmark,
        rate: item.rate,
      };
    });

    try {
      await this.$api.manualLoans.createManualLoanBasket(
        data,
        this.side,
        this.isPreEstablished,
        this.settlementType
      );

      // success, show and then close form
      this.showSuccess = true;

      this.formStatus = 'closing';

      await wait(1200);
      this.closeDialog();
    } catch (err) {
      // error, show and allow user to try fix
      if (err instanceof BadRequestError) {
        const errMsg = err.responseData?.msgkey
          ? (this.$t(`manualLoans.${err.responseData.msgkey}`) as string)
          : 'Bad input';
        this.apiErrors = [errMsg];
      } else {
        this.apiErrors = ['Unknown Error'];
      }
    } finally {
      this.formStatus = 'idle';
    }
  }

  protected setFormFromFileUpload(form: {
    counterparty: CompanyInfo | null;
    benchmark: string | null;
    securities: Array<NullableAll<SecurityListItem>>;
  }): void {
    // prefill the form with the provided data
    this.counterparty = form.counterparty;
    this.counterparties = form.counterparty ? [form.counterparty] : [];
    this.securities = form.securities;

    // must have at least 1 security item
    if (this.securities.length === 0) {
      this.addListItem();
    }

    if (form.benchmark) {
      this.benchmark = form.benchmark;
      this.rateType = 'Floating';
    } else {
      this.rateType = 'Fixed';
    }

    // Go to the summary view, which will attempt to validate the form
    this.goToSummary();
  }

  protected resetForm(): void {
    this.apiErrors = [];
    this.counterparty = null;
    this.rateType = 'Fixed';
    this.benchmark = null;
    this.securities = [];
    this.counterparties = [];
    this.isLoadingCounterparties = false;
    this.showSummary = false;
    this.showSuccess = false;
    this.addListItem();

    // reset form validation
    this.$v.$reset();

    // clear the file upload
    this.$emit('update:fileUpload', null);
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  form {
    /* The view is split into two main parts. */
    .form-part-1 {
      border-right: solid 1px;
    }

    .securities-list {
      min-height: 20vh;
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      transition: min-height 200ms linear;

      /* increase the minimum height when the order is larger */
      &.is-large-order {
        min-height: 50vh;
      }

      .securities-item {
        position: relative;
        gap: 1rem;
      }
    }

    /* The input section of the form */
    .form-input {
      min-height: 30rem;

      .securities-item {
        .row-number {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    /* The summary view of the form data */
    .form-summary {
      min-height: 30rem;

      .securities-item {
        border-bottom: solid 1px;

        .price {
          min-width: 8rem;
        }

        .quantity .value {
          min-width: 6rem;
        }

        .rate .value {
          min-width: 6rem;
        }
      }
    }
  }
}

/* Theme specific overrides */
.theme--dark {
  .form-summary {
    .securities-item {
      border-color: rgba(255, 255, 255, 0.55);
    }
  }
}

.theme--light {
  .form-summary {
    .securities-item {
      border-color: rgba(0, 0, 0, 0.55);
    }
  }
}
</style>
