<template>
  <v-card class="flex d-flex flex-column">
    <!-- Table header (batch actions, filters, tabs) -->
    <v-container :class="[showTableFilters ? 'py-0' : 'pb-1']" fluid>
      <v-row no-gutters>
        <v-col
          :class="[
            'gap-1',
            'd-flex',
            'align-center',
            !showTableFilters && selectedItems.length > 0 ? 'pa-4' : '',
          ]"
          lg="6"
          md="12"
          sm="12"
          xl="6"
        >
          <slot name="header" />

          <!-- Displays number of selected items -->
          <template v-if="selectedItems.length">
            <span>
              {{
                selectedItems.length
                  ? `${selectedItems.length} of ${formattedOpenLoans.length} selected`
                  : ''
              }}
            </span>
          </template>

          <!-- All batch actions buttons and dialogs -->
          <open-loans-batch-actions
            v-if="selectedItems.length"
            :items="selectedItems"
            @success="selectedItems = []"
          />
        </v-col>

        <v-col
          v-if="showTableFilters"
          class="gap-1 d-flex align-center"
          lg="6"
          md="12"
          sm="12"
          xl="6"
        >
          <!-- Filters -->
          <counterparty-search
            class="counterparties"
            clearable
            data-test="counterparty-search"
            include-sponsored
            placeholder="All counterparties"
            :side="counterpartySide"
            :value="counterparty"
            @change="$emit('update:counterparty', $event)"
          />
          <simple-equity-search
            class="simple-equity-search"
            clear-after-select
            clearable
            label="Security"
            :value="equity"
            @input="$emit('update:equity', $event)"
          />
          <v-select
            v-if="canBorrowAndLend"
            class="side"
            clearable
            :items="sideItems"
            label="Side"
            :value="side"
            @change="$emit('update:side', $event)"
          />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-switch
                  class="mt-5x"
                  hide-details
                  :input-value="showAll"
                  label="show archived"
                  @change="$emit('update:showAll', $event)"
                />
              </span>
            </template>
            <span class="tip-background">
              Enabling show archived will include in the results Positions that were closed before
              today
            </span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="mt-n2 mb-4" no-gutters>
        <!-- Tabs -->
        <v-tabs
          ref="tabs"
          v-model="selectedTabIndex"
          background-color="transparent"
          @change="onChangeTab"
        >
          <v-tab v-for="tab in tabs" :key="tab.name" :value="tab.name">
            {{ tab.name }} ({{ tab.total }})
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>
    <!-- The <v-data-table> -->
    <open-loans-table
      :corporate-actions="openLoans.corporateActions"
      data-test="open-loans-table"
      :items="formattedOpenLoans"
      :items-per-page="options.itemsPerPage"
      :page="options.page"
      :selected-items.sync="selectedItems"
      :server-items-length="tabs[selectedTabIndex].total"
      :sort-by="options.sortBy[0]"
      :sort-desc="options.sortDesc[0]"
      @update:options="$emit('update:options', $event)"
      @view-loan="handleViewLoan"
    />
  </v-card>
</template>

<script lang="ts">
import { Equity } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import OpenLoansBatchActions from '@/modules/open-loans/components/OpenLoansBatchActions.vue';
import OpenLoansTable from '@/modules/open-loans/components/OpenLoansTable.vue';
import { OpenLoanDisplayItem, OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo, Side } from '@/modules/user-accounts/types/user-accounts';
import { SocketEvents } from '@/store/store';
import {
  LoanStatus,
  OpenLoan,
  OpenLoans,
  OpenLoansParams,
  isTerminalLoanStatus,
} from '@/utils/api/loans';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import { throttle } from 'lodash';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { mapGetters, mapState } from 'vuex';

interface Tab {
  name: 'all' | 'recalled' | 'rerate';
  total: number;
}

@Component({
  props: {
    showTableFilters: {
      type: Boolean,
      default: true,
      required: false,
    },
    tab: {
      type: String,
      required: true,
    },
    options: {
      type: Object as PropType<DataOptions>,
      required: true,
    },
    counterparty: {
      type: null as unknown as PropType<CompanyInfo | null>,
      validator: (value: CompanyInfo | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    equity: {
      type: null as unknown as PropType<Equity | null>,
      validator: (value: Equity | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    side: {
      type: null as unknown as PropType<'lender' | 'borrower' | null>,
      validator: (value: 'lender' | 'borrower' | null): boolean => {
        return value === null || value === 'lender' || value === 'borrower';
      },
      required: true,
    },
    showAll: {
      type: Boolean,
    },
    isSponsored: {
      // type acrobacy to allow the missing prop to be "undefined"
      // "type: Boolean" defaults" to "false" if the prop is missing
      type: undefined as unknown as PropType<boolean | undefined>,
      required: false,
    },
  },
  components: {
    CounterpartySearch,
    OpenLoansBatchActions,
    OpenLoansTable,
    SimpleEquitySearch,
  },
  computed: {
    ...mapState(['clientConfig', 'uxConfig', 'socketEvents']),
    ...mapGetters(['canBorrow', 'canBorrowAndLend']),
  },
})
export default class OpenLoansList extends Vue {
  // props
  protected readonly tab!: Tab['name'];
  protected readonly counterparty!: CompanyInfo | null;
  protected readonly equity!: Equity | null;
  protected readonly side!: 'lender' | 'borrower' | null;
  protected readonly showAll!: boolean;
  protected readonly options!: DataOptions;
  protected readonly isSponsored?: boolean;

  // store state
  protected canBorrow!: boolean;
  protected canBorrowAndLend!: boolean;
  protected clientConfig!: ClientConfig;
  protected uxConfig!: UXConfig;
  protected socketEvents!: SocketEvents;

  protected openLoans: OpenLoans = {
    total: 0,
    recalledTotal: 0,
    rerateTotal: 0,
    items: [],
    corporateActions: {},
  };

  protected sideItems = [
    { text: 'Lender', value: 'lender' },
    { text: 'Borrower', value: 'borrower' },
  ];

  protected selectedItemsIds: number[] = [];
  protected abortController: AbortController | null = null;
  protected throttledLoadAsyncData = throttle(this.loadAsyncData, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  protected get selectedTabIndex(): number {
    return this.tabs.findIndex((tab) => tab.name === this.tab);
  }

  protected set selectedTabIndex(index: number) {
    this.$emit('update:tab', this.tabs[index].name);
  }

  protected get tabs(): Tab[] {
    return [
      { name: 'all', total: this.openLoans.total },
      { name: 'recalled', total: this.openLoans.recalledTotal },
      { name: 'rerate', total: this.openLoans.rerateTotal },
    ];
  }

  /**
   * Get the loan objects using selectedItemsId
   */
  protected get selectedItems(): OpenLoanItem[] {
    return this.formattedOpenLoans.filter(
      (loan) => this.selectedItemsIds.includes(loan.id) && !this.isTerminalLoanStatus(loan.status)
    );
  }

  /**
   * v-data-table always emits the whole object, but the object can become stale
   * once the original object is updated (via sockets, for example)
   * therefore, we store the ids (and get a fresh version of the loan object via getter)
   */
  protected set selectedItems(items: OpenLoanItem[]) {
    this.selectedItemsIds = items.map((loan) => loan.id);
  }

  /**
   * Get a text-searchable version of the manual loans list
   */
  protected get formattedOpenLoans(): OpenLoanDisplayItem[] {
    return this.openLoans.items.map((loan) => {
      const statusDisplay = this.$i18n.tc(`loan-status-${loan.status}`);

      return {
        ...loan,
        statusDisplay,
      };
    });
  }

  protected get counterpartySide(): Side {
    return this.canBorrowAndLend ? 'all' : this.canBorrow ? 'lender' : 'borrower';
  }

  protected get isOrderbookEnabled(): boolean {
    return this.uxConfig.orderbookEnabledOverride !== null
      ? this.uxConfig.orderbookEnabledOverride
      : this.clientConfig.orderbookEnabled;
  }

  @Watch('tab')
  @Watch('equity')
  @Watch('counterparty')
  @Watch('side')
  @Watch('showAll')
  protected onChangeFilters(): void {
    this.$emit('update:options', { ...this.options, page: 1 });
  }

  @Watch('options')
  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  protected onSocketEvents(): void {
    void this.throttledLoadAsyncData();
  }

  protected created(): void {
    void this.loadAsyncData();
  }

  protected async loadAsyncData(): Promise<void> {
    if (this.abortController) {
      // we want to initiate a new request, but the previous one is still pending
      // cancel to avoid stale responses arriving late (and potentially in the wrong order)
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    try {
      const params: OpenLoansParams = {
        filters: {
          showAll: this.showAll,
          isRecalled: this.tabs[this.selectedTabIndex].name === 'recalled',
          isRenegotiating: this.tabs[this.selectedTabIndex].name === 'rerate',
          side: this.side,
          counterpartyCompanyId: this.counterparty ? this.counterparty.companyId : null,
          cusip: this.equity ? this.equity.cusip : null,
          isSponsored: this.isSponsored,
        },
        pagination: {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
        sort: `${this.options.sortDesc[0] ? '-' : '+'}${this.options.sortBy[0]}`,
      };
      const res = await this.$api.openLoans.fetchOpenLoans(params, this.abortController.signal);
      if (!res) {
        // request was cancelled, there's another response arriving
        return;
      }
      this.openLoans = res;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected onChangeTab(): void {
    this.selectedItemsIds = [];
  }

  protected handleViewLoan(
    detailLoan: OpenLoan | null,
    activeTab: 'history' | 'recalls' | 'corporateActions' = 'history'
  ): void {
    this.$emit('update:detailLoan', detailLoan);
    this.$emit('update:detailLoanInitialTab', activeTab);
  }

  protected isTerminalLoanStatus(s: LoanStatus): boolean {
    return isTerminalLoanStatus(s);
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.counterparties {
  width: 8rem;
}

.simple-equity-search {
  width: 2.5rem;
}

.side {
  width: 2.5rem;
}

.filter-list {
  width: 2.5rem;
}
</style>
