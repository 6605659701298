<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('userSettings.description') }}</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-alert border="left" dense elevation="8" type="info">
            Contact your broker if you want to switch to a different email address.
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="loginState.user.emailAddress"
            :label="$t('loginEmail')"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" @click="changePassword = true"
            >{{ $t('changePassword.title') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <change-password v-if="changePassword" @close-modal="changePassword = false"></change-password>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ChangePassword from '@/modules/auth/components/ChangePassword.vue';
import { mapState } from 'vuex';
import { LoginState } from '@/store/store';

@Component({
  components: { ChangePassword },
  data: () => {
    return {
      changePassword: false,
    };
  },
  computed: {
    ...mapState(['loginState']),
  },
})
export default class UserSettings extends Vue {
  protected loginState!: LoginState;
}
</script>

<style lang="scss" scoped></style>
