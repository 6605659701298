import Decimal from 'decimal.js';
import { Equity } from '@/modules/common/types/api';

export function normalizeEquity(eq: Equity): Equity {
  // input from server will have rate a string, we convert it to Decimal
  const eqWithStrPrice: { lastClosePrice: string | Decimal } = eq;
  if (typeof eqWithStrPrice.lastClosePrice === 'string') {
    eq.lastClosePrice = new Decimal(eqWithStrPrice.lastClosePrice);
  }

  return eq as Equity;
}
