import { ApiService } from '@/modules/common/services/api.service';
import Vue from 'vue';
import { Api } from '@/modules/common/types/api';

export class LenderLoansApiService extends ApiService {
  private featureUrl = '/lender';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.lenderLoans = singleton;
    } else {
      v.prototype.$api = {
        lenderLoans: singleton,
      };
    }
  }

  /**
   * Batch recall multiple loans by the lender
   */
  public async batchRecallLenderLoan(
    requests: Api.LenderLoans.LoanRecallRequestItem[]
  ): Promise<Api.LenderLoans.BatchActionResponse> {
    const url = this.baseUrl + this.featureUrl + '/loans/recall';
    const { data } = await this.axios.post<Api.LenderLoans.BatchActionResponse>(url, requests);
    return data;
  }

  /**
   * Batch cancel-recall multiple loans by the lender
   */
  public async batchCancelRecallLenderLoan(
    displayIds: string[]
  ): Promise<Api.LenderLoans.BatchActionResponse> {
    const url = this.baseUrl + this.featureUrl + '/loans/cancel-recall';
    // HTTP delete should not have request bodies (enforced by axios types)
    const { data } = await this.axios.put<Api.LenderLoans.BatchActionResponse>(url, displayIds);
    return data;
  }

  /**
   * Batch buyin multiple loans by the lender
   */
  public async batchBuyInLenderLoan(ids: number[]): Promise<Api.LenderLoans.BatchActionResponse> {
    const url = this.baseUrl + this.featureUrl + '/loans/buyin';
    const { data } = await this.axios.post<Api.LenderLoans.BatchActionResponse>(url, ids);
    return data;
  }
}
