<template>
  <div>
    <dashboard-panel no-collapse title="open positions">
      <snapshot-manager
        :drivers="['url', 'sessionStorage']"
        :get-state-mutators="getStateMutators"
        :snapshot="snapshot"
        storage-key="snapshot-broker-open-loans-list"
      >
        <broker-open-loans-list
          :borrower.sync="borrower"
          :display-id.sync="displayId"
          :equity.sync="equity"
          :lender.sync="lender"
          :options.sync="options"
          :statuses.sync="statuses"
        />
      </snapshot-manager>
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import BrokerOpenLoansList from '@/modules/broker-admin/components/BrokerOpenLoansList.vue';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { Equity } from '@/modules/common/types/api';
import { DataOptions } from 'vuetify';
import { LoanStatus } from '@/utils/api/loans';

const MAX_ITEMS_PER_PAGE = 50;

@Component({
  components: {
    DashboardPanel,
    SnapshotManager,
    BrokerOpenLoansList,
  },
})
export default class BrokerOpenLoansListView extends Vue {
  protected tab: 'all' | 'recalled' | 'rerate' = 'all';
  protected counterparty: null | CompanyInfo = null;
  protected equity: Equity | null = null;
  protected side: 'lender' | 'borrower' | null = null;
  protected showAll = false;
  protected lender: null | CompanyInfo = null;
  protected borrower: null | CompanyInfo = null;
  protected statuses: null | LoanStatus[] = null;
  protected displayId: null | string = null;

  protected readonly options: Pick<DataOptions, 'sortBy' | 'sortDesc' | 'page' | 'itemsPerPage'> = {
    sortBy: ['createdAt'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: MAX_ITEMS_PER_PAGE,
  };

  protected get snapshot(): Record<string, string> {
    const snapshot: Record<string, string> = {
      sortDesc: this.options.sortDesc[0] ? 'true' : 'false',
      sortBy: this.options.sortBy[0],
      page: this.options.page.toString(),
      itemsPerPage: this.options.itemsPerPage.toString(),
    };
    if (this.lender) {
      snapshot.lender = this.lender.displayBoxId as string;
    }
    if (this.borrower) {
      snapshot.borrower = this.borrower.displayBoxId as string;
    }
    if (this.equity) {
      snapshot.cusip = this.equity.cusip;
    }
    if (this.statuses) {
      snapshot.statuses = this.statuses ? this.statuses.join(',') : '';
    }
    if (this.displayId) {
      snapshot.displayId = this.displayId;
    }

    return snapshot;
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      lender: async (value) => {
        try {
          const res = await this.$api.userAccounts.fetchCompanyByDisplayBoxId(value);
          if (res) {
            this.lender = res;
          } else {
            throw new Error('Failed to fetch selected lender');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      borrower: async (value) => {
        try {
          const res = await this.$api.userAccounts.fetchCompanyByDisplayBoxId(value);
          if (res) {
            this.borrower = res;
          } else {
            throw new Error('Failed to fetch selected borrower');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      cusip: async (value) => {
        try {
          const res = await this.$api.manualLoans.fetchEquityByCusip(value);
          if (res) {
            this.equity = res;
          } else {
            throw new Error('Failed to fetch selected equity');
          }
        } catch (e) {
          this.$log.warn(e);
        }
      },
      statuses: (value) => {
        if (value.length > 0) {
          this.statuses = value.split(',') as LoanStatus[];
        }
      },
      displayId: (value) => {
        this.displayId = value;
      },
      sortBy: (value) => {
        this.options.sortBy = [value];
      },
      sortDesc: (value) => {
        this.options.sortDesc = [value === 'true'];
      },
      page: (value) => {
        this.options.page = Math.max(parseInt(value), 1);
      },
      itemsPerPage: (value) => {
        const parsedValue = parseInt(value);
        this.options.itemsPerPage = parsedValue > 0 ? parsedValue : MAX_ITEMS_PER_PAGE;
      },
    };
  }
}
</script>

<style lang="scss"></style>
