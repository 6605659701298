import { useInterval } from '@/composables/native';
import {
  type LoginWithUserCredentialsRequest,
  type PlainMessage,
  serviceLogin,
  setConnectAccessToken,
} from '@/connect';
import router from '@/router/router';
import { store as vuex } from '@/store/store';
import type { JWT } from '@/utils/helpers/socket';
import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useStoreSocket } from './store-socket';

const STORAGE_JWT = 'STORAGE_JWT';
const CHANNEL_MULTITAB_JWT = 'CHANNEL_MULTITAB_JWT';

// time between heartbeats in ms: m * s * ms
export const HEARTBEAT_INTERVAL = 5 * 60 * 1000;

export const useStoreAuth = defineStore('auth', () => {
  const storeSocket = useStoreSocket();

  let loginEmail: string | null = null;

  const strJWT = ref(localStorage.getItem(STORAGE_JWT) ?? 'null');

  const jwt = computed<null | JWT>({
    get() {
      return JSON.parse(strJWT.value);
    },
    set(value) {
      strJWT.value = JSON.stringify(value);
      localStorage.setItem(STORAGE_JWT, strJWT.value);
    },
  });

  // Another tab
  const authChannel = new BroadcastChannel(CHANNEL_MULTITAB_JWT);
  authChannel.onmessage = async (event) => {
    // we need to be able to compare JWT objects by value, not reference
    const strRecievedToken = JSON.stringify(event.data);

    // we already have it, no action needed
    if (strRecievedToken === strJWT.value) return;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const wasLoggedIn = !!jwt.value;
    jwt.value = event.data;
    const isLoggedIn = !!jwt.value;

    if (wasLoggedIn && !isLoggedIn) {
      // if we were previously logged-in and another tab sends a not-logged-in token then we should logout as well
      await router.replace({ name: 'logout' });
    } else if (!wasLoggedIn && isLoggedIn) {
      // if we were previously not logged-in and another tab sends a logged-in token
      // then we should authenticate the socket (happens when assigning jwt.value) and go to the homepage
      await router.replace({ name: 'home' });
    }
  };

  // when the socket opens we need to authenticate to it
  storeSocket.onOpen(authentifyWebSocket);
  // when the socket receives a JWT token from the backend we need to store it
  // (for when we're waiting identity verification step)
  storeSocket.on('sendJWT', (tokens: JWT) => (jwt.value = tokens));

  const heartbeat = useInterval(
    () =>
      storeSocket.send({
        action: 'sessionHeartbeat',
        payload: HEARTBEAT_INTERVAL.toString(),
      }),
    HEARTBEAT_INTERVAL
  );

  async function authentifyWebSocket() {
    const accessToken = await refreshAuthTokens();
    if (accessToken === null) return;
    await storeSocket.send({ action: 'authenticate', payload: accessToken }, ['is-authenticated']);
    storeSocket.lockSocket.unlock('is-authenticated');
    heartbeat.start();
  }

  let timeoutId: null | number = null;
  watch(
    jwt,
    async (tokens, tokensOld) => {
      const { accessToken = null, refreshToken = null } = tokens ?? {};

      // For connect-es
      setConnectAccessToken(accessToken);

      if (timeoutId !== null) clearTimeout(timeoutId);

      // Refresh token on another tabs
      const isFirstWatchPass = tokensOld === undefined;
      if (tokens !== null || !isFirstWatchPass) {
        authChannel.postMessage(tokens);
      }

      const axiosCommonHeaders = (axios.defaults.headers.common ??= {});

      // just logged out
      if (accessToken === null || refreshToken === null) {
        if (isFirstWatchPass) return;

        heartbeat.stop();

        // clear all sessionStorage to avoid inconsistent state
        // (i.e. snapshot-manager, potentially others in the future)
        sessionStorage.clear();

        // reset axios Authorization header
        delete axiosCommonHeaders.Authorization;

        // handle vuex non-migrated stuff
        await vuex.dispatch('logout');

        storeSocket.reconnect();
        return;
      }

      // just logged in or just loaded the page where is already logged in
      if (tokens && !tokensOld && storeSocket.isConnected) {
        void authentifyWebSocket();
      }

      axiosCommonHeaders.Authorization = `Bearer ${accessToken}`;

      const jwtPayload = JSON.parse(atob(accessToken.split('.')[1]));
      const expirationTime = jwtPayload.exp * 1000;
      const expiresIn = expirationTime - Date.now();
      const refreshIn = expiresIn * 0.9;

      timeoutId = window.setTimeout(() => tryRefreshAuthTokens(refreshToken), refreshIn);
    },
    { immediate: true, flush: 'sync' }
  );

  async function tryRefreshAuthTokens(refreshToken: string) {
    await navigator.locks.request(CHANNEL_MULTITAB_JWT, { ifAvailable: true }, async (lock) => {
      if (lock === null) return; // only refresh token if we obtained lock
      const result = await serviceLogin.refreshToken({ token: refreshToken });
      jwt.value = result.success ? result.data : null;
      if (!result.success) {
        await logout();
        return router.replace({ name: 'login', params: { error: result.error } });
      }
    });
  }

  async function refreshAuthTokens() {
    if (jwt.value === null) return null;
    await tryRefreshAuthTokens(jwt.value.refreshToken);
    return jwt.value?.accessToken ?? null;
  }

  async function onLogin2FARequired(email: string, loginPassword: string) {
    await router.push({ name: 'login-2fa', params: { loginEmail: email, loginPassword } });
  }

  async function onLoginConfirmIdentityRequired(email: string) {
    await storeSocket.send(
      {
        action: 'socketConfirmIdentityWait',
        payload: email,
      },
      ['is-authenticated']
    );
    await router.push({ name: 'confirm-identity', params: { loginEmail: email } });
  }

  async function onIdentityConfirmedButUserNotLoggedIn(email: string) {
    // If the password is missing or from another user we cannot decrypt the auctions
    // => ask the user to login again so that we can decrypt
    await router.replace({
      name: 'login',
      params: { email, message: 'loginIdentityConfirmationSucceeded' },
    });
  }

  async function login(credentials: PlainMessage<LoginWithUserCredentialsRequest>) {
    loginEmail = credentials.email;
    const result = await serviceLogin.loginWithUserCredentials(credentials);
    if (result.success) {
      jwt.value = result.data;
    } else {
      switch (result.error) {
        case 'rest.LoginIncorrect':
        case 'rest.LoginTooManyAttempts':
          break;
        case 'rest.Login2FARequired':
          await onLogin2FARequired(credentials.email, credentials.password);
          return;
        case 'rest.LoginConfirmIdentityRequired':
          await onLoginConfirmIdentityRequired(credentials.email);
          return;
      }
    }
    return result;
  }

  async function loginWithSAMLTicket(ticket: string) {
    const result = await serviceLogin.loginWithSAMLTicket(ticket);
    if (result.success) {
      jwt.value = result.data;
    }
    return result;
  }

  async function logout() {
    try {
      if (jwt.value !== null) {
        // this may fail if token expired
        await serviceLogin.logout();
      }
    } finally {
      loginEmail = null;
      jwt.value = null;
    }
  }

  async function confirmIdentity(email: string, secret: string) {
    const result = await serviceLogin.confirmIdentity({ secret });
    if (result.success) {
      if (loginEmail !== email) {
        await onIdentityConfirmedButUserNotLoggedIn(email);
      } else {
        await router.replace({ name: 'home' });
      }
    }
    return result;
  }

  return {
    login,
    loginWithSAMLTicket,
    logout,
    confirmIdentity,
    isUserAuthenticated: computed(() => !!jwt.value),
    refreshAuthTokens,
  };
});

export type StoreAuth = ReturnType<typeof useStoreAuth>;
