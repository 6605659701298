import Decimal from 'decimal.js';

export interface CounterpartyCredit {
  companyId: number;
  companyDisplay: string;
  totalBilateral: Decimal;
  totalInSFT: Decimal;
  grandTotal: Decimal;
}

export function normalizeCounterpartyCredit(c: CounterpartyCredit): void {
  // input from server will have totalBilateral as a string, we convert it to Decimal
  const cWithStrTotalBilateral: { totalBilateral: string | Decimal } = c;
  if (typeof cWithStrTotalBilateral.totalBilateral === 'string') {
    c.totalBilateral = new Decimal(cWithStrTotalBilateral.totalBilateral);
  }

  // input from server will have totalInSFT as a string, we convert it to Decimal
  const cWithStrTotalInSFT: { totalInSFT: string | Decimal } = c;
  if (typeof cWithStrTotalInSFT.totalInSFT === 'string') {
    c.totalInSFT = new Decimal(cWithStrTotalInSFT.totalInSFT);
  }

  c.grandTotal = new Decimal(0).add(c.totalBilateral).add(c.totalInSFT);
}
