<template>
  <v-card class="flex d-flex flex-column">
    <v-container class="py-0" fluid>
      <v-row no-gutters>
        <v-col class="d-flex align-center pt-sm-4" cols="12" lg="6" md="12" sm="12" xl="6">
          <!-- @TODO: check if borrow/lend buttons will be restricted to certain users -->
          <div class="gap-1 d-flex flex-wrap">
            <aurora-btn-dropdown
              v-if="canBorrow"
              color="secondary"
              data-test="borrow-button"
              :disabled="!hasTraderUserRole"
              main-text="Borrow"
              split
              timeframe="createLoans"
              @click="$emit('create-order', 'borrower')"
            >
              <v-list dense>
                <v-list-item @click="uploadDialog = 'borrower'">
                  <v-list-item-content>
                    <v-list-item-title>Upload Demand Orders</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon dense small> mdi-upload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </aurora-btn-dropdown>

            <aurora-btn-dropdown
              v-if="canLend"
              color="secondary"
              data-test="lend-button"
              :disabled="!hasTraderUserRole"
              main-text="Lend"
              split
              timeframe="createLoans"
              @click="$emit('create-order', 'lender')"
            >
              <v-list dense>
                <v-list-item @click="uploadDialog = 'lender'">
                  <v-list-item-content>
                    <v-list-item-title>Upload Supply Orders</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon dense small> mdi-upload</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </aurora-btn-dropdown>
          </div>

          <template v-if="selectedItems.length">
            <span class="ml-2 mr-2">
              {{
                selectedItems.length
                  ? `${selectedItems.length}/${formattedOrders.length} selected`
                  : ''
              }}
            </span>
          </template>

          <aurora-btn
            v-if="selectedItems.length >= 1"
            color="red"
            data-test="batch-action-cancel"
            :disabled="!hasTraderUserRole"
            small
            timeframe="createLoans"
            @click="openDialog = 'cancelAll'"
          >
            Cancel
          </aurora-btn>

          <aurora-btn
            v-if="selectedItems.length"
            color="secondary ml-2"
            data-test="order-list-edit-btn"
            :disabled="!hasTraderUserRole"
            small
            timeframe="createLoans"
            @click="openDialog = 'modifyAll'"
          >
            Edit
          </aurora-btn>
        </v-col>

        <v-col class="gap-1 d-flex align-center pt-sm-4" cols="12" lg="6" md="12" sm="12" xl="6">
          <simple-equity-search
            v-model="selectedEquity"
            class="simple-equity-search"
            clearable
            label="Security"
          />
          <v-select v-model="selectedSide" class="side" clearable :items="sideItems" label="Side" />
          <v-select
            v-model="selectedRouting"
            class="side"
            clearable
            :items="routingItems"
            label="Active/Inactive"
          />
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-switch
                  class="mt-0"
                  data-test="show-all-orders"
                  :false-value="false"
                  hide-details
                  label="show archived"
                  :true-value="true"
                  :value="showAllMarketplaceOrders"
                  @change="onChangeFilter"
                />
              </span>
            </template>
            <span class="tip-background">
              Enabling show archived will include in the results Orders that were closed before
              today
            </span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="mt-n2 mb-4" no-gutters>
        <v-tabs ref="tabs" v-model="selectedTabIndex" background-color="transparent">
          <v-tab
            v-for="tabName in tabNames"
            :key="tabName"
            :value="tabName"
            @change="selectedItems = []"
          >
            {{ tabName }} ({{ groupedOrders[tabName].length }})
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>
    <!-- Dialog will not monitor changes to selectedItems list, it will only be used to initialize a local list on mount. -->
    <marketplace-batch-cancel-all-dialog
      v-if="openDialog === 'cancelAll'"
      :items="selectedItems"
      @back="openDialog = null"
      @close-modal="openDialog = null"
      @success="selectedItems = []"
    />

    <!-- Dialog will not monitor changes to selectedItems list, it will only be used to initialize a local list on mount. -->
    <marketplace-batch-modify-all-dialog
      v-if="openDialog === 'modifyAll'"
      :items="selectedItems"
      v-on="$listeners"
      @back="openDialog = null"
      @close-modal="openDialog = null"
      @success="selectedItems = []"
    />

    <marketplace-orders-table
      data-test="marketplace-orders-table"
      :items="formattedOrders"
      :omit-headers="['company', 'orderRef']"
      :selected-items.sync="selectedItems"
      @action="$emit('action', $event)"
      @edit-order="$emit('edit-order', $event)"
      @view-order="$emit('view-order', $event)"
    />

    <marketplace-orders-upload-dialog
      v-if="uploadDialog !== null"
      :side="uploadDialog"
      @close-modal="uploadDialog = null"
    />
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import Component from 'vue-class-component';
import { Api, Equity } from '@/modules/common/types/api';
import MarketplaceBatchCancelAllDialog from '@/modules/marketplace/components/MarketplaceBatchCancelAllDialog.vue';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import MarketplaceBatchModifyAllDialog from '@/modules/marketplace/components/MarketplaceBatchModifyAllDialog.vue';
import MarketplaceOrdersTable from '@/modules/marketplace/components/MarketplaceOrdersTable.vue';
import MarketplaceOrderDetailsDialog from '@/modules/marketplace/components/MarketplaceOrderDetailsDialog.vue';
import { SocketEvents } from '@/store/store';
import MarketplaceOrdersUploadDialog from '@/modules/marketplace/components/MarketplaceOrdersUploadDialog.vue';
import UploadProgressPopup from '@/modules/common/components/UploadProgressPopup.vue';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { Watch } from 'vue-property-decorator';
import { throttle } from 'lodash';

type TabName = 'all' | 'open' | 'partial' | 'filled' | 'closed';
type TabbedOrders = Record<TabName, Api.Marketplace.Order[]>;

@Component({
  components: {
    SimpleEquitySearch,
    MarketplaceOrdersTable,
    MarketplaceOrderDetailsDialog,
    MarketplaceOrdersUploadDialog,
    UploadProgressPopup,
    BtnDropdown,
    MarketplaceBatchCancelAllDialog,
    MarketplaceBatchModifyAllDialog,
  },
  computed: {
    ...mapState(['socketEvents']),
    ...mapGetters(['hasTraderUserRole', 'canBorrow', 'canLend']),
  },
})
export default class MarketplaceOrdersList extends Vue {
  protected socketEvents!: SocketEvents;
  protected hasTraderUserRole!: boolean;
  protected canBorrow!: boolean;
  protected canLend!: boolean;

  protected marketplaceOrders: Api.Marketplace.OrdersResponse = [];
  protected showAllMarketplaceOrders = false;
  protected selectedEquity: Equity | null = null;
  protected sideItems = [
    { text: 'Lender', value: 'lender' },
    { text: 'Borrower', value: 'borrower' },
  ];
  protected selectedSide: 'lender' | 'borrower' | null = null;
  protected routingItems = [
    { text: 'Active', value: 'routed' },
    { text: 'Inactive', value: 'unrouted' },
  ];
  protected selectedRouting: Api.Marketplace.Order['routingStatus'] | null = null;
  protected selectedItemsIds: string[] = [];
  protected openDialog: 'cancelAll' | 'modifyAll' | null = null;
  protected search = '';
  protected selectedTabIndex = 0; // all
  protected tabNames: TabName[] = ['all', 'open', 'partial', 'filled', 'closed'];
  protected uploadDialog: 'borrower' | 'lender' | null = null;
  protected orderRefToCancel: string | null = null;
  protected throttledFetchOrders = throttle(this.fetchOrders, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  /**
   * Get the order objects using selectedItemsId
   */
  protected get selectedItems(): Api.Marketplace.Order[] {
    return this.formattedOrders.filter(({ orderRef }) => this.selectedItemsIds.includes(orderRef));
  }

  /**
   * v-data-table always emits the whole object, but the object can become stale
   * once the original object is updated (via sockets, for example)
   * therefore, we store the ids (and get a fresh version of the loan object via getter)
   */
  protected set selectedItems(items: Api.Marketplace.Order[]) {
    this.selectedItemsIds = items.map((order) => order.orderRef);
  }

  protected get groupedOrders(): TabbedOrders {
    let filteredOrders = this.marketplaceOrders;

    if (this.selectedEquity) {
      filteredOrders = filteredOrders.filter((i) => i.equity.id === this.selectedEquity?.id);
    }

    if (this.selectedSide) {
      filteredOrders = filteredOrders.filter((i) => i.side === this.selectedSide);
    }

    if (this.selectedRouting !== null) {
      filteredOrders = filteredOrders.filter((i) => i.routingStatus === this.selectedRouting);
    }

    const grouped = filteredOrders.reduce<TabbedOrders>(
      (acc, order) => {
        acc.all.push(order);
        if (order.status === 'open') {
          acc.open.push(order);
        }
        if (order.status === 'open' && order.filled > 0) {
          acc.partial.push(order);
        }
        if (order.status === 'filled') {
          acc.filled.push(order);
        }
        if (order.status === 'canceled' || order.status === 'expired') {
          acc.closed.push(order);
        }
        return acc;
      },
      {
        all: [],
        open: [],
        partial: [],
        filled: [],
        closed: [],
      }
    );

    return grouped;
  }

  protected get selectedTabName(): TabName {
    return this.tabNames[this.selectedTabIndex];
  }

  protected get formattedOrders(): Api.Marketplace.Order[] {
    return this.groupedOrders[this.selectedTabName];
  }

  @Watch('socketEvents.marketplace.orders')
  protected onSocketEvents(): void {
    void this.throttledFetchOrders();
  }

  protected mounted(): void {
    void this.fetchOrders();
  }

  protected async fetchOrders(): Promise<void> {
    this.marketplaceOrders = await this.$api.marketplace.fetchOrdersList({
      showAll: this.showAllMarketplaceOrders,
    });
  }

  protected onChangeFilter(showAllLoans: boolean): void {
    this.showAllMarketplaceOrders = showAllLoans;
    void this.fetchOrders();
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.simple-equity-search {
  width: 2.5rem;
}

.side {
  width: 2.5rem;
}

.filter-list {
  width: 2.5rem;
}
</style>
