<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    scrollable
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline"> Delete Demand </span>
        </v-card-title>

        <v-card-text>
          <!-- Form summary -->
          <v-container v-shortkey="['enter']" @shortkey="submitForm()">
            <v-row class="ma-0 text--primary">
              <v-col class="py-0">
                <div v-if="isBatchOperation">
                  Delete the <b>{{ selectedItems.length }}</b> selected demand items?
                </div>
                <div v-else>Delete the selected demand item?</div>
              </v-col>
            </v-row>

            <div v-if="isBatchOperation" class="py-8 pl-10 borrower-orders-list">
              <v-row v-for="item of selectedItems" :key="item.id" align="center" class="pb-1">
                <v-col class="py-0"> {{ item.equity.ticker }} [{{ item.equity.cusip }}] </v-col>
                <v-col class="py-0"> Quantity: <pretty-number :value="item.quantity" /></v-col>
                <v-col class="py-0"> Limit: <rate-output :rate="item.rate" /> </v-col>
              </v-row>
            </div>
            <div v-else class="py-8 pl-10">
              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Security </v-col>
                <v-col class="py-0">
                  {{ itemToDelete.equity.ticker }} [{{ itemToDelete.equity.cusip }}]
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Quantity </v-col>
                <v-col class="py-0">
                  <pretty-number :value="itemToDelete.quantity" />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Limit </v-col>
                <v-col class="py-0">
                  <rate-output :rate="itemToDelete.rate" />
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="showError || showSuccess">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div
                  class="v-alert v-alert--dense text--primary text-body-2 text-center"
                  :class="{ error: showError, success: showSuccess }"
                >
                  <div v-if="showError">
                    {{ errorMsgs.apiErrors.join('\n') }}
                  </div>
                  <div v-if="showSuccess">Your demand item(s) have been deleted.</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Dialog box actions -->
        <v-card-actions class="d-flex">
          <div class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn
              color="secondary"
              data-test="cancel-btn"
              :disabled="isProcessing"
              @click="closeDialog()"
            >
              {{ $t('dialogs.cancelButton') }}
            </v-btn>

            <v-btn
              color="primary"
              :disabled="isProcessing"
              :loading="isProcessing"
              type="submit"
              @click="submitForm()"
            >
              {{ $t('dialogs.confirmButton') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { mapActions } from 'vuex';
import wait from '@/modules/common/services/wait';
import Vue, { PropType } from 'vue';
import { BorrowerOrderListItem } from '@/modules/order-management/types/borrower-orders';

interface FormErrors {
  apiErrors: string[];
}

@Component({
  components: {},
  props: {
    selectedItems: {
      type: Array as PropType<BorrowerOrderListItem[]>,
      required: true,
    },
  },
  methods: {
    ...mapActions(['fetchBorrowerOrders']),
  },
})
export default class DeleteBorrowerOrderDialog extends Vue {
  // props
  protected readonly selectedItems!: BorrowerOrderListItem[];

  // store state/actions/etc
  protected fetchBorrowerOrders!: () => Promise<void>;

  protected isProcessing = false;
  protected showSuccess = false;
  protected apiErrors: string[] = [];

  protected get isBatchOperation(): boolean {
    return this.selectedItems.length > 1;
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
    };

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  /**
   * the item to delete when just one Borrower Order selected
   */
  protected get itemToDelete(): BorrowerOrderListItem {
    return this.selectedItems[0];
  }

  protected async submitForm(): Promise<void> {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;

    try {
      const ids = this.selectedItems.map((item) => item.id);
      await this.$api.orderManagement.batchDeleteBorrowerOrder(ids);

      // success, alert user and then close form
      this.showSuccess = true;

      // reload the list in the background
      void this.fetchBorrowerOrders();

      await wait(1200);
      await this.closeDialog();
    } catch (err) {
      this.apiErrors = [`${err}`];
    } finally {
      this.isProcessing = false;
    }
  }

  protected closeDialog(): void {
    // deselect item(s) in parent list and close modal
    this.$emit('update:selectedItems', []);
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  min-height: 12rem;
}
.borrower-orders-list {
  max-height: 20vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
