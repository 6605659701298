import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

export interface DialogOptions {
  title?: string | TranslateResult;
  message?: string | TranslateResult;
  icon?: string;
  color?: string;
  borderColor?: 'none' | 'error' | 'warning';
  positionAt?: 'bottom' | 'center' | 'top';
  persistent?: boolean;
  acceptText?: string | TranslateResult;
  isRejectable?: boolean;
  rejectText?: string | TranslateResult;
}

export interface ConfirmDialogImperativeOptions extends DialogOptions {
  onAccept?: () => void;
  onReject?: () => void;
}

export class DialogManager extends Vue {
  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    v.prototype.$dialog = singleton;
  }

  public ask(options: ConfirmDialogImperativeOptions): void {
    this.$root.$emit('askQuestion', options);
  }

  public confirm(options: ConfirmDialogImperativeOptions): void {
    this.$root.$emit('confirmMessage', options);
  }

  public alert(options: ConfirmDialogImperativeOptions): void {
    this.$root.$emit('alertMessage', options);
  }

  public close(): void {
    this.$root.$emit('closeDialog');
  }
}

export function useDialogManager(): DialogManager {
  return Vue.prototype.$dialog;
}
