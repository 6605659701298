<template>
  <div>
    <v-col v-if="fetchStatus === 'fetching'" class="center">
      <v-row class="justify-center">
        <fulfilling-bouncing-circle-spinner :animation-duration="1500" color="#4caf50" :size="20" />
        <div class="ml-2">Searching for liquidity ...</div>
      </v-row>
    </v-col>

    <v-col v-if="fetchStatus === 'failed'" class="mt-4">
      <v-row>
        <div class="error v-alert v-alert--dense text--primary text-body-2 text-center">
          Failed to fetch available liquidity
        </div>
      </v-row>
    </v-col>

    <template v-if="fetchStatus === 'completed'">
      <v-col v-if="!items.length" class="mt-4">
        <v-row>
          <h3>No liquidity available</h3>
        </v-row>
      </v-col>

      <div v-else class="table-container mt-4">
        <div>
          <v-data-table
            dense
            disable-filtering
            disable-pagination
            disable-sort
            fixed-header
            :headers="tableHeaders"
            hide-default-footer
            item-key="key"
            :items="items"
          >
            <template #item="{ item }">
              <tr>
                <td>{{ item.lender.name }}</td>
                <td><pretty-number :value="item.quantity" /></td>
                <td class="text-end">
                  <rate-output :rate="item.rate" />
                </td>
                <td class="text-end">
                  <rate-output :precision="2" :rate="item.independentAmountRate" />
                </td>
                <td class="text-end">
                  <numeric-input
                    v-if="liquidity && liquidity.key === item.key"
                    v-model="quantityValue"
                    autofocus
                    :error-messages="errorMessages"
                    :max="liquidity?.quantity"
                    :min="0"
                    :step="100"
                    type="integer"
                  >
                    <template #append>
                      <v-btn
                        class="set-max pa-0"
                        data-test="set-max-quantity"
                        outlined
                        x-small
                        @click="setQuantityToMax()"
                      >
                        Max
                      </v-btn>
                    </template>
                  </numeric-input>
                  <div v-else class="py-4">
                    <v-btn
                      color="primary"
                      data-test="set-liquidity"
                      outlined
                      small
                      @click="setLiquidity(item)"
                    >
                      Set quantity
                    </v-btn>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { DataTableHeader } from 'vuetify';
import { BorrowLiquidityWithKey } from '@/utils/api/borrower';
import { Equity } from '@/modules/common/types/api';
import { ClientConfig } from '@/utils/helpers/rest';

const POLLING_INTERVAL = 1 * 1000;

const tableHeaders: DataTableHeader[] = [
  { text: 'Lender', value: 'lender' },
  { text: 'Available', value: 'available' },
  { text: 'Rate', value: 'rate', align: 'end' },
  { text: 'IA', value: 'independentAmountRate', align: 'end' },
  { text: '', value: 'quantity', width: '16rem' },
];

@Component({
  props: {
    equity: Object as PropType<Equity>,
    liquidity: Object as PropType<BorrowLiquidityWithKey>,
    quantity: Number,
    /**
     * An array of the available liquidity found for the given equity.
     * Use with .sync to keep updated
     */
    availableLiquidity: Array as PropType<BorrowLiquidityWithKey[]>,
    errorMessages: {},
  },
  methods: {
    ...mapActions(['getBorrowLiquidity']),
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class BorrowDialogLiquiditySelector extends Vue {
  // props
  protected readonly equity!: Equity;
  protected readonly liquidity!: BorrowLiquidityWithKey | null;
  protected readonly quantity!: number | null;
  protected readonly errorMessages?: string[];

  // state
  protected clientConfig!: ClientConfig;

  // store methods
  protected getBorrowLiquidity!: (equityId: number) => Promise<BorrowLiquidityWithKey[]>;

  private fetchStatus: 'fetching' | 'completed' | 'failed' = 'fetching';
  private items: BorrowLiquidityWithKey[] = [];
  private pollInterval: ReturnType<typeof setInterval> | null = null;
  private pollStatus: 'updating' | 'waiting' = 'waiting';

  protected get tableHeaders(): DataTableHeader[] {
    return tableHeaders;
  }

  protected get quantityValue(): number | null {
    // because quantity"is mandatory on NumericInput, ensure "quantity" is null (and not "undefined")
    return typeof this.quantity === 'undefined' ? null : this.quantity;
  }

  protected set quantityValue(value: number | '' | null) {
    /*
     * Empty string check is necessary to avoid runtime error
     * (even though v-model.number="quantityValue" is used)
     */
    this.$emit('update:quantity', value === '' ? null : value);
  }

  @Watch('equity')
  protected onEquityChange(): void {
    void this.fetchAndStartPolling();
  }

  protected mounted(): void {
    void this.fetchAndStartPolling();
  }

  protected destroyed(): void {
    this.stopPolling();
  }

  private async fetchAndStartPolling(): Promise<void> {
    this.stopPolling();
    this.fetchStatus = 'fetching';
    await this.fetchLiquidity();
    this.startPolling();
  }

  private startPolling(): void {
    this.stopPolling();
    this.pollInterval = setInterval(async () => {
      // Only fetch again if previous has finished (or skip 1 cycle avoiding overlaps)
      if (this.pollStatus === 'waiting') {
        this.pollStatus = 'updating';
        await this.fetchLiquidity();
        this.pollStatus = 'waiting';
      }
    }, POLLING_INTERVAL);
  }

  private stopPolling(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
    this.pollStatus = 'waiting';
  }

  private setLiquidity(liquidity: BorrowLiquidityWithKey | null): void {
    this.$emit('update:liquidity', liquidity);
  }

  private setQuantityToMax(): void {
    this.quantityValue = this.liquidity?.quantity || null;
  }

  private async fetchLiquidity(): Promise<void> {
    try {
      this.items = await this.getBorrowLiquidity(this.equity.id);
      this.fetchStatus = 'completed';
      const newSelectedLiquidity =
        this.items.find((liquidity) => liquidity.key === this.liquidity?.key) || null;

      if (this.liquidity && !newSelectedLiquidity) {
        // Reset quantity when liquidity is not available anymore
        this.quantityValue = null;
      }

      // Update the selected liquidity's details and the updated list of available liquidity
      this.$emit('update:liquidity', newSelectedLiquidity);
      this.$emit('update:availableLiquidity', this.items);
    } catch (e) {
      this.fetchStatus = 'failed';
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 30vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}

.v-btn.set-max {
  height: 1.5rem;
}
</style>
