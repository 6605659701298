import logger from '@/modules/common/services/logger.service';
import i18n from '@/localisation/i18n';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { ApiError } from '@/utils/errors';
import { Api } from '@/modules/common/types/api';
import { useStoreAuth } from '@/store/store-auth';

export function installAxiosInterceptors(): void {
  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // No need to report success
      return response;
    },
    async function handleError(error) {
      const storeAuth = useStoreAuth();

      if (
        isAxiosResponseError(error) &&
        error.response?.status === 401 &&
        error.config &&
        storeAuth.isUserAuthenticated
      ) {
        try {
          const authToken = await storeAuth.refreshAuthTokens();
          error.config.headers.Authorization = `Bearer ${authToken}`;
          return axios(error.config);
        } catch (e) {
          return handleError(e);
        }
      }

      // Any status codes that falls outside the range of 2xx is reported on the console
      logErrorResponse(error);
      if (error.response && error.response.status === 401 && error.config.url !== '/api/1/me') {
        // Duplication warning: logout redirect is also present on api.service.ts
        // directly manipulating the window.location.href is not ideal
        // when all API calls are moved to the api.service.ts this can be rethinked
        window.location.href = '/#/logout';
      }
      return Promise.reject(error);
    }
  );
}

function logErrorResponse(e: AxiosError | Record<string, unknown>): void {
  // not an axios error from the server? must be a client message
  if (!isAxiosResponseError(e)) {
    // skip the `response` handling
    return;
  }

  const msgKey = e.response?.data.msgkey || e.toString();
  let msgArgs: Record<string, string> = {};
  if (e.response?.data.msgargs) {
    msgArgs = e.response.data.msgargs || {};
  }

  // if the backend returns 'ReportToEngineering' it should be accompanied by an 'ERR-UUID'
  if (msgKey === 'rest.ReportToEngineering') {
    logger.error(`ReportToEngineering: ERR-UUID=${msgArgs.uuid || 'unknown'}`);
  }

  // for all errors print the 'x-request-id'
  if (e.response?.status && e.response.status >= 400) {
    logger.error(
      `Status ${e.response.status}: x-request-id=${
        e.response.headers['x-request-id'] || 'unknown'
      }: ${msgKey}: ${JSON.stringify(msgArgs)}`
    );
  }
}

export function i18nServerMessage(
  e: AxiosError | ApiError | Record<string, unknown> | unknown,
  fallback?: string
): string {
  try {
    if (!isAxiosResponseError(e) && !(e instanceof ApiError)) {
      // Not an error object -> skip the `response` handling
      // build an alternative message in the exception handler
      throw e;
    }

    let data: Api.ErrorResponse | Api.SimpleErrorResponse | undefined;
    if (isAxiosResponseError(e)) {
      data = e.response?.data;
    } else {
      data = e.responseData;
    }

    if (!data) {
      return `${e}`;
    }

    if (data.msg && !data.msgkey) {
      return i18n.tc(data.msg);
    }

    const msgKey = data?.msgkey || e.toString();
    const isI18nResponse = !!data?.msgkey;
    let msgArgs: Record<string, string> = {};
    if (isI18nResponse && data?.msgargs) {
      msgArgs = (data.msgargs as Record<string, string>) || {};
    }

    // Pluralize automatically if there is a {n} or {count} argument
    const choice = parseInt(msgArgs.n, 10) || parseInt(msgArgs.count, 10) || 0;
    const msgTxt = i18n.tc(msgKey, choice, msgArgs);
    if (isI18nResponse && msgTxt === msgKey) {
      // log missing translations
      logger.debug(`No translation for: ${msgKey}`);
      Sentry.captureMessage(`No translation for: ${msgKey}`);
    }
    return msgTxt;
  } catch (e2) {
    // server did not return 'StatusResponse{}' as defined in rest/buffers.go
    // build an alternative message
    const msgKey = fallback ? fallback : 'rest.ServiceUnavailable';
    const msgTxt = i18n.tc(msgKey, 0, { error: e2 });

    return msgTxt;
  }
}

export function errorString(e: unknown): string {
  if (typeof e === 'string') {
    return e;
  } else if (e && typeof e === 'object' && 'message' in e) {
    return `${(e as unknown as Record<string, unknown>).message}`;
  } else {
    return 'Unexpected error';
  }
}

/**
 * Provides type narrowing to an Axios Error with a response object present
 * i.e. a server error returned from axios
 */
export function isAxiosResponseError(err: unknown): err is AxiosError {
  if (err && typeof err === 'object' && 'response' in err) {
    return true;
  } else {
    return false;
  }
}
