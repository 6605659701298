<template>
  <div>
    <v-btn icon small @click="handleBellClick">
      <v-badge v-if="unreadCount > 0" bordered color="error" :content="unreadCount" overlap>
        <v-icon>mdi-bell</v-icon>
      </v-badge>
      <v-icon v-else>mdi-bell</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
/**
 * BrowserNotification is small component to display the number of notifications
 * and optionally trigger a native Browser Notification.
 */
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { SocketMessage, SocketNotification } from '@/utils/helpers/socket';
import { AppState } from '@/store/store';

@Component({
  props: {
    notificationTitle: String,
  },
  methods: {
    ...mapActions(['markNotificationRead', 'fetchMyNotificationCounts']),
  },
  computed: {
    ...mapState(['lastNotification', 'unreadNotificationCount']),
  },
})
export default class BrowserNotification extends Vue {
  // store state/getters/actions
  protected lastNotification!: AppState['lastNotification'];
  protected unreadNotificationCount!: number;
  private notificationTitle!: string;
  private fetchMyNotificationCounts!: () => Promise<void>;
  private markNotificationRead!: (ids: string[]) => Promise<null>;

  protected get unreadCount(): number {
    return this.unreadNotificationCount;
  }

  @Watch('lastNotification')
  protected showBrowserNotification(sm: SocketMessage<SocketNotification>): void {
    // https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification#Parameters
    Vue.prototype.$notification.show(
      this.notificationTitle,
      {
        body: sm.payload.message,
        icon: '/favicon.ico',
        requireInteraction: true,
      },
      {
        onclick: () => {
          if (sm.payload.notificationOnClick != null) {
            // @TODO shouldn't hardcode paths in the backend
            // instead use route names, so that we can update paths as needed
            void this.$router.push({
              path: sm.payload.notificationOnClick,
              params: {},
            });
          }

          // clicking the browser notification marks the original notification as 'read'
          if (sm.payload.notificationRefID != null) {
            void this.markNotificationRead([sm.payload.notificationRefID]);
          }
        },
      }
    );
  }

  protected async mounted(): Promise<void> {
    await this.fetchMyNotificationCounts();
  }

  /**
   * Temporary solution until notifications 2.0 is implemented
   * Clicking toggles between notifications and previous page
   */
  protected handleBellClick(): void {
    if (this.$route.name === 'notifications') {
      this.$router.back();
    } else {
      void this.$router.push({ name: 'notifications' });
    }
  }
}
</script>

<style lang="scss" scoped></style>
