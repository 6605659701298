import { ApiService } from '@/modules/common/services/api.service';
import { Api } from '@/modules/common/types/api';
import { Decimal } from 'decimal.js';
import { noop } from 'lodash';
import Vue from 'vue';
import { TimeInForce } from '@/utils/helpers/tif_options';

export class OrderManagementApiService extends ApiService {
  private featureUrl = '/oms';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.orderManagement = singleton;
    } else {
      v.prototype.$api = {
        orderManagement: singleton,
      };
    }
  }

  /**
   * Get a paginated list of all Lender Orders
   */
  public async getLenderOrdersList(
    offset = 0,
    limit = 100,
    dir: 'asc' | 'desc' = 'asc',
    sort?: string,
    filter?: string
  ): Promise<Api.LenderOrders.LenderOrderListResponse> {
    const url = this.baseUrl + this.featureUrl + `/lender/orders`;
    const params = { filter, sort, dir, limit, offset };

    const { data } = await this.axios.get<Api.LenderOrders.Raw.OrderListResponse>(url, {
      params,
    });

    // convert various values from raw API response to appropriate types
    const result: Api.LenderOrders.LenderOrderListResponse = {
      ...data,
      data: data.data.map((item) => ({
        ...item,
        equity: { ...item.equity, lastClosePrice: new Decimal(item.equity.lastClosePrice) },
        rate: new Decimal(item.rate),
        timeInForce: TimeInForce[item.timeInForce],
        expiredAt: item.expiredAt ? new Date(item.expiredAt) : null,
      })),
    };

    return result;
  }

  /**
   * Create a new Lender Order
   */
  public async createLenderOrder(
    equityId: number,
    quantity: number,
    rate: Decimal | string,
    timeInForce: TimeInForce | string,
    isLendable: boolean
  ): Promise<void> {
    const url = this.baseUrl + this.featureUrl + '/lender/orders';
    const postData: Api.LenderOrders.NewLenderOrderRequest = {
      equityId,
      quantity,
      rate,
      timeInForce,
      isLendable,
    };
    return this.axios.post<void>(url, postData).then(noop);
  }

  /**
   * Update an existing Lender Order
   */
  public async updateLenderOrder(
    id: number,
    quantity: number,
    rate: Decimal | string,
    timeInForce: TimeInForce | string,
    isLendable: boolean
  ): Promise<void> {
    const url = this.baseUrl + this.featureUrl + `/lender/orders/${id}`;
    const putData: Api.LenderOrders.UpdateLenderOrderRequest = {
      quantity,
      rate,
      timeInForce,
      isLendable,
    };
    return this.axios.put<void>(url, putData).then(noop);
  }

  /**
   * Batch update lendability of multiple Lender Orders
   */
  public async batchUpdateLenderOrderLendability(
    lenderOrders: Api.LenderOrders.LendabilityChangeRequestItem[]
  ): Promise<void> {
    const url = this.baseUrl + this.featureUrl + '/lender/orders/lendability';
    return this.axios.post(url, lenderOrders).then(noop);
  }

  /**
   * Batch delete multiple Lender Positions
   */
  public async batchDeleteLenderOrderLendability(ids: number[]): Promise<void> {
    const url = this.baseUrl + this.featureUrl + '/lender/orders/batch-delete';
    return this.axios.post<number[]>(url, ids).then(noop);
  }

  /**
   * Upload a file containing data to be parsed for creating multiple new Lender Orders
   */
  public async uploadLenderOrdersFile(
    file: File,
    skipWarnings: boolean
  ): Promise<Api.LenderOrders.LenderOrderUploadResponse> {
    const url = this.baseUrl + this.featureUrl + '/lender/orders/file-upload';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('skipWarnings', `${skipWarnings}`);

    const { data } = await this.axios.post<Api.LenderOrders.LenderOrderUploadResponse>(
      url,
      formData,
      { headers }
    );

    return data;
  }

  /**
   * Get a paginated list of all Borrower Orders
   */
  public async getBorrowerOrdersList(
    offset = 0,
    limit = 100,
    dir: 'asc' | 'desc' = 'asc',
    sort?: string,
    filter?: string
  ): Promise<Api.BorrowerOrders.BorrowerOrderListResponse> {
    const url = this.baseUrl + this.featureUrl + `/borrower/orders`;
    const params = { filter, sort, dir, limit, offset };

    const { data } = await this.axios.get<Api.BorrowerOrders.Raw.OrderListResponse>(url, {
      params,
    });

    // convert various values from raw API response to appropriate types
    const result: Api.BorrowerOrders.BorrowerOrderListResponse = {
      ...data,
      data: data.data.map((item) => ({
        ...item,
        equity: { ...item.equity, lastClosePrice: new Decimal(item.equity.lastClosePrice) },
        rate: new Decimal(item.rate),
        timeInForce: TimeInForce[item.timeInForce],
        expiredAt: item.expiredAt ? new Date(item.expiredAt) : null,
      })),
    };

    return result;
  }

  /**
   * Checks for the existence of a Borrower Order with the same data
   */
  public async checkBorrowerOrderExistence(
    equityId: number,
    rate: Decimal | string
  ): Promise<Api.BorrowerOrders.CheckBorrowerOrderExistenceResponse> {
    const url = this.baseUrl + this.featureUrl + `/borrower/orders/check-exists`;
    const postData: Api.BorrowerOrders.CheckBorrowerOrderExistenceRequest = {
      equityId,
      rate,
    };

    const { data } = await this.axios.post<Api.BorrowerOrders.CheckBorrowerOrderExistenceResponse>(
      url,
      postData
    );

    return data;
  }

  /**
   * Create a new Borrower Order
   */
  public async createBorrowerOrder(
    equityId: number,
    quantity: number,
    rate: Decimal | string,
    timeInForce: TimeInForce | string,
    isBorrowable: boolean
  ): Promise<void> {
    const url = this.baseUrl + this.featureUrl + '/borrower/orders';
    const postData: Api.BorrowerOrders.NewBorrowerOrderRequest = {
      equityId,
      quantity,
      rate,
      timeInForce,
      isBorrowable,
    };
    return this.axios.post<void>(url, postData).then(noop);
  }

  /**
   * Update an existing Borrower Order
   */
  public async updateBorrowerOrder(
    id: string,
    quantity: number,
    rate: Decimal | string,
    timeInForce: TimeInForce | string,
    isBorrowable: boolean
  ): Promise<void> {
    const url = this.baseUrl + this.featureUrl + `/borrower/orders/${id}`;
    const putData: Api.BorrowerOrders.UpdateBorrowerOrderRequest = {
      quantity,
      rate,
      timeInForce,
      isBorrowable,
    };
    return this.axios.put<void>(url, putData).then(noop);
  }

  /**
   * Batch update borrowability of multiple Borrower Orders
   */
  public async batchUpdateBorrowerOrderBorrowability(
    borrowerOrders: Api.BorrowerOrders.BorrowabilityChangeRequestItem[]
  ): Promise<void> {
    const url = this.baseUrl + this.featureUrl + '/borrower/orders/borrowability';
    return this.axios.post(url, borrowerOrders).then(noop);
  }

  /**
   * Batch delete multiple Borrower Positions
   */
  public async batchDeleteBorrowerOrder(ids: string[]): Promise<void> {
    const url = this.baseUrl + this.featureUrl + '/borrower/orders/batch-delete';
    return this.axios.post<string[]>(url, ids).then(noop);
  }

  /**
   * Upload a file containing data to be parsed for creating multiple new Borrower Orders
   */
  public async uploadBorrowerOrdersFile(
    file: File,
    skipWarnings = false
  ): Promise<Api.BorrowerOrders.BorrowerOrderUploadResponse> {
    const url = this.baseUrl + this.featureUrl + `/borrower/orders/file-upload`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('skipWarnings', `${skipWarnings}`);

    const { data } = await this.axios.post<Api.BorrowerOrders.BorrowerOrderUploadResponse>(
      url,
      formData,
      { headers }
    );

    return data;
  }
}
