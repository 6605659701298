import Vue from 'vue';
import { ApiService } from '@/modules/common/services/api.service';
import { Api } from '@/modules/common/types/api';
import {
  normalizeContractDetails,
  normalizeContractSummary,
  normalizeTermLoansUploadQuantitiesResponse,
  normalizeTermLoansUploadSecuritiesResponse,
} from './termloans-normalize.service';

export class TermLoansApiService extends ApiService {
  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.termLoans = singleton;
    } else {
      v.prototype.$api = {
        termLoans: singleton,
      };
    }
  }

  public async fetchContracts(): Promise<Api.TermLoans.ContractSummary[]> {
    const url = this.baseUrl + '/term-loan-contract';
    const res = await this.axios.get<Api.TermLoans.Raw.ContractsResponse>(url);

    const mapped = res.data.items.map((item) => ({
      ...item,
      ...normalizeContractSummary(item),
    }));

    return mapped;
  }

  public async fetchDetails(displayId: string): Promise<Api.TermLoans.ContractDetails> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}`;
    const res = await this.axios.get<Api.TermLoans.Raw.ContractDetailsResponse>(url);

    return normalizeContractDetails(res.data);
  }

  public create = async (
    payload: Api.TermLoans.ContractRequest
  ): Promise<Api.TermLoans.ContractFormResponse> => {
    const url = this.baseUrl + '/term-loan-contract';
    const res = await this.axios.post<Api.TermLoans.ContractFormResponse>(url, payload);
    return res.data;
  };

  public update = async (
    displayId: string,
    payload: Api.TermLoans.ContractRequest
  ): Promise<Api.TermLoans.ContractFormResponse> => {
    const url = this.baseUrl + '/term-loan-contract/' + displayId;
    const res = await this.axios.post<Api.TermLoans.ContractFormResponse>(url, payload);
    return res.data;
  };

  public manageLoans = async (
    displayId: string,
    payload: Api.TermLoans.ContractManageLoansRequest
  ): Promise<Api.TermLoans.ContractFormResponse> => {
    const url = this.baseUrl + '/term-loan-contract/' + displayId + '/loans';
    const res = await this.axios.post<Api.TermLoans.ContractFormResponse>(url, payload);
    return res.data;
  };

  public async deleteDraft(displayId: string): Promise<void> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}`;
    return this.axios.delete(url).then(() => undefined);
  }

  public async cancelProposal(displayId: string): Promise<void> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}/cancel`;
    return this.axios.post(url).then(() => undefined);
  }

  public async acceptProposal(displayId: string): Promise<void> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}/accept`;
    return this.axios.post(url).then(() => undefined);
  }

  public async rejectProposal(displayId: string): Promise<void> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}/reject`;
    return this.axios.post(url).then(() => undefined);
  }

  public async uploadTermLoansSecurities(
    file: File
  ): Promise<Api.TermLoans.SecuritiesUploadResponse> {
    const url = this.baseUrl + '/term-loan-contract/instruments/upload';
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.axios.post<Api.TermLoans.Raw.SecuritiesUploadResponse>(
      url,
      formData,
      { headers }
    );

    return normalizeTermLoansUploadSecuritiesResponse(data);
  }

  public async uploadTermLoansQuantities(
    displayId: string,
    file: File
  ): Promise<Api.TermLoans.QuantitiesUploadResponse> {
    const url = this.baseUrl + `/term-loan-contract/${displayId}/loans/upload`;
    const headers = { 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await this.axios.post<Api.TermLoans.Raw.QuantitiesUploadResponse>(
      url,
      formData,
      { headers }
    );

    return normalizeTermLoansUploadQuantitiesResponse(data);
  }
}
