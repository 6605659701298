<template>
  <v-navigation-drawer
    v-shortkey="['[']"
    app
    clipped
    disable-resize-watcherclass="sidebar"
    :mini-variant="isMiniVariant"
    permanent
    @shortkey.native="toggleMiniVariant"
  >
    <div class="d-flex flex-column justify-space-between fill-height">
      <v-list dense expand nav>
        <template v-for="item in items">
          <!-- Render expandable/collapsable group with children items -->
          <v-list-group
            v-if="item.children && !item.isDisabled"
            :key="item.key"
            v-model="preparedExpandedGroups[item.key]"
            :class="matchedParentKey === item.key ? 'matched-route' : ''"
            :data-test="`key-${item.key}`"
            :prepend-icon="item.icon"
            @input="toggleListGroup(item.key)"
          >
            <template #activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="child in item.children"
              :key="child.to"
              class="pl-16"
              link
              :to="{ name: child.to }"
            >
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <!-- Render an item with a link -->
          <v-list-item
            v-else
            :key="item.key"
            :data-test="`key-${item.key}`"
            :disabled="item.isDisabled"
            link
            :to="{ name: item.to }"
          >
            <v-list-item-icon>
              <v-icon :class="{ 'text--disabled': item.isDisabled }">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>

      <!-- Invisible <div> occupying the space between items and chevron button -->
      <div class="fill-height" @dblclick="toggleMiniVariant"></div>

      <div class="text-center mb-4">
        <v-tooltip open-delay="500" right>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              data-test="mini-variant-toggler"
              elevation="2"
              fab
              small
              v-on="on"
              @click="toggleMiniVariant"
            >
              <v-icon v-if="isMiniVariant">mdi-chevron-double-right</v-icon>
              <v-icon v-else>mdi-chevron-double-left</v-icon>
            </v-btn>
          </template>
          <span v-if="isMiniVariant">Expand <strong>[</strong></span>
          <span v-else>Collapse <strong>[</strong></span>
        </v-tooltip>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import { mapState } from 'vuex';
import { SidebarGroup, SidebarItem } from '@/modules/sidebar/types/sidebar';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import { LoginState } from '@/store/store';
import { getSidebarItems } from '@/modules/sidebar/helpers/sidebar';

// Used in sessionStorage by component
const SESSION_STORAGE_KEY = 'sideBarState';

interface SidebarStorage {
  isMiniVariant: boolean;
  expandedGroups: Record<string, boolean>;
}

@Component({
  components: {},
  computed: {
    ...mapState(['clientConfig', 'uxConfig', 'loginState', 'route']),
  },
})
export default class Sidebar extends Vue {
  // store state
  protected readonly clientConfig!: ClientConfig;
  protected readonly uxConfig!: UXConfig;
  protected readonly loginState!: LoginState;
  protected route!: Route;

  protected isMiniVariant = false;
  private expandedGroups: Record<string, boolean> = {};

  /*
   * Get the relevant items for the logged in user/configs
   */
  protected get items(): SidebarItem[] {
    return getSidebarItems(this.clientConfig as ClientConfig, this.uxConfig, this.loginState);
  }

  /*
   * Get filtered group items
   */
  protected get groupItems(): SidebarGroup[] {
    return this.items.filter((item): item is SidebarGroup => item.kind === 'group');
  }

  /*
   * Return expandedGroups ensuring child is expanded if it matches current route name
   * (don't expand current route name if mini-variant is enabled)
   */
  protected get preparedExpandedGroups(): Record<string, boolean> {
    if (!this.isMiniVariant && this.matchedParentKey) {
      this.expandedGroups[this.matchedParentKey] = true;
      this.setExpandedGroupOnStorage(this.matchedParentKey, true);
    }

    return this.expandedGroups;
  }

  /*
   * Find the first child within sidebar groups that matches our current route and return key
   */
  protected get matchedParentKey(): string | null {
    for (const item of this.groupItems) {
      for (const child of item.children) {
        if (child.to === this.route.name) {
          return item.key;
        }
      }
    }
    return null;
  }

  /*
   * Retrieve (or create) sessionStorage state
   * Each browser tab has its own sessionStorage state
   */
  protected created(): void {
    this.initStorage();
    const storage = this.getStateFromStorage();
    this.expandedGroups = storage.expandedGroups;
    this.setIsMiniVariant(storage.isMiniVariant);
  }

  /*
   * When user logs out, Sidebar stops being rendered
   * vue-class-component implements destroyed, but not unmounted
   */
  protected destroyed(): void {
    this.resetStateOnStorage();
  }

  /*
   * Expand or collapse a group
   */
  protected toggleListGroup(itemKey: string): void {
    if (this.isMiniVariant) {
      // Disable mini-variant (so user can see children)
      this.setIsMiniVariant(false);

      // Restore other previous saved states (but not itself)
      this.restoreAllGroups(itemKey);
    }

    // Save the state in storage
    this.setExpandedGroupOnStorage(itemKey, this.expandedGroups[itemKey]);
  }

  /*
   * Toggle mini-variant via chevron button
   */
  protected toggleMiniVariant(): void {
    if (this.isMiniVariant) {
      this.setIsMiniVariant(false);
      // Remember and restore previous expanded states
      this.restoreAllGroups();
    } else {
      this.setIsMiniVariant(true);
    }
  }

  /*
   * Set isMiniVariant in state and storage
   */
  private setIsMiniVariant(isMiniVariant: boolean): void {
    const storage = this.getStateFromStorage();
    this.isMiniVariant = storage.isMiniVariant = isMiniVariant;
    this.setStateOnStorage(storage);

    if (this.isMiniVariant) {
      // collapse all groups but don't save in storage
      for (const itemKey in this.expandedGroups) {
        this.expandedGroups[itemKey] = false;
      }
    }
  }

  /*
   * Restore previous saved state from storage
   * (don't restore itemKey state if passed)
   */
  private restoreAllGroups(excludeKey?: string): void {
    const { expandedGroups } = this.getStateFromStorage();
    for (const itemKey in expandedGroups) {
      if (itemKey !== excludeKey) {
        this.expandedGroups[itemKey] = expandedGroups[itemKey];
      }
    }
  }

  /*
   * Get and parse from sessionStorage (or create a default)
   */
  private getStateFromStorage(): SidebarStorage {
    const value = window.sessionStorage.getItem(SESSION_STORAGE_KEY);
    return value ? JSON.parse(value) : { isMiniVariant: false, expandedGroups: {} };
  }

  /*
   * Update expandedGroups entry on sessionStorage
   */
  private setExpandedGroupOnStorage(itemKey: string, isExpanded: boolean): void {
    const storage = this.getStateFromStorage();
    storage.expandedGroups[itemKey] = isExpanded;
    this.setStateOnStorage(storage);
  }

  /*
   * Stringify and save on sessionStorage
   */
  private setStateOnStorage(storage: SidebarStorage): void {
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(storage));
  }

  /*
   * Add groups to storage with a default value (true) if they don't exist yet
   */
  private initStorage() {
    const expandedKeys = Object.keys(this.getStateFromStorage().expandedGroups);
    for (const item of this.groupItems) {
      if (!expandedKeys.includes(item.key)) {
        this.setExpandedGroupOnStorage(item.key, true);
      }
    }
  }

  private resetStateOnStorage(): void {
    window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--dark .v-list-group__header.v-list-item--active {
    color: white;
  }

  .theme--dark .matched-route .v-list-group__header,
  .theme--dark .v-list a.v-list-item--active {
    color: #4caf50;
  }

  .theme--light .v-list-group__header.v-list-item--active {
    color: rgba(0, 0, 0, 0.87);
  }

  .theme--light .matched-route .v-list-group__header,
  .theme--light .v-list a.v-list-item--active {
    color: #2196f3;
  }
}
</style>
