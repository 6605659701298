<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <v-card-text>
      <v-container>
        <v-row class="mt-n10" dense>
          <v-col cols="12">
            <term-loans-select-index />
          </v-col>
          <v-col cols="12">
            <uploader
              v-if="parseErrors.length === 0"
              dense
              :execute="(file) => $api.termLoans.uploadTermLoansSecurities(file)"
              hide-cancel-btn
              :upload-method="uploadMethod"
              @close-modal="step = 'form'"
              @parse-errors="displayParseErrors($event)"
              @upload="populateFromUpload($event.instruments)"
              @upload-method="uploadMethod = $event"
            >
              <template #description>
                <div>Expected column:</div>
                <div>Ticker or CUSIP</div>
              </template>
            </uploader>
            <upload-parse-errors
              v-else
              :errors="parseErrors"
              :headers="[
                { text: 'Row', value: 'row' },
                { text: 'Error', value: 'errMsg' },
              ]"
              :upload-method="uploadMethod"
              @back="parseErrors = []"
            >
              <template #item="{ item }">
                <tr>
                  <td>{{ item.row }}</td>
                  <td>{{ item.errormsg }}</td>
                </tr>
              </template>
            </upload-parse-errors>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <multiple-equity-selector
              :equities.sync="contract.equities"
              :error-messages="errorMsgs['equities']"
            />
          </v-col>
          <v-col class="text-end pr-4" cols="12">
            <strong>{{ contract.equities.length }}</strong> total securities
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="d-flex">
      <div class="d-flex flex-grow-1 justify-space-between align-end">
        <v-btn color="secondary" @click="$emit('back')">Back</v-btn>
        <v-btn color="primary" type="submit" @click="goToNextStep()">Next</v-btn>
      </div>
    </v-card-actions>
  </span>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { Api, Equity } from '@/modules/common/types/api';
import TermLoansSelectIndex from '@/modules/termloans/components/TermLoansSelectIndex.vue';
import MultipleEquitySelector from '@/modules/common/components/MultipleEquitySelector.vue';
import Uploader from '@/modules/common/components/Uploader.vue';
import UploadParseErrors from '@/modules/common/components/UploadParseErrors.vue';
import type { UploadMethod } from '@/modules/common/types/upload';
import ConfirmAutocomplete from '@/modules/open-loans/components/ConfirmAutocomplete.vue';

interface FormErrors {
  equities: string[];
}

@Component({
  props: {
    contract: {
      type: Object as PropType<Api.TermLoans.ContractSummary>,
      required: true,
    },
  },
  components: {
    TermLoansSelectIndex,
    MultipleEquitySelector,
    Uploader,
    UploadParseErrors,
    ConfirmAutocomplete,
  },
  mixins: [validationMixin],
  validations: function (this: TermLoansDefineEquities) {
    return {
      contract: {
        equities: {
          required,
        },
      },
    };
  },
})
export default class TermLoansDefineEquities extends mixins(validationMixin) {
  // props
  protected readonly contract!: Api.TermLoans.ContractSummary;

  protected uploadMethod: UploadMethod | null = null;
  protected parseErrors: Api.TermLoans.UploadErrorItem[] = [];

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      equities: [],
    };

    if (this.$v.contract?.equities?.$dirty) {
      if (!this.$v.contract.equities.required)
        errors.equities.push('please select at least one security.');
    }

    return errors;
  }

  protected populateFromUpload(equities: Equity[]): void {
    this.$emit('update:contract', { ...this.contract, equities });
  }

  protected displayParseErrors(errors: Api.TermLoans.UploadErrorItem[]): void {
    this.parseErrors = errors;
  }

  protected goToNextStep(): void {
    if (this.validateForm()) {
      this.$emit('next');
    }
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.$v.$touch();
    return !this.$v.$anyError;
  }
}
</script>

<style lang="scss" scoped></style>
