<template>
  <div>
    <counterparty-search
      v-model="selectedSearchItem"
      :autofocus="autofocus"
      :bilateral="bilateral"
      :dense="dense"
      :disabled="disabled"
      :error-messages="errorMessages"
      :exclude-company-id="excludeCompanyId"
      :exclude-company-ids="selectedCompanyIds"
      :include-sponsored="includeSponsored"
      :label="label"
      :placeholder="placeholder"
      :prepend-item="prependItem"
      :side="side"
      :sponsored="sponsored"
      @blur="$emit('blur', $event)"
      @change="onChange"
      @input="onItemSelected"
    />
    <div v-if="!!counterparties && counterparties.length > 0" class="mt-n4">
      <v-chip
        v-for="item in counterparties"
        :key="item.companyId"
        close
        :disabled="disabled"
        small
        @click="onChipClick(item)"
        @click:close="onChipClick(item)"
      >
        {{ item.displayBoxId }}
      </v-chip>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Side } from '@/modules/user-accounts/types/user-accounts';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';

@Component({
  components: { CounterpartySearch },
  props: {
    counterparties: {
      type: Array as PropType<CompanyInfo[]>,
      default: [],
    },

    // passthrough props to CounterpartySearch
    label: String,
    autofocus: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    dense: Boolean,
    errorMessages: {},
    placeholder: String,
    prependItem: {
      type: Object as PropType<CompanyInfo>,
    },
    // search through borrower traders, lender traders or both
    side: {
      type: String as PropType<Side>,
      default: 'all',
    },
    sponsored: {
      type: Boolean,
      default: false,
    },
    includeSponsored: {
      type: Boolean,
      default: false,
    },
    bilateral: {
      type: Boolean,
      default: false,
    },
    excludeCompanyId: {
      type: Number,
      required: false,
    },
  },
})
export default class MultipleChipCounterpartySearch extends Vue {
  protected counterparties!: CompanyInfo[];
  protected selectedSearchItem: CompanyInfo | null = null;

  // passthrough props to CounterpartySearch
  protected readonly label?: string;
  protected readonly autofocus?: boolean;
  protected readonly disabled?: boolean;
  protected readonly clearable?: boolean;
  protected readonly dense?: boolean;
  protected readonly errorMessages?: string[];
  protected readonly placeholder?: string;
  protected readonly side!: Side;
  protected readonly sponsored!: boolean;
  protected readonly includeSponsored!: boolean;
  protected readonly excludeCompanyId?: number;
  protected readonly bilateral!: boolean;
  protected readonly prependItem!: CompanyInfo;

  protected get selectedCompanyIds(): number[] {
    return this.counterparties.map((c) => c.companyId);
  }

  protected onItemSelected(item: CompanyInfo): void {
    this.addSelectedItem(item);
    this.clearSearch();
  }

  protected onChange(item: CompanyInfo): void {
    this.addSelectedItem(item);
    this.clearSearch();
  }

  protected onChipClick(i: CompanyInfo): void {
    this.removeSelectedItem(i);
  }

  protected addSelectedItem(item: CompanyInfo): void {
    let hasItem = false;
    for (const v of this.counterparties) {
      if (v.companyId == item.companyId) {
        hasItem = true;
      }
    }

    if (!hasItem) {
      this.$emit('update:counterparties', this.counterparties.concat(item));
    }
  }

  protected removeSelectedItem(item: CompanyInfo): void {
    this.$emit(
      'update:counterparties',
      this.counterparties.filter((v) => v.companyId !== item.companyId)
    );
  }

  protected clearSearch(): void {
    this.$nextTick(() => {
      this.selectedSearchItem = null;
    });
  }
}
</script>

<style lang="scss" scoped></style>
