<template>
  <v-card class="flex d-flex flex-column">
    <loan-details-dialog
      v-if="detailLoan"
      ref="loan-details-dialog"
      :loan-id="detailLoan.id"
      @close-modal="detailLoan = null"
    />
    <snapshot-manager
      :drivers="['sessionStorage']"
      :get-state-mutators="getStateMutators"
      :snapshot="snapshot"
      storage-key="snapshot-marketplace-symbol-overview-loans"
    >
      <open-loans-table
        :corporate-actions="openLoans.corporateActions"
        :items="openLoans.items"
        :items-per-page="options.itemsPerPage"
        :omit-headers="[
          'equity.ticker',
          'equity.cusip',
          'renegotiateRate',
          'nextAllowedBuyInExecutionDate',
          'recalledQuantity',
        ]"
        :page="options.page"
        :server-items-length="openLoans.total"
        :show-dropdown-actions="false"
        :show-select="false"
        :sort-by="options.sortBy[0]"
        :sort-desc="options.sortDesc[0]"
        @update:options="options = $event"
        @view-loan="detailLoan = $event"
      />
    </snapshot-manager>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import { LoginState, SocketEvents } from '@/store/store';
import { formatDate } from '@/utils/helpers/dates';
import { DataOptions } from 'vuetify';
import { OpenLoan, OpenLoans, OpenLoansParams } from '@/utils/api/loans';
import { ClientConfig } from '@/utils/helpers/rest';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import { Equity } from '@/modules/common/types/api';
import { Watch } from 'vue-property-decorator';
import OpenLoansTable from '@/modules/open-loans/components/OpenLoansTable.vue';
import { throttle } from 'lodash';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';

const MAX_ITEMS_PER_PAGE = 50;

@Component({
  components: {
    RateOutput,
    LoanStatusChip,
    LoanDetailsDialog,
    SnapshotManager,
    OpenLoansTable,
  },
  props: {
    equity: Object as PropType<Equity>,
  },
  computed: {
    ...mapState(['clientConfig', 'loginState', 'socketEvents']),
  },
})
export default class MarketplaceSymbolOverviewLoans extends Vue {
  // props
  protected readonly equity!: Equity;

  protected options: Pick<DataOptions, 'sortBy' | 'sortDesc' | 'page' | 'itemsPerPage'> = {
    sortBy: ['createdAt'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: MAX_ITEMS_PER_PAGE,
  };

  // store state
  protected clientConfig!: ClientConfig;
  protected loginState!: LoginState;
  protected socketEvents!: SocketEvents;

  protected openLoans: OpenLoans = {
    items: [],
    total: 0,
    recalledTotal: 0,
    rerateTotal: 0,
    corporateActions: {},
  };
  protected detailLoan: OpenLoan | null = null;
  protected throttledLoadAsyncData = throttle(this.loadAsyncData, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  protected get snapshot(): Record<string, string> {
    const snapshot: Record<string, string> = {
      sortDesc: this.options.sortDesc[0] ? 'true' : 'false',
      sortBy: this.options.sortBy[0],
      page: this.options.page.toString(),
      itemsPerPage: this.options.itemsPerPage.toString(),
    };

    return snapshot;
  }

  @Watch('equity')
  @Watch('options')
  protected onUpdateProps(): void {
    void this.throttledLoadAsyncData();
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  protected onSocketEvents(): void {
    void this.throttledLoadAsyncData();
  }

  protected async loadAsyncData(): Promise<void> {
    try {
      const params: OpenLoansParams = {
        filters: { cusip: this.equity.cusip },
        pagination: {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
        sort: `${this.options.sortDesc[0] ? '-' : '+'}${this.options.sortBy[0]}`,
      };
      this.openLoans = await this.$api.openLoans.fetchOpenLoans(params);
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      sortBy: (value) => {
        this.options.sortBy = [value];
      },
      sortDesc: (value) => {
        this.options.sortDesc = [value === 'true'];
      },
      page: (value) => {
        this.options.page = Math.max(parseInt(value), 1);
      },
      itemsPerPage: (value) => {
        const parsedValue = parseInt(value);
        this.options.itemsPerPage = parsedValue > 0 ? parsedValue : MAX_ITEMS_PER_PAGE;
      },
    };
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MM/dd/yy');
  }
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
}

::v-deep {
  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }
  }

  .v-chip {
    width: 78px;
  }
}
</style>
