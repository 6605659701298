<template>
  <v-data-table
    class="d-flex flex flex-column"
    dense
    fixed-header
    :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
    :headers="tableHeaders"
    item-key="displayId"
    :items="contract.items"
    :items-per-page="200"
    must-sort
    v-on="$listeners"
    @click:row="
      (agg) =>
        $emit('click:row', { termContractDisplayId: contract.displayId, cusip: agg.equity.cusip })
    "
  >
    <template #[`item.side`]>
      <format-side :side="contract.side" />
    </template>
    <template #[`item.ticker`]="{ item }">
      {{ item.equity.ticker }}
    </template>
    <template #[`item.cusip`]="{ item }">
      {{ item.equity.cusip }}
    </template>
    <template #[`item.loansCount`]="{ item }">
      <pretty-number :value="item.loansCount" />
    </template>
    <template #[`item.totalOpenQuantity`]="{ item }">
      <pretty-number :value="item.totalOpenQuantity" />
    </template>
    <template #[`item.totalReturnedQuantity`]="{ item }">
      <pretty-number :value="item.totalReturnedQuantity" />
    </template>
    <template #[`item.totalPendingReturnQuantity`]="{ item }">
      <pretty-number :value="item.totalPendingReturnQuantity" />
    </template>
    <template #[`item.totalOpenQuantityForReturn`]="{ item }">
      <pretty-number :value="item.totalOpenQuantityForReturn" />
    </template>
    <template #[`item.totalValue`]="{ item }"> ${{ formatPrice(item.totalValue) }} </template>
    <template #[`item.position`]="{ item }">
      <rate-output :precision="2" :rate="item.position" />
    </template>
  </v-data-table>
</template>

<script lang="ts">
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Api } from '@/modules/common/types/api';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';

const tableHeaders: DataTableHeader[] = [
  {
    text: 'Side',
    value: 'side',
    class: 'text-truncate row-icon status',
    cellClass: 'text-truncate row-icon status',
    align: 'start',
    sortable: false,
  },
  {
    text: 'Ticker',
    value: 'ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },
  {
    text: 'CUSIP',
    value: 'cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },
  {
    text: '#Loans',
    value: 'loansCount',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
  },
  {
    text: 'Total Open',
    value: 'totalOpenQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Total Returned',
    value: 'totalReturnedQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Total Pending Return',
    value: 'totalPendingReturnQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Total Open for Return',
    value: 'totalOpenQuantityForReturn',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Total Value',
    value: 'totalValue',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Position',
    value: 'position',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
];

@Component({
  components: {
    BtnDropdown,
  },
  props: {
    contract: {
      type: Object as PropType<Api.TermLoans.ContractDetails>,
      required: true,
    },
  },
})
export default class TermLoansContractAggLoans extends Vue {
  // props
  protected readonly contract!: Api.TermLoans.ContractDetails;

  protected tableHeaders = tableHeaders;

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  td {
    cursor: pointer;
  }
}
</style>
