<template>
  <span :class="[`${side}`, 'text-capitalize']">
    <slot>{{ side }}</slot>
  </span>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

type Side = 'borrower' | 'lender';

@Component({
  props: {
    side: String as PropType<Side>,
  },
})
export default class FormatSide extends Vue {
  protected readonly side!: Side;
}
</script>

<style lang="scss">
.lender {
  color: rgb(102, 186, 255);
}

.borrower {
  color: rgb(76, 175, 80);
}
</style>
