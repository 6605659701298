<template>
  <v-card class="flex d-flex flex-column">
    <v-container class="py-0" fluid>
      <v-row class="mt-2 mb-4" no-gutters>
        <v-tabs v-model="selectedTabIndex" @change="onTabChange">
          <v-tab
            v-for="tabName in tabNames"
            :key="tabName"
            :data-test="`aggregated-loans-${tabName}-tab-name`"
            :value="tabName"
          >
            {{ tabName }}
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>

    <open-loans-table-aggregate
      :aggregated-loans="aggregatedLoans.items"
      :grouping-type="tab"
      :items-per-page="options.itemsPerPage"
      :page="options.page"
      :server-items-length="aggregatedLoans.total"
      :sort-by="options.sortBy[0] || defaultSort"
      :sort-desc="options.sortDesc[0]"
      @click:row="$emit('click:row', $event)"
      @open-dialog="dialog = $event"
      @update:options="$emit('update:options', $event)"
    />

    <mass-modify-loans-dialog
      v-if="dialog"
      :action="dialog.action"
      :counterparty="dialog.counterparty"
      :equity="dialog.equity"
      :grouping-type="tab"
      @close-modal="dialog = null"
    />
  </v-card>
</template>

<script lang="ts">
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import MassModifyLoansDialog from '@/modules/open-loans/components/MassModifyLoansDialog.vue';
import OpenLoansTableAggregate from '@/modules/open-loans/components/OpenLoansTableAggregate.vue';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { SocketEvents } from '@/store/store';
import { AggregatedLoans, AggregatedOpenLoansParams } from '@/utils/api/loans';
import { throttle } from 'lodash';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { mapState } from 'vuex';

type TabName = 'counterparty' | 'security';

interface OpenDialog {
  action: MultipleLoanAction;
  equity?: { cusip: string; ticker: string };
  counterparty?: CompanyInfo;
}

@Component({
  props: {
    tab: {
      type: String as PropType<TabName>,
      required: true,
    },
    options: {
      type: Object as PropType<DataOptions>,
      required: true,
    },
  },
  components: {
    OpenLoansTableAggregate,
    SnapshotManager,
    MassModifyLoansDialog,
  },
  computed: {
    ...mapState(['socketEvents']),
  },
})
export default class OpenLoansListWithAggregate extends Vue {
  protected readonly options!: DataOptions;
  protected readonly tab!: TabName;

  protected tabNames: TabName[] = ['security', 'counterparty'];
  protected dialog: OpenDialog | null = null;

  // store state
  protected socketEvents!: SocketEvents;

  protected aggregatedLoans: AggregatedLoans = { items: [], total: 0 };

  protected abortController: AbortController | null = null;
  protected throttledLoadAsyncData = throttle(this.loadAsyncData, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  protected get defaultSort(): string {
    return this.tab == 'security' ? 'ticker' : 'counterpartyDisplay';
  }

  protected get selectedTabIndex(): number {
    return this.tabNames.findIndex((t) => t === this.tab);
  }

  protected set selectedTabIndex(index: number) {
    this.$emit('update:tab', this.tabNames[index]);
  }

  @Watch('options')
  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  protected onSocketEvents(): void {
    if (!this.options.sortBy.length) {
      // when changing tabs we have set "sortBy" to an empty array
      // the template passes down "defaultSort", which will retrigger this watcher (options)
      return;
    }
    void this.throttledLoadAsyncData();
  }

  protected created(): void {
    void this.loadAsyncData();
  }

  protected async loadAsyncData(): Promise<void> {
    if (this.abortController) {
      // we want to initiate a new request, but the previous one is still pending
      // cancel to avoid stale responses arriving late (and potentially in the wrong order)
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    try {
      const params: AggregatedOpenLoansParams = {
        group: this.tab,
        sort: `${this.options.sortDesc[0] ? '-' : '+'}${this.options.sortBy[0]}`,
        pagination: {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
      };
      const res = await this.$api.openLoans.fetchAggregatedOpenLoans(
        params,
        this.abortController.signal
      );
      if (!res) {
        // request was cancelled, there's another response arriving
        return;
      }
      this.aggregatedLoans = res;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected onTabChange(): void {
    // cols are different depending on the group,
    // reset items to avoid visual glitches while fetching new data
    // @TODO: should we actually show a loader?
    this.aggregatedLoans.items = [];

    // we must let the template use its defaults when changing tabs,
    // so we set "sortBy" to an empty array
    this.$emit('update:options', { ...this.options, page: 1, sortBy: [] });
  }
}
</script>

<style lang="scss" scoped></style>
