<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <marketplace-top-of-book-table :as-broker="asBroker" :items="topOfBook">
      <template #top>
        <marketplace-top-of-book-watchlist-tabs
          v-if="withWatchlists"
          :selected-display-id="watchlistDisplayId"
          @change="onTabChange($event)"
        />
      </template>
    </marketplace-top-of-book-table>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import MarketplaceTopOfBookWatchlistTabs from '@/modules/marketplace/components/MarketplaceTopOfBookWatchlistTabs.vue';
import MarketplaceTopOfBookTable from '@/modules/marketplace/components/MarketplaceTopOfBookTable.vue';

import { Api } from '@/modules/common/types/api';

const POLLING_INTERVAL = 2;

@Component({
  props: {
    withWatchlists: {
      type: Boolean,
      default: false,
    },
    asBroker: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MarketplaceTopOfBookWatchlistTabs,
    MarketplaceTopOfBookTable,
  },
})
export default class MarketplaceTopOfBook extends Vue {
  protected readonly withWatchlists!: boolean;
  protected readonly asBroker!: boolean;
  protected topOfBook: Api.Marketplace.TopOfBookResponse = [];
  protected watchlistDisplayId: string | null = null;
  protected pollInterval: ReturnType<typeof setInterval> | null = null;

  protected async mounted(): Promise<void> {
    await this.fetchContents();
    // @TODO: replace with socket events
    this.pollInterval = setInterval(this.fetchContents, POLLING_INTERVAL * 1000);
  }

  protected async fetchContents(): Promise<void> {
    if (this.asBroker) {
      this.topOfBook = await this.$api.marketplace.fetchAdminTopOfBook();
    } else {
      this.topOfBook = this.watchlistDisplayId
        ? await this.$api.marketplace.fetchWatchlistContent(this.watchlistDisplayId)
        : await this.$api.marketplace.fetchTopOfBook();
    }
  }

  protected onTabChange(watchlistDisplayId: string | null): void {
    this.watchlistDisplayId = watchlistDisplayId;
    void this.fetchContents();
  }

  // vue-class-component implements destroyed, but not unmounted
  protected destroyed(): void {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }
}
</script>

<style lang="scss" scoped></style>
