import { CompanyAccountLite } from '@/utils/helpers/rest';
import Decimal from 'decimal.js';
import { OpenLoan } from '@/utils/api/loans';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';

export interface BorrowerOpenLoan extends OpenLoan {
  readonly side: 'borrower';
}

export interface BorrowLiquidity {
  lender: CompanyAccountLite;
  quantity: number;
  rate: Decimal;
  independentAmountRate: Decimal;
  roundingRule: RoundingRule;
}

export interface BorrowLiquidityWithKey extends BorrowLiquidity {
  key: string;
}

export interface BorrowRequest {
  instrumentId: number;
  maxRate: Decimal;
  quantity: number;
  counterpartyId: number | null;
  borrowOrderId?: string;
}

export function normalizeBorrowLiquidity(l: BorrowLiquidityWithKey): void {
  // input from server will have rate as a string, we convert it to Decimal
  const lWithStrRate: { rate: string | Decimal } = l;
  if (typeof lWithStrRate.rate === 'string') {
    l.rate = new Decimal(lWithStrRate.rate);
  }
  // input from server will have rate as a string, we convert it to Decimal
  const lWithStrIARate: { independentAmountRate: string | Decimal } = l;
  if (typeof lWithStrIARate.independentAmountRate === 'string') {
    l.independentAmountRate = new Decimal(lWithStrIARate.independentAmountRate);
  }
  l.key = `${l.rate}_${l.lender.id}`;
}
