<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @keydown.esc="showSummary ? goBack() : closeDialog()"
    @shortkey.native="showSummary ? goBack() : closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">Borrow</span>
          <v-spacer />
          <span class="text--primary headline-2">
            {{ equityDescription }}
          </span>
        </v-card-title>

        <v-card-text class="simple-equity-search">
          <v-container v-if="!showSummary">
            <simple-equity-search
              v-model="equity"
              :autofocus="!equity"
              :error-messages="errorMsgs['equity']"
              label="security"
              @change="$v.equity.$touch()"
              @input="$v.equity.$touch()"
            />

            <template v-if="equity && !equity.cannotTradeMessage">
              <borrow-dialog-liquidity-selector
                class="mx-n4"
                :equity="equity"
                :error-messages="errorMsgs['quantity']"
                :liquidity.sync="liquidity"
                :quantity.sync="quantity"
                @update:quantity="
                  $event === null ? $v.quantity.$reset() : $v.quantity.$touch();
                  quantity = $event;
                "
              />
              <div
                v-if="errorMsgs['liquidity'].length && !liquidity"
                class="v-messages theme--dark error--text justify-end mt-2 text-right"
              >
                <!-- If user clicks "Borrow" without first selecting a liquidity -->
                <span>
                  {{ errorMsgs.liquidity.join('\n') }}
                </span>
              </div>
            </template>
          </v-container>

          <v-container v-if="showSummary" v-shortkey="['enter']" @shortkey="submitForm()">
            <v-row class="text--primary">
              <v-col class="py-0">
                <h2 class="text-h6 text--secondary">Continue?</h2>
                <div>
                  You are about to borrow <strong>{{ equityDescription }}</strong>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <div class="py-8 pl-10">
              <borrow-dialog-contract-details
                :equity="equity"
                :liquidity="liquidity"
                :quantity="quantity"
              />
            </div>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="showError || showSuccess">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div
                  class="v-alert v-alert--dense text--primary text-body-2 text-center"
                  :class="{ error: showError, success: showSuccess }"
                >
                  <div v-if="showError">
                    {{ errorMsgs.apiErrors.join('\n') }}
                  </div>
                  <div v-if="showSuccess">Your borrow has been created.</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <div class="d-flex flex-grow-1 justify-space-between align-end">
            <template v-if="!showSummary">
              <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
              <v-btn color="primary" type="submit" @click="goToSummary()"> Borrow</v-btn>
            </template>

            <template v-if="showSummary">
              <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="goBack()">
                Back
              </v-btn>
              <aurora-btn
                color="primary"
                :disabled="formStatus !== 'idle'"
                :loading="formStatus === 'submitting'"
                timeframe="createLoans"
                type="submit"
                @click="submitForm()"
              >
                Confirm
              </aurora-btn>
            </template>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { integer, maxValue, minValue, required } from 'vuelidate/lib/validators';
import wait from '@/modules/common/services/wait';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import BorrowDialogLiquiditySelector from '@/modules/borrower/components/BorrowDialogLiquiditySelector.vue';
import { BorrowLiquidityWithKey, BorrowRequest } from '@/utils/api/borrower';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import { Equity } from '@/modules/common/types/api';
import { calculateOrderNotional } from '@/utils/helpers/auction-numbers';
import BorrowDialogContractDetails from '@/modules/borrower/components/BorrowDialogContractDetails.vue';
import { LoginState } from '@/store/store';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import { canTradeEquity } from '@/utils/helpers/equity-validators';
import i18n from '@/localisation/i18n';

interface FormErrors {
  apiErrors: string[];
  equity: string[];
  liquidity: string[];
  quantity: string[];
}

@Component({
  components: {
    BorrowDialogContractDetails,
    SimpleEquitySearch,
    BorrowDialogLiquiditySelector,
  },
  methods: {
    ...mapActions(['submitBorrow', 'fetchBorrowerOrders']),
  },
  computed: {
    ...mapState(['loginState']),
  },
  mixins: [validationMixin],
  validations: function (this: BorrowDialog) {
    return {
      equity: { required, canTrade: canTradeEquity() },
      liquidity: { required },
      quantity: {
        integer,
        required,
        minValue: minValue(1),
        maxValue: maxValue(this.liquidity?.quantity as number),
      },
    };
  },
})
export default class BorrowDialog extends mixins(validationMixin) {
  // store methods
  protected submitBorrow!: (req: BorrowRequest) => Promise<void>;
  protected loginState!: NonNullableAll<LoginState>;
  private fetchBorrowerOrders!: () => Promise<void>;
  private equity: Equity | null = null;
  private liquidity: BorrowLiquidityWithKey | null = null;
  private quantity: number | null = null;
  private formStatus: DialogFormStatus = 'idle';
  private showSummary = false;
  private showSuccess = false;
  private apiErrors: string[] = [];

  private get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      equity: [],
      liquidity: [],
      quantity: [],
    };

    // equity errors
    if (this.$v.equity.$dirty) {
      if (!this.$v.equity.required) errors.equity.push('please select a security.');
      if (this.equity?.cannotTradeMessage && !this.$v.equity.canTrade) {
        errors.equity.push(i18n.t(this.equity.cannotTradeMessage) as string);
      }
    }

    if (this.$v.liquidity.$dirty) {
      if (!this.$v.liquidity.required) errors.liquidity.push('please select a liquidity.');
    }

    if (this.$v.quantity.$dirty) {
      if (!this.$v.quantity.required) errors.quantity.push('please enter a quantity.');
      if (!this.$v.quantity.integer) errors.quantity.push('please enter a valid quantity.');
      if (!this.$v.quantity.minValue) errors.quantity.push('must be greater than 0.');
      if (!this.$v.quantity.maxValue) errors.quantity.push('must be lower, not available');
    }

    return errors;
  }

  private get equityDescription(): string {
    return this.equity ? `${this.equity.ticker} [${this.equity.cusip}]` : '';
  }

  private get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  private async submitForm(): Promise<void> {
    if (
      this.formStatus !== 'idle' ||
      !this.validateForm() ||
      !this.liquidity ||
      !this.equity ||
      !this.quantity
    ) {
      return;
    }

    this.formStatus = 'submitting';

    try {
      await this.submitBorrow({
        instrumentId: this.equity.id,
        maxRate: this.liquidity.rate,
        counterpartyId: this.liquidity.lender.id,
        quantity: this.quantity,
      });

      void this.fetchBorrowerOrders();

      this.formStatus = 'closing';
      this.showSuccess = true;
      await wait(1200);
      this.closeDialog();
    } catch (err) {
      this.apiErrors = [`${err}`];
      this.formStatus = 'idle';
    }
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  private goToSummary(): void {
    if (this.validateForm()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const notional = calculateOrderNotional(this.quantity, this.equity!.lastClosePrice);
      new RiskLimitValidator(this.$dialog, this.loginState.user).checkAndConfirmRiskLimits(
        notional,
        () => {
          this.showSummary = true;
        }
      );
    }
  }

  private goBack(): void {
    this.showSummary = false;
    this.$v.$reset();
  }

  private closeDialog(): void {
    this.$emit('close-modal');

    // close risk-validator popups
    this.$dialog.close();
  }
}
</script>
<style lang="scss" scoped>
.simple-equity-search {
  min-height: 350px;
}
</style>
