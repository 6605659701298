<template>
  <div>
    <dashboard-panel no-collapse title="supply">
      <lender-orders-list />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import LenderOrdersList from '@/modules/order-management/components/LenderOrdersList.vue';

@Component({
  components: {
    DashboardPanel,
    LenderOrdersList,
  },
})
export default class LenderSupply extends Vue {}
</script>

<style lang="scss"></style>
