<template>
  <v-autocomplete
    v-model="currEquity"
    auto-select-first
    :autofocus="currEquity == null"
    :color="color"
    :disabled="disabled"
    :error-messages="errorMessages"
    hide-no-data
    hide-selected
    item-text="description"
    item-value="id"
    :items="equitiesNormalized"
    :label="label"
    :loading="isSearching"
    outlined
    :placeholder="$t('securityAuction.tip')"
    prepend-icon="mdi-database-search"
    return-object
    @update:search-input="onSearchEquities"
  ></v-autocomplete>
</template>

<script lang="ts">
/**
 * <security-selector> is a component to query the database and select a valid security
 */
import { Component, Vue, Watch } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';
import { AuctionEquity, normalizeAuctionEquity } from '@/utils/helpers/rest';
import { extend } from 'lodash';
import { formatEquityDescription } from '@/utils/helpers/equities';

const maxEquityDescriptionLength = 60;

interface ExtendedEquity extends AuctionEquity {
  description: string;
}

@Component({
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    equity: {
      type: Object,
    },
    color: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String || [],
      default: '',
    },
  },
  model: {
    prop: 'equity',
    event: 'changed',
  },
  data: () => ({
    currEquity: null,
    isSearching: false,
    equityError: [],
  }),
})
export default class SecuritySelector extends Vue {
  private equity!: AuctionEquity;
  private currEquity!: AuctionEquity | ExtendedEquity;

  // equities autocomplete/search options
  private isSearching = false;
  private equities: AuctionEquity[] = [];
  private maxQueryCount = 30;
  private lastQuery = '';
  private lastQueryCount = 0;

  // getter for equities with a bit of cleanup
  private get equitiesNormalized(): ExtendedEquity[] {
    return this.equities.map((eq) => {
      return extend(eq, { description: this.formatEquityDescription(eq) });
    });
  }

  // watch for an equity to be selected from the dropdown and forward it to the parent
  @Watch('currEquity')
  private onChangedSecurityByDropdown(selected: AuctionEquity) {
    this.$emit('changed', selected);
  }

  // watch for the parent to change the equity and update the dropdown
  @Watch('equity')
  private onChangedByParent(equity: AuctionEquity) {
    this.currEquity = equity;
  }

  private mounted() {
    if (this.equity) {
      this.equities = [this.equity];
      this.currEquity = this.equity;
    }
  }

  private formatEquityDescription(equity: AuctionEquity): string {
    return formatEquityDescription(equity, maxEquityDescriptionLength);
  }

  // typed in equity search box
  private async onSearchEquities(newQuery: string) {
    // clicked an entry in the list, no need to search
    if (
      !newQuery ||
      (this.currEquity &&
        'description' in this.currEquity &&
        newQuery === this.currEquity.description)
    ) {
      return;
    }

    // equities have already been loaded
    if (
      this.equities.length > 0 &&
      this.lastQuery.length > 0 &&
      newQuery.indexOf(this.lastQuery) !== -1 &&
      this.lastQueryCount < this.maxQueryCount
    ) {
      return;
    }

    // equities have already been requested
    if (this.isSearching) {
      return;
    }
    this.isSearching = true;

    // submit new query
    try {
      const q = `/api/1/equities/search?query=${newQuery}&limit=${this.maxQueryCount}`;

      const { data } = await axios.get(q);
      this.equities = data.equities.map((eq: AuctionEquity) => normalizeAuctionEquity(eq));
      this.lastQuery = newQuery;
      this.lastQueryCount = this.equities.length;
    } catch (e) {
      if ((e as AxiosError).response && (e as AxiosError).response?.status === 404) {
        this.$log.debug(`no results for ${newQuery}`);
      } else {
        this.$log.warn(e);
      }
    } finally {
      this.isSearching = false;
    }
  }
}
</script>
