<template>
  <v-hover v-slot="{ hover }">
    <v-data-table
      v-columns-resizable
      class="elevation-0 pb-8"
      :class="['auctions-history', { 'on-hover': hover }]"
      dense
      disable-pagination
      fixed-header
      :headers="tableColumns"
      hide-default-footer
      item-class="inactive-row"
      :items="auctionsView"
      :items-per-page="10"
      :no-data-text="$tc(noClosedAuctions, auctions.length, { count: auctions.length })"
      :show-expand="false"
      :single-expand="false"
      sort-by="endsAt"
    >
      <template #header>
        <thead>
          <tr>
            <th
              v-for="(g, index) in groupColumns"
              :key="index"
              :class="g.class"
              :colspan="g.colspan"
              style="border-bottom: solid #fff 1px !important"
            >
              {{ g.text }}
            </th>
          </tr>
        </thead>
      </template>

      <!-- customize all columns  -->
      <template #item="{ item }">
        <tr>
          <!-- check-off auction -->
          <td class="action-col">
            <template v-if="item.companyOrderTickets[0].checkedOffAt == null">
              <v-btn
                color="green"
                :disabled="!hasTraderUserRole"
                x-small
                @click="checkoffAuction(item)"
              >
                <v-icon x-small> mdi-check</v-icon>
              </v-btn>
            </template>
          </td>

          <!-- re-initiate auction -->
          <td class="action-col">
            <v-btn
              color="blue"
              :disabled="!hasTraderUserRole"
              x-small
              @click="reinitiateAuction(item)"
            >
              <v-icon x-small> mdi-reload</v-icon>
            </v-btn>
          </td>

          <!-- security name and code -->
          <td class="is-equity-name text-no-wrap">
            {{ item.equity.ticker }} [{{ item.equity.cusip }}]
          </td>

          <!-- auction ended when? -->
          <td class="text-no-wrap vertical-sep-right">
            <pretty-date-time :when="item.endsAt"></pretty-date-time>
          </td>

          <!-- V-IF -->
          <template v-if="item.status === AuctionStatus.Error">
            <td class="text-center x-exec-error vertical-sep-right" colspan="3">
              {{ $t('auction-is-status-err') }}
            </td>
          </template>
          <!-- V-ELSE-IF -->
          <template v-else-if="!auctionTotalFilledVolume(item)">
            <td class="text-center x-no-match-col vertical-sep-right" colspan="3">
              {{ $t('no-match') }}
            </td>
          </template>
          <!-- V-ELSE -->
          <template v-else>
            <td class="text-end text-no-wrap x-limit-col is-numeric">
              <auctions-rate-output
                v-if="item.crossingRate"
                :integral-len="crossingRateMeta.integralLen"
                :rate="item.crossingRate"
              ></auctions-rate-output>
            </td>
            <td class="text-end text-no-wrap x-filled-col is-numeric">
              <pretty-number :value="auctionTotalFilledVolume(item)" />
            </td>
            <td class="text-end text-no-wrap x-percent-col is-numeric vertical-sep-right">
              {{
                getPercentageAsString(
                  (auctionTotalFilledVolume(item) / item.companyTotalVolume) * 100
                )
              }}
            </td>
          </template>

          <!-- order details -->
          <td class="is-stacked-order-list" colspan="6">
            <table class="is-stacked-order-list auctions-history">
              <template v-for="(ticket, i) in item.companyOrderTickets">
                <tr
                  v-for="(order, j) in ticket.orders"
                  :key="order.id"
                  :class="{ 'order-split': i !== 0 && j === 0 }"
                >
                  <td class="o-comment-col">
                    <v-tooltip
                      v-if="ticket.notes && j === 0"
                      color="form-tooltip"
                      left
                      max-width="60vw"
                    >
                      <template #activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-icon class="mx-1" small> mdi-message-text </v-icon>
                        </span>
                      </template>
                      <span>{{ ticket.notes }}</span>
                    </v-tooltip>
                  </td>
                  <td class="text-end text-no-wrap o-direction-col">
                    {{ $t(ticket.direction) }}
                  </td>
                  <td class="text-end text-no-wrap o-limit-col is-numeric">
                    <auctions-rate-output
                      :integral-len="crossingRateMeta.integralLen"
                      :rate="order.rate"
                    ></auctions-rate-output>
                  </td>
                  <td class="text-end text-no-wrap o-volume-col is-numeric">
                    <pretty-number :value="order.quantity" />
                  </td>
                  <template v-if="order.filledVolume">
                    <td class="text-end text-no-wrap o-filled-col is-numeric">
                      <pretty-number :value="order.filledVolume" />
                    </td>
                    <td class="text-end text-no-wrap o-percent-col is-numeric">
                      {{ getPercentageAsString((order.filledVolume / order.quantity) * 100) }}
                    </td>
                  </template>
                  <template v-else>
                    <td class="text-center o-no-match-col" colspan="2">{{ $t('no-match') }}</td>
                  </template>
                </tr>
              </template>
            </table>
          </td>
        </tr>
      </template>

      <template #footer>
        <template v-if="!auctions.length">
          <v-alert border="left" class="mt-6" color="info" icon="mdi-information">
            {{ $tc(noClosedAuctions, auctions.length, { count: auctions.length }) }}
          </v-alert>
        </template>

        <template v-if="hasTraderUserRole && auctions.length && !isCheckedOff">
          <v-alert border="left" class="mt-6" color="info" icon="mdi-clock-fast" text>
            {{ $t('checkoff-auctions') }}
          </v-alert>
        </template>
      </template>
    </v-data-table>
  </v-hover>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapMutations } from 'vuex';
import { getPercentageAsString } from '@/utils/helpers/auction-numbers';
import { PriceAlignment, getClosedAuctionRateAlignment } from '@/utils/helpers/price-alignment';
import { ClosedAuction, auctionTotalFilledVolume } from '@/utils/helpers/rest';
import { AuctionStatus } from '@/utils/helpers/buffers';
import AuctionsRateOutput from '@/modules/common/components/format-rate/AuctionsRateOutput.vue';
import PrettyDateTime from '@/modules/common/components/format-date/PrettyDateTime.vue';
import i18n from '@/localisation/i18n';

@Component({
  components: {
    PrettyDateTime,
    AuctionsRateOutput,
  },
  props: {
    auctions: Array,
    isCheckedOff: Boolean,
    noClosedAuctions: String,
  },
  data: () => ({
    AuctionStatus,
    auctionTotalFilledVolume,
    getPercentageAsString,
    groupColumns: [
      { text: '', colspan: 2 },
      { text: i18n.t('auction'), colspan: 2, class: 'text-center vertical-sep-right' },
      { text: i18n.t('crossing'), colspan: 3, class: 'text-center vertical-sep-right' },
      { text: i18n.t('yourOrder'), colspan: 6, class: 'text-center' },
    ],
    tableColumns: [
      // 'auction' columns
      { text: '', value: 'checkoff', filterable: false, sortable: false, class: 'action-col' },
      { text: '', value: 'restart', filterable: false, sortable: false, class: 'action-col' },
      { text: i18n.t('security'), value: 'equity.name', class: 'text-no-wrap' },
      { text: i18n.t('endedAt'), value: 'endsAt', class: 'text-no-wrap vertical-sep-right' },

      // 'crossing' columns
      {
        text: i18n.t('Rate'),
        value: 'crossingRate',
        sortable: false,
        align: 'end',
        class: 'text-no-wrap x-limit-col',
      },
      {
        text: i18n.t('quantityFilled'),
        value: 'filledVol',
        sortable: false,
        align: 'end',
        class: 'text-no-wrap x-filled-col',
      },
      {
        text: '(%)',
        value: 'filledPct',
        sortable: false,
        align: 'end',
        class: 'text-no-wrap x-percent-col vertical-split vertical-sep-right',
      },

      // 'your order' columns (only used for rendering the header, nested order table is not a <v-data-table>)
      { text: '', value: 'comment', filterable: false, sortable: false, class: 'o-comment-col' },
      {
        text: '',
        value: 'oDirection',
        sortable: false,
        align: 'end',
        class: 'text-no-wrap o-direction-col',
      },
      {
        text: i18n.t('Rate'),
        value: 'olimitRate',
        sortable: false,
        filterable: false,
        align: 'end',
        class: 'text-no-wrap o-limit-col',
      },
      {
        text: i18n.t('Quantity'),
        value: 'oQuantity',
        sortable: false,
        align: 'end',
        class: 'text-no-wrap o-volume-col',
      },
      {
        text: i18n.t('quantityFilled'),
        value: 'oFilledVol',
        sortable: false,
        align: 'end',
        class: 'text-no-wrap o-filled-col',
      },
      {
        text: '(%)',
        value: 'oFilledPct',
        sortable: false,
        align: 'end',
        class: 'text-no-wrap o-percent-col',
      },
    ],
    auctionsView: [],
  }),
  computed: {
    ...mapGetters(['hasTraderUserRole']),
  },
  methods: {
    ...mapMutations(['updateAuctionsHistoryMyOrder']),
  },
})
export default class AuctionsHistoryTable extends Vue {
  private auctions!: ClosedAuction[];
  private auctionsView!: ClosedAuction[];
  private crossingRateMeta!: PriceAlignment;

  // Store state
  private readonly hasTraderUserRole!: boolean;

  @Watch('auctions')
  private onChangedAuctionList(): void {
    // get the print format by looking at all order- and crossing prices
    this.crossingRateMeta = getClosedAuctionRateAlignment(this.auctions);

    // display auction list only after we know how to format prices
    this.auctionsView = this.auctions;
  }

  private reinitiateAuction(auction: ClosedAuction): void {
    this.$emit('reinitiateAuction', auction);
  }

  private checkoffAuction(auction: ClosedAuction): void {
    this.$emit('checkoffAuction', auction);
  }

  private mounted(): void {
    this.onChangedAuctionList();
  }
}
</script>

<style lang="scss">
td.is-stacked-order-list {
  padding: 0 !important;
}

div.auctions-history,
table.is-stacked-order-list {
  // start of generic 'Auction' columns
  th.vertical-sep-right,
  td.vertical-sep-right {
    border-right-style: dashed !important;
    border-right-width: 1px !important;
    border-right-color: #393939 !important;
  }

  th.action-col,
  td.action-col {
    width: 38px;
    max-width: 38px;
    padding-left: 8px !important;
    padding-right: 4px !important;
  }

  // end of 'Auction' columns

  // start of 'Crossing' columns
  th.x-limit-col,
  td.x-limit-col {
    padding-left: 4px;
    padding-right: 4px;
  }

  // th.x-filled-col,
  // td.x-filled-col {
  //   // auto width!
  // }

  // th.x-percent-col,
  // td.x-percent-col {
  //   // auto width!
  // }

  td.x-exec-error {
    // no width! cell should stretch to match x-limit-, filled- and x-percent-col
    font-family: 'Courier New', Courier, monospace;
    color: #777;
    white-space: nowrap;
    overflow: hidden;
  }

  td.x-no-match-col {
    // no width! cell should stretch to match limit-, filled- and x-percent-col
    font-family: 'Courier New', Courier, monospace;
    white-space: nowrap;
    overflow: hidden;
  }

  // end of 'Crossing' columns

  // start of 'Your Order' columns
  // these columns are part of a nested table and must have a fixed width so they align in the outer table as well
  th.o-comment-col,
  td.o-comment-col {
    white-space: nowrap;
    overflow: hidden;
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    padding: 0 !important;
  }

  th.o-direction-col,
  td.o-direction-col {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    padding: 0 4px !important;
  }

  th.o-limit-col,
  td.o-limit-col {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
    padding: 0 4px !important;
  }

  th.o-volume-col,
  td.o-volume-col {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
    padding: 0 4px !important;
  }

  th.o-filled-col,
  td.o-filled-col {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
    padding: 0 4px !important;
  }

  th.o-percent-col,
  td.o-percent-col {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    padding: 0 4px !important;
  }

  td.o-no-match-col {
    // width exactly the same as 'o-filled-col' + 'o-percent-col'
    font-family: 'Courier New', Courier, monospace;
    white-space: nowrap;
    overflow: hidden;
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    padding: 0 4px !important;
  }

  // end of 'Your Order' columns
}
</style>
