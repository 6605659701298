<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('publishAuction') }}</span>
        <v-spacer></v-spacer>
        <span class="text--primary headline-2">{{ formatEquityDescription(auction.equity) }}</span>
      </v-card-title>
      <v-card-text>
        <template v-if="state === State.CreateAuctionErr && !!createAuctionErr">
          <v-alert dense type="error">{{ createAuctionErr }}</v-alert>
        </template>
        <template v-if="createOrderErr != ''">
          <v-alert dense type="error">{{ createOrderErr }}</v-alert>
        </template>
      </v-card-text>
      <v-card-text>
        <auction-publication
          :auction="auction"
          :staged-auction="stagedAuction"
        ></auction-publication>
      </v-card-text>
      <v-card-text>
        <v-alert v-if="state === State.CreateOrderErr" dense type="error">
          {{ $t('auction-state-create-order-err') }}
          <div>
            {{ $t('auction-state-create-order-err2') }}
            <v-tooltip v-if="!!createOrderErr" color="form-tooltip" left max-width="600px">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                </span>
              </template>
              <span>{{ createOrderErr }}</span>
            </v-tooltip>
          </div>
        </v-alert>
        <v-alert v-else-if="state === State.Done" type="success">
          {{ $t('your-order-created') }}
        </v-alert>

        <v-alert v-else color="info" dense>
          <div class="title">{{ $t('visible-to') }} {{ $t('participants') }}</div>
          <div>
            {{ $t('auction') }}
            <template v-if="auction.leakDirection">to {{ $t(direction) }}&nbsp;</template>
            <template v-else>for&nbsp;</template>
            <template v-if="auction.leakQuantity">
              <pretty-number :value="auction.shareableQuantity" /> &nbsp;
            </template>
            <i>{{ auction.equity.name }}</i>
            shares
            <template v-if="auction.leakRate"> at <rate-output :rate="leakedRate" /> </template>
            <template
              v-if="auction.leakIsStackedOrder && auction.firstOrderTicket.orders.length > 1"
              ><i>(stacked order)</i>
            </template>
          </div>
        </v-alert>

        <countdown
          v-if="canSubmitStates.includes(state)"
          class="mt-8 px-8"
          expired-message="confirmAuctionExpired"
          info-message="confirmAuctionBefore"
          :when="stagedAuction.endsAt"
          @expired-countdown="auctionExpired"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="d-none d-sm-flex"
          color="secondary"
          :disabled="!canCancelStates.includes(state)"
          text
          @click="closeModalDialog"
        >
          {{ $t('cancelButton') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="d-none d-sm-flex"
          color="secondary"
          :disabled="!canBackStates.includes(state)"
          text
          @click="changeAuction"
        >
          <v-icon left> mdi-arrow-left-bold</v-icon>
          {{ $t('backButton') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!canSubmitStates.includes(state)"
          min-width="120"
          type="submit"
          @click="encryptAndSubmit"
        >
          <v-icon left>mdi-lock</v-icon>
          {{ $t('submitAuctionButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { extend } from 'lodash';
import wait from '@/modules/common/services/wait';

import {
  AuctionDialogBuffer,
  AuctionEquity,
  BespokeAuction,
  ClientConfig,
  OrderTicket,
} from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import AuctionSummary from '@/modules/auction/components/AuctionSummary.vue';
import Countdown from '@/modules/common/components/Countdown.vue';
import AuctionPublication from '@/modules/auction/components/AuctionPublication.vue';
import { Watch } from 'vue-property-decorator';
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import { formatEquityDescription } from '@/utils/helpers/equities';

// we use >= State.X in some places so the order matters!
enum State {
  Expired,
  Input,
  CreateAuctionErr,
  Submitted,
  CreateOrderErr,
  Done,
}

const canBackStates: State[] = [State.Input, State.CreateAuctionErr, State.Expired];
const canCancelStates: State[] = [
  State.Input,
  State.CreateAuctionErr,
  State.CreateOrderErr,
  State.Expired,
  State.Done,
];
const canSubmitStates: State[] = [State.Input, State.CreateAuctionErr];

@Component({
  components: {
    AuctionPublication,
    AuctionSummary,
    Countdown,
  },
  props: {
    auction: Object,
    stagedAuction: Object,
  },
  data: () => ({
    State,
    canBackStates,
    canCancelStates,
    canSubmitStates,
    createAuctionErr: '',
    createOrderErr: '',
    state: State.Input,
  }),
  computed: {
    ...mapState(['clientConfig', 'dayTotal']),
  },
  methods: {
    ...mapActions(['submitBespokeAuction', 'submitOrderTicket']),
  },
})
export default class AuctionConfirmation extends Vue {
  // props
  protected auction!: AuctionDialogBuffer;
  protected stagedAuction!: BespokeAuction;

  // internal state
  protected orderTicket!: OrderTicket;
  protected state: State = State.Input;

  protected createAuctionErr = '';
  protected createOrderErr = '';

  protected eqDescriptionLimit = 60;

  // store state refs
  protected clientConfig!: ClientConfig;

  // store methods
  protected submitBespokeAuction!: (a: BespokeAuction) => Promise<BespokeAuction>;
  protected submitOrderTicket!: (ticket: OrderTicket) => Promise<OrderTicket>;

  protected get direction(): string {
    return this.auction.shareableDirection;
  }

  protected get leakedRate(): string {
    return this.auction.shareableRate.toFixed(RATE_PRECISION);
  }

  @Watch('state')
  protected onStateChange(): void {
    this.$emit('toggle-can-cancel', this.state in canCancelStates);
  }

  protected formatEquityDescription(equity: AuctionEquity): string {
    return formatEquityDescription(equity, this.eqDescriptionLimit);
  }

  protected created(): void {
    // there is only 1 order when initiating an auction
    this.orderTicket = this.stagedAuction.companyOrderTickets[0];
  }

  protected async encryptAndSubmit(): Promise<void> {
    if (!canSubmitStates.includes(this.state)) {
      return;
    }

    this.createAuctionErr = '';
    this.createOrderErr = '';
    this.state = State.Submitted;

    let auction: BespokeAuction;
    try {
      // create auction
      auction = await this.submitBespokeAuction(
        extend(this.stagedAuction, {
          // hide (some) auction information
          leakedDirection: this.auction.leakDirection ? this.auction.shareableDirection : null,
          leakedQuantity: this.auction.leakQuantity ? this.auction.shareableQuantity : null,
          leakedRate: this.auction.leakRate ? this.auction.shareableRate : null,
          leakedIsStackedOrder: this.auction.leakIsStackedOrder
            ? this.orderTicket.orders.length > 1
            : null,
        })
      );
    } catch (e) {
      this.createAuctionErr = errorString(e);
      this.state = State.CreateAuctionErr;
      return;
    }

    try {
      // update the order with the auction ID for the next submit
      this.orderTicket.auctionId = auction.id;

      await this.submitOrderTicket(this.orderTicket);

      // switch to Done display ...
      this.state = State.Done;

      // close modal after 2s
      await wait(1000);
      this.closeModalDialog();
    } catch (e) {
      this.createOrderErr = errorString(e);
      this.state = State.CreateOrderErr;

      return;
    }
  }

  protected auctionExpired(): void {
    // auction cut-off expired, reschedule this auction
    this.state = State.Expired;
  }

  protected changeAuction(): void {
    this.$emit('change-auction');
  }

  protected closeModalDialog(): void {
    // refuse to close modal when pending
    if (!canCancelStates.includes(this.state)) {
      return;
    }

    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
.branding-sidebar {
  background-color: #2c3e50;
}
</style>
