<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row class="pa-0 ma-0" no-gutters>
      <v-col class="pl-0 py-1" cols="2">
        <template v-if="ticketIsEditable()">
          <v-btn
            v-if="!ticketIsNew()"
            color="primary"
            :disabled="!hasTraderUserRole"
            icon
            x-small
            @click="onEditTicket"
            ><v-icon> mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!hasTraderUserRole"
            icon
            x-small
            @click="onDeleteTicket"
          >
            <v-icon> mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-col>
      <v-col class="pl-8 py-1" cols="10">
        <div :class="{ 'is-clickable': isExpandable }" @click="onToggleStackedTicket">
          <div>
            <!-- @TODO: <template> usage below triggers warnings (hence the many disable-next-line directives) -->
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>
              <a v-if="isExpandable" class="is-table-action">
                <v-icon color="primary" size="24">
                  {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </a>
              <span v-else class="px-2"></span>
            </template>
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>{{ $t(ticket.direction) }}&nbsp;</template>
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>
              <span class="is-numeric">
                <b>
                  <pretty-number :value="ticket.totalQuantity" />
                </b>
              </span>
              &nbsp;
            </template>
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>
              <i>
                {{ equity.symbol }}
              </i>
              &nbsp;
            </template>
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>shares&nbsp;</template>
            <template v-if="!isExpandable">
              at
              <span class="is-numeric">
                <b>
                  <rate-output :rate="ticket.avgRate" />
                </b>
              </span>
              &nbsp;
            </template>
            <template v-else>
              <i> ({{ $t('stacked-order') }}) </i>
            </template>
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>
              <template v-if="ticket.filledVolume > 0">
                | <b><pretty-number :value="ticket.filledVolume" /></b> filled
              </template>
            </template>
            <!-- eslint-disable-next-line vue/no-lone-template -->
            <template>
              <v-tooltip v-if="ticket.notes" color="form-tooltip" left max-width="60vw">
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon class="ml-4 mr-1" small> mdi-message-text </v-icon>
                  </span>
                </template>
                <span>{{ ticket.notes }}</span>
              </v-tooltip>
            </template>

            <!-- eslint-disable-next-line vue/require-v-for-key vue/no-use-v-if-with-v-for -->
            <div v-for="order in ticket.orders" v-if="isExpanded && isExpandable" class="pl-16">
              <!-- eslint-disable-next-line vue/no-lone-template -->
              <template>
                <span class="is-numeric">
                  <pretty-number :value="order.quantity" />
                </span>
                &nbsp;
              </template>
              <!-- eslint-disable-next-line vue/no-lone-template -->
              <template>shares at&nbsp;</template>
              <!-- eslint-disable-next-line vue/no-lone-template -->
              <template>
                <span class="is-numeric">
                  <rate-output :rate="order.rate" />
                </span>
              </template>
              <template v-if="order.filledVolume > 0">
                | <b><pretty-number :value="order.filledVolume" /></b> filled
              </template>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';
import { AuctionStatus } from '@/utils/helpers/buffers';
import { AuctionEquity, BespokeAuction, OrderTicket } from '@/utils/helpers/rest';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  props: {
    auction: Object,
    ticket: Object,
    auctionId: String,
    equity: Object,
    isFirstTicket: Boolean,
  },
  data: () => ({
    AuctionStatus,
    isDecryptFailed: false,
    isDecrypted: false,
    isExpanded: false,
    isExpandable: false,
  }),
  computed: {
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class BespokeOrderTicket extends Vue {
  // props
  private auction!: BespokeAuction;
  private ticket!: OrderTicket;
  private auctionId!: string;
  private equity!: AuctionEquity;

  // Store state
  private readonly hasTraderUserRole!: boolean;

  private isExpanded!: boolean;
  private isExpandable!: boolean;

  private mounted() {
    this.isExpandable = this.ticket.orders.length > 1;
  }

  private onToggleStackedTicket() {
    this.isExpanded = !this.isExpanded;
  }

  private async onAddTicket() {
    await this.$emit('add-ticket', this.auction);
  }

  private async onEditTicket() {
    await this.$emit('edit-ticket', this.auction, this.ticket);
  }

  private ticketIsNew(): boolean {
    return this.ticket.id === '';
  }

  private ticketIsEditable(): boolean {
    return this.auction.status === AuctionStatus.Open || this.auction.executedAt == null;
  }

  private async onDeleteTicket() {
    await this.$dialog.ask({
      title: i18n.t('deleteAuctionOrder') as string,
      color: 'error',
      icon: 'mdi-delete',
      message: i18n.t('deleteAuctionOrderMessage', {
        equityName: this.equity.name,
        direction: i18n.t(this.ticket.direction),
        amount: formatPrettyNumber(this.ticket.totalQuantity),
      }) as string,
      acceptText: i18n.t('deleteAuctionOrder') as string,
      onAccept: () => this.$emit('delete-ticket', this.auction, this.ticket),
      rejectText: i18n.t('cancelButton'),
    });
  }
}
</script>

<style lang="scss" scoped>
.is-table-action {
  margin-right: 4px;
}
</style>
