<template>
  <v-card class="flex d-flex flex-column">
    <new-manual-loan-dialog
      v-if="showNewDialog"
      :file-upload.sync="manualLoanUploadData"
      :is-pre-established="isPreEstablished"
      :show-dialog.sync="showNewDialog"
      :side="actingSide"
    />

    <update-manual-loan-status-dialog
      :is-pre-established="isPreEstablished"
      :manual-loans.sync="selectedItems"
      :new-status="manualLoanNewStatus"
      :show-dialog.sync="showStatusChangeDialog"
      :side="actingSide"
    />

    <!-- File upload dialog box -->
    <upload-progress-popup
      :display-status.sync="isUploading"
      :progress-promise="uploadProgressPromise"
    />

    <v-container v-if="canBorrow" class="py-0" data-test="vTabsButtons" fluid>
      <v-row class="mt-2 mb-4" no-gutters>
        <v-tabs ref="tabs" v-model="selectedTabIndex" background-color="transparent">
          <v-tab
            v-for="tabName in tabNames"
            :key="tabName"
            :data-test="`manual-loans-${tabName}-tab-name`"
            :value="tabName"
          >
            {{ tabName }} ({{ groupedLoans[tabName].length }})
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>

    <v-data-table
      ref="tableRef"
      v-model="selectedItems"
      class="d-flex flex flex-column"
      :custom-filter="searchableTableFilter"
      dense
      fixed-header
      :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
      :headers="tableColumns"
      height="100%"
      item-key="id"
      :items="formattedManualLoans"
      :items-per-page="50"
      :search="search"
      :show-select="hasTraderUserRole"
      sort-by="date"
      sort-desc
    >
      <!-- Top bar above table  -->
      <template #top>
        <div class="d-flex flex-row">
          <!-- Left-most button group -->
          <div class="col col-auto d-flex flex-column justify-space-between">
            <div class="d-flex">
              <div class="d-flex flex-row align-center d-none">
                <div v-if="selectedTabName === 'outgoing' && canBorrow" class="d-flex">
                  <aurora-btn-dropdown
                    color="secondary"
                    data-test="new-manual-loan-btn"
                    :disabled="!hasTraderUserRole"
                    :main-text="
                      isPreEstablished
                        ? $tc('preEstablishedLoans.newBorrow.title')
                        : $tc('manualLoans.newBorrow.title')
                    "
                    split
                    timeframe="createLoans"
                    @click="onNewDialog('borrower')"
                  >
                    <v-list dense>
                      <v-list-item
                        data-test="cancel-pending-menu-item"
                        @click="$refs.borrowerFileInput.click()"
                      >
                        <v-list-item-content>
                          <v-list-item-title>Upload Borrow CSV</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon dense small> mdi-upload</v-icon>
                          <input
                            ref="borrowerFileInput"
                            :accept="uploadFileTypes"
                            class="d-none"
                            type="file"
                            @change="onChangeFileUpload('borrower', $refs.borrowerFileInput)"
                          />
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </aurora-btn-dropdown>
                </div>
                <div class="d-flex flex-row">
                  <div class="d-flex flex-row align-center">
                    <div v-if="selectedTabName === 'outgoing' && canLend" class="d-flex ml-5">
                      <aurora-btn-dropdown
                        color="secondary"
                        data-test="new-manual-loan-btn"
                        :disabled="!hasTraderUserRole"
                        :main-text="
                          isPreEstablished
                            ? $tc('preEstablishedLoans.newLoan.title')
                            : $tc('manualLoans.newLoan.title')
                        "
                        split
                        timeframe="createLoans"
                        @click="onNewDialog('lender')"
                      >
                        <v-list dense>
                          <v-list-item @click="$refs.lenderFileInput.click()">
                            <v-list-item-content>
                              <v-list-item-title>Upload Loan CSV</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon dense small> mdi-upload</v-icon>
                              <input
                                id="fileUpload"
                                ref="lenderFileInput"
                                :accept="uploadFileTypes"
                                class="d-none"
                                name="fileUpload"
                                type="file"
                                value="fileUpload"
                                @change="onChangeFileUpload('lender', $refs.lenderFileInput)"
                              />
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </aurora-btn-dropdown>
                    </div>

                    <!-- Batch actions -->
                    <aurora-btn
                      v-if="selectedTabName === 'incoming'"
                      class="mr-1"
                      :disabled="!hasTraderUserRole || !selectedItems.length"
                      icon
                      small
                      timeframe="createLoans"
                      :title="$t('manualLoans.batchActions.acceptLoans')"
                      @click="onBatchAccept(selectedItems)"
                    >
                      <v-icon>mdi-check</v-icon>
                    </aurora-btn>
                    <aurora-btn
                      v-if="selectedTabName === 'incoming'"
                      class="mr-1"
                      :disabled="!hasTraderUserRole || !selectedItems.length"
                      icon
                      small
                      timeframe="createLoans"
                      :title="$t('manualLoans.batchActions.rejectLoans')"
                      @click="onBatchReject(selectedItems)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </aurora-btn>
                    <aurora-btn
                      v-if="selectedTabName === 'outgoing'"
                      class="ml-10 mr-1"
                      :disabled="
                        !hasTraderUserRole || !selectedItems.length || selectedSides.length !== 1
                      "
                      icon
                      small
                      timeframe="createLoans"
                      :title="$t('manualLoans.batchActions.cancelLoans')"
                      @click="onBatchCancel(selectedItems)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </aurora-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex align-end selected-items-count">
              <transition name="fade">
                <small v-if="selectedItems.length">
                  {{
                    selectedItems.length
                      ? `${selectedItems.length} of ${formattedManualLoans.length} selected`
                      : ''
                  }}
                </small>
              </transition>
            </div>
          </div>

          <!-- filter box -->
          <div class="d-flex col-md-2 ml-auto search">
            <v-text-field
              v-model="search"
              clearable
              dense
              label="Filter list"
              placeholder="filter..."
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </div>

          <div class="d-flex col-auto justify-end filters">
            <v-switch v-model="filterShowAll" class="pt-1" hide-details label="show all" />
          </div>
        </div>
      </template>

      <!-- Table rows -->
      <template #[`item.side`]="{ item }">
        <format-side :side="item.side" />
      </template>

      <template #[`item.rate`]="{ item }">
        <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
      </template>

      <template #[`item.isPreEstablished`]="{ item }">
        {{ item.isPreEstablished ? 'PRE' : '' }}
      </template>

      <template #[`item.price`]="{ item }"> ${{ formatPrice(item.price) }}</template>

      <template #[`item.quantity`]="{ item }">
        <pretty-number :value="item.quantity" />
      </template>

      <template #[`item.contractAmount`]="{ item }">
        ${{ formatPrice(item.contractAmount) }}
      </template>

      <template #[`item.settlementAmount`]="{ item }">
        ${{ formatPrice(item.settlementAmount) }}
      </template>

      <template #[`item.settlementType`]="{ item }">
        {{ settlementTypeDisplayText[item.settlementType] }}
      </template>

      <template #[`item.independentAmountRate`]="{ item }">
        <rate-output
          v-if="selectedTabName === 'incoming' || item.side === 'lender'"
          :precision="2"
          :rate="item.independentAmountRate"
        />
        <span v-else>N/A</span>
      </template>

      <!-- Row item actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex flex-row justify-end row-actions">
          <aurora-btn
            v-if="selectedTabName === 'incoming'"
            class="ml-1 action-accept"
            :disabled="!hasTraderUserRole || !item.isActionable"
            icon
            small
            timeframe="createLoans"
            :title="$t('manualLoans.itemActions.acceptLoan')"
            @click="onBatchAccept([item])"
          >
            <v-icon>mdi-check</v-icon>
          </aurora-btn>
          <aurora-btn
            v-if="selectedTabName === 'incoming'"
            class="ml-1 action-reject"
            :disabled="!hasTraderUserRole || !item.isActionable"
            icon
            small
            timeframe="createLoans"
            :title="$t('manualLoans.itemActions.rejectLoan')"
            @click="onBatchReject([item])"
          >
            <v-icon>mdi-close</v-icon>
          </aurora-btn>
          <aurora-btn
            v-if="selectedTabName === 'outgoing'"
            class="ml-1 action-cancel"
            :disabled="!hasTraderUserRole || !item.isActionable"
            icon
            small
            timeframe="createLoans"
            :title="$t('manualLoans.itemActions.cancelLoan')"
            @click="onBatchCancel([item])"
          >
            <v-icon>mdi-delete</v-icon>
          </aurora-btn>
        </div>
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip :class="statusCssClass[item.status]" small>
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import SystemBar from '@/modules/common/components/SystemBar.vue';
import i18n from '@/localisation/i18n';
import { DataTableHeader } from 'vuetify';
import {
  ManualLoanListDisplayItem,
  ManualLoanListItem,
} from '@/modules/manual-loan/types/manual-loans';
import { Side } from '@/modules/user-accounts/types/user-accounts';
import PrettyDate from '@/modules/common/components/format-date/PrettyDate.vue';
import {
  ManualLoanStatus,
  manualLoanStatusCssClass,
} from '@/modules/manual-loan/constants/manual-loans.const';
import { mapActions, mapGetters, mapState } from 'vuex';
import NewManualLoanDialog from './NewManualLoanDialog.vue';
import UpdateManualLoanStatusDialog from './UpdateManualLoanStatusDialog.vue';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION, RATE_PRECISION } from '@/modules/common/constants/precision';
import UploadProgressPopup from '@/modules/common/components/UploadProgressPopup.vue';
import wait from '@/modules/common/services/wait';
import PrettyDateTime from '@/modules/common/components/format-date/PrettyDateTime.vue';
import { Api } from '@/modules/common/types/api';
import { searchableTableFilter } from '@/utils/helpers/searchable-fields';
import { isAfter, sub } from 'date-fns';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { ClientConfig } from '@/utils/helpers/rest';
import { settlementTypeDisplayText } from '@/modules/marketplace/helpers/marketplace';

type TabName = 'incoming' | 'outgoing';
type TabbedManualLoans = Record<TabName, ManualLoanListItem[]>;

const allTableHeaders: DataTableHeader[] = [
  {
    text: i18n.t('manualLoans.listCols.status') as string,
    value: 'status',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
    width: '7rem',
  },
  {
    text: i18n.tc('manualLoans.listCols.side'),
    value: 'side',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '4rem',
  },
  {
    text: i18n.t('manualLoans.listCols.counterparty') as string,
    value: 'counterpartyDisplay',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '12rem',
  },
  {
    text: i18n.t('manualLoans.listCols.ticker') as string,
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.t('manualLoans.listCols.cusip') as string,
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.t('manualLoans.listCols.price') as string,
    value: 'price',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.t('manualLoans.listCols.qty') as string,
    value: 'quantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.t('manualLoans.listCols.rate') as string,
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.t('manualLoans.listCols.contractAmount') as string,
    value: 'contractAmount',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.t('manualLoans.listCols.independentAmountRate') as string,
    value: 'independentAmountRate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.t('manualLoans.listCols.settlementAmount') as string,
    value: 'settlementAmount',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.t('manualLoans.listCols.settlementType') as string,
    value: 'settlementType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.t('manualLoans.listCols.actions') as string,
    value: 'actions',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    sortable: false,
    filterable: false,
    width: 120,
  },
];

@Component({
  components: {
    SystemBar,
    PrettyDate,
    PrettyDateTime,
    NewManualLoanDialog,
    UpdateManualLoanStatusDialog,
    UploadProgressPopup,
    BtnDropdown,
  },
  props: {
    isPreEstablished: Boolean,
  },
  methods: {
    ...mapActions(['setFilterPreEstablished', 'setFilterStatuses', 'fetchManualLoanRequests']),
  },
  computed: {
    // mapState doesn't fully support non-namespaced modules yet
    // https://github.com/vuejs/vuex/issues/1592
    // ...mapState('manualLoans', ['manualLoanRequests']),
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ...mapState({
      manualLoanRequests: (state: any) => state.manualLoans.manualLoanRequests,
    }),
    ...mapState(['clientConfig']),
    /* eslint-enable @typescript-eslint/no-explicit-any */
    ...mapGetters(['hasTraderUserRole', 'canBorrow', 'canLend']),
  },
})
export default class ManualLoansList extends Vue {
  public $refs!: {
    borrowerFileInput: HTMLInputElement;
    lenderFileInput: HTMLInputElement;
    tableRef: HTMLInputElement;
  };
  protected readonly isPreEstablished!: boolean;

  protected setFilterPreEstablished!: (isPreEstablished: boolean) => void;
  protected setFilterStatuses!: (statuses: ManualLoanStatus[]) => void;
  protected fetchManualLoanRequests!: () => Promise<void>;
  protected manualLoanRequests!: ManualLoanListItem[];
  protected manualLoanUploadData: Api.ManualLoans.ManualLoanBasketUploadResponse | null = null;

  protected readonly hasTraderUserRole!: boolean;
  protected readonly canBorrow!: boolean;
  protected readonly canLend!: boolean;
  protected readonly clientConfig!: ClientConfig;

  protected actingSide: Side = 'borrower';
  protected showNewDialog = false;
  protected showStatusChangeDialog = false;

  protected selectedItems: ManualLoanListDisplayItem[] = [];
  protected manualLoanNewStatus:
    | ManualLoanStatus.Accepted
    | ManualLoanStatus.Rejected
    | ManualLoanStatus.Canceled
    | null = null;
  protected searchableTableFilter = searchableTableFilter;
  protected filterShowAll = false;
  protected search = '';

  // used to trigger timed animation
  protected time: Date = new Date();
  protected timer: NodeJS.Timeout | null = null;
  protected manualLoans: ManualLoanListItem[] = [];

  protected settlementTypeDisplayText = settlementTypeDisplayText;

  /**
   * possible file types that can be uploaded
   */
  protected readonly uploadFileTypes = '.csv, .tsv, .xls, .xlsx, .dummy, text/*';
  protected isUploading = false;
  protected uploadProgressPromise: Promise<unknown> | null = null;
  protected ratePrecision = RATE_PRECISION;
  protected pricePrecision = PRICE_PRECISION;
  protected statusCssClass = manualLoanStatusCssClass;
  protected tabNames: TabName[] = ['incoming', 'outgoing'];
  protected selectedTabIndex = 1; // outgoing is always available for both lenders and borrowers;

  protected get nonSftLoansEnabled(): boolean {
    return (
      !this.isPreEstablished &&
      (this.clientConfig.bilateralLoansEnabled || this.clientConfig.occBilateralLoansEnabled)
    );
  }

  protected get tableColumns(): DataTableHeader[] {
    return allTableHeaders.filter((h) => {
      // show IA related columns only when enabled and settlement type only for manual loans.
      if (!this.nonSftLoansEnabled && h.value === 'settlementType') {
        return false;
      }

      return true;
    });
  }

  protected get groupedLoans(): TabbedManualLoans {
    let loans = this.manualLoans;

    if (!this.filterShowAll) {
      loans = loans.filter((item) => {
        // hide automatched items after 2 seconds
        if (item.status === ManualLoanStatus.Automatched) {
          const limit = sub(new Date(), { seconds: 2 });
          return isAfter(item.updatedAt, limit);
        }

        // remaining filters applied server-side
        return true;
      });
    }

    const grouped = loans.reduce<TabbedManualLoans>(
      (acc, loan) => {
        if (loan.side === loan.initiatorSide) {
          acc.outgoing.push(loan);
        } else {
          acc.incoming.push(loan);
        }
        return acc;
      },
      { incoming: [], outgoing: [] }
    );

    return grouped;
  }

  protected get selectedTabName(): TabName {
    return this.tabNames[this.selectedTabIndex];
  }

  /**
   * Get a formatted version of the manual loans list
   * provides formatted display versions of the various fields
   */
  protected get formattedManualLoans(): ManualLoanListDisplayItem[] {
    return this.groupedLoans[this.selectedTabName].map((item) => {
      const isActionable = item.status === ManualLoanStatus.Pending;

      return {
        ...item,
        isActionable,
      };
    });
  }

  protected get selectedItemsMessage(): string {
    return this.selectedItems.length
      ? `${this.selectedItems.length} of ${this.formattedManualLoans.length} selected`
      : '';
  }

  protected get selectedSides(): string[] {
    // find all selected sides
    //  can only batch delete if all loan requests have the same side
    return [...new Set(this.selectedItems.map((item) => item.initiatorSide))];
  }

  @Watch('filterShowAll')
  protected onFilterShowAll(): void {
    this.setFilterStatuses(
      this.filterShowAll ? [] : [ManualLoanStatus.Pending, ManualLoanStatus.Automatched]
    );
    void this.fetchManualLoanRequests();
  }

  @Watch('manualLoanRequests')
  @Watch('time') // touch the state every second to trigger recalculation of filteredManualLoans getter.
  protected updateManualLoans(): void {
    this.manualLoans = [...this.manualLoanRequests];
  }

  protected async mounted(): Promise<void> {
    // DO NOT REMOVE or EDIT unless you understand
    // https://provablelabs.atlassian.net/browse/AURORA-1480
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.tableRef as any).multipleSelect = () => {
      /* */
    };

    // store filters in the store so that socket `refresh` events know how to
    // refresh as the filters are applied server-side
    this.setFilterPreEstablished(this.isPreEstablished);
    this.setFilterStatuses(
      this.filterShowAll ? [] : [ManualLoanStatus.Pending, ManualLoanStatus.Automatched]
    );

    // initial fetch of the user's manual loans
    await this.fetchManualLoanRequests();

    // lenders/borrowers permissions, show the outgoing tab when there are no incoming loans
    if (this.canBorrow && this.canLend) {
      this.selectedTabIndex = this.groupedLoans.incoming.length ? 0 : 1;
    }
    // borrowers should start by inspecting `incoming` loans
    else if (this.canBorrow) {
      this.selectedTabIndex = 0;
    }
    // when only lenders, do nothing (because we don't have any tabs)

    // update animation clock every second
    this.timer = setInterval(() => (this.time = new Date()), 1000);
  }

  protected beforeUnmount(): void {
    // clear timer for animation clock
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  protected onBatchAccept(items: ManualLoanListDisplayItem[]): void {
    // display action confirmation dialog
    this.selectedItems = items;
    this.manualLoanNewStatus = ManualLoanStatus.Accepted;
    this.actingSide = 'borrower';
    this.showStatusChangeDialog = true;
  }

  protected onBatchReject(items: ManualLoanListDisplayItem[]): void {
    // display action confirmation dialog
    this.selectedItems = items;
    this.manualLoanNewStatus = ManualLoanStatus.Rejected;
    this.actingSide = 'borrower';
    this.showStatusChangeDialog = true;
  }

  protected onBatchCancel(items: ManualLoanListDisplayItem[]): void {
    // display action confirmation dialog
    this.selectedItems = items;
    this.manualLoanNewStatus = ManualLoanStatus.Canceled;
    this.actingSide = items[0].initiatorSide; // we know all selected have the same initiator-side
    this.showStatusChangeDialog = true;
  }

  protected onNewDialog(actingSide: Side): void {
    this.actingSide = actingSide;
    this.showNewDialog = true;
  }

  /**
   * Handles file upload for creation of new Manual Loans
   */
  protected async onChangeFileUpload(actingSide: Side, input: HTMLInputElement): Promise<void> {
    if (input.files) {
      // post the file for parsing and show a progress dialog box
      const promise = this.$api.manualLoans.uploadManualLoanFile(input.files[0]);
      this.uploadProgressPromise = promise;
      try {
        this.manualLoanUploadData = await promise;

        // show dialog for new loan after the progress dialog closes
        await wait(650);
        this.actingSide = actingSide;
        this.showNewDialog = true;
        // reset file input (Chrome needs this to make sure user can upload same file again)
        this.$refs[`${actingSide}FileInput`].value = '';
      } catch (err) {
        // Do nothing: the error will be displayed in the upload progress dialog box
      }
    }
  }

  protected formatPrice(price: number): string {
    return getPriceAsString(price, this.pricePrecision);
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  .selected-items-count {
    min-height: 2rem;

    small {
      font-size: 0.75rem;
    }
  }

  // remove extra margin on file input icon
  .v-btn .v-file-input .v-input__prepend-outer {
    margin: 0;
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }

    tr {
      .row-actions {
        opacity: 0.4;
        pointer-events: none;
        transition: opacity linear 0.15s;
      }

      &:hover .row-actions {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }

  // @TODO use sass variables to store these colours
  .v-chip {
    &.status-pending {
      color: #fff;
      background: #ffae00;
    }

    &.status-accepted {
      color: #fff;
      background: #4caf50;
    }

    &.status-automatched {
      color: #fff;
      background: #4caf50;
    }

    &.status-rejected {
      color: #fff;
      background: #df1616;
    }

    &.status-canceled {
      color: #fff;
      background: #7a7a7a;
    }

    &.status-expired {
      color: #fff;
      background: #8f8f8f;
    }
  }

  // General Transitions and Animations
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
