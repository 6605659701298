<template>
  <div class="px-6 py-2">
    <v-row v-if="fetchStatus === 'fetching'" class="justify-center pt-16 flex-column align-center">
      <fulfilling-bouncing-circle-spinner :animation-duration="1500" color="#4caf50" :size="50" />
      <h3 class="mt-2">Fetching killswitch details...</h3>
    </v-row>

    <v-row
      v-else-if="fetchStatus === 'failed'"
      class="justify-center pt-16 flex-column align-center"
    >
      <v-alert dense type="error">
        {{ fetchError }}
      </v-alert>
      <v-btn color="primary mt-4" @click="reloadPage()">Try again</v-btn>
    </v-row>

    <v-row v-else-if="fetchStatus === 'completed'">
      <v-col cols="12">
        <v-divider class="mt-8" />
        <v-switch
          color="red"
          data-test="kill-switch-switch"
          :input-value="isKillSwitchOn"
          :label="isKillSwitchOn ? 'Marketplace shut down' : 'Marketplace operational'"
          readonly
          @click="toggleKillSwitch"
        />
        <v-alert outlined text type="error">
          When Marketplace is disabled through killswitch:
          <ul class="mt-2">
            <li>All Marketplace operations (including trading) are disabled</li>
            <li>All routed venue orders are canceled</li>
          </ul>
        </v-alert>

        <v-divider class="mt-8" />
        <v-switch
          :class="{ 'halt-trading-disabled': isKillSwitchOn }"
          color="red"
          data-test="trading-switch"
          :disabled="isKillSwitchOn"
          :input-value="isHaltTradingOn"
          :label="isHaltTradingOn ? 'Trading shut down' : 'Trading operational'"
          readonly
          @click="toggleHaltTrading"
        />
        <v-alert outlined text type="error">
          When Trading is disabled through killswitch:
          <ul class="mt-2">
            <li>Users are <strong>not allowed</strong> to create or modify orders</li>
            <li>Users can still cancel and unroute orders</li>
          </ul>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { ApiError } from '@/utils/errors';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class BrokerKillSwitch extends Vue {
  protected fetchStatus: 'fetching' | 'completed' | 'failed' = 'fetching';
  protected fetchError = '';
  protected isKillSwitchOn = false;
  protected isHaltTradingOn = false;

  protected async mounted(): Promise<void> {
    try {
      const data = await this.$api.common.fetchKillSwitchState();
      this.fetchStatus = 'completed';

      this.isKillSwitchOn = data.isKillSwitchOn;
      this.isHaltTradingOn = data.isHaltTradingOn;
    } catch (err) {
      this.fetchStatus = 'failed';
      this.fetchError = (err as ApiError).message;
    }
  }

  protected toggleKillSwitch(): void {
    // confirm is green button, alert is red button
    const dialogMethod = this.isKillSwitchOn ? 'confirm' : 'alert';

    this.$dialog[dialogMethod]({
      title: 'Are you sure?',
      message: `You are about to ${this.isKillSwitchOn ? 'enable' : 'disable'} the Marketplace`,
      isRejectable: true,
      rejectText: 'Cancel',
      acceptText: this.isKillSwitchOn ? 'Enable Marketplace' : 'Disable Marketplace',
      onAccept: async () => {
        await this.$api.common.updateKillSwitch(this.isKillSwitchOn ? 'disable' : 'enable');
        this.isKillSwitchOn = !this.isKillSwitchOn;

        if (this.isKillSwitchOn) {
          await this.$api.common.updateHaltTrading('enable');
          this.isHaltTradingOn = true;
        }
      },
    });
  }

  protected toggleHaltTrading(): void {
    // confirm is green button, alert is red button
    const dialogMethod = this.isHaltTradingOn ? 'confirm' : 'alert';

    this.$dialog[dialogMethod]({
      title: 'Are you sure?',
      message: `You are about to ${this.isHaltTradingOn ? 'enable' : 'disable'} trading`,
      isRejectable: true,
      rejectText: 'Cancel',
      acceptText: this.isHaltTradingOn ? 'Enable Trading' : 'Disable Trading',
      onAccept: async () => {
        await this.$api.common.updateHaltTrading(this.isHaltTradingOn ? 'disable' : 'enable');
        this.isHaltTradingOn = !this.isHaltTradingOn;
      },
    });
  }

  protected reloadPage(): void {
    window.location.reload();
  }
}
</script>

<style lang="scss" scoped>
.halt-trading-disabled {
  pointer-events: none;
}
</style>
