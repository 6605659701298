<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('confirm-order') }}</span>
        <v-spacer></v-spacer>
        <span class="text--primary headline-2">{{ formatEquityDescription(auction.equity) }}</span>
      </v-card-title>
      <v-card-text>
        <!-- Your Order - confirmation -->
        <v-alert color="info" dense>
          <div class="title">
            {{ $t('your-order') }}
          </div>
          <div>
            <div v-for="order in stagedTicket.orders" :key="order.id">
              {{ $t(stagedTicket.direction) }}
              <pretty-number :value="order.quantity" />
              {{ auction.equity.name }} shares at <rate-output :rate="order.rate" />
            </div>
          </div>
        </v-alert>
      </v-card-text>
      <v-card-text>
        <auction-summary :auction="auction"></auction-summary>
      </v-card-text>
      <v-card-text>
        <v-alert v-if="state === State.Err" dense type="error">
          {{ $t('create-order-err') }}
          <div>
            {{ $t('create-order-err2') }}
            <v-tooltip v-if="!!createOrderErr" color="form-tooltip" left max-width="600px">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                </span>
              </template>
              <span>{{ createOrderErr }}</span>
            </v-tooltip>
          </div>
        </v-alert>
        <v-alert v-else-if="state === State.Done" type="success">
          {{ $t('your-order-created') }}
        </v-alert>

        <countdown
          v-if="canSubmitStates.includes(state)"
          class="mt-8 px-8"
          expired-message="joinAuctionExpired"
          info-message="joinAuctionBefore"
          :when="auction.endsAt"
          @Expired-countdown="auctionExpired"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="d-none d-sm-flex"
          color="secondary"
          :disabled="!canCancelStates.includes(state)"
          text
          @click="closeModalDialog"
        >
          {{ $t('cancelButton') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="d-none d-sm-flex"
          color="secondary"
          :disabled="!canCancelStates.includes(state)"
          text
          @click="changeOrder"
        >
          <v-icon left> mdi-arrow-left-bold</v-icon>
          {{ $t('backButton') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!canSubmitStates.includes(state)"
          min-width="120"
          type="submit"
          @click="encryptAndSubmit"
        >
          <v-icon left>mdi-lock</v-icon>
          {{ $t('submitOrderButton') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import AuctionSummary from '@/modules/auction/components/AuctionSummary.vue';
import Countdown from '@/modules/common/components/Countdown.vue';
import wait from '@/modules/common/services/wait';
import { formatEquityDescription } from '@/utils/helpers/equities';
import {
  AuctionEquity,
  BespokeAuction,
  ClientConfig,
  OrderTicket,
  RestOperation,
  StagedOrderTicket,
  getBestOrderFromTicket,
} from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';

// we use >= State.X in some places so the order matters!
enum State {
  Expired,
  Input,
  Submitted,
  Err,
  Done,
}

const canCancelStates: State[] = [State.Input, State.Err, State.Expired, State.Done];
const canSubmitStates: State[] = [State.Input, State.Err];

@Component({
  components: {
    AuctionSummary,
    Countdown,
  },
  props: {
    crudAction: Number,
    auction: Object,
    stagedTicket: Object,
  },
  data: () => ({
    State,
    canCancelStates,
    canSubmitStates,
    createOrderErr: '',
    state: State.Input,
  }),
  computed: {
    ...mapState(['clientConfig']),
  },
  methods: {
    ...mapActions(['submitOrderTicket', 'updateOrderTicket']),
  },
})
export default class AuctionOrderConfirmation extends Vue {
  // props
  private crudAction!: RestOperation;
  private auction!: BespokeAuction;
  private stagedTicket!: StagedOrderTicket;

  // internal state
  private state: State = State.Input;

  private eqDescriptionLimit = 60;
  private createOrderErr = '';

  // store state refs
  private clientConfig!: ClientConfig;

  // store methods
  private submitOrderTicket!: (ticket: OrderTicket) => Promise<OrderTicket>;
  private updateOrderTicket!: (ticket: OrderTicket) => Promise<OrderTicket>;

  @Watch('state')
  protected onStateChange(): void {
    this.$emit('toggle-can-cancel', this.state in canCancelStates);
  }

  protected formatEquityDescription(equity: AuctionEquity): string {
    return formatEquityDescription(equity, this.eqDescriptionLimit);
  }

  protected async encryptAndSubmit(): Promise<void> {
    if (!canSubmitStates.includes(this.state)) {
      return;
    }

    // no need to try anything if we can't encrypt the order
    this.createOrderErr = '';
    this.state = State.Submitted;

    try {
      // the initiator of the auction may update leaked information if it still known what
      // the direction(bid/ask) was of the first order
      if (this.auction.isOwnCompany && this.auction.originalDirection) {
        // replace the current order with the edited
        const ticketList: OrderTicket[] = [
          ...this.auction.companyOrderTickets.filter((e) => {
            return e.id !== this.stagedTicket.id;
          }),
          this.stagedTicket,
        ];

        // find the best order in the updated order list and publish that
        const leakage = getBestOrderFromTicket(
          this.auction,
          this.auction.originalDirection,
          ticketList
        );
        this.stagedTicket.leakedDirection = leakage.leakedDirection;
        this.stagedTicket.leakedQuantity = leakage.leakedQuantity;
        this.stagedTicket.leakedRate = leakage.leakedRate;
        this.stagedTicket.leakedIsStackedOrder = leakage.leakedIsStackedOrder;
      }

      if (this.crudAction === RestOperation.Create) {
        await this.submitOrderTicket(this.stagedTicket);
      } else {
        await this.updateOrderTicket(this.stagedTicket);
      }

      this.state = State.Done;

      // close modal after 2s
      await wait(1000);
      this.closeModalDialog();
    } catch (e) {
      this.createOrderErr = errorString(e);
      this.state = State.Err;

      return;
    }
  }

  protected auctionExpired(): void {
    // auction cut-off Expired, reschedule this auction
    this.state = State.Expired;
  }

  protected changeOrder(): void {
    this.$emit('change-ticket');
  }

  protected closeModalDialog(): void {
    // refuse to close modal when pending
    if (!canCancelStates.includes(this.state)) {
      return;
    }

    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
.message-spacer {
  margin-bottom: 10px;
}
</style>
