import Decimal from 'decimal.js';
import { DialogManager } from '@/plugins/dialog-manager';
import { formatDecimalAsCurrencyString } from '@/utils/helpers/auction-numbers';
import { isNull } from 'lodash';
import { CustomRule } from 'vuelidate/lib/validators';
import { MyAccount } from '@/utils/helpers/rest';
import { noop } from 'lodash';

// minValueIf - vuelidate validator to check minValue for optional fields
//   check if hard limit is not below soft limit only if soft limit has been set
export const minValueIf =
  (ifCondition: boolean, ifValue: Decimal | null): CustomRule =>
  (value: Decimal | null) => {
    return !ifCondition || isNull(ifValue) || isNull(value) || ifValue.lessThanOrEqualTo(value);
  };

// apply risk limits to the user that is logged in
export class RiskLimitValidator {
  private readonly dm: DialogManager;
  private readonly user: MyAccount;
  private personalLimitDescription = 'your personal';
  private companyLimitDescription = 'the company';

  public constructor(dm: DialogManager, user: MyAccount) {
    this.dm = dm;
    this.user = user;
  }

  public get softLimitValue(): Decimal | null {
    return this.usePersonalSoftLimit() ? this.user.orderSoftLimit : this.user.defaultOrderSoftLimit;
  }

  private get hardLimitValue(): Decimal | null {
    return this.usePersonalHardLimit() ? this.user.orderHardLimit : this.user.defaultOrderHardLimit;
  }

  private get softLimitDescription(): string {
    return this.usePersonalSoftLimit()
      ? this.personalLimitDescription
      : this.companyLimitDescription;
  }

  private get hardLimitDescription(): string {
    return this.usePersonalHardLimit()
      ? this.personalLimitDescription
      : this.companyLimitDescription;
  }

  // maxOrderHardLimitRule - vuelidate validator to check the order hard limit
  public maxOrderHardLimitRule = (): CustomRule => (notional: Decimal | null) => {
    return !this.isGreaterThanOrderHardLimit(notional);
  };

  public isGreaterThanOrderSoftLimit(notional: Decimal | null): boolean {
    return this.isGreaterThanOptionalLimit(notional, this.softLimitValue);
  }

  public isGreaterThanOrderHardLimit(notional: Decimal | null): boolean {
    return this.isGreaterThanOptionalLimit(notional, this.hardLimitValue);
  }

  // check hard- and soft-limits as there is no situation where we only check one of them
  public checkAndConfirmRiskLimits(orderValue: Decimal, accept: () => void): void {
    if (!this.checkHardLimit(orderValue)) {
      // no need to check soft-limit if hard-limit is exceeded
      return;
    }

    // check soft-limit and ask for confirmation when it is exceeded
    this.checkSoftLimit(orderValue, () => accept());
  }

  // checkSoftLimit - check against risky-order-size, just fat-finger-check, can be ignored by the user
  public checkSoftLimit(orderValue: Decimal, accept: () => void, reject?: () => void): void {
    if (!this.isGreaterThanOrderSoftLimit(orderValue)) {
      accept();
      return;
    }
    this.dm.ask({
      title: 'Fat Finger Check',
      positionAt: 'bottom',
      color: 'warning',
      borderColor: 'warning',
      message: `The notional value of your order(s) exceeds ${
        this.softLimitDescription
      } limit of ${formatDecimalAsCurrencyString(
        'USD',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.softLimitValue!,
        0
      )}<br><b>Please confirm you would like to submit.</b>`,
      acceptText: 'submit',
      rejectText: 'go back',
      onAccept: () => accept(),
      onReject: () => (reject ? reject() : {}),
    });
  }

  // checkHardLimit - check against max-order-size, cannot be ignored
  public checkHardLimit(orderValue: Decimal): boolean {
    if (!this.isGreaterThanOrderHardLimit(orderValue)) {
      return true;
    }

    this.dm.confirm({
      title: 'Risk Limit Exceeded',
      positionAt: 'bottom',
      borderColor: 'error',
      color: 'error',
      message: `The notional value of your order(s) exceeds ${
        this.hardLimitDescription
      } limit of ${formatDecimalAsCurrencyString(
        'USD',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.hardLimitValue!,
        0
      )}<br><b>Please review the set risk limits with your admin and/or reduce the size of the order.</b>`,
      acceptText: 'close',
      onAccept: noop,
    });

    // no need to wait for the dialog manager, there is nothing the user can do to change this
    return false;
  }

  private usePersonalSoftLimit(): boolean {
    return this.usePersonalLimit(this.user.orderSoftLimit, this.user.defaultOrderSoftLimit);
  }

  private usePersonalHardLimit(): boolean {
    return this.usePersonalLimit(this.user.orderHardLimit, this.user.defaultOrderHardLimit);
  }

  // use the user's personal limit if it is set and less than the company's default limit
  private usePersonalLimit(userLimit: Decimal | null, companyLimit: Decimal | null): boolean {
    return (
      !isNull(userLimit) && (isNull(companyLimit) || userLimit.lessThanOrEqualTo(companyLimit))
    );
  }

  private isGreaterThanOptionalLimit(notional, limit: Decimal | null): boolean {
    return !(isNull(notional) || isNull(limit) || limit.greaterThanOrEqualTo(notional));
  }
}
