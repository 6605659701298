<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <v-card-text>
      <div class="pr-4 pl-2">
        <term-loans-contract-body-summary :contract="contract" />
        <v-divider class="mt-3 mb-0" />

        <template v-if="contract.side !== 'lender'">
          <uploader
            v-if="parseErrors.length === 0"
            :execute="(file) => $api.termLoans.uploadTermLoansQuantities(contract.displayId, file)"
            hide-cancel-btn
            :upload-method="uploadMethod"
            @parse-errors="
              parseErrors = $event;
              apiError = null;
            "
            @upload="populateFromUpload($event.items)"
            @upload-method="uploadMethod = $event"
          >
            <template #description>
              <div>Expected columns:</div>
              <div>Ticker or CUSIP | Quantity</div>
            </template>
          </uploader>
          <upload-parse-errors
            v-else
            :errors="parseErrors"
            :headers="[
              { text: 'Row', value: 'row' },
              { text: 'Error', value: 'errMsg' },
            ]"
            :upload-method="uploadMethod"
            @back="parseErrors = []"
          >
            <template #item="{ item }">
              <tr>
                <td>{{ item.row }}</td>
                <td>{{ item.errormsg }}</td>
              </tr>
            </template>
          </upload-parse-errors>
        </template>

        <v-alert v-if="items.length === 0" class="mt-8" color="orange" dense outlined>
          No securities added yet
        </v-alert>

        <template v-if="contract.side === 'borrower' && remainingEquities.length > 0">
          <v-select
            class="mt-4"
            :error-messages="securitySelectorValidationError"
            item-text="ticker"
            :items="remainingEquities"
            label="Add security"
            return-object
            @change="onAddSecurity"
          />
        </template>

        <div class="table-container mt-4">
          <term-loans-manage-contract-edit-table
            v-if="items.length"
            :items="items"
            :side="contract.side"
            @update-single-quantity="updateSingleQuantity"
          />
        </div>

        <term-loans-manage-contract-totals
          class="mt-8"
          :contract="contract"
          :new-total-value="newTotalValue"
        />
      </div>
      <v-row v-if="apiError">
        <v-col class="pa-0 px-1 col-6 offset-3 mt-8">
          <div
            class="v-alert v-alert--dense text--primary text-body-2 text-center"
            :class="{ error: apiError !== null }"
          >
            <div v-if="apiError">
              {{ apiError }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="contract.side === 'borrower'" class="d-flex justify-end">
      <v-btn class="mb-2" color="primary" type="submit" @click="goToNextStep"> Next </v-btn>
    </v-card-actions>
  </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Api, Equity } from '@/modules/common/types/api';
import TermLoansContractBodySummary from '@/modules/termloans/components/TermLoansContractBodySummary.vue';
import TermLoansManageContractEditTable from '@/modules/termloans/components/TermLoansManageContractEditTable.vue';
import TermLoansManageContractTotals from '@/modules/termloans/components/TermLoansManageContractTotals.vue';
import Decimal from 'decimal.js';
import Uploader from '@/modules/common/components/Uploader.vue';
import { UploadMethod } from '@/modules/common/types/upload';
import UploadParseErrors from '@/modules/common/components/UploadParseErrors.vue';
import { getItemPosition } from '@/modules/termloans/helpers/termloans';

@Component({
  components: {
    TermLoansContractBodySummary,
    TermLoansManageContractEditTable,
    TermLoansManageContractTotals,
    Uploader,
    UploadParseErrors,
  },
  props: {
    contract: {
      type: Object as PropType<Api.TermLoans.ContractSummary>,
      required: true,
    },
    newTotalValue: {
      type: Decimal,
      required: true,
    },
    items: {
      type: Array as PropType<Api.TermLoans.UpdatedItem[]>,
      required: true,
    },
  },
})
export default class TermLoansManageQuantities extends Vue {
  // props
  protected readonly contract!: Api.TermLoans.ContractSummary;
  protected readonly newTotalValue!: Decimal;
  protected readonly items!: Api.TermLoans.UpdatedItem[];

  protected securitySelectorValidationError: string | null = null;
  protected apiError: string | null = null;
  protected uploadMethod: UploadMethod | null = null;
  protected parseErrors: Api.TermLoans.UploadErrorItem[] = [];

  protected get remainingEquities(): Equity[] {
    if (!this.contract) {
      return [];
    }
    const existingCusips = this.items.map((item) => item.cusip);
    return this.contract.equities.filter((equity) => !existingCusips.includes(equity.cusip));
  }

  // @TODO: we should instead check if the new total value is different from the current contract value
  protected get hasUpdateAtLeastOneQuantity(): boolean {
    return this.items.some((item) => item.newQuantity !== item.currentQuantity);
  }

  protected populateFromUpload(newItems: Api.TermLoans.QuantitiesUploadResponse['items']): void {
    this.apiError = null;

    const updatedItems = newItems.map((item) => {
      const existingItem = this.items.find((i) => i.cusip === item.instrument.cusip);
      return {
        cusip: item.instrument.cusip,
        ticker: item.instrument.ticker,
        lastClosePrice: item.instrument.lastClosePrice,
        currentQuantity: existingItem ? existingItem.currentQuantity : 0,
        position: getItemPosition(
          item.instrument.lastClosePrice,
          existingItem ? existingItem.currentQuantity : 0,
          this.newTotalValue
        ),
        newQuantity: item.quantity,
        hasExceeded: false,
      };
    });
    this.$emit('update:items', updatedItems);
  }

  protected updateSingleQuantity(payload: {
    item: Api.TermLoans.UpdatedItem;
    quantity: number;
  }): void {
    const item = this.items.find((i) => i.cusip === payload.item.cusip);
    if (!item || !this.contract) {
      return;
    }
    item.newQuantity = payload.quantity;

    // @TODO: there's probably a much better way of doing this
    // without it, when user changes the quantity via arrow up/down, the position incorrectly updates
    this.$nextTick(() => {
      this.items.forEach((i) => this.updateItem(i));
    });
  }

  protected updateItem(item: Api.TermLoans.UpdatedItem): void {
    item.position = getItemPosition(item.lastClosePrice, item.newQuantity, this.newTotalValue);
    item.hasExceeded = item.position.greaterThan(this.contract?.maxEquityPercent || 0);
  }

  protected onAddSecurity(item: Equity): void {
    this.securitySelectorValidationError = null;

    if (!this.contract) {
      return;
    }
    this.items.push({
      cusip: item.cusip,
      ticker: item.ticker,
      lastClosePrice: item.lastClosePrice,
      currentQuantity: 0,
      position: new Decimal(0),
      newQuantity: 0,
      hasExceeded: false,
    });
  }

  protected goToNextStep(): void {
    this.securitySelectorValidationError = null;
    this.apiError = null;

    if (this.items.length === 0) {
      this.securitySelectorValidationError = 'Please add at least one security';
      return;
    }

    if (!this.hasUpdateAtLeastOneQuantity) {
      this.apiError = 'Please update at least one quantity';
      return;
    }

    this.$emit('next');
  }
}
</script>
<style lang="scss" scoped>
.v-card {
  min-height: 15rem;
}

.actions {
  gap: 1rem;
}

.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 25vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
