<template>
  <v-data-table
    class="d-flex flex flex-column last-col-sticky"
    dense
    fixed-header
    :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
    :headers="tableHeaders"
    item-key="id"
    :items="aggregatedLoans"
    :items-per-page="itemsPerPage"
    must-sort
    :page="page"
    :server-items-length="serverItemsLength"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    v-on="$listeners"
  >
    <template #[`item.side`]="{ item }">
      <format-side :side="item.side" />
    </template>
    <template #[`item.avgRate`]="{ item }">
      <rate-output :rate="item.avgRate" />
    </template>
    <template #[`item.totalOpenQty`]="{ item }">
      <pretty-number :value="item.totalOpenQty" />
    </template>
    <template #[`item.totalRecallQty`]="{ item }">
      <pretty-number :value="item.totalRecallQty" />
    </template>
    <template #[`item.totalValue`]="{ item }"> ${{ formatPrice(item.totalValue) }} </template>
    <!-- Dropdown actions -->
    <template #[`item.actions`]="{ item }">
      <div class="actions d-flex justify-end">
        <btn-dropdown
          color="secondary"
          data-test="actions-dropdown"
          :disabled="!hasOpsUserRole"
          main-text="Actions"
          x-small
        >
          <v-list v-if="item.side === 'lender'" ref="dropdown-actions" dense>
            <aurora-list-item
              data-test="agg-recall-menu-item"
              timeframe="recallLoans"
              @click="openDialog('recall', item)"
            >
              <v-list-item-title> Recall</v-list-item-title>
            </aurora-list-item>
          </v-list>
          <v-list v-if="item.side === 'borrower'" ref="dropdown-actions" dense>
            <aurora-list-item
              data-test="agg-return-menu-item"
              timeframe="settleLoans"
              @click="openDialog('return', item)"
            >
              <v-list-item-title> Return</v-list-item-title>
            </aurora-list-item>
          </v-list>
        </btn-dropdown>
      </div>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { AggregatedLoanByCounterpartyItem, AggregatedLoanBySecurityItem } from '@/utils/api/loans';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapGetters } from 'vuex';

@Component({
  components: {
    BtnDropdown,
  },
  props: {
    groupingType: {
      type: String as PropType<'counterparty' | 'security'>,
      required: true,
    },
    aggregatedLoans: {
      type: Array as PropType<AggregatedLoanByCounterpartyItem[] | AggregatedLoanBySecurityItem[]>,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    serverItemsLength: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['hasOpsUserRole']),
  },
})
export default class OpenLoansTableAggregate extends Vue {
  protected readonly groupingType!: 'counterparty' | 'security';
  protected readonly aggregatedLoans!:
    | AggregatedLoanByCounterpartyItem[]
    | AggregatedLoanBySecurityItem[];
  protected readonly page!: number;
  protected readonly itemsPerPage!: number;
  protected readonly sortBy!: string;
  protected readonly sortDesc!: boolean;
  protected readonly serverItemsLength!: number;

  // store state
  protected readonly hasOpsUserRole!: boolean;

  protected sideHeader: DataTableHeader = {
    text: 'Side',
    value: 'side',
    class: 'text-truncate row-icon status',
    cellClass: 'text-truncate row-icon status',
    align: 'start',
  };

  protected loansHeader: DataTableHeader = {
    text: '#Loans',
    value: 'loansCount',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
  };

  protected commonHeader: DataTableHeader[] = [
    {
      text: 'Total Open',
      value: 'totalOpenQty',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'end',
    },
    {
      text: 'Avg Rate',
      value: 'avgRate',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'end',
    },
    {
      text: 'Total Value',
      value: 'totalValue',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'end',
    },
    {
      text: 'Total Recalled',
      value: 'totalRecallQty',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'end',
    },
    // "spacer" column before "actions" is required for .last-col-sticky class to work nicely
    {
      text: '',
      value: 'spacer',
      width: '100%',
      sortable: false,
    },
    {
      text: '', // No label (less is more!)
      value: 'actions',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'center',
      sortable: false,
      width: '100%',
    },
  ];

  protected bySecurityHeader: DataTableHeader[] = [
    this.sideHeader,
    {
      text: 'Ticker',
      value: 'ticker',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'start',
    },
    {
      text: 'CUSIP',
      value: 'cusip',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'start',
    },
    this.loansHeader,
    {
      text: '#Counterparties',
      value: 'counterpartyCount',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'end',
    },
    ...this.commonHeader,
  ];

  protected byCounterpartyHeader: DataTableHeader[] = [
    this.sideHeader,
    {
      text: 'Counterparty',
      value: 'counterpartyDisplay',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'start',
    },
    this.loansHeader,
    {
      text: '#Securities',
      value: 'securityCount',
      class: 'text-truncate',
      cellClass: 'text-truncate',
      align: 'end',
    },
    ...this.commonHeader,
  ];

  protected get tableHeaders(): DataTableHeader[] {
    return this.groupingType === 'counterparty' ? this.byCounterpartyHeader : this.bySecurityHeader;
  }

  protected mounted(): void {
    if (this.groupingType !== 'security' && this.groupingType !== 'counterparty') {
      throw new Error('The property groupingType must be either "counterparty" or "security"');
    }
  }

  protected openDialog(
    action: MultipleLoanAction,
    item: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem
  ): void {
    if ('ticker' in item) {
      this.$emit('open-dialog', {
        action,
        equity: { ticker: item.ticker, cusip: item.cusip },
      });
    } else {
      this.$emit('open-dialog', {
        action,
        counterparty: item.counterparty,
      });
    }
  }

  // @TODO: make a proper link so users can open in a new tab, for example
  protected handleRowClick(
    item: AggregatedLoanByCounterpartyItem | AggregatedLoanBySecurityItem
  ): void {
    const query =
      'counterparty' in item
        ? { counterpartyDisplayBoxId: item.counterparty.displayBoxId as string }
        : { cusip: item.cusip };

    void this.$router.push({
      name: 'loans.open',
      query: { side: item.side, ...query },
    });
  }

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  td {
    cursor: pointer;
  }
}
</style>
