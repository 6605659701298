import { OpenLoan, RenegotiateSide, normalizeOpenLoan } from '@/utils/api/loans';
import Decimal from 'decimal.js';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { parseISO } from 'date-fns';
import { CompanyInfo, UserAccount } from '@/modules/user-accounts/types/user-accounts';

export interface BrokerOpenLoan extends OpenLoan {
  lenderDisplay: string;
  borrowerDisplay: string;
  renegotiateSideDisplay: string | null;
  dtccRefId: string;
}

/**
 * An extended version of the Open Loan items with display versions of data
 */
export type BrokerOpenLoanDisplay = DisplayDataItem<BrokerOpenLoan | 'status'>;

export function normalizeBrokerOpenLoan(l: BrokerOpenLoan): void {
  normalizeOpenLoan(l);

  l.renegotiateSideDisplay = l.renegotiation
    ? l.renegotiation.side === RenegotiateSide.Lender
      ? l.lenderDisplay
      : l.borrowerDisplay
    : null;
}

export interface NsccAccount {
  id: number;
  mainID: string;
  subID: string;
  isActive: boolean;

  // form validation helpers
  errorMainID: string;
  errorSubID: string;
}

export type DeliverOrderSender = 'provable' | 'loanet' | 'self';

export interface CompanyAccount {
  id: number;
  name: string;
  kind: string;
  tradingPermissions: number; // bit-set with allowed trade actions
  sponsor: CompanyInfo | null;

  defaultOrderSoftLimit: Decimal | null;
  defaultOrderHardLimit: Decimal | null;

  profileID: string;
  profileLabel: string;

  displayBoxID: string | null;
  nsccAccounts: NsccAccount[];
  preferredIndependentAmountRate: Decimal;
  preferredRoundingRule: RoundingRule | null;
  hasLoanetIntegration: boolean;
  deliverOrderSender: DeliverOrderSender;
  benchmarkType?: string;
  benchmarkPrice?: Decimal;
  defaultOmsCounterparties: CompanyInfo[];
  bilateralCounterparties: CompanyInfo[];
}

export interface CompanyAccountRequest
  extends Omit<CompanyAccount, 'sponsor' | 'bilateralCounterparties'> {
  sponsorCompanyId: number | null;
  bilateralCounterpartyIds: number[];
}

export interface RawBrokerCompanies {
  companies: CompanyAccount[];
}

export function normalizeCompanyAccount(c: CompanyAccount): void {
  // input from server will have rate a string, we convert it to Decimal
  const cWithStrRate: { preferredIndependentAmountRate: string | Decimal } = c;
  if (typeof cWithStrRate.preferredIndependentAmountRate === 'string') {
    c.preferredIndependentAmountRate = new Decimal(cWithStrRate.preferredIndependentAmountRate);
  }

  // input from server will have soft-order-limit as a string, we convert it to Decimal
  const cWithStrOrderSoftLimit: { defaultOrderSoftLimit: string | Decimal | null } = c;
  if (typeof cWithStrOrderSoftLimit.defaultOrderSoftLimit === 'string') {
    c.defaultOrderSoftLimit = new Decimal(cWithStrOrderSoftLimit.defaultOrderSoftLimit);
  }

  // input from server will have hard-order-limit as a string, we convert it to Decimal
  const cWithStrOrderHardLimit: { defaultOrderHardLimit: string | Decimal | null } = c;
  if (typeof cWithStrOrderHardLimit.defaultOrderHardLimit === 'string') {
    c.defaultOrderHardLimit = new Decimal(cWithStrOrderHardLimit.defaultOrderHardLimit);
  }

  // input from server will have benchmark price as a string, we convert it to Decimal
  // const cWithStrBenchmarkPrice: { benchmarkPrice: Decimal | undefined } = c;
  if (c.benchmarkPrice) {
    c.benchmarkPrice = new Decimal(c.benchmarkPrice);
  }
}

export function normalizeUserAccount(
  ua:
    | UserAccount
    | {
        disabledAt: string;
        lastLoginAt: string;
        orderSoftLimit: string;
        orderHardLimit: string;
        defaultOrderSoftLimit: string;
        defaultOrderHardLimit: string;
      }
): void {
  if (ua.disabledAt) {
    ua.disabledAt = parseISO(ua.disabledAt as string);
  }
  if (ua.lastLoginAt) {
    ua.lastLoginAt = parseISO(ua.lastLoginAt as string);
  }

  // input from server will have soft-order-limit as a string, we convert it to Decimal
  const uaWithStrOrderSoftLimit: { orderSoftLimit: string | Decimal | null } = ua;
  if (typeof uaWithStrOrderSoftLimit.orderSoftLimit === 'string') {
    ua.orderSoftLimit = new Decimal(uaWithStrOrderSoftLimit.orderSoftLimit);
  }

  // input from server will have hard-order-limit as a string, we convert it to Decimal
  const uaWithStrOrderHardLimit: { orderHardLimit: string | Decimal | null } = ua;
  if (typeof uaWithStrOrderHardLimit.orderHardLimit === 'string') {
    ua.orderHardLimit = new Decimal(uaWithStrOrderHardLimit.orderHardLimit);
  }

  // input from server will have soft-order-limit as a string, we convert it to Decimal
  const uaWithStrDefaultOrderSoftLimit: { defaultOrderSoftLimit: string | Decimal | null } = ua;
  if (typeof uaWithStrDefaultOrderSoftLimit.defaultOrderSoftLimit === 'string') {
    ua.defaultOrderSoftLimit = new Decimal(uaWithStrDefaultOrderSoftLimit.defaultOrderSoftLimit);
  }

  // input from server will have hard-order-limit as a string, we convert it to Decimal
  const uaWithStrDefaultOrderHardLimit: { defaultOrderHardLimit: string | Decimal | null } = ua;
  if (typeof uaWithStrDefaultOrderHardLimit.defaultOrderHardLimit === 'string') {
    ua.defaultOrderHardLimit = new Decimal(uaWithStrDefaultOrderHardLimit.defaultOrderHardLimit);
  }
}
