<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="1300px"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistents
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <loan-details-card
      :as-broker="asBroker"
      :initial-tab="initialTab"
      :loan-id="loanId"
      :should-retry-fetch="shouldRetryFetch"
    />
    <v-btn class="close-icon" icon @click="closeDialog()">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-dialog>
</template>

<script lang="ts">
import LoanDetailsCard from '@/modules/open-loans/components/LoanDetailsCard.vue';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    LoanDetailsCard,
  },
  props: {
    loanId: Number,
    asBroker: Boolean,
    initialTab: {
      type: String as PropType<'history' | 'recalls' | 'corporateActions'>,
      default: 'history',
    },
    shouldRetryFetch: {
      type: Boolean,
      default: false,
    },
  },
})
export default class LoanDetailsDialog extends Vue {
  // props
  protected readonly loanId!: number;
  protected readonly asBroker!: boolean;
  protected readonly initialTab!: 'history' | 'recalls';
  // useful when order has just been matched
  // and the loan events are not available yet (but will be soon)
  protected readonly shouldRetryFetch!: boolean;

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>
<style lang="scss" scoped>
.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}
::v-deep {
  .table-container {
    height: 35vh;
  }
}
</style>
