<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <open-loans-action-manager ref="actionManager" />
    <v-data-table
      ref="tableRef"
      v-model="localSelectedItems"
      class="d-flex flex flex-column last-col-sticky"
      dense
      fixed-header
      :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
      :headers="tableColumns"
      height="100%"
      :hide-default-footer="selectedItems && selectedItems.length > 0"
      item-key="id"
      :items="items"
      :items-per-page="itemsPerPage"
      must-sort
      :page="page"
      :search="search"
      :server-items-length="serverItemsLength"
      :show-select="showSelect"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      v-on="$listeners"
      @current-items="onChangeItems"
      @toggle-select-all="toggleOpenLoans"
    >
      <template #[`header.data-table-select`]="{ props, on }">
        <v-simple-checkbox
          :indeterminate-icon="hasSelectedAllOpenLoans ? 'mdi-checkbox-marked' : 'mdi-minus-box'"
          v-bind="props"
          :ripple="false"
          v-on="on"
        />
      </template>

      <!-- Table rows -->
      <template #[`item.transactAt`]="{ item }"> {{ formatDateTime(item.transactAt) }} </template>

      <template #[`item.reportedAt`]="{ item }">
        {{ formatDateTime(item.reportedAt) }}
      </template>

      <template #[`item.displayId`]="{ item }">
        <truncate-text max-width="8rem" :value="item.displayId"></truncate-text>
      </template>

      <template #[`item.issuer`]="{ item }">
        <truncate-text max-width="10.25rem" :value="item.issuer"></truncate-text>
      </template>

      <template #[`item.lei`]="{ item }">
        <truncate-text max-width="8.5rem" :value="item.lei"></truncate-text>
      </template>

      <template #[`item.quantity`]="{ item }">
        <pretty-number :value="item.quantity" />
      </template>

      <template #[`item.collateralType`]="{ item }">
        <truncate-text max-width="11.50rem" :value="item.collateralType"></truncate-text>
      </template>

      <template #[`item.collateralValue`]="{ item }">
        ${{ formatPrice(item.collateralValue) }}
      </template>

      <template #[`item.collateralPct`]="{ item }">
        <rate-output :rate="item.collateralPct" rate-modifier="" />
      </template>

      <template #[`item.marketValue`]="{ item }"> ${{ formatPrice(item.marketValue) }} </template>

      <template #[`item.rate`]="{ item }">
        <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
      </template>

      <template #[`item.endsAt`]="{ item }">
        {{ formatDate(item.endsAt) }}
      </template>

      <!-- Dropdown actions -->
      <template #[`item.actions`]="{ item }">
        <div class="actions d-flex justify-end">
          <v-btn
            v-if="item.venue === Venue.FinraVenueProvable"
            class="icon-action"
            data-test="view-loan"
            icon
            x-small
          >
            <v-icon size="24">mdi-eye</v-icon>
          </v-btn>
          <v-btn class="icon-action" data-test="edit-transaction" icon x-small>
            <v-icon size="24">mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </span>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import TruncateText from '@/modules/common/components/TruncateText.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import OpenLoansActionManager from '@/modules/open-loans/components/OpenLoansActionManager.vue';
import { OpenLoanDisplayItem, OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import { FinraTransaction, Venue } from '@/utils/api/finra-transactions';
import { LoanStatus, isTerminalLoanStatus } from '@/utils/api/loans';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { formatDate } from '@/utils/helpers/dates';
import { ClientConfig } from '@/utils/helpers/rest';
import { Decimal } from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapState } from 'vuex';

const allTableHeaders: DataTableHeader[] = [
  {
    text: i18n.tc('Transact Time'),
    value: 'transactAt',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Report Time'),
    value: 'reportedAt',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Report Type'),
    value: 'reportType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Reporting ID'),
    value: 'displayId',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Ticker'),
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '4rem',
  },
  {
    text: i18n.tc('CUSIP'),
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Issuer'),
    value: 'issuer',
    class: 'text-truncate',
    cellClass: 'text-truncate issuer',
  },
  {
    text: i18n.tc('Lei'),
    value: 'lei',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Quantity'),
    value: 'quantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('Rate / Fee'),
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('Market Value'),
    value: 'marketValue',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('Collateral Type'),
    value: 'collateralType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },
  {
    text: i18n.tc('Collateral Value'),
    value: 'collateralValue',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('% Collateralized'),
    value: 'collateralPct',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('Venue'),
    value: 'venue',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },
  {
    text: i18n.tc('Due Date'),
    value: 'endsAt',
    class: 'text-truncate',
    cellClass: 'text-truncate col-due-date',
    align: 'end',
  },
  {
    text: i18n.tc('Counterparty'),
    value: 'counterpartyName',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },
  {
    text: i18n.tc('Counterparty Type'),
    value: 'counterpartyType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },
  {
    text: i18n.tc('FTD'),
    value: 'ftd',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },

  // "spacer" column before "actions" is required for .last-col-sticky class to work nicely
  {
    text: '',
    value: 'spacer',
    width: '100%',
    sortable: false,
  },
  {
    text: '', // No label (less is more!)
    value: 'actions',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
    sortable: false,
    width: '100%',
  },
];

@Component({
  components: {
    TruncateText,
    BtnDropdown,
    LoanStatusChip,
    OpenLoansActionManager,
  },
  props: {
    items: Array as PropType<FinraTransaction[]>,
    selectedItems: Array as PropType<FinraTransaction[]>,
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showDropdownActions: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: '',
    },
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    sortBy: {
      type: String,
      default: 'createdAt',
    },
    sortDesc: {
      type: Boolean,
      default: true,
    },
    serverItemsLength: Number,
  },
  data: () => ({
    Venue,
  }),
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class ReportedTransactionsTable extends Vue {
  public $refs!: {
    actionManager: OpenLoansActionManager;
  };

  // props
  protected readonly items!: OpenLoanDisplayItem[];
  protected readonly selectedItems!: OpenLoanItem[];
  protected readonly omitHeaders!: string[];
  protected readonly showDropdownActions!: boolean;
  protected readonly showSelect!: boolean;
  protected readonly search!: string;

  protected readonly pricePrecision = PRICE_PRECISION;

  // store state
  protected readonly clientConfig!: ClientConfig;

  protected openLoanCount = 0;

  protected get localSelectedItems(): OpenLoanItem[] {
    return this.selectedItems;
  }

  protected set localSelectedItems(items: OpenLoanItem[]) {
    this.$emit('update:selected-items', items);
  }

  protected get tableColumns(): DataTableHeader[] {
    return allTableHeaders.filter((h) => !this.omitHeaders.includes(h.value));
  }

  protected get hasSelectedAllOpenLoans(): boolean {
    return this.selectedItems && this.selectedItems.length === this.openLoanCount;
  }

  protected mounted(): void {
    // DO NOT REMOVE or EDIT unless you understand
    // https://provablelabs.atlassian.net/browse/AURORA-1480
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs as any).tableRef.multipleSelect = () => {
      /* */
    };
  }

  protected filterOpenLoanItems(items: OpenLoanItem[]): OpenLoanItem[] {
    return items.filter((item) => !this.isTerminalLoanStatus(item.status));
  }

  // everytime the page is changed we need to count how many open-loans are on it
  protected onChangeItems(items: OpenLoanDisplayItem[]): void {
    this.openLoanCount = this.filterOpenLoanItems(items).length;
  }

  // toggle selected open-loans
  // ignore `payload.value` as it takes closed-loans into account
  protected toggleOpenLoans(payload: { items: OpenLoanItem[]; value: boolean }): void {
    this.$emit(
      'update:selected-items',
      this.hasSelectedAllOpenLoans ? [] : this.filterOpenLoanItems(payload.items)
    );
  }

  protected isTerminalLoanStatus(s: LoanStatus): boolean {
    return isTerminalLoanStatus(s);
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MM/dd/yy');
  }

  protected formatDateTime(value: Date): string {
    return formatDate(value, 'MM/dd/yy HH:mm:ss');
  }

  protected formatTime(value: Date): string {
    return formatDate(value, 'HH:mm:ss');
  }

  protected formatPrice(price: Decimal): string {
    return getPriceAsString(price, this.pricePrecision);
  }
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
}

.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    // decrease horizontal padding to make more columns fit
    padding: 0 10px;
  }

  .issuer {
    text-transform: capitalize; // or uppercase?
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }
  }

  .v-chip {
    min-width: 4rem;
  }

  // enhance status column
  th.row-icon.status {
    padding-left: 29px !important;
  }

  // @TODO use sass variables to store these colours
  .v-chip {
    &.status {
      padding: 0 16px;
    }
  }
}
</style>
