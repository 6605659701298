<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- add user account -->
    <v-btn class="ma-6" color="primary" @click="createUser">
      <v-icon left> mdi-plus</v-icon>
      {{ $t('adminAddUser') }}
    </v-btn>

    <!-- search bar -->
    <v-text-field
      v-model="tableSearch"
      class="mx-6"
      clearable
      :label="$t('adminBrokerSearchUser')"
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>

    <v-data-table
      class="elevation-0 pb-8"
      dense
      disable-pagination
      fixed-header
      :headers="tableColumns"
      hide-default-footer
      :items="users"
      :no-data-text="$tc('no users', users.length, { count: users.length })"
      :search="tableSearch"
      sort-by="name"
    >
      <!-- user icon -->
      <!-- eslint-disable-next-line vue/no-lone-template -->
      <template>
        <v-icon disabled small> mdi-account</v-icon>
      </template>

      <template #[`item.companyName`]="{ item }">
        <span>{{ longCompanyName(item) }}</span>
      </template>

      <!-- trading allowed for this user -->
      <template #[`item.tradingPermissions`]="{ item }">
        <template v-if="item.tradingPermissions !== null"
          >{{ $t(`tradingPermissions.options.${item.tradingPermissions}`) }}
        </template>
        <template v-else>{{ $t(`tradingPermissions.inherit`) }}</template>
      </template>

      <template #[`item.userRole`]="{ item }">
        <template v-if="item.accountStatus === 'pending-approval-for-roles'">
          {{ $t(item.roles[0]) }} &#8674; {{ $t(item.rolesPendingApproval[0]) }}
        </template>
        <template v-else>
          {{ $t(item.roles[0]) }}
        </template>
      </template>

      <!-- trading allowed for this user -->
      <template #[`item.accountStatus`]="{ item }">
        <template v-if="item.accountStatus === 'pending-approval' && hasBrokerComplianceRepRole">
          <v-btn color="primary" x-small @click="approveUser(item)">
            {{ $t('adminApproveUser') }}
          </v-btn>
        </template>
        <template
          v-else-if="
            item.accountStatus === 'pending-approval-for-roles' && hasBrokerComplianceRepRole
          "
        >
          <v-btn color="primary" x-small @click="approveUserRoles(item)">
            {{ $t('adminApproveRoles') }}
          </v-btn>
        </template>
        <template v-else>
          {{ $t(`userAccounts.status.${item.accountStatus}`) }}
        </template>
      </template>

      <template #[`item.tfaIsEnabled`]="{ item }">
        <v-icon v-if="item.tfaIsEnabled"> mdi-two-factor-authentication</v-icon>
      </template>

      <template #[`item.lastLoginAt`]="{ item }">
        {{ formattedDate(item.lastLoginAt) }}
      </template>

      <template #[`item.lastActive`]="{ item }">
        {{ formatLastActive(item.lastActive) }}
      </template>

      <template #[`item.totalActiveMinutes`]="{ item }">
        {{ getFormatedDuration(item.totalActiveMinutes) }}
      </template>

      <!-- action buttons -->
      <template #[`item.actions`]="{ item }">
        <div class="text-no-wrap">
          <v-tooltip color="primary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="ma-1" color="primary" icon x-small @click="editUser(item)">
                  <v-icon> mdi-account-edit </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('adminEditUser') }}</span>
          </v-tooltip>

          <v-tooltip v-if="item.disabledAt === null" color="primary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="ma-1" color="primary" icon x-small @click="disableUser(item)">
                  <v-icon> mdi-account-off </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('adminDisableUser') }}</span>
          </v-tooltip>
          <v-tooltip v-else color="primary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="ma-1" color="primary" icon x-small @click="enableUser(item)">
                  <v-icon> mdi-account-check </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('adminEnableUser') }}</span>
          </v-tooltip>

          <v-tooltip color="primary" top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn class="ma-1" color="primary" icon x-small @click="deleteUser(item)">
                  <v-icon> mdi-account-remove </v-icon>
                </v-btn>
              </span>
            </template>
            <span>{{ $t('adminDeleteUser') }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <!-- at least 1 company before adding users! -->
    <v-alert
      v-if="!companies.length"
      border="left"
      colored-border
      elevation="2"
      text
      type="warning"
    >
      <span v-html="$t('noTraderCompanies')"></span>
    </v-alert>

    <!-- edit user popup -->
    <broker-user-dialog
      v-if="dialogOptions.isActive"
      :crud-action="dialogOptions.crudAction"
      :title="dialogOptions.title"
      :user="dialogBuffer"
      @close-modal="closeModal"
    ></broker-user-dialog>
  </div>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import BrokerUserDialog from '@/modules/broker-admin/components/BrokerUserDialog.vue';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';
import { AppState } from '@/store/store';
import { HEARTBEAT_INTERVAL } from '@/store/store-auth';
import { formatDate } from '@/utils/helpers/dates';
import { RestOperation } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { formatDuration, intervalToDuration, isAfter, parseISO, subMilliseconds } from 'date-fns';
import { cloneDeep, noop } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapGetters, mapState } from 'vuex';

const defaultUser = {
  id: 0,
  companyID: 0,
  name: '',
  emailAddress: '',
  roles: [],
  tradingPermissions: null,
};

const tableColumns: DataTableHeader[] = [
  { text: '', value: 'icon', sortable: false, width: 16, class: 'row-icon', cellClass: 'row-icon' },
  { text: i18n.tc('userName'), value: 'name', class: 'text-no-wrap text-truncate' },
  { text: i18n.tc('companyName'), value: 'companyName', class: 'text-no-wrap text-truncate' },
  { text: i18n.tc('userEmail'), value: 'emailAddress', class: 'text-no-wrap text-truncate' },
  {
    text: i18n.tc('userRole'),
    value: 'userRole',
    class: 'text-no-wrap text-truncate',
    align: 'start',
    sortable: false,
  },
  {
    text: i18n.tc('tradingPermissions.title'),
    value: 'tradingPermissions',
    class: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('userAccounts.status.title'),
    value: 'accountStatus',
    class: 'text-no-wrap text-truncate',
    align: 'start',
    sortable: false,
  },
  {
    text: i18n.tc('2FA'),
    value: 'tfaIsEnabled',
    align: 'end',
    class: 'text-no-wrap text-truncate',
    sortable: false,
  },
  {
    text: i18n.tc('userAccounts.lastLoginAt'),
    value: 'lastLoginAt',
    class: 'text-no-wrap text-truncate',
    cellClass: 'text-no-wrap text-truncate',
    sort: (a: Date, b: Date) => (a == b ? 0 : a > b ? 1 : -1),
  },
  {
    text: i18n.tc('userAccounts.lastActive'),
    value: 'lastActive',
    class: 'text-no-wrap text-truncate',
    cellClass: 'text-no-wrap text-truncate',
    sort: (a: Date, b: Date) => (a == b ? 0 : a > b ? 1 : -1),
  },
  {
    text: i18n.tc('userAccounts.totalActive'),
    value: 'totalActiveMinutes',
    class: 'text-no-wrap text-truncate',
    cellClass: 'text-no-wrap text-truncate',
  },
  { text: '', value: 'actions', sortable: false },
];

@Component({
  components: {
    BrokerUserDialog,
  },
  props: {
    userFilter: String,
  },
  methods: {
    ...mapActions([
      'fetchBrokerAdminUsers',
      'fetchBrokerCompanies',
      'deleteBrokerAdminUser',
      'disableBrokerAdminUser',
      'enableBrokerAdminUser',
      'approveBrokerAdminUser',
      'approveBrokerAdminRoles',
    ]),
  },
  computed: {
    ...mapState(['users', 'companies']),
    ...mapGetters(['hasBrokerComplianceRepRole']),
  },
})
export default class BrokerUsers extends Vue {
  // Props
  protected readonly userFilter!: string;

  // Store state
  protected readonly users!: AppState['users'];
  protected readonly companies!: AppState['companies'];
  protected readonly hasBrokerComplianceRepRole!: boolean;
  // Store actions
  protected readonly fetchBrokerCompanies!: () => void;
  protected readonly fetchBrokerAdminUsers!: () => void;
  protected readonly deleteBrokerAdminUser!: (user: UserAccount) => void;
  protected readonly disableBrokerAdminUser!: (user: UserAccount) => void;
  protected readonly enableBrokerAdminUser!: (user: UserAccount) => void;
  protected readonly approveBrokerAdminUser!: (user: UserAccount) => void;
  protected readonly approveBrokerAdminRoles!: (user: UserAccount) => void;

  protected tableSearch = '';
  protected tableColumns = tableColumns;

  protected addBuffer: Partial<NullableAll<UserAccount>> | null = null;
  protected dialogBuffer: Partial<NullableAll<UserAccount>> | null = null;
  protected dialogOptions = {
    isActive: false,
    crudAction: RestOperation.Noop,
    title: 'User',
    cancelOptions: ['escape', 'outside'], // no 'X' for real FORMs
  };

  @Watch('userFilter')
  protected onChangeFilter(newFilter: string): void {
    this.tableSearch = newFilter;
  }

  @Watch('tableSearch')
  protected onChangeSearch(newQuery: string): void {
    // update the query params
    void this.$router.replace({ query: { filter: newQuery } }).catch(noop); // ignore dup nav error
  }

  protected async mounted(): Promise<void> {
    this.tableSearch = this.userFilter;

    try {
      await this.fetchBrokerAdminUsers();
      await this.fetchBrokerCompanies();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected createUser(): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Create;
    this.dialogOptions.title = 'Add User';

    // addBuffer is a 2nd buffer behind the dialog so that the admin
    // can escape from `Create New User` and return with partially filled record
    // browsing the user list will not destroy the partially filled record
    if (this.addBuffer == null) {
      this.addBuffer = cloneDeep(defaultUser);
    }
    this.dialogBuffer = this.addBuffer;
  }

  protected editUser(rowUser: UserAccount): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Update;
    this.dialogOptions.title = 'Update User Profile';
    // do not modify the row while typing
    this.dialogBuffer = cloneDeep(rowUser);
  }

  protected closeModal(): void {
    // wipe user data
    this.dialogOptions.isActive = false;
    this.dialogOptions.crudAction = RestOperation.Noop;
    this.dialogOptions.title = '';
    this.addBuffer = null;
    this.dialogBuffer = null;
  }

  protected deleteUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminDeleteUser'),
      color: 'error',
      icon: 'mdi-alert',
      message: i18n.t('adminDeleteUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminDeleteUser'),
      onAccept: () => this.doDeleteUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected disableUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminDisableUser'),
      color: 'warning',
      icon: 'mdi-account-off',
      message: i18n.t('adminDisableUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminDisableUser'),
      onAccept: () => this.doDisableUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected enableUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminEnableUser'),
      color: 'info',
      icon: 'mdi-account-check',
      message: i18n.t('adminEnableUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
      }),
      acceptText: i18n.t('adminEnableUser'),
      onAccept: () => this.doEnableUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected approveUser(rowUser: UserAccount): void {
    this.$dialog.ask({
      title: i18n.t('adminApproveUser'),
      color: 'info',
      icon: 'mdi-account-check',
      message: i18n.t('adminApproveUserMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
        role: i18n.t(rowUser.roles[0]),
        tradingPermissions:
          rowUser.tradingPermissions !== null
            ? i18n.t(`tradingPermissions.options.${rowUser.tradingPermissions}`)
            : i18n.t(`tradingPermissions.inherit`),
      }),
      acceptText: i18n.t('adminApproveUser'),
      onAccept: () => this.doApproveUser(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected formattedDate(date: Date): string {
    return formatDate(date, 'hh:mm:ss MM/dd/yy');
  }

  private approveUserRoles(rowUser: UserAccount) {
    this.$dialog.ask({
      title: i18n.t('adminApproveRoles'),
      color: 'info',
      icon: 'mdi-account-check',
      message: i18n.t('adminApproveRolesMessage', {
        userName: rowUser.name,
        companyName: rowUser.companyName,
        emailAddress: rowUser.emailAddress,
        role: i18n.t(rowUser.roles[0]),
        newRole: i18n.t(`${rowUser.rolesPendingApproval && rowUser.rolesPendingApproval[0]}`),
        tradingPermissions:
          rowUser.tradingPermissions !== null
            ? i18n.t(`tradingPermissions.options.${rowUser.tradingPermissions}`)
            : i18n.t(`tradingPermissions.inherit`),
      }) as string,
      acceptText: i18n.t('adminApproveRoles') as string,
      onAccept: () => this.doApproveRoles(rowUser),
      rejectText: i18n.t('cancelButton'),
    });
  }

  private async doDeleteUser(rowUser: UserAccount) {
    try {
      await this.deleteBrokerAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminDeletedUserMessage'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doDisableUser(rowUser: UserAccount) {
    try {
      await this.disableBrokerAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminDisabledUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doEnableUser(rowUser: UserAccount) {
    try {
      await this.enableBrokerAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminEnabledUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doApproveUser(rowUser: UserAccount) {
    try {
      await this.approveBrokerAdminUser(rowUser);

      this.$snackbar.confirm(i18n.tc('adminApprovedUser'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private async doApproveRoles(rowUser: UserAccount) {
    try {
      await this.approveBrokerAdminRoles(rowUser);

      this.$snackbar.confirm(i18n.tc('adminApprovedRoles'));
    } catch (e) {
      this.$snackbar.error(errorString(e as Error));
    }
  }

  private longCompanyName(u: UserAccount): string {
    return `${u.companyName} ${
      u.companyDisplayBoxID != null ? ' (' + u.companyDisplayBoxID + ')' : ''
    }`;
  }

  private getFormatedDuration(minutes: number) {
    // intervalToDuration can take interval bounderies in ms.
    const duration = intervalToDuration({ start: 0, end: minutes * 60 * 1000 });
    return formatDuration(duration);
  }

  private formatLastActive(lastActive: string): string {
    if (lastActive === null) {
      return '';
    }

    const now = new Date();
    const nowThreshold = subMilliseconds(now, HEARTBEAT_INTERVAL);

    if (isAfter(parseISO(lastActive), nowThreshold)) {
      return 'now';
    } else {
      return formatDate(lastActive, 'hh:mm:ss MM/dd/yy');
    }
  }
}
</script>

<style lang="scss" scoped></style>
