<template>
  <v-card class="flex d-flex flex-column">
    <loan-details-dialog
      v-if="detail"
      as-broker
      :initial-tab="detail.isCorpAction ? 'corporateActions' : 'history'"
      :loan-id="detail.loan.id"
      @close-modal="detail = null"
    />

    <v-data-table
      v-model="selectedItems"
      class="d-flex flex flex-column"
      dense
      fixed-header
      :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
      :headers="tableHeaders"
      :hide-default-footer="selectedItems && selectedItems.length > 0"
      item-key="id"
      :items="formattedOpenLoans"
      :items-per-page="options.itemsPerPage"
      must-sort
      no-data-text="There are no loans matching this filter"
      :page="options.page"
      :server-items-length="brokerOpenLoans.total"
      show-select
      :sort-by="options.sortBy[0]"
      :sort-desc="options.sortDesc[0]"
      @update:options="$emit('update:options', $event)"
    >
      <!-- Top bar above table  -->
      <template #top>
        <div class="d-flex flex-column align-start ml-3 mb-3 mr-3">
          <div class="d-flex justify-space-between container container--fluid px-0 py-0">
            <div class="d-flex">
              <counterparty-search
                class="counterparties ml-8"
                clearable
                include-sponsored
                placeholder="All Lenders"
                side="lender"
                :value="lender"
                @change="$emit('update:lender', $event)"
              />
              <counterparty-search
                class="counterparties ml-8"
                clearable
                include-sponsored
                placeholder="All Borrowers"
                side="borrower"
                :value="borrower"
                @change="$emit('update:borrower', $event)"
              />
              <simple-equity-search
                class="ml-8"
                clearable
                label="Security"
                :value="equity"
                @input="$emit('update:equity', $event)"
              />
              <v-select
                class="status-list ml-8"
                clearable
                deletable-chips
                item-text="label"
                item-value="status"
                :items="statusItems"
                multiple
                placeholder="All Loan Statuses"
                small-chips
                :value="statuses"
                @change="$emit('update:statuses', $event)"
              />
              <v-text-field
                class="ml-8"
                clearable
                :label="$t('searchLoanId')"
                prepend-inner-icon="mdi-magnify"
                :value="displayId"
                @input="$emit('update:displayId', $event)"
              ></v-text-field>
            </div>
          </div>
        </div>
      </template>

      <!-- Table rows -->
      <template #[`item.status`]="{ item }">
        <loan-status-chip
          class="justify-center"
          :loan="item"
          @click="detail = { loan: item, isCorpAction: false }"
        />
      </template>

      <template #[`item.displayId`]="{ item }">
        <span :title="item.id">{{ item.displayId }}</span>
      </template>

      <template #[`item.equity.ticker`]="{ item }">
        <div class="d-flex">
          <span>{{ item.equity.ticker }}</span>
          <v-tooltip v-if="clientConfig.corpActionsEnabled" top>
            <template #activator="{ on, attrs }">
              <button
                v-if="brokerOpenLoans.corporateActions[item.equity.cusip]"
                class="corp-actions-button"
                v-bind="attrs"
                small
                v-on="on"
                @click="detail = { loan: item, isCorpAction: true }"
              >
                <v-icon color="purple darken-1" small> mdi-flash</v-icon>
              </button>
            </template>
            <span>Upcoming events</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.settlementType`]="{ item }">
        {{ settlementTypeDisplayText[item.settlementType] }}
      </template>

      <template #[`item.updatedAt`]="{ item }">
        {{ formatTimestamp(item.updatedAt) }}
      </template>

      <template #[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template #[`item.openQuantity`]="{ item }">
        <span :title="`Original: ${item.openQuantity + item.returnedQuantity}`">
          <pretty-number :value="item.openQuantity" />
        </span>
      </template>

      <template #[`item.returnedQuantity`]="{ item }">
        <pretty-number :value="item.returnedQuantity" />
      </template>

      <template #[`item.contractAmount`]="{ item }">
        ${{ formatPrice(item.contractAmount) }}
      </template>

      <template #[`item.settlementAmount`]="{ item }">
        ${{ formatPrice(item.settlementAmount) }}
      </template>

      <template #[`item.independentAmountRate`]="{ item }">
        <rate-output :precision="2" :rate="item.independentAmountRate" />
      </template>

      <template #[`item.rate`]="{ item }">
        <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
      </template>

      <template #[`item.renegotiateRate`]="{ item }">
        <template v-if="item.renegotiation">
          <rate-output
            :rate="item.renegotiation.rate"
            :rate-modifier="item.renegotiation.rateModifier"
          />
          <span v-if="item.renegotiateSideDisplay" class="ml-2 renegotiate-side"
            >by {{ item.renegotiateSideDisplay }}</span
          >
        </template>
      </template>

      <template #[`item.recalledQuantity`]="{ item }">
        <template v-if="item.recalledQuantity > 0">
          <pretty-number :value="item.recalledQuantity" />
        </template>
      </template>

      <template #[`item.nextAllowedBuyInExecutionDate`]="{ item }">
        <template v-if="item.recalledQuantity > 0">
          {{ formatDate(item.nextAllowedBuyInExecutionDate) }}
        </template>
      </template>

      <!-- no actions for brokers (yet) -->

      <!-- summary row -->
      <template v-if="selectedItems && selectedItems.length > 0" #[`body.append`]>
        <open-loans-aggregates :items="selectedItems" :table-columns="tableHeaders" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Equity } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import OpenLoansAggregates from '@/modules/open-loans/components/OpenLoansAggregates.vue';
import OpenLoansTable from '@/modules/open-loans/components/OpenLoansTable.vue';
import { OpenLoanDisplayItem } from '@/modules/open-loans/types/open-loans';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { SocketEvents } from '@/store/store';
import { BrokerOpenLoanDisplay } from '@/utils/api/broker';
import {
  BrokerOpenLoans,
  BrokerOpenLoansRequest,
  LoanStatus,
  loanStatuses,
  statusColor,
} from '@/utils/api/loans';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { formatDate } from '@/utils/helpers/dates';
import { ClientConfig } from '@/utils/helpers/rest';
import { Decimal } from 'decimal.js';
import { throttle } from 'lodash';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import { mapState } from 'vuex';
import { settlementTypeDisplayText } from '@/modules/marketplace/helpers/marketplace';

const tableHeaders: DataTableHeader[] = [
  {
    text: i18n.tc('Status'),
    value: 'status',
    class: 'text-truncate row-icon status',
    cellClass: 'text-truncate row-icon status',
    align: 'center',
    width: '7rem',
  },
  {
    text: i18n.tc('Update Time'),
    value: 'updatedAt',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '6.5rem',
  },
  {
    text: i18n.tc('Lender'),
    value: 'lenderDisplay',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '12rem',
  },
  {
    text: i18n.tc('Borrower'),
    value: 'borrowerDisplay',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '12rem',
  },
  {
    text: i18n.tc('Settlement Type'),
    value: 'settlementType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Ticker'),
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('CUSIP'),
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Start Date'),
    value: 'createdAt',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '6.5rem',
  },
  {
    text: i18n.tc('Open'),
    value: 'openQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '6rem',
  },
  {
    text: i18n.tc('Returned'),
    value: 'returnedQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '6rem',
  },
  {
    text: i18n.tc('Rebate'),
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '6rem',
  },
  {
    text: i18n.tc('Contract Amount'),
    value: 'contractAmount',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: i18n.tc('IA'),
    value: 'independentAmountRate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: i18n.tc('Settlement Amount'),
    value: 'settlementAmount',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: i18n.tc('Suggested rate'),
    value: 'renegotiateRate',
    class: 'text-truncate',
    cellClass: 'text-truncate col-renegotiate',
    align: 'end',
    width: '6rem',
  },
  {
    text: i18n.tc('Recalled Quantity'),
    value: 'recalledQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate col-recalled',
    align: 'end',
    width: '6rem',
  },
  {
    text: i18n.tc('Due Date'),
    value: 'nextAllowedBuyInExecutionDate',
    class: 'text-truncate',
    cellClass: 'text-truncate col-due-date',
    align: 'end',
    width: '6rem',
  },
  {
    text: '', // No label (less is more!)
    value: 'actions',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
    sortable: false,
  },
  {
    text: i18n.tc('Loan ID'),
    value: 'displayId',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '11rem',
  },
  {
    text: i18n.tc('DTCC SFT ID'),
    value: 'dtccRefId',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '11rem',
  },
];

@Component({
  components: {
    OpenLoansAggregates,
    LoanStatusChip,
    OpenLoansTable,
    CounterpartySearch,
    SimpleEquitySearch,
    LoanDetailsDialog,
  },
  props: {
    options: {
      type: Object as PropType<DataOptions>,
      required: true,
    },
    equity: {
      type: null as unknown as PropType<Equity | null>,
      validator: (value: Equity | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    lender: {
      type: null as unknown as PropType<CompanyInfo | null>,
      validator: (value: CompanyInfo | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    borrower: {
      type: null as unknown as PropType<CompanyInfo | null>,
      validator: (value: CompanyInfo | null): boolean => {
        return value === null || value instanceof Object;
      },
      required: true,
    },
    statuses: {
      type: null as unknown as PropType<LoanStatus[] | null>,
      validator: (value: LoanStatus[] | null): boolean => {
        return value === null || value instanceof Array;
      },
      required: true,
    },
    displayId: {
      type: null as unknown as PropType<string | null>,
      validator: (value: string | null): boolean => {
        return value === null || true;
      },
      required: true,
    },
  },
  computed: {
    ...mapState(['clientConfig', 'socketEvents']),
  },
})
export default class BrokerOpenLoansList extends Vue {
  // props
  protected readonly equity!: Equity | null;
  protected readonly lender!: null | CompanyInfo;
  protected readonly borrower!: null | CompanyInfo;
  protected readonly statuses!: null | LoanStatus[];
  protected readonly displayId!: null | string;
  protected readonly options!: DataOptions;

  // store state
  protected readonly clientConfig!: ClientConfig;
  protected readonly socketEvents!: SocketEvents;

  protected readonly pricePrecision = PRICE_PRECISION;
  protected readonly settlementTypeDisplayText = settlementTypeDisplayText;

  protected selectedItems: BrokerOpenLoan[] = [];
  protected brokerOpenLoans: BrokerOpenLoans = { items: [], total: 0, corporateActions: {} };
  protected abortController: AbortController | null = null;

  protected detail: { loan: OpenLoanDisplayItem; isCorpAction: boolean } | null = null;
  protected throttledLoadAsyncData = throttle(this.loadAsyncData, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  protected get tableHeaders(): DataTableHeader[] {
    return tableHeaders;
  }

  /**
   * Get a formatted version of the open loans
   */
  protected get formattedOpenLoans(): BrokerOpenLoanDisplay[] {
    return this.brokerOpenLoans.items.map((loan) => {
      const statusDisplay = this.$i18n.tc(`loan-status-${loan.status}`);

      return {
        ...loan,
        statusDisplay,
      };
    });
  }

  protected get statusItems(): Array<{ status: string; label: string }> {
    return loanStatuses.map((status: string) => {
      return { status: status, label: i18n.t(`loan-status-${status}`) as string };
    });
  }

  @Watch('equity')
  @Watch('lender')
  @Watch('borrower')
  @Watch('statuses')
  @Watch('displayId')
  protected onChangeFilters(): void {
    this.$emit('update:options', { ...this.options, page: 1 });
  }

  @Watch('options')
  @Watch('socketEvents.openLoans.brokerLoan')
  protected onSocketEvents(): void {
    void this.throttledLoadAsyncData();
  }

  protected created(): void {
    void this.loadAsyncData();
  }

  protected async loadAsyncData(): Promise<void> {
    if (this.abortController) {
      // we want to initiate a new request, but the previous one is still pending
      // cancel to avoid stale responses arriving late (and potentially in the wrong order)
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    try {
      const params: BrokerOpenLoansRequest = {
        filters: {
          lender: this.lender ? this.lender.companyId : null,
          borrower: this.borrower ? this.borrower.companyId : null,
          cusip: this.equity ? this.equity.cusip : null,
          statuses: this.statuses ? this.statuses.join(',') : null,
          displayId: this.displayId ? this.displayId.toUpperCase() : null,
        },
        pagination: {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
        sort: `${this.options.sortDesc[0] ? '-' : '+'}${this.options.sortBy[0]}`,
      };
      const res = await this.$api.openLoans.fetchBrokerOpenLoans(
        params,
        this.abortController.signal
      );
      if (!res) {
        // request was cancelled, there's another response arriving
        return;
      }
      this.brokerOpenLoans = res;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected getStatusColor(status: LoanStatus): string {
    return statusColor(status);
  }

  protected formatDate(date: Date): string {
    return formatDate(date, 'MM/dd/yy');
  }

  protected formatTimestamp(value: Date): string {
    return formatDate(value, 'MMM d h:mm a');
  }

  protected formatPrice(price: Decimal): string {
    return getPriceAsString(price, this.pricePrecision);
  }
}
</script>

<style lang="scss" scoped>
.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  .status-list {
    width: 18rem;
  }

  .selected-items-count {
    min-height: 2rem;

    small {
      font-size: 0.75rem;
    }
  }

  // remove extra margin on file input icon
  .v-btn .v-file-input .v-input__prepend-outer {
    margin: 0;
  }

  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }

    tr {
      .row-actions {
        opacity: 0.4;
        pointer-events: none;
        transition: opacity linear 0.15s;
      }

      &:hover .row-actions {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }

  .renegotiate-side {
    color: #fff;
    background: #7a7a7a;
    font-size: 0.75rem;
    border-radius: 8px;
    padding: 4px 10px;
  }

  // enhance status column
  th.row-icon.status {
    padding-left: 29px !important;
  }

  // @TODO use sass variables to store these colours
  .v-chip {
    &.status {
      padding: 0 16px;
    }
  }
}
</style>
