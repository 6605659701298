<template>
  <v-data-table
    ref="tableRef"
    v-model="localSelectedItems"
    class="d-flex flex flex-column last-col-sticky"
    dense
    fixed-header
    :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
    :headers="tableColumns"
    item-key="orderRef"
    :items="items"
    :items-per-page="50"
    must-sort
    :show-select="showSelect"
    sort-by="createdAt"
    sort-desc
    @current-items="currentRenderedItems = $event"
    @pagination="localSelectedItems = []"
    @toggle-select-all="toggleOrders"
  >
    <template #[`header.data-table-select`]="{ props, on }">
      <v-simple-checkbox
        data-test="order-selection-checkbox-all"
        :indeterminate-icon="hasSelectedAll ? 'mdi-checkbox-marked' : 'mdi-minus-box'"
        v-bind="props"
        :ripple="false"
        v-on="on"
      />
    </template>
    <template #[`item.data-table-select`]="{ item, isSelected, select }">
      <v-simple-checkbox
        data-test="order-selection-checkbox"
        :disabled="isStatusDisabled(item.status)"
        :off-icon="
          isStatusDisabled(item.status) ? 'mdi-checkbox-blank-off-outline' : '$checkboxOff'
        "
        :ripple="false"
        :value="isSelected"
        @input="select($event)"
      />
    </template>

    <!-- Table rows -->
    <template #[`item.status`]="{ item }">
      <v-chip
        class="justify-center"
        :color="getStatus(item).color"
        data-test="status-chip"
        small
        @click="$emit('view-order', item.orderRef)"
      >
        <span class="">{{ getStatus(item).label }}</span>
      </v-chip>
    </template>

    <template #[`item.company`]="{ item }">
      {{ formatCompanyName(item.company) }}
    </template>

    <template #[`item.side`]="{ item }">
      <format-side :side="item.side" />
    </template>

    <template #[`item.routingStatus`]="{ item }">
      {{
        item.routingStatus === 'routed'
          ? 'Active'
          : item.routingStatus === 'pending'
            ? 'Pending'
            : '–'
      }}
    </template>

    <template #[`item.equity`]="{ item }">
      <div class="row ma-0">
        <span class="col pa-0 pr-1 max-ticker-width">{{ item.equity.ticker }}</span>
        <span class="col pa-0 text-truncate">[{{ item.equity.cusip }}]</span>
      </div>
    </template>

    <template #[`item.filled`]="{ item }">
      <span v-if="item.filled === 0">–</span>
      <span v-else><pretty-number :value="item.filled" /></span>
    </template>

    <template #[`item.avgExecutionRate`]="{ item }">
      <span v-if="item.avgExecutionRate === null">–</span>
      <rate-output v-else :rate="item.avgExecutionRate" />
    </template>

    <template #[`item.quantity`]="{ item }">
      <pretty-number :value="item.quantity" />
    </template>

    <template #[`item.openQuantity`]="{ item }">
      <pretty-number :value="item.openQuantity" />
    </template>

    <template #[`item.totalValue`]="{ item }"> ${{ formatPrice(item.totalValue) }} </template>

    <template #[`item.rate`]="{ item }">
      <span v-if="item.rate === null">–</span>
      <rate-output v-else :rate="item.rate" />
    </template>

    <template #[`item.createdAt`]="{ item }">
      <strong>{{ formatDate(item.createdAt) }}</strong>
      <span class="ml-1 font-weight-light">{{ formatTime(item.createdAt) }}</span>
    </template>

    <template #[`item.orderType`]="{ item }">
      <span class="text-capitalize">{{ item.orderType }}</span>
    </template>

    <template #[`item.timeInForceType`]="{ item }">
      {{ timeInForceAbbr(item.timeInForceType) }}
    </template>

    <template #[`item.minQuantity`]="{ item }">
      <span v-if="item.minQuantity === 1">–</span>
      <pretty-number v-else :value="item.minQuantity" />
    </template>

    <template #[`item.counterparties`]="{ item }">
      {{
        item.counterparties && item.counterparties.length > 0
          ? item.counterparties.map(formatCompanyBoxId).join(', ')
          : 'Any'
      }}
    </template>

    <template #[`item.orderRef`]="{ item }">
      {{ item.orderRef }}
    </template>

    <!-- actions -->
    <template #[`item.actions`]="{ item }">
      <div class="actions d-flex justify-end">
        <marketplace-cancel-btn
          v-if="actions.includes('cancel') && item.status === 'open'"
          data-test="cancel-order"
          icon
          :order-ref="item.orderRef"
          v-on="$listeners"
        />

        <aurora-btn
          v-if="actions.includes('edit') && item.status === 'open'"
          class="icon-action"
          data-test="edit-order"
          :disabled="!hasTraderUserRole"
          icon
          timeframe="createLoans"
          x-small
          @click="$emit('edit-order', item)"
        >
          <v-icon size="24">mdi-pencil</v-icon>
        </aurora-btn>

        <marketplace-toggle-route-btn
          v-if="actions.includes('route') && item.status === 'open'"
          data-test="toggle-route-order"
          icon
          :order-ref="item.orderRef"
          :routing-status="item.routingStatus"
          v-on="$listeners"
        />

        <v-btn
          class="icon-action"
          data-test="view-order"
          icon
          x-small
          @click="$emit('view-order', item.orderRef)"
        >
          <v-icon size="24">mdi-eye</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Api } from '@/modules/common/types/api';
import MarketplaceCancelBtn from '@/modules/marketplace/components/MarketplaceCancelBtn.vue';
import MarketplaceToggleRouteBtn from '@/modules/marketplace/components/MarketplaceToggleRouteBtn.vue';
import { getStatus, timeInForceAbbr } from '@/modules/marketplace/helpers/marketplace';
import {
  formatCompanyBoxId,
  formatCompanyName,
} from '@/modules/user-accounts/helpers/user-accounts';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { formatDate } from '@/utils/helpers/dates';
import { ClientConfig } from '@/utils/helpers/rest';
import Decimal from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapGetters, mapState } from 'vuex';

const allTableHeaders: DataTableHeader[] = [
  {
    text: 'Status',
    value: 'status',
    class: 'text-truncate row-icon status',
    cellClass: 'text-truncate row-icon status',
    align: 'center',
    width: '7rem',
  },
  {
    text: 'Side',
    value: 'side',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '4rem',
  },
  {
    text: 'Active',
    value: 'routingStatus',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Company',
    value: 'company',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Ticker',
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'CUSIP',
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Open Qty',
    value: 'openQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Rate Limit',
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Exec Qty',
    value: 'filled',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Avg Exec Rate',
    value: 'avgExecutionRate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Total Qty',
    value: 'quantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Total Value',
    value: 'totalValue',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Create Time',
    value: 'createdAt',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Type',
    value: 'orderType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Time in Force',
    value: 'timeInForceType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Min. Quantity',
    value: 'minQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: 'Counterparty',
    value: 'counterparties',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },
  {
    text: 'Sett. Type',
    value: 'settlementType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
  },
  {
    text: 'OrderRef',
    value: 'orderRef',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  // "spacer" column before "actions" is required for .last-col-sticky class to work nicely
  {
    text: '',
    value: 'spacer',
    width: '100%',
    sortable: false,
  },
  {
    text: '', // No label (less is more!)
    value: 'actions',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
    sortable: false,
    width: '100%',
  },
];

const allActions = ['view', 'edit', 'route', 'unroute', 'cancel'];

const counterpartyChoiceEnabledCols = ['counterparty'];
const settlementTypeChoiceEnabledCols = ['settlementType'];

@Component({
  components: {
    RateOutput,
    MarketplaceToggleRouteBtn,
    MarketplaceCancelBtn,
  },
  props: {
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    actions: { type: Array as PropType<string[]>, default: () => allActions },
    items: Array as PropType<Api.Marketplace.OrdersResponse>,
    selectedItems: Array as PropType<Api.Marketplace.OrdersResponse>,
    showSelect: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class MarketplaceOrdersTable extends Vue {
  // props
  protected readonly omitHeaders!: string[];
  protected readonly actions!: string[];
  protected readonly items!: Api.Marketplace.OrdersResponse;
  protected readonly selectedItems!: Api.Marketplace.Order[];
  protected readonly showSelect!: boolean;

  // store state
  protected clientConfig!: ClientConfig;
  protected readonly hasTraderUserRole!: boolean;

  // subset of items currently rendered in the table
  protected currentRenderedItems: Api.Marketplace.OrdersResponse = [];

  protected getStatus = getStatus;
  protected timeInForceAbbr = timeInForceAbbr;
  protected readonly pricePrecision = PRICE_PRECISION;
  protected readonly formatCompanyName = formatCompanyName;
  protected readonly formatCompanyBoxId = formatCompanyBoxId;

  protected get localSelectedItems(): Api.Marketplace.Order[] {
    return this.selectedItems;
  }

  protected set localSelectedItems(items: Api.Marketplace.Order[]) {
    this.$emit('update:selected-items', items);
  }

  protected get hasSelectedAll(): boolean {
    return (
      this.selectedItems &&
      this.selectedItems.length === this.filterOpenItems(this.currentRenderedItems).length
    );
  }

  protected get tableColumns(): DataTableHeader[] {
    return allTableHeaders
      .filter(
        (h) =>
          this.clientConfig.orderbookCounterpartyChoiceEnabled ||
          !counterpartyChoiceEnabledCols.includes(h.value)
      )
      .filter(
        (h) =>
          this.clientConfig.orderbookSettlementTypeChoiceEnabled ||
          !settlementTypeChoiceEnabledCols.includes(h.value)
      )
      .filter((h) => !this.omitHeaders.includes(h.value));
  }

  protected mounted(): void {
    // DO NOT REMOVE or EDIT unless you understand
    // https://provablelabs.atlassian.net/browse/AURORA-1480
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.tableRef as any).multipleSelect = () => {
      /* */
    };
  }

  // if the order status is one of the fixed array items return true
  // in the template the checkbox :disabled will be true
  protected isStatusDisabled(status: Api.Marketplace.Order['status']): boolean {
    const disabledStatuses: Array<Api.Marketplace.Order['status']> = [
      'closed',
      'canceled',
      'filled',
      'expired',
    ];

    return disabledStatuses.includes(status);
  }

  protected filterOpenItems(items: Api.Marketplace.OrdersResponse): Api.Marketplace.OrdersResponse {
    return items.filter((item) => item.status !== 'filled' && item.status !== 'canceled');
  }

  protected toggleOrders(payload: { items: Api.Marketplace.OrdersResponse; value: boolean }): void {
    this.$emit(
      'update:selected-items',
      this.hasSelectedAll ? [] : this.filterOpenItems(payload.items)
    );
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM dd');
  }

  protected formatTime(value: Date): string {
    return formatDate(value, 'HH:mm');
  }

  protected formatPrice(price: Decimal): string {
    return getPriceAsString(price, this.pricePrecision);
  }
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
}

.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }
  }

  .v-chip {
    width: 78px;
  }
}
</style>
