// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/login/login.proto (package aurora.core.apiengine.loginv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ConfirmIdentityRequest, ConfirmIdentityResponse, LoginWithApiCredentialsRequest, LoginWithApiCredentialsResponse, LoginWithSAMLTicketRequest, LoginWithSAMLTicketResponse, LoginWithUserCredentialsRequest, LoginWithUserCredentialsResponse, RefreshTokenRequest, RefreshTokenResponse } from "./login_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service aurora.core.apiengine.loginv1.LoginService
 */
export const LoginService = {
  typeName: "aurora.core.apiengine.loginv1.LoginService",
  methods: {
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.LoginWithApiCredentials
     */
    loginWithApiCredentials: {
      name: "LoginWithApiCredentials",
      I: LoginWithApiCredentialsRequest,
      O: LoginWithApiCredentialsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.LoginWithUserCredentials
     */
    loginWithUserCredentials: {
      name: "LoginWithUserCredentials",
      I: LoginWithUserCredentialsRequest,
      O: LoginWithUserCredentialsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.RefreshToken
     */
    refreshToken: {
      name: "RefreshToken",
      I: RefreshTokenRequest,
      O: RefreshTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.ConfirmIdentity
     */
    confirmIdentity: {
      name: "ConfirmIdentity",
      I: ConfirmIdentityRequest,
      O: ConfirmIdentityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loginv1.LoginService.LoginWithSAMLTicket
     */
    loginWithSAMLTicket: {
      name: "LoginWithSAMLTicket",
      I: LoginWithSAMLTicketRequest,
      O: LoginWithSAMLTicketResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

