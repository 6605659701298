<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    min-width="auto"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on }">
      <v-text-field
        dense
        v-bind="attrs"
        :error-messages="errorMessages"
        :label="label"
        readonly
        :value="formatDisplayDate(value)"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="localDate"
      :allowed-dates="allowedDates"
      color="primary"
      :data-test-trigger-id="attrs['data-test-id']"
      :max="localMax"
      :min="localMin"
      no-title
      @input="handleDatePickerInput"
    />
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import { formatDate, parseDate } from '@/utils/helpers/dates';

@Component({
  props: {
    value: {
      type: null as unknown as PropType<Date | null>,
      validator: (value: Date | null): boolean => {
        return value === null || value instanceof Date;
      },
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    min: {
      type: Date,
      required: false,
    },
    max: {
      type: Date,
      required: false,
    },
    allowedDates: {
      type: Function as PropType<(date: Date) => boolean>,
      required: false,
    },
    errorMessages: {
      type: Array,
      required: false,
    },
  },
  inheritAttrs: false,
})
export default class TextDatePicker extends Vue {
  protected readonly value!: Date;
  protected readonly label!: string;
  protected readonly min?: Date;
  protected readonly max?: Date;
  protected readonly allowedDates?: (date: Date) => boolean;
  protected readonly errorMessages?: string[];

  protected isMenuOpen = false;
  protected localDate: string | null = null;

  // $attrs doesn't include class and style (https://v2.vuejs.org/v2/api/#vm-attrs)
  protected get attrs(): Record<string, unknown> {
    return {
      ...this.$attrs,
      class: this.$vnode.data?.staticClass,
      style: this.$vnode.data?.staticStyle,
    };
  }

  protected get localMin(): string {
    return this.min ? this.formatVuetifyDate(this.min) : '';
  }

  protected get localMax(): string {
    return this.max ? this.formatVuetifyDate(this.max) : '';
  }

  @Watch('value')
  protected onValueChange(value: Date): void {
    this.localDate = this.formatVuetifyDate(value);
  }

  protected created(): void {
    if (this.value !== null) {
      this.localDate = this.formatVuetifyDate(this.value);
    }
  }

  protected handleDatePickerInput(): void {
    this.isMenuOpen = false;
    this.$emit('input', parseDate(this.localDate as string));
  }

  protected formatVuetifyDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd');
  }

  protected formatDisplayDate(date: Date): string {
    return formatDate(date, 'MMM d, y');
  }
}
</script>

<style lang="scss" scoped></style>
