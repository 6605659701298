<template>
  <v-row class="d-flex align-start">
    <v-col cols="6 border-right-divider">
      <table class="summary">
        <tbody>
          <tr v-if="asBroker">
            <td>Company</td>
            <td>{{ order.company.companyName }} ({{ order.company.displayBoxId }})</td>
          </tr>
          <tr v-if="!hideRouting">
            <td>Active</td>
            <td>
              {{
                order.routingStatus === 'routed'
                  ? 'Active'
                  : order.routingStatus === 'pending'
                    ? 'Pending'
                    : 'Inactive'
              }}
            </td>
          </tr>
          <tr>
            <td>Security</td>
            <td>{{ order.equity.ticker }} [{{ order.equity.cusip }}]</td>
          </tr>
          <tr>
            <td>Side</td>
            <td>
              <format-side :side="order.side" />
            </td>
          </tr>
          <tr>
            <td>Limit</td>
            <td>
              <span v-if="order.rate === null">–</span>
              <rate-output v-else :rate="order.rate" />
            </td>
          </tr>

          <tr>
            <td>Quantity</td>
            <td>
              <pretty-number :value="order.quantity" />
              <span v-if="order.minQuantity > 1">
                &nbsp;(min. <pretty-number :value="order.minQuantity" />)
              </span>
            </td>
          </tr>
          <tr>
            <td>Open Qty.</td>
            <td>
              <pretty-number v-if="order.filled" :value="order.quantity - order.filled" />
              <pretty-number v-else :value="order.quantity" />
            </td>
          </tr>
          <tr v-if="order.orderRef">
            <td>Filled Qty.</td>
            <td>
              <pretty-number v-if="order.filled" :value="order.filled" />
              <span v-else>-</span>
            </td>
          </tr>
          <tr v-if="order.orderRef">
            <td>Avg. Execution Rate</td>
            <td>
              <rate-output v-if="order.avgExecutionRate" :rate="order.avgExecutionRate" />
              <span v-else>–</span>
            </td>
          </tr>
        </tbody>
      </table>
    </v-col>
    <v-col cols="6 pl-6">
      <table class="summary">
        <tbody>
          <tr v-if="order.createdAt">
            <td>Created Time</td>
            <td>
              {{ formatDate(order.createdAt) }}
            </td>
          </tr>
          <tr>
            <td>Counterparties</td>
            <td v-if="order.counterparties && order.counterparties.length > 0">
              {{ order.counterparties.map(formatCompanyName).join(', ') }}
            </td>
            <td v-else>Any</td>
          </tr>
          <tr>
            <td>IA Limit</td>
            <td>
              <rate-output :precision="2" :rate="order.independentAmountRate" />
            </td>
          </tr>
          <tr>
            <td>Rounding Rule</td>
            <td>
              {{ roundingRuleToShortString(order.roundingRule) }}
            </td>
          </tr>
          <tr>
            <td>Time in Force</td>
            <td>
              {{ timeInForceLabel(order.timeInForceType) }}
            </td>
          </tr>
          <tr>
            <td>Settlement Type</td>
            <td>{{ order.settlementType }}</td>
          </tr>
          <tr>
            <td>Anonymous Order</td>
            <td>
              {{ order.isAnonymous ? 'Yes' : 'No' }}
            </td>
          </tr>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import Decimal from 'decimal.js';
import { formatDate } from '@/utils/helpers/dates';
import { timeInForceLabel } from '@/modules/marketplace/helpers/marketplace';
import { Api } from '@/modules/common/types/api';
import { formatCompanyName } from '@/modules/user-accounts/helpers/user-accounts';
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';
import { roundingRuleToShortString } from '@/modules/sec-lending/helpers/contract-details';

@Component({
  methods: { formatCompanyName, roundingRuleToShortString },
  props: {
    order: Object as PropType<Api.Marketplace.Order>,
    asBroker: {
      type: Boolean,
      default: false,
    },
    hideRouting: {
      type: Boolean,
      default: false,
    },
  },
})
export default class MarketplaceOrderSummary extends Vue {
  // props
  protected readonly order!: Api.Marketplace.Order;
  protected readonly asBroker!: boolean;
  protected readonly hideRouting!: boolean;

  protected timeInForceLabel = timeInForceLabel;

  protected prettyPrice(value: Decimal): string {
    return formatPrettyNumber(value.toNumber());
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM dd h:mm a');
  }
}
</script>
<style lang="scss" scoped>
.border-right-divider {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.summary {
  width: 100%;
  line-height: 2;
}

.summary td:first-child {
  width: 40%;
  color: #888;
}

.summary td:last-child {
  width: 60%;
  font-weight: bold;
  color: white;
}
</style>
