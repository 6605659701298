<template>
  <v-card>
    <v-container fluid>
      <div class="auctions closed-auctions">
        <h3 class="subtitle-2">{{ $t('closedAuctions.title') }}</h3>
        <auctions-history-table
          :auctions="closedAuctions"
          :is-checked-off="false"
          no-closed-auctions="closedAuctions.count"
          @checkoffAuction="askCheckoffAuction"
          @reinitiateAuction="reinitiateAuction"
        />
      </div>

      <div v-if="checkedAuctions.length" class="auctions">
        <h3 class="subtitle-2">{{ $t('checkedAuctions.title') }}</h3>
        <auctions-history-table
          :auctions="checkedAuctions"
          :is-checked-off="true"
          no-closed-auctions="checkedAuctions.count"
          @reinitiateAuction="reinitiateAuction"
        />
      </div>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import i18n from '@/localisation/i18n';
import { mapActions, mapState } from 'vuex';
import { ClosedAuction, auctionTotalFilledVolume } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import SystemBar from '@/modules/common/components/SystemBar.vue';
import AuctionsHistoryTable from '@/modules/auction/components/AuctionsHistoryTable.vue';
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';

@Component({
  components: {
    SystemBar,
    AuctionsHistoryTable,
  },
  data: () => ({
    // auction lists
    closedAuctions: [],
    checkedAuctions: [],
  }),
  methods: {
    ...mapActions(['fetchAuctionsHistory', 'checkoffAuction']),
  },
  computed: {
    ...mapState(['auctionsHistory']),
  },
})
export default class AuctionsHistory extends Vue {
  private fetchAuctionsHistory!: () => Promise<null>;
  private checkoffAuction!: (auction: ClosedAuction) => Promise<null>;
  private closedAuctions!: ClosedAuction[];
  private checkedAuctions!: ClosedAuction[];

  @Watch('auctionsHistory')
  private onAuctionHistory(auctions: ClosedAuction[]): void {
    // split in checked and not-checked auctions
    this.closedAuctions = auctions.filter(
      (auction: ClosedAuction) =>
        auction.companyOrderTickets.length > 0 &&
        auction.companyOrderTickets[0].checkedOffAt == null
    );
    this.checkedAuctions = auctions.filter(
      (auction: ClosedAuction) =>
        auction.companyOrderTickets.length > 0 &&
        auction.companyOrderTickets[0].checkedOffAt != null
    );
  }

  private async mounted(): Promise<void> {
    try {
      await this.fetchAuctionsHistory();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  private reinitiateAuction() {
    this.$dialog.confirm({
      title: '@TODO',
      message: 'Switch to /desktop/sec-lending and Initiate an Auction',
    });
    // @TODO: needs to (re)implemented if this function is still required
    // this.$router.push({
    //   name: 'reinitiate-auction',
    //   params: { desktop: 'auctions', closedAuction: auction },
    // });
  }

  private askCheckoffAuction(auction: ClosedAuction) {
    const filledVolume = auctionTotalFilledVolume(auction);

    const filledVolumePercentage = auction.companyTotalVolume
      ? (filledVolume / auction.companyTotalVolume) * 100
      : 0;

    const filledText = i18n.tc('OrderIsFilled', filledVolume, {
      percent: formatPrettyNumber(filledVolumePercentage),
    });

    if (auction.executedAt == null) {
      this.$snackbar.error(i18n.t('AuctionStillOpen') as string);
      return;
    }

    this.$dialog.ask({
      title: i18n.tc('checkoffAuction'),
      color: 'success',
      icon: 'mdi-check',
      message:
        i18n.tc('AuctionSecurity') +
        ': ' +
        auction.equity.name +
        '<br>' +
        i18n.tc('AuctionEnded') +
        ': ' +
        i18n.d(auction.executedAt, 'long') +
        '.<br><br>' +
        filledText +
        '.<br><br>' +
        '<b>' +
        i18n.tc('OrderIsProcessed') +
        '?</b><br>' +
        i18n.tc('OrderRemoved') +
        '.',
      acceptText: i18n.tc('checkoffAuction'),
      onAccept: () => this.doCheckoffAuction(auction),
      rejectText: i18n.tc('cancelButton'),
    });
  }

  private async doCheckoffAuction(auction: ClosedAuction) {
    try {
      await this.checkoffAuction(auction);

      this.$snackbar.confirm(i18n.t('checkedOffAuction') as string);
    } catch (e) {
      this.$snackbar.error(errorString(e));
    }
  }
}
</script>

<style lang="scss" scoped>
.closed-auctions {
  margin: 16px 0 64px 0;
}
</style>
