<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="!showSummary && closeDialog()"
    @keydown.esc="showSummary ? goBack() : closeDialog()"
    @shortkey.native="showSummary ? goBack() : closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline"> New <format-side :side="side" /> Sponsored Position </span>
        </v-card-title>

        <v-card-text>
          <v-container v-if="!showSummary">
            <v-row class="mt-n4" dense>
              <v-col cols="6">
                <counterparty-search
                  v-model="counterparty"
                  :autofocus="!counterparty"
                  clearable
                  :error-messages="errorMsgs['counterparty']"
                  label="Counterparty"
                  sponsored
                />
              </v-col>
              <v-col cols="6">
                <simple-equity-search
                  v-model="equity"
                  :error-messages="errorMsgs['equity']"
                  label="Security"
                  @change="
                    $v.$reset();
                    $v.equity.$touch();
                  "
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6">
                <numeric-input
                  v-model="quantity"
                  :error-messages="errorMsgs['quantity']"
                  label="Quantity"
                  :min="0"
                  :step="100"
                  type="integer"
                  @change="
                    $v.$reset();
                    $v.quantity.$touch();
                  "
                />
              </v-col>
              <v-col cols="6">
                <numeric-input
                  v-model="rate"
                  :autofocus="!!equity"
                  :error-messages="errorMsgs['rate']"
                  label="Rate"
                  :precision="ratePrecision"
                  :step="0.25"
                  suffix="%"
                  type="decimal"
                  @blur="$v.rate.$touch()"
                  @change="
                    $v.$reset();
                    $v.rate.$touch();
                  "
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6">
                <v-select
                  v-model="rateType"
                  class="mt-n2"
                  :items="['Fixed', 'Floating']"
                  @change="onChangeRateType"
                />
              </v-col>
              <v-col v-if="rateType === 'Floating'" cols="6">
                <benchmark-select
                  v-model="benchmark"
                  class="mt-n2"
                  :error-messages="errorMsgs.benchmark"
                  @blur="$v.benchmark.$touch()"
                  @input="$v.benchmark.$touch()"
                />
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="showSummary" v-shortkey="['enter']" @shortkey="submitForm()">
            <v-row class="text--primary">
              <v-col class="py-0">
                <h2 class="text-h6 text--secondary">Continue?</h2>
                <v-row class="d-flex align-start">
                  <v-col cols="6 border-right-divider">
                    <table class="summary">
                      <tbody>
                        <tr>
                          <td>Counterparty</td>
                          <td>
                            {{ counterparty ? formatCompanyName(counterparty) : 'Any' }}
                          </td>
                        </tr>
                        <tr>
                          <td>Security</td>
                          <td>{{ equity ? `${equity.ticker} [${equity.cusip}]` : '' }}</td>
                        </tr>
                        <tr>
                          <td>Side</td>
                          <td>
                            <format-side :side="side" />
                          </td>
                        </tr>
                        <tr>
                          <td>Quantity</td>
                          <td>
                            <pretty-number :value="quantity" />
                          </td>
                        </tr>
                        <tr>
                          <td>Rate</td>
                          <td>
                            <span v-if="rate === null">–</span>
                            <rate-output v-if="benchmark" :rate="rate" :rate-modifier="benchmark" />
                            <rate-output v-else :rate="rate" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                  <v-col cols="6 pl-6">
                    <table class="summary">
                      <tbody>
                        <tr>
                          <td>IA Limit</td>
                          <td>
                            <span v-if="independentAmountRate.toNumber() === 0">Any</span>
                            <rate-output v-else :precision="2" :rate="independentAmountRate" />
                          </td>
                        </tr>
                        <tr>
                          <td>Rounding Rule</td>
                          <td>
                            {{ roundingRuleToShortString(roundingRule) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="showError">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div
                  class="v-alert v-alert--dense text--primary text-body-2 text-center"
                  :class="{ error: showError }"
                >
                  <div v-if="showError">
                    {{ errorMsgs.apiErrors.join('\n') }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex">
          <div class="d-flex flex-grow-1 justify-space-between align-end">
            <template v-if="!showSummary">
              <v-btn color="secondary" @click="closeDialog">Cancel</v-btn>
              <v-btn
                color="primary"
                data-test="confirm-button"
                type="submit"
                @click="goToSummary()"
              >
                Create
              </v-btn>
            </template>

            <template v-if="showSummary">
              <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="goBack()">
                Back
              </v-btn>
              <aurora-btn
                color="primary"
                data-test="create-button"
                :disabled="formStatus !== 'idle'"
                :loading="formStatus === 'submitting'"
                timeframe="createLoans"
                @click="submitForm"
              >
                Create
              </aurora-btn>
            </template>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { PropType } from 'vue';
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';
import {
  decimal,
  integer,
  maxValue,
  minValue,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import { notGreaterThanPrecision } from '@/utils/helpers/custom-validators';
import { MAX_RATE, RATE_PRECISION } from '@/modules/common/constants/precision';
import Decimal from 'decimal.js';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import { Equity } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import {
  RoundingRule,
  roundingRuleToShortString,
} from '@/modules/sec-lending/helpers/contract-details';
import { Api } from '@/modules/common/types/api';
import { calculateOrderNotional, formatDecimalAsString } from '@/utils/helpers/auction-numbers';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import { LoginState } from '@/store/store';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { ApiError } from '@/utils/errors';
import { formatCompanyName } from '@/modules/user-accounts/helpers/user-accounts';
import { RateType } from '@/modules/marketdata/types/marketdata';
import BenchmarkSelect from '@/modules/marketdata/components/BenchmarkSelect.vue';
import { Benchmark } from '@/utils/api/loans';
import i18n from '@/localisation/i18n';

interface FormErrors {
  apiErrors: string[];
  counterparty: string[];
  equity: string[];
  quantity: string[];
  rate: string[];
  benchmark: string[];
}

@Component({
  methods: { roundingRuleToShortString },
  props: {
    side: {
      type: String as PropType<'borrower' | 'lender'>,
      required: true,
    },
  },
  components: {
    SimpleEquitySearch,
    CounterpartySearch,
    BtnDropdown,
    BenchmarkSelect,
  },
  computed: {
    ...mapState(['loginState']),
  },
  mixins: [validationMixin],
  validations: function (this: SponsorshipPositionDialog) {
    return {
      counterparty: { required },
      equity: { required },
      quantity: {
        integer,
        required,
        minValue: minValue(1),
      },
      rate: {
        decimal,
        required,
        notGreaterThanPrecision: notGreaterThanPrecision(RATE_PRECISION),
        minValue: minValue(-MAX_RATE),
        maxValue: maxValue(MAX_RATE),
      },
      benchmark: {
        required: requiredIf((vm: SponsorshipPositionDialog) => vm.rateType === 'Floating'),
      },
    };
  },
})
export default class SponsorshipPositionDialog extends mixins(validationMixin) {
  // props
  protected readonly side!: 'borrower' | 'lender';

  // store state
  protected loginState!: NonNullableAll<LoginState>;

  protected equity: Equity | null = null;
  protected formStatus: DialogFormStatus = 'idle';
  protected showSummary = false;
  protected apiErrors: string[] = [];
  protected rate: Decimal | null = null;
  protected ratePrecision = RATE_PRECISION;
  protected rateType: RateType = 'Fixed';
  protected benchmark: Benchmark | null = null;
  protected quantity: number | null = null;
  protected counterparty: CompanyInfo | null = null;
  protected expandedPanel: number | null = null;

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      counterparty: [],
      equity: [],
      quantity: [],
      rate: [],
      benchmark: [],
    };

    if (this.$v.counterparty.$dirty) {
      if (!this.$v.counterparty.required) errors.counterparty.push('please select a counterparty.');
    }

    if (this.$v.equity.$dirty) {
      if (!this.$v.equity.required) errors.equity.push('please select a security.');
      if (this.equity && this.equity.cannotTradeMessage) {
        errors.equity.push(i18n.t(this.equity.cannotTradeMessage) as string);
      }
    }

    if (this.$v.quantity.$dirty) {
      if (!this.$v.quantity.required) errors.quantity.push('please enter a quantity.');
      if (!this.$v.quantity.integer) errors.quantity.push('please enter a valid quantity.');
      if (!this.$v.quantity.minValue) errors.quantity.push('must be greater than 0.');
    }

    if (this.$v.rate.$dirty) {
      if (!this.$v.rate.required) errors.rate.push('rate is required.');
      if (this.rate && !this.$v.rate.notGreaterThanPrecision)
        errors.rate.push(`rate must not be more than ${RATE_PRECISION} decimal places.`);
      if (!this.$v.rate.minValue) {
        errors.rate.push(`Rate cannot be lower than -${this.formatRate(MAX_RATE)}.`);
      }
      if (!this.$v.rate.maxValue) {
        errors.rate.push(`Rate cannot be more than ${this.formatRate(MAX_RATE)}.`);
      }
    }

    if (this.$v.benchmark.$dirty) {
      if (!this.$v.benchmark.required) errors.benchmark.push('please select a benchmark.');
    }

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected get independentAmountRate(): Decimal {
    return this.loginState.user.companyPreferredIndependentAmountRate;
  }

  protected get roundingRule(): RoundingRule {
    return this.loginState.user.companyPreferredRoundingRule === null
      ? RoundingRule.NoRounding
      : this.loginState.user.companyPreferredRoundingRule;
  }

  protected async submitForm(): Promise<void> {
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }
    this.formStatus = 'submitting';

    try {
      const payload: Api.Sponsorship.BasketResponseItem = {
        side: this.side,
        equity: this.equity as Equity,
        counterparty: this.counterparty as CompanyInfo,
        quantity: this.quantity as number,
        rate: this.rate as Decimal,
        benchmark: this.benchmark,
      };
      await this.$api.sponsorship.createLoans({
        side: this.side,
        entries: [payload],
      });
      this.closeDialog();
      this.$snackbar.confirm('Sponsored position successfully created.');
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [`Operation Failed: ${errorMessage}`];
      this.formStatus = 'idle';
    }
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  protected goToSummary(): void {
    if (!this.validateForm()) {
      return;
    }
    const notional = calculateOrderNotional(this.quantity, (this.equity as Equity).lastClosePrice);
    // // will stop workflow and display soft/hard limits dialog if needed
    new RiskLimitValidator(this.$dialog, this.loginState.user).checkAndConfirmRiskLimits(
      notional,
      () => (this.showSummary = true)
    );
  }

  protected goBack(): void {
    this.showSummary = false;
    this.$v.$reset();
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected onChangeRateType(rateType: RateType): void {
    this.rateType = rateType;
    if (rateType === 'Fixed') {
      this.benchmark = null;
    }
  }

  protected prettyNumber(value: number): string {
    return formatPrettyNumber(value);
  }

  protected formatRate(rate: number): string {
    return formatDecimalAsString(new Decimal(rate), this.ratePrecision);
  }

  protected formatCompanyName(counterparty: CompanyInfo): string {
    return formatCompanyName(counterparty);
  }
}
</script>

<style lang="scss" scoped>
.col-6 + .col-6 {
  padding-left: 12px;
}

::v-deep {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}

.border-right-divider {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.summary {
  width: 100%;
  line-height: 2;
}

.summary td:first-child {
  width: 40%;
  color: #888;
}

.summary td:last-child {
  width: 60%;
  font-weight: bold;
  color: white;
}
</style>
