<template>
  <dashboard-panel class="chart-panel" no-collapse title="Trading Analytics">
    <v-container class="container d-flex flex-column" fluid>
      <v-row class="mt-4 d-flex flex-grow-0" no-gutters>
        <v-col class="filters d-flex flex-wrap align-center">
          <div>
            <text-date-picker
              v-model="from"
              label="From"
              :max="pickerMax"
              :min="pickerMin"
              @input="updateToAndFrom()"
            />
          </div>
          <div>
            <text-date-picker
              v-model="to"
              label="To"
              :max="pickerMax"
              :min="pickerMin"
              @input="updateToAndFrom()"
            />
          </div>
          <simple-equity-search
            v-model="instrument"
            class="simple-equity-search"
            dense
            label="Security"
            @change="fetchData()"
          />
        </v-col>
      </v-row>
      <div v-if="dataAvailable" class="mt-4 flex-grow-1">
        <v-chart autoresize :option="eChartOptions" />
      </div>
      <v-alert v-else-if="invalidPeriod" class="mt-4" dense outlined type="warning">
        Invalid Period! The start date has to be before or equal to end date (
        <b>{{ formatDate(from, true) }}</b>
        is later than <b>{{ formatDate(to, true) }}</b
        >). Please select a valid period.
      </v-alert>
      <v-alert v-else class="mt-4" :color="primaryBaseColor" dense outlined type="warning">
        There is no data available on loans or borrows for the period
        {{ formatDate(from, true) }} to {{ formatDate(to, true) }}. Please select another period.
      </v-alert>
    </v-container>
  </dashboard-panel>
</template>

<script lang="ts">
import type { Api } from '@/modules/analytics/types/statistics';
import TextDatePicker from '@/modules/common/components/TextDatePicker.vue';
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import { Equity } from '@/modules/common/types/api';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import { formatDate } from '@/utils/helpers/dates';
import axios from 'axios';
import { isBefore, setSeconds, subMonths, subYears } from 'date-fns';
import Decimal from 'decimal.js';
import { EChartsOption, LegendComponentOption, SeriesOption } from 'echarts';
import { BarChart, LineChart, ScatterChart } from 'echarts/charts';
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import type { LegendStyleOption } from 'echarts/types/src/component/legend/LegendModel';
import type { LabelOption, OptionDataValue } from 'echarts/types/src/util/types';
import { flatten, uniq } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import VChart from 'vue-echarts';
type TradingTimeSeries = Api.TradingTimeSeries;

echarts.use([
  CanvasRenderer,
  GridComponent,
  LineChart,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  BarChart,
  VisualMapComponent,
  TitleComponent,
  DataZoomComponent,
  ScatterChart,
  MarkLineComponent,
  MarkPointComponent,
]);

export interface TradingAnalyticsSeries {
  dates: Date[];
  loans: TradingTimeSeries[];
  borrows: TradingTimeSeries[];
}

// same interface from LegendComponent, but they did not export it
export interface LegendDataItem extends LegendStyleOption {
  name?: string;
  icon?: string;
  textStyle?: LabelOption;
  tooltip?: unknown;
}

const colors = {
  graph: {
    loans: ['#66baff', '#1d94de', '#66baff', '#3267d9'],
    borrows: ['#5dd961', '#799e73', '#5ead60', '#1b9e4b'],
  },
  legend: {
    borrow: '#46e370',
    loan: '#39dde6',
    inactive: '#9c9c9c',
  },
};

@Component({
  components: {
    SimpleEquitySearch,
    VChart,
    DashboardPanel,
    TextDatePicker,
  },
  computed: {
    primaryBaseColor() {
      const primary = this.$vuetify?.theme?.currentTheme?.primary as {
        base?: string;
      };
      return primary?.base || '#4caf50';
    },
  },
})
export default class TradingAnalytics extends Vue {
  // https://axios-http.com/docs/cancellation
  protected abortController: AbortController | null = null;
  protected instrument: Equity | null = null;

  protected series = this.emptySeries();
  protected now = setSeconds(new Date(), 0);
  protected pickerMax = this.now;
  protected lastMonth = subMonths(this.now, 1);
  protected pickerMin = subYears(this.now, 1);

  protected dataAvailable = true;
  protected invalidPeriod = false;

  // default view is last month (params for fetching)
  protected from = this.lastMonth;
  protected to = this.pickerMax;

  protected get params(): { from: string; to: string; cusip?: string } {
    return {
      from: this.formatDate(this.from),
      to: this.formatDate(this.to),
      cusip: this.instrument?.cusip,
    };
  }

  protected get eChartOptions(): EChartsOption {
    return {
      darkMode: true,
      title: {
        text: '',
      },
      textStyle: {
        color: this.$vuetify.theme.dark ? 'white' : 'black',
        fontSize: 13,
      },
      legend: this.getLegend(),
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
      },
      grid: [
        {
          top: 80,
          left: 80,
          right: 160,
          bottom: 100,
        },
      ],
      xAxis: [
        {
          type: 'category',
          data: uniq(flatten(this.series.dates.map((entry) => this.formatDate(entry)))),
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Rate',
          scale: true,
          axisLabel: {
            formatter: this.rateFormatter,
          },
        },
        {
          type: 'value',
          name: 'Volume',
          offset: 30,
          min: 0,
          scale: true,
          axisPointer: {
            snap: true,
          },
          axisLabel: {
            align: 'center',
          },
        },
        {
          type: 'value',
          name: '#Open',
          offset: 100,
          min: 0,
          scale: true,
          axisPointer: {
            snap: true,
          },
          axisLine: {
            onZero: false,
          },
          axisLabel: {
            align: 'right',
          },
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: [0, 1],
          start: 0,
          end: 100,
        },
        {
          show: true,
          xAxisIndex: [0, 1],
          type: 'slider',
          bottom: 10,
          start: 10,
          end: 100,
        },
      ],
      series: this.getSeriesDataAvailable(),
    };
  }

  protected emptySeries(): TradingAnalyticsSeries {
    return {
      dates: [],
      loans: [],
      borrows: [],
    };
  }

  protected formatDate(date: Date, formatForDisplay = false): string {
    return formatDate(date, formatForDisplay ? 'MMM d, y' : 'yyyy-MM-dd');
  }

  protected async updateToAndFrom(): Promise<void> {
    // set it to true to ignore the alerts until we verify whether the dates are correct
    this.invalidPeriod = false;

    if (isBefore(this.to, this.from)) {
      this.dataAvailable = false;
      this.invalidPeriod = true;
    } else {
      await this.fetchData();
    }
  }

  protected async mounted(): Promise<void> {
    await this.fetchData();
  }

  protected async fetchData(): Promise<void> {
    if (this.abortController) {
      // we want to initiate a new request, but the previous one is still pending
      // cancel to avoid stale responses arriving late (and potentially in the wrong order)
      this.abortController.abort();
    }

    await this.getSeriesData(this.params);
  }

  protected async getSeriesData(
    params: Record<string, string | number | null | undefined>
  ): Promise<void> {
    const newSeries = this.emptySeries();

    this.abortController = new AbortController();
    try {
      const { data } = await axios.get<Api.TradingSeriesResponse>(
        `/api/1/analytics/seclending/stats/series`,
        {
          params: params,
          signal: this.abortController.signal,
        }
      );

      // normalize loans
      newSeries.loans = data.loanSeries.data;
      newSeries.loans.forEach((ls) => this.normalizeTradingTimeSeries(ls));

      // normalize borrows
      newSeries.borrows = data.borrowSeries.data;
      newSeries.borrows.forEach((bs) => this.normalizeTradingTimeSeries(bs));

      // collect dates from both series
      newSeries.dates = uniq([...newSeries.loans, ...newSeries.borrows].map((elem) => elem.date));

      // if no data is available, set the variable false to display the card
      this.dataAvailable = !(newSeries.loans.length == 0 && newSeries.borrows.length == 0);

      // update the graph
      this.series = newSeries;
      // other error, log error message and show whatever we had before (so either alert or the previous state graph)
    } catch (err) {
      if (axios.isCancel(err)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }

      // other error, clear the graph
      this.$snackbar.error(
        'There was an issue while retrieving this period. Please try again later.'
      );
    }
  }

  protected normalizeTradingTimeSeries(timeSeries: Api.TradingTimeSeries): void {
    // convert strings to decimals (export type Value = string | number | Decimal)
    timeSeries.averageRate = new Decimal(timeSeries.averageRate);

    timeSeries.rates.forEach((pair) => {
      pair.rate = new Decimal(pair.rate);
    });
  }

  protected rateData(data: TradingTimeSeries[]): Array<Array<string | number>> {
    return flatten(
      data.map((series) => {
        return series.rates.map((pair) => {
          return [
            this.formatDate(series.date),
            pair.rate.toDecimalPlaces(RATE_PRECISION).toNumber(),
            pair.volume,
          ];
        });
      })
    );
  }

  protected volumeData(data: TradingTimeSeries[]): number[] {
    return data.map((series) => {
      return series.totalVolume;
    });
  }

  protected countData(data: TradingTimeSeries[]): number[] {
    return data.map((series) => {
      return series.count;
    });
  }

  protected avgRateData(data: TradingTimeSeries[]): number[] {
    return data.map((series) => {
      return series.averageRate.toDecimalPlaces(RATE_PRECISION).toNumber();
    });
  }

  // this needs to be this way as the value type from tooltip valueFormatter in echarts is value: OptionDataValue | OptionDataValue[]
  protected rateFormatter(value: OptionDataValue | OptionDataValue[]): string {
    return `${value}%`;
  }

  protected getSeriesDataAvailable(): SeriesOption[] {
    const seriesData: SeriesOption[] = [];
    if (this.series.borrows.length > 0) {
      seriesData.push(
        ...this.seriesOptions('Borrow', colors.graph.borrows, '-60%', this.series.borrows)
      );
    }
    if (this.series.loans.length > 0) {
      seriesData.push(...this.seriesOptions('Loan', colors.graph.loans, '0%', this.series.loans));
    }

    return seriesData;
  }

  protected getLegend(): LegendComponentOption {
    const legend: LegendComponentOption = {};
    legend.data = [];

    if (this.series.loans.length > 0) {
      legend.data.push(...this.addLegendData('Loan', colors.legend.loan));
      legend.selected = { ...legend.selected, ...this.addToLegendSelected('Loan') };
    }
    if (this.series.borrows.length > 0) {
      legend.data.push(...this.addLegendData('Borrow', colors.legend.borrow));
      legend.selected = { ...legend.selected, ...this.addToLegendSelected('Borrow') };
    }

    legend.inactiveColor = this.$vuetify.theme.dark ? colors.legend.inactive : 'grey';
    legend.textStyle = {
      color: this.$vuetify.theme.dark ? 'white' : 'black',
      fontSize: 13,
      overflow: 'truncate',
    };

    return legend;
  }

  protected addLegendData(title: string, color: string): LegendDataItem[] {
    return [
      { name: `Average ${title} Rate` },
      {
        name: `Total Volume Open ${title}s`,
        itemStyle: { color: color, borderWidth: 10, borderType: 'solid' },
      },
      { name: `${title} Rate` },
      { name: `Number of Open ${title}s` },
    ];
  }

  protected addToLegendSelected(title: string): Record<string, boolean> {
    return {
      [`${title} Rate`]: false,
      [`Average ${title} Rate`]: true,
      [`Number of Open ${title}s`]: false,
      [`Total Volume Open ${title}s`]: true,
    };
  }

  protected seriesOptions(
    title: string,
    color: string[],
    offset: string,
    data: TradingTimeSeries[]
  ): SeriesOption[] {
    return [
      {
        name: `Average ${title} Rate`,
        type: 'line',
        yAxisIndex: 0,
        data: this.avgRateData(data),
        smooth: true,
        tooltip: {
          valueFormatter: this.rateFormatter,
        },
        itemStyle: {
          color: color[0],
        },
        lineStyle: {
          color: color[0],
          type: 'solid',
          opacity: 100,
          width: 4,
        },
      },
      {
        name: `Total Volume Open ${title}s`,
        type: 'bar',
        yAxisIndex: 1,
        data: this.volumeData(data),
        itemStyle: {
          color: color[1],
          opacity: 0.4,
        },
      },
      // at the moment, echarts is rendering itself based on the dimensions, at a later point we can decide which dimension takes precedence etc.
      {
        name: `${title} Rate`,
        type: 'scatter',
        emphasis: {
          focus: 'self',
        },
        dimensions: [
          { type: 'ordinal' },
          { name: 'Rate', type: 'number' },
          { name: 'Volume', type: 'number' },
        ],
        encode: {
          tooltip: [1, 2],
        },
        legendHoverLink: false,
        symbol: 'circle',
        data: this.rateData(data),
        tooltip: {
          trigger: 'item',
        },
        universalTransition: {
          divideShape: 'clone',
        },
        labelLayout: {
          moveOverlap: 'shiftY',
        },
        markPoint: {
          symbolSize: [80, 60],
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
          },
          label: {
            color: 'white',
            fontSize: '10',
            fontWeight: 'bold',
          },
          data: [
            {
              name: 'Max',
              type: 'max',
              symbolOffset: [0, offset],
              emphasis: {
                label: {
                  show: true,
                  overflow: 'truncate',
                },
              },
              itemStyle: {
                color: color[1],
              },
            },
            {
              name: 'Min',
              type: 'min',
              symbolOffset: [0, offset],
              emphasis: {
                label: {
                  show: true,
                  overflow: 'truncate',
                },
              },
              itemStyle: {
                color: color[1],
              },
            },
          ],
        },
        itemStyle: {
          color: color[2],
        },
      },
      {
        name: `Number of Open ${title}s`,
        type: 'line',
        symbol: 'diamond',
        symbolSize: 10,
        yAxisIndex: 2,
        data: this.countData(data),
        smooth: true,
        itemStyle: {
          color: color[3],
        },
        lineStyle: {
          color: color[3],
          type: 'dashed',
          opacity: 0.8,
          width: 4,
        },
      },
    ];
  }
}
</script>

<style lang="scss" scoped>
.container,
.chart-panel {
  height: 100%;
}

.filters {
  gap: 1rem;
  max-width: 500px;
}

.filters > * {
  width: 150px;
}
</style>
