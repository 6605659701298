<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="!showSummary && closeDialog()"
    @keydown.esc="showSummary ? goBack() : closeDialog()"
    @shortkey.native="showSummary ? goBack() : closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">Buy-In Execution Ticket</span>
          <v-spacer />
          <span class="text--primary headline-2">{{ equityDescription }}</span>
        </v-card-title>

        <v-card-text :class="{ 'show-summary': showSummary }">
          <!-- Input fields -->
          <v-container v-if="!showSummary" class="form-input pl-12">
            <v-row>
              <v-col class="col-4 py-1 text-capitalize"> counterparty</v-col>
              <v-col class="py-1">
                {{ loan.counterpartyDisplay }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-4 py-1 text-capitalize"> current rate</v-col>
              <v-col class="py-1"><rate-output :rate="loan.rate" /></v-col>
            </v-row>

            <v-row>
              <v-col class="col-4 py-1 text-capitalize"> last close price</v-col>
              <v-col class="py-1"> ${{ formatPrice(loan.equity.lastClosePrice) }}</v-col>
            </v-row>

            <v-row class="pt-6">
              <v-col class="col-4 py-1 text-capitalize"> quantity recalled</v-col>
              <v-col class="py-1">
                <pretty-number :value="loan.recalledQuantity" />
              </v-col>
            </v-row>
            <v-row v-if="loan.recalledQuantity !== loan.allowedBuyInExecutionQuantity" class="mt-0">
              <v-col class="col-4 py-1 text-small"> available to buy-in</v-col>
              <v-col class="py-1">
                <pretty-number :value="loan.openQuantityToBuyIn" />
              </v-col>
            </v-row>

            <v-row v-if="loan.pendingBuyInQuantity > 0" class="orange--text">
              <v-col class="col-4 py-1 text-capitalize"> pending buy-in</v-col>
              <v-col class="py-1">
                <pretty-number :value="loan.pendingBuyInQuantity" />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row align="center" class="pt-6">
              <v-col class="col-12 py-0 text-capitalize"> execution details</v-col>
              <v-col class="py-0">
                <numeric-input
                  v-model="quantity"
                  autofocus
                  :error-messages="errorMsgs['quantity']"
                  label="quantity"
                  :max="loan.openQuantityToBuyIn"
                  :min="0"
                  placeholder="0"
                  :step="100"
                  type="integer"
                  @blur="$v.quantity.$touch()"
                  @input="$v.quantity.$touch()"
                >
                  <template #append>
                    <v-btn class="set-max pa-0" outlined x-small @click="setQuantityToMax()">
                      Max
                    </v-btn>
                  </template>
                </numeric-input>
              </v-col>
              <v-col class="py-0">
                <numeric-input
                  v-model="buyInPrice"
                  :error-messages="errorMsgs['buyInPrice']"
                  label="price"
                  placeholder="0.00"
                  :precision="pricePrecision"
                  prefix="$"
                  :rounding="priceRounding"
                  :step="0.1"
                  type="decimal"
                  @blur="$v.buyInPrice.$touch()"
                  @input="$v.buyInPrice.$touch()"
                />
              </v-col>
            </v-row>
          </v-container>

          <!-- Form summary -->
          <v-container
            v-if="showSummary"
            v-shortkey="['enter']"
            class="form-summary"
            @shortkey="submitForm()"
          >
            <v-row class="text--primary">
              <v-col class="py-0">
                <h2 class="text-h6 text--secondary">Continue?</h2>
                <div v-if="quantity === loan.openQuantity">
                  You are passing a buy-in for all <b>{{ quantity }}</b> shares of this loan.
                </div>
                <div v-else>
                  You are passing a buy-in for <b>{{ quantity }}</b> shares of this loan.
                </div>
              </v-col>
            </v-row>

            <div class="py-8 pl-10">
              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Counterparty</v-col>
                <v-col class="py-0">
                  {{ loan.counterpartyDisplay }}
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Current Rate</v-col>
                <v-col class="py-0"><rate-output :rate="loan.rate" /></v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> recalled quantity</v-col>
                <v-col class="py-0">
                  <pretty-number :value="loan.recalledQuantity" />
                </v-col>
              </v-row>
              <v-row v-if="loan.pendingBuyInQuantity > 0" align="center" class="orange--text">
                <v-col class="col-4 py-1 text-capitalize"> pending buy-in</v-col>
                <v-col class="py-0">
                  <pretty-number :value="loan.pendingBuyInQuantity" />
                </v-col>
              </v-row>

              <v-row align="center" class="primary--text">
                <v-col class="col-4 py-1 text-capitalize"> buy-in quantity</v-col>
                <v-col class="py-0">
                  <pretty-number :value="quantity" />
                </v-col>
              </v-row>
              <v-row align="center" class="primary--text">
                <v-col class="col-4 py-1 text-capitalize"> price</v-col>
                <v-col class="py-0"> ${{ formatPrice(buyInPrice) }}</v-col>
              </v-row>
            </div>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="showError || showSuccess">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div
                  class="v-alert v-alert--dense text--primary text-body-2 text-center"
                  :class="{ error: showError, success: showSuccess }"
                >
                  <div v-if="showError">
                    {{ errorMsgs.apiErrors.join('\n') }}
                  </div>
                  <div v-if="showSuccess">Your buy-in has been submitted.</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Dialog box actions -->
        <v-card-actions class="d-flex">
          <div v-if="!showSummary" class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" data-test="cancel-btn" @click="closeDialog">
              {{ backButtonText }}
            </v-btn>
            <v-btn color="primary" type="submit" @click="goToSummary()">
              {{ $t('dialogs.submitButton') }}
            </v-btn>
          </div>
          <div v-if="showSummary" class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn
              color="secondary"
              data-test="back-btn"
              :disabled="formStatus !== 'idle'"
              @click="goBack()"
            >
              {{ backButtonText }}
            </v-btn>

            <v-btn
              color="primary"
              :disabled="formStatus !== 'idle'"
              :loading="formStatus === 'submitting'"
              type="submit"
              @click="submitForm()"
            >
              {{ $t('dialogs.confirmButton') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapState } from 'vuex';
import { ClientConfig } from '@/utils/helpers/rest';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { validationMixin } from 'vuelidate';
import { decimal, integer, maxValue, minValue, required } from 'vuelidate/lib/validators';
import wait from '@/modules/common/services/wait';
import { ApiError } from '@/utils/errors';
import { LenderOpenLoan } from '@/utils/api/lender';
import Decimal from 'decimal.js';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import { PropType } from 'vue';
import { BorrowerOpenLoan } from '@/utils/api/borrower';

interface FormErrors {
  apiErrors: string[];
  quantity: string[];
  buyInPrice: string[];
}

@Component({
  props: {
    loan: Object as PropType<LenderOpenLoan | BorrowerOpenLoan | null>,
  },
  computed: {
    ...mapState(['clientConfig']),
  },
  mixins: [validationMixin],
  validations: function (this: BuyInDialog) {
    return {
      quantity: {
        integer,
        required,
        minValue: minValue(1),
        maxValue: maxValue(this.loan.allowedBuyInExecutionQuantity),
        maxValueWithPending: maxValue(this.loan.openQuantityToBuyIn),
      },
      buyInPrice: {
        decimal,
        required,
        minValue: minValue(0),
      },
    };
  },
})
export default class BuyInDialog extends mixins(validationMixin) {
  // props
  protected readonly loan!: LenderOpenLoan;

  // store state
  protected clientConfig!: ClientConfig;

  protected quantity: number | null = null;
  protected buyInPrice: Decimal | null = null;
  protected showSummary = false;
  protected formStatus: DialogFormStatus = 'idle';
  protected showSuccess = false;
  protected apiErrors: string[] = [];
  protected pricePrecision = PRICE_PRECISION;
  protected priceRounding = Decimal.ROUND_FLOOR;

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      quantity: [],
      buyInPrice: [],
    };

    // quantity errors
    if (this.$v.quantity.$dirty) {
      if (!this.$v.quantity.required) errors.quantity.push('please enter a quantity.');
      if (!this.$v.quantity.integer) errors.quantity.push('please enter a valid quantity.');
      if (!this.$v.quantity.minValue) errors.quantity.push('must be greater than 0.');
      if (!this.$v.quantity.maxValue)
        errors.quantity.push('cannot execute more than the available buy-in quantity');
      if (!this.$v.quantity.maxValueWithPending)
        errors.quantity.push('cannot execute this amount due to a pending buy-in');
    }
    // buy-in price errors
    if (this.$v.buyInPrice.$dirty) {
      if (!this.$v.buyInPrice.required) errors.buyInPrice.push('please enter a value.');
      if (!this.$v.buyInPrice.decimal) errors.buyInPrice.push('please enter a valid value.');
      if (!this.$v.buyInPrice.minValue) errors.buyInPrice.push('must be greater than 0.');
    }

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected get equityDescription(): string {
    return `${this.loan.equity.name} / ${this.loan.equity.ticker} [${this.loan.equity.cusip}]`;
  }

  /**
   * get the text to display in the back button
   */
  protected get backButtonText(): string {
    if (this.showSummary) {
      return this.$t('dialogs.goBackButton') as string;
    } else {
      return this.$t('dialogs.cancelButton') as string;
    }
  }

  protected created(): void {
    // pre-fill the quantity with max amount
    if (this.loan.openQuantityToBuyIn > 0) {
      this.setQuantityToMax();
    }
  }

  protected setQuantityToMax(): void {
    this.quantity = this.loan.openQuantityToBuyIn;
  }

  protected goToSummary(): void {
    // validate form, and go to summary if valid
    this.showSummary = this.validateForm();
  }

  protected goBack(): void {
    this.showSummary = false;
  }

  protected async submitForm(): Promise<void> {
    // run validation
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }

    this.formStatus = 'submitting';

    try {
      const avgPricePerShare = this.buyInPrice as Decimal;
      await this.$api.openLoans.postBuyIn(this.loan, this.quantity as number, avgPricePerShare);

      // success, alert user and then close form
      this.showSuccess = true;
      this.formStatus = 'closing';

      await wait(1200);
      this.closeDialog();
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [errorMessage];
    } finally {
      this.formStatus = 'idle';
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected formatPrice(price: number): string {
    return getPriceAsString(price, this.pricePrecision);
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  min-height: 19rem;
  max-height: 22rem;
  transition:
    min-height 250ms linear,
    max-height 250ms linear;

  &.show-summary {
    min-height: 18rem;
    max-height: 22rem;
  }
}

.form-input {
  .text-small {
    font-size: 82%;
  }

  .v-btn.set-max {
    height: 1.5rem;
  }
}

// General Transitions and Animations
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
