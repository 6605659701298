<template>
  <div>
    <independent-amounts-statistics />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import IndependentAmountsStatistics from '@/modules/analytics/components/IndependentAmountsStatistics.vue';

@Component({
  components: { IndependentAmountsStatistics },
})
export default class DashboardView extends Vue {}
</script>

<style lang="scss"></style>
