<template>
  <div class="wrapper d-flex flex-column fill-height">
    <dashboard-panel no-collapse title="Term Loans">
      <loan-details-dialog
        v-if="detailLoan"
        ref="loan-details-dialog"
        :loan-id="detailLoan.id"
        @close-modal="detailLoan = null"
      />
      <open-loans-table
        v-if="openLoans"
        :corporate-actions="openLoans.corporateActions"
        data-test="term-loans-loans-table"
        :items="openLoans.items"
        :items-per-page="50"
        :page="1"
        :server-items-length="openLoans.total"
        :show-dropdown-actions="false"
        :show-select="false"
        sort-by="createdAt"
        sort-desc
        @update:options="$emit('update:options', $event)"
        @view-loan="detailLoan = $event"
      />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Api } from '@/modules/common/types/api';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import OpenLoansTable from '@/modules/open-loans/components/OpenLoansTable.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import { OpenLoan, OpenLoans } from '@/utils/api/loans';
import { mapState } from 'vuex';
import { SocketEvents } from '@/store/store';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    DashboardPanel,
    OpenLoansTable,
    LoanDetailsDialog,
  },
  computed: {
    ...mapState(['socketEvents']),
  },
})
export default class TermLoansLoansView extends Vue {
  protected socketEvents!: SocketEvents;

  protected openLoans: OpenLoans | null = null;
  protected selectedContract: Api.TermLoans.ContractDetails | null = null;
  protected detailLoan: OpenLoan | null = null;

  @Watch('socketEvents.termLoans')
  protected onSocketEvents(): void {
    void this.fetch();
  }

  protected async mounted(): Promise<void> {
    await this.fetch();
  }

  protected async fetch(): Promise<void> {
    this.openLoans = await this.$api.openLoans.fetchOpenLoans({
      filters: {
        cusip: this.$route.params.cusip,
        termContractDisplayId: this.$route.params.termContractDisplayId,
      },
    });
  }

  protected async handleContractClick(contract: Api.TermLoans.ContractDetails): Promise<void> {
    this.selectedContract = await this.$api.termLoans.fetchDetails(contract.displayId);
  }

  protected handleAggClick(params: { contractDisplayId: string; cusip: string }): void {
    void this.$router.push({ name: 'loans.open', params });
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
}
</style>
