<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="600"
    origin="center center"
    overlay-color="secondary"
    overlay-opacity="0.25"
    persistent
    transition="scale-transition"
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('loginLostDeviceTitle') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-6">
            <v-alert v-if="tfaError" type="error">
              {{ tfaError }}
              <br />
              {{ $t('loginLostDeviceError2') }}
            </v-alert>
            <v-alert v-else border="left" color="primary" icon="mdi-information" text>
              {{ $t('loginLostDeviceInfo1', { title: clientConfig.systemTitleShort }) }}
              <br />
              {{ $t('loginLostDeviceInfo2') }}
              <br />
              {{ $t('loginLostDeviceInfo3') }}
            </v-alert>
          </v-container>

          <v-container class="mt-6">
            <v-text-field
              v-model="emailAddress"
              autocomplete="username email"
              autofocus
              :error-messages="clErrors.emailAddress"
              :label="$t('loginEmail')"
              required
              :rules="emailRules"
            ></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text to="/">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="px-8 mx-4"
            color="primary"
            :disabled="isProcessing"
            :loading="isProcessing"
            type="submit"
            @click="onSubmit"
          >
            <v-icon left>mdi-email</v-icon>
            {{ $t('loginResetButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import { errorString } from '@/utils/helpers/rest-response';
import { FormValidator } from '@/utils/helpers/input-form';
import { mapActions, mapState } from 'vuex';
import i18n from '@/localisation/i18n';
import { AppState } from '@/store/store';

@Component({
  props: {
    lostEmail: {
      type: String,
      default: '',
    },
    lostMessage: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    emailRules: [(v: string) => /.+@.+/.test(v) || v === '' || i18n.t('formfield.InvalidEmail')],
  }),
  computed: {
    ...mapState(['clientConfig']),
  },
  methods: {
    ...mapActions(['lost2FADevice']),
  },
})
export default class LostDevice2FA extends Vue {
  public emailAddress = '';

  // props
  protected readonly lostEmail!: string;
  protected readonly lostMessage!: string;

  // store
  protected readonly clientConfig!: AppState['clientConfig'];
  protected readonly lost2FADevice!: (email: string) => void;

  protected clErrors: Record<string, string> = {};
  protected tfaError = '';
  protected isProcessing = false;

  protected mounted(): void {
    this.emailAddress = this.lostEmail;
  }

  // @click ...
  protected async onSubmit(): Promise<void> {
    if (this.isProcessing) {
      return;
    }
    this.isProcessing = true;

    try {
      this.tfaError = '';
      this.clErrors = new FormValidator(this, ['emailAddress']).check();

      if (isEmpty(this.clErrors)) {
        await this.doSubmit();
      }
    } finally {
      this.isProcessing = false;
    }
  }

  protected async doSubmit(): Promise<void> {
    try {
      this.lost2FADevice(this.emailAddress);

      this.resetMessage(
        this.$t('loginResetDeviceEmailSent', {
          emailAddress: this.emailAddress,
        }) as string,
        this.$t('loginClose') as string
      );
      await this.$router.push({
        name: 'reset-2fa',
        params: {
          loginEmail: this.emailAddress,
          lostMessage: 'loginResetDeviceEmailSent',
        },
      });
    } catch (e) {
      this.tfaError = errorString(e);
    }
  }

  private resetMessage(message: string, actionText: string) {
    this.$snackbar.show({ message: message, actionText: actionText });
  }
}
</script>

<style lang="scss" scoped></style>
