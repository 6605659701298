<template>
  <v-card class="flex d-flex flex-column">
    <!-- Table header (batch actions, filters, tabs) -->
    <v-container class="py-0" fluid>
      <v-row no-gutters>
        <v-col class="gap-1 d-flex align-center" lg="6" md="12" sm="12" xl="6">
          <!-- Displays number of selected items -->
          <template v-if="selectedItems.length">
            <span>
              {{
                selectedItems.length
                  ? `${selectedItems.length} of ${formattedFinraTransaction.length} selected`
                  : ''
              }}
            </span>
          </template>
        </v-col>

        <v-col class="gap-1 d-flex align-center" lg="6" md="12" sm="12" xl="6">
          <!-- Filters -->
          <counterparty-search
            v-model="selectedCounterparty"
            class="counterparties"
            clearable
            data-test="counterparty-search"
            include-sponsored
            placeholder="All counterparties"
            :side="counterpartySide"
            @change="onChangeFilters()"
          />
          <simple-equity-search
            v-model="selectedEquity"
            class="simple-equity-search"
            clear-after-select
            clearable
            label="Security"
            @input="onChangeFilters()"
          />
          <v-select
            v-if="canBorrowAndLend"
            v-model="selectedSide"
            class="side"
            clearable
            :items="sideItems"
            label="Side"
            @change="onChangeFilters()"
          />
          <v-switch
            v-model="showAllLoans"
            class="mt-5x"
            hide-details
            label="show all"
            @change="onChangeFilters()"
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- The <v-data-table> -->
    <reported-transactions-table
      data-test="reported-transactions-table"
      :items="formattedFinraTransaction"
      :items-per-page="options.itemsPerPage"
      :page="options.page"
      :selected-items.sync="selectedItems"
      :server-items-length="finraTransactions.total"
      :sort-by="options.sortBy[0]"
      :sort-desc="options.sortDesc[0]"
      @update:options="onUpdateOptions"
    />
  </v-card>
</template>

<script lang="ts">
import { Equity } from '@/modules/common/types/api';
import ReportedTransactionsTable from '@/modules/finra/components/ReportedTransactionsTable.vue';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import OpenLoansBatchActions from '@/modules/open-loans/components/OpenLoansBatchActions.vue';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { CompanyInfo, Side } from '@/modules/user-accounts/types/user-accounts';
import { SocketEvents } from '@/store/store';
import {
  FinraTransaction,
  FinraTransactions,
  FinraTransactionsParams,
} from '@/utils/api/finra-transactions';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import { throttle } from 'lodash';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { mapGetters, mapState } from 'vuex';

const MAX_ITEMS_PER_PAGE = 50;

@Component({
  components: {
    ReportedTransactionsTable,
    CounterpartySearch,
    LoanDetailsDialog,
    OpenLoansBatchActions,
    SimpleEquitySearch,
  },
  computed: {
    ...mapState(['clientConfig', 'uxConfig', 'socketEvents']),
    ...mapGetters(['canBorrow', 'canBorrowAndLend']),
  },
})
export default class ReportedTransactionList extends Vue {
  // store state
  protected clientConfig!: ClientConfig;
  protected uxConfig!: UXConfig;
  protected socketEvents!: SocketEvents;
  protected canBorrow!: boolean;
  protected canBorrowAndLend!: boolean;

  protected finraTransactions: FinraTransactions = { total: 0, items: [] };

  // filtering
  protected selectedCounterparty: null | CompanyInfo = null;
  protected selectedEquity: Equity | null = null;

  protected selectedSide: 'lender' | 'borrower' | null = null;
  protected sideItems = [
    { text: 'Lender', value: 'lender' },
    { text: 'Borrower', value: 'borrower' },
  ];
  protected showAllLoans = false;

  // sorting and pagination
  protected options = {
    sortBy: ['transactAt'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: MAX_ITEMS_PER_PAGE,
  };

  protected selectedItemsIds: number[] = [];
  protected abortController: AbortController | null = null;
  protected throttledLoadAsyncData = throttle(this.loadAsyncData, 500, {
    leading: true,
    trailing: true, // because we want the most recent data
  });

  /**
   * Get the loan objects using selectedItemsId
   */
  protected get selectedItems(): FinraTransaction[] {
    return this.formattedFinraTransaction.filter((transaction) =>
      this.selectedItemsIds.includes(transaction.id)
    );
  }

  /**
   * v-data-table always emits the whole object, but the object can become stale
   * once the original object is updated (via sockets, for example)
   * therefore, we store the ids (and get a fresh version of the loan object via getter)
   */
  protected set selectedItems(items: FinraTransaction[]) {
    this.selectedItemsIds = items.map((transaction) => transaction.id);
  }

  /**
   * Get a text-searchable version of the manual loans list
   */
  protected get formattedFinraTransaction(): FinraTransaction[] {
    return this.finraTransactions.items.map((transaction) => {
      return {
        ...transaction,
      };
    });
  }

  protected get counterpartySide(): Side {
    return this.canBorrowAndLend ? 'all' : this.canBorrow ? 'lender' : 'borrower';
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  protected onSocketEvents(): void {
    void this.throttledLoadAsyncData();
  }

  protected async loadAsyncData(): Promise<void> {
    if (this.abortController) {
      // we want to initiate a new request, but the previous one is still pending
      // cancel to avoid stale responses arriving late (and potentially in the wrong order)
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    try {
      const params: FinraTransactionsParams = {
        filters: {
          showAll: this.showAllLoans,
          side: this.selectedSide,
          counterpartyCompanyId: this.selectedCounterparty
            ? this.selectedCounterparty.companyId
            : null,
          cusip: this.selectedEquity ? this.selectedEquity.cusip : null,
        },
        pagination: {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
        sort: `${this.options.sortDesc[0] ? '-' : '+'}${this.options.sortBy[0]}`,
      };
      const res = await this.$api.finraTransactions.fetchFinraTransactions(
        params,
        this.abortController.signal
      );
      if (!res) {
        // request was cancelled, there's another response arriving
        return;
      }
      this.finraTransactions = res;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected onChangeFilters(): void {
    this.options.page = 1;
    void this.loadAsyncData();
  }

  protected onUpdateOptions(options: DataOptions): void {
    this.options = options;
    void this.loadAsyncData();
  }

  protected getStateMutators(): Record<string, (value: string) => void> {
    return {
      side: (value) => {
        this.selectedSide = value === 'lender' || value === 'borrower' ? value : null;
      },
      showAll: (value) => {
        this.showAllLoans = value === 'true';
      },
      counterpartyDisplayBoxId: async (value) => {
        try {
          const res = await this.$api.userAccounts.fetchCompanyByDisplayBoxId(value);
          if (res) {
            this.selectedCounterparty = res;
          } else {
            throw new Error('Failed to fetch selected counterparty');
          }
        } catch (e) {
          this.selectedCounterparty = null;
          this.$log.warn(e);
        }
      },
      cusip: async (value) => {
        try {
          const res = await this.$api.manualLoans.fetchEquityByCusip(value);
          if (res) {
            this.selectedEquity = res;
          } else {
            throw new Error('Failed to fetch selected equity');
          }
        } catch (e) {
          this.selectedEquity = null;
          this.$log.warn(e);
        }
      },
      sortBy: (value) => {
        this.options.sortBy = [value];
      },
      sortDesc: (value) => {
        this.options.sortDesc = [value === 'true'];
      },
      page: (value) => {
        this.options.page = Math.max(parseInt(value), 1);
      },
      itemsPerPage: (value) => {
        const parsedValue = parseInt(value);
        this.options.itemsPerPage = parsedValue > 0 ? parsedValue : MAX_ITEMS_PER_PAGE;
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.counterparties {
  width: 8rem;
}

.simple-equity-search {
  width: 2.5rem;
}

.side {
  width: 2.5rem;
}

.filter-list {
  width: 2.5rem;
}
</style>
