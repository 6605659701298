import Decimal from 'decimal.js';
import { parseISO } from 'date-fns';
import { Equity } from '@/modules/common/types/api';
import { normalizeEquity } from '@/modules/common/helpers/api';

export enum Venue {
  FinraVenueProvable = 'PVBL',
}

export interface FinraTransactionsRequest {
  filters: {
    showAll: boolean;
    side: 'lender' | 'borrower' | null;
    counterpartyCompanyId: number | null;
    cusip: string | null;
  };

  // sorting:
  sort: string | null;

  pagination: {
    page: number; // current page number
    limit: number; // page-size
  };
}

export interface FinraTransactionsParams {
  filters?: Partial<FinraTransactionsRequest['filters']>;
  sort?: Partial<FinraTransactionsRequest['sort']>;
  pagination?: Partial<FinraTransactionsRequest['pagination']>;
}

export interface FinraTransactions {
  items: FinraTransaction[];
  total: number;
}

export interface FinraTransaction {
  id: number;
  displayId: string;
  equity: Equity;
  transactAt: Date;
  reportedAt: Date;
  reportType: string;
  issuer: string;
  lei: string;
  quantity: number;
  rate: Decimal;
  rateModifier: string;
  collateralType: string;
  collateralValue: Decimal;
  collateralPct: Decimal;
  marketValue: Decimal;
  venue: string;
  counterpartyId: number;
  counterpartyName: string;
  counterpartyType: string;
  ftd: string;
  createdAt: Date;
  endsAt: Date;
}

export function normalizeFinraTransaction(trans: FinraTransaction): void {
  normalizeEquity(trans.equity);

  // input from server will have rate as a string, we convert it to Decimal
  const transWithStrRate: { rate: string | Decimal } = trans;
  if (typeof transWithStrRate.rate === 'string') {
    trans.rate = new Decimal(transWithStrRate.rate);
  }

  // input from server will have collateralValue as a string, we convert it to Decimal
  const transWithStrCollateralValue: { collateralValue: string | Decimal } = trans;
  if (typeof transWithStrCollateralValue.collateralValue === 'string') {
    trans.collateralValue = new Decimal(transWithStrCollateralValue.collateralValue);
  }

  // input from server will have rate as a string, we convert it to Decimal
  const transWithStrCollateralPct: { collateralPct: string | Decimal } = trans;
  if (typeof transWithStrCollateralPct.collateralPct === 'string') {
    trans.collateralPct = new Decimal(transWithStrCollateralPct.collateralPct);
  }

  // input from server will have collateralValue as a string, we convert it to Decimal
  const transWithStrMarketValue: { marketValue: string | Decimal } = trans;
  if (typeof transWithStrMarketValue.marketValue === 'string') {
    trans.marketValue = new Decimal(transWithStrMarketValue.marketValue);
  }

  // input from server will have createdAt as a string, we convert it to Date
  const transWithCreatedAt: { createdAt: string | Date } = trans;
  if (typeof transWithCreatedAt.createdAt === 'string') {
    trans.createdAt = parseISO(transWithCreatedAt.createdAt);
  }

  // input from server will have transactAt as a string, we convert it to Date
  const transWithTransActAt: { transactAt: string | Date } = trans;
  if (typeof transWithTransActAt.transactAt === 'string') {
    trans.transactAt = parseISO(transWithTransActAt.transactAt);
  }

  // input from server will have reportedAt as a string, we convert it to Date
  const transWithReportedAt: { reportedAt: string | Date } = trans;
  if (typeof transWithReportedAt.reportedAt === 'string') {
    trans.reportedAt = parseISO(transWithReportedAt.reportedAt);
  }

  // input from server will have endsAt as a string, we convert it to Date
  const transWithEndsAt: { endsAt: string | Date } = trans;
  if (typeof transWithEndsAt.endsAt === 'string') {
    trans.endsAt = parseISO(transWithEndsAt.endsAt);
  }
}
