import { store } from '@/store/store';
import { Role } from '@/utils/helpers/permissions';
import { TradingPermission } from '@/utils/helpers/trading-permissions';
import { NavigationGuard, RedirectOption, Route, RouteConfig } from 'vue-router';

export const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/auth/views/Login.vue'),
    props: true,
    beforeEnter: function (_to, _from, next) {
      // if user already logged in, redirect to dashboard
      // @TODO note that in tests store isn't imported and thus we can't test
      // router where user is logged in. Should resolve this, perhaps with a type
      // of Store dependency injection?
      if (store?.state.loginState.user) {
        next({ name: 'dashboard', replace: true });
      } else {
        next();
      }
    } as NavigationGuard,
  },
  {
    path: '/sso/:ticket',
    name: 'sso',
    component: () => import('@/modules/auth/views/SSO.vue'),
    props: true,
    beforeEnter: function (_to, _from, next) {
      // if user already logged in, redirect to dashboard
      // @TODO note that in tests store isn't imported and thus we can't test
      // router where user is logged in. Should resolve this, perhaps with a type
      // of Store dependency injection?
      if (store?.state.loginState.user) {
        next({ name: 'dashboard', replace: true });
      } else {
        next();
      }
    } as NavigationGuard,
  },
  {
    path: '/confirm-identity/:secretCode?',
    name: 'confirm-identity',
    component: () => import('@/modules/auth/views/ConfirmIdentity.vue'),
    props: true,
  },
  {
    path: '/lost-password',
    name: 'lost-password',
    component: () => import('@/modules/auth/views/LostPassword.vue'),
    props: true,
  },
  {
    path: '/set-password/:secretCode',
    name: 'set-password',
    component: () => import('@/modules/auth/views/SetPassword.vue'),
    props: true,
  },
  {
    path: '/login-2fa',
    name: 'login-2fa',
    component: () => import('@/modules/auth/views/Login2FA.vue'),
    props: true,
    beforeEnter: function (to, _from, next) {
      if (store?.state.loginState.user) {
        next({ name: 'dashboard', replace: true });
      } else if (!('loginEmail' in to.params)) {
        // go back to login page to get email and/or encrypted password
        next({ name: 'login', replace: true });
      } else {
        next();
      }
    } as NavigationGuard,
  },
  {
    path: '/lost-device',
    name: 'lost-device',
    component: () => import('@/modules/auth/views/LostDevice2FA.vue'),
    props: true,
  },
  {
    path: '/reset-2fa/:secretCode?',
    name: 'reset-2fa',
    component: () => import('@/modules/auth/views/ResetDevice2FA.vue'),
    props: true,
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/modules/settings/views/Settings.vue'),
    props: true,
    redirect: { name: 'settings.userSettings' },
    meta: { requiresAuth: Role.AnyUser },
    children: [
      {
        path: 'user-settings',
        name: 'settings.userSettings',
        component: () => import('@/modules/settings/components/UserSettings.vue'),
      },
      {
        path: 'register-2fa',
        name: 'settings.register2fa',
        component: () => import('@/modules/settings/components/RegisterDevice2FA.vue'),
      },
      {
        path: 'desktop-config',
        name: 'settings.desktopConfig',
        component: () => import('@/modules/settings/components/DesktopConfiguration.vue'),
      },
      // @TODO: temporarily disabled until FAQ content is updated -->
      // {
      //   path: 'faq',
      //   name: 'settings.faq',
      //   component: FaqText,
      // },
      {
        path: 'feedback',
        name: 'settings.feedback',
        component: () => import('@/modules/feedback/components/FeedbackForm.vue'),
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/modules/auth/views/Logout.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/modules/dashboard/views/Dashboard.vue'),
    meta: { requiresAuth: Role.AnyUser },
    beforeEnter: function (to, _from, next) {
      // All $router.push('dashboard') and $router.replace('dashboard') calls must be wrapped in
      // a try/catch to silence the redirect-warning.
      // @TODO: remove the try/catch's when future version of Vue does not raise the redirect-warning
      const userContext = store?.state.loginState.permissionValidator?.defaultApiContext();
      const userHomePage = `dashboard.${userContext}` as const;
      if (to.name == 'dashboard') {
        // redirect user to the appropriate dashboard
        next({ name: userHomePage });
      } else {
        next();
      }
    } as NavigationGuard,
    children: [
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/modules/notifications/views/Notifications.vue'),
        meta: { requiresAuth: Role.AnyUser },
      },
    ],
  },
  {
    path: '/dashboard/trader',
    name: 'dashboard.trader',
    redirect: function (_to) {
      // which page is the trader's home page depends on their Trading Permissions
      const userPerm = store?.state.loginState.tradingPermissions;
      const sponsorshipSide = store?.state.loginState.user?.sponsorshipSide;
      return {
        name:
          userPerm & TradingPermission.LenderOrBorrowerPermissions
            ? sponsorshipSide === 'sponsored'
              ? 'sponsorship.loans'
              : 'loans.open'
            : 'notrading',
      };
    } as RedirectOption,
    component: () => import('@/modules/dashboard/views/Dashboard.vue'), // all (trader) children are wrapped inside Dashboard.vue
    meta: { requiresAuth: Role.TraderViewer },
    children: [
      {
        path: '/desktop/notrading',
        name: 'notrading',
        component: () => import('@/modules/trader-user/views/NoTrading.vue'),
      },
      {
        path: '/desktop/loans-explorer',
        name: 'loans.explorer',
        component: () => import('@/modules/open-loans/views/LoansExplorer.vue'),
        meta: { requiresPerm: TradingPermission.LenderOrBorrowerPermissions },
      },
      {
        path: '/desktop/open-loans',
        name: 'loans.open',
        component: () => import('@/modules/open-loans/views/OpenLoans.vue'),
        meta: { requiresPerm: TradingPermission.LenderOrBorrowerPermissions },
      },
      {
        path: '/desktop/sponsorship-loans',
        name: 'sponsorship.loans',
        component: () => import('@/modules/sponsorship/views/SponsorshipLoans.vue'),
        meta: { requiresPerm: TradingPermission.LenderOrBorrowerPermissions },
      },
      {
        path: '/desktop/manual-loans',
        name: 'loans.manual',
        component: () => import('@/modules/manual-loan/views/ManualLoansView.vue'),
        meta: { requiresPerm: TradingPermission.LenderOrBorrowerPermissions },
      },
      {
        path: '/desktop/pre-established-loans',
        name: 'loans.pre-established',
        component: () => import('@/modules/manual-loan/views/PreEstablishedLoansView.vue'),
        meta: { requiresPerm: TradingPermission.LenderOrBorrowerPermissions },
      },
      {
        path: '/desktop/borrower/demand',
        name: 'borrower.demand',
        component: () => import('@/modules/borrower/views/BorrowerDemand.vue'),
        meta: { requiresPerm: TradingPermission.BorrowerPermissions },
      },
      {
        path: '/desktop/borrower/locates',
        name: 'borrower.locates',
        component: () => import('@/modules/borrower/views/BorrowerLocatesView.vue'),
        meta: { requiresPerm: TradingPermission.BorrowerPermissions },
      },
      {
        path: '/desktop/lender/supply',
        name: 'lender.supply',
        component: () => import('@/modules/lender/views/LenderSupply.vue'),
        meta: { requiresPerm: TradingPermission.LenderPermissions },
      },
      {
        path: '/desktop/sec-lending/active',
        name: 'sec-lending.active',
        component: () => import('@/modules/sec-lending/views/SecuritiesLendingActive.vue'),
        meta: {
          requiresPerm: TradingPermission.LenderOrBorrowerPermissions,
        },
      },
      {
        path: '/desktop/sec-lending/history',
        name: 'sec-lending.history',
        component: () => import('@/modules/sec-lending/views/SecuritiesLendingHistory.vue'),
        meta: {
          requiresPerm: TradingPermission.LenderOrBorrowerPermissions,
        },
      },
      {
        path: '/desktop/analytics/var',
        name: 'analytics.varCalculator',
        component: () => import('@/modules/analytics/views/AnalyticsHome.vue'),
      },
      {
        path: '/desktop/reports',
        name: 'reports',
        component: () => import('@/modules/reports/views/ReportsHome.vue'),
      },
      {
        path: '/desktop/finra/transactions',
        name: 'transactions',
        component: () => import('@/modules/finra/views/ReportedTransactions.vue'),
      },
    ],
  },
  {
    path: '/trader-admin/users',
    name: 'trader-admin.users',
    component: () => import('@/modules/trader-admin/views/TraderUsers.vue'),
    meta: { requiresAuth: Role.TraderAdmin },
  },
  {
    path: '/trader-admin/sso',
    name: 'trader-admin.sso',
    component: () => import('@/modules/trader-admin/views/TraderAdminSSOPreferences.vue'),
    meta: { requiresAuth: Role.TraderAdmin },
  },
  {
    path: '/trader-admin/company-preferences',
    name: 'trader-admin.company-preferences',
    component: () => import('@/modules/trader-admin/views/TraderAdminCompanyPreferences.vue'),
  },
  {
    path: '/trader-admin/counterparty-credit',
    name: 'trader-admin.counterparty-credit',
    component: () => import('@/modules/trader-admin/views/TraderAdminCounterpartyCredit.vue'),
  },
  {
    path: '/trader-admin/uptime-statistics',
    name: 'trader-admin.uptime-statistics',
    component: () => import('@/modules/trader-admin/views/TraderUptimeStatistics.vue'),
  },
  {
    path: '/dashboard/broker',
    name: 'dashboard.broker',
    redirect: function (_to) {
      // which page is the broker's home page depends on their Role
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const userRoles = store?.state.loginState.user!.roles;
      return {
        name: userRoles.includes('broker-admin')
          ? 'broker-admin.accounts.users'
          : 'broker-user.uptime-statistics',
      };
    } as RedirectOption,
    component: () => import('@/modules/dashboard/views/Dashboard.vue'), // all (broker) children are wrapped inside Dashboard.vue
    meta: { requiresAuth: Role.BrokerUser },
    children: [
      {
        path: '/broker-admin/accounts/users:filter?',
        name: 'broker-admin.accounts.users',
        component: () => import('@/modules/broker-admin/views/BrokerUsersView.vue'),
        props: (route) => ({ userFilter: route.query.filter }),
      },
      {
        path: '/broker-admin/accounts/companies',
        name: 'broker-admin.accounts.companies',
        component: () => import('@/modules/broker-admin/views/BrokerCompaniesView.vue'),
      },
      {
        path: '/broker-admin/accounts/profiles',
        name: 'broker-admin.accounts.profiles',
        component: () => import('@/modules/investor-profiles/views/InvestorProfiles.vue'),
      },
      {
        path: '/broker-admin/accounts/ipblacklist',
        name: 'broker-admin.accounts.ipblacklist',
        component: () => import('@/modules/broker-admin/views/BrokerIpBlacklistView.vue'),
      },
      {
        path: '/broker-admin/ref-tables/equities',
        name: 'broker-admin.tables.equities',
        component: () => import('@/modules/equities/views/Equities.vue'),
      },
      {
        path: '/broker-admin/kill-switch',
        name: 'broker-admin.kill-switch',
        component: () => import('@/modules/broker-admin/views/BrokerKillSwitchHome.vue'),
        meta: { requiresAuth: Role.BrokerAdmin },
      },
      {
        path: '/broker-user/stats',
        name: 'broker-user.stats',
        component: () => import('@/modules/broker-admin/views/BrokerStatsHome.vue'),
        meta: { requiresAuth: Role.BrokerUser },
      },
      {
        path: '/broker-user/client-monitor/lend-orders',
        name: 'broker-user.client-monitor.lend-orders',
        component: () => import('@/modules/broker-admin/views/BrokerLendOrdersListView.vue'),
        meta: { requiresAuth: Role.BrokerUser },
      },
      {
        path: '/broker-user/client-monitor/borrow-orders',
        name: 'broker-user.client-monitor.borrow-orders',
        component: () => import('@/modules/broker-admin/views/BrokerBorrowOrdersListView.vue'),
        meta: { requiresAuth: Role.BrokerUser },
      },
      {
        path: '/broker-user/client-monitor/marketplace-orders',
        name: 'broker-user.client-monitor.marketplace-orders',
        component: () => import('@/modules/broker-admin/views/BrokerMarketplaceOrdersListView.vue'),
        meta: { requiresAuth: Role.BrokerUser },
      },
      {
        path: '/broker-user/client-monitor/marketplace-orderbook',
        name: 'broker-user.client-monitor.marketplace-orderbook',
        component: () => import('@/modules/broker-admin/views/BrokerUserOrderbook.vue'),
        meta: { requiresAuth: Role.BrokerUser },
      },
      {
        // cusip is an optional param, if missing will be filled out by beforeEnter
        path: '/broker-user/client-monitor/depth-of-book/:cusip?',
        name: 'broker-user.client-monitor.marketplace-depth-of-book',
        component: () =>
          import('@/modules/broker-admin/views/BrokerUserMarketplaceDepthOfBook.vue'),
        meta: { requiresAuth: Role.BrokerUser },
        beforeEnter: function (to, _from, next) {
          if (to.params.cusip) {
            next();
          } else {
            // When there's no cusip in the route: get it from the store or use a default
            const cusip = store.state.lastVisitedSymbolOverview
              ? store.state.lastVisitedSymbolOverview.cusip
              : '037833100'; // AAPL
            next({
              name: 'broker-user.client-monitor.marketplace-depth-of-book',
              params: { cusip },
              replace: true,
            });
          }
        } as NavigationGuard,
      },
      {
        path: '/broker-user/client-monitor/open-loans',
        name: 'broker-user.client-monitor.open-loans',
        component: () => import('@/modules/broker-admin/views/BrokerOpenLoansListView.vue'),
        meta: { requiresAuth: Role.BrokerUser },
      },
      {
        path: '/broker-user/client-monitor/reports',
        name: 'broker-user.client-monitor.reports',
        component: () => import('@/modules/broker-admin/views/BrokerReportsView.vue'),
        meta: { requiresAuth: Role.BrokerUser },
      },
      {
        path: '/broker-user/uptime-statistics',
        name: 'broker-user.uptime-statistics',
        component: () => import('@/modules/broker-admin/views/BrokerUptimeStatistics.vue'),
      },
    ],
  },
  // Term Loans
  {
    path: '/desktop/term-loans',
    name: 'termloans.contracts',
    component: () => import('@/modules/termloans/views/TermLoansContractsView.vue'),
    meta: { requiresAuth: Role.TraderViewer },
  },
  {
    path: '/desktop/term-loans/:termContractDisplayId/:cusip',
    name: 'termloans.loans',
    component: () => import('@/modules/termloans/views/TermLoansLoansView.vue'),
    meta: { requiresAuth: Role.TraderViewer },
  },

  // Marketplace
  {
    path: '/desktop/marketplace/orderbook',
    name: 'marketplace.orderbook',
    component: () => import('@/modules/marketplace/views/MarketplaceOrderbook.vue'),
    meta: { requiresAuth: Role.TraderViewer },
  },
  {
    // cusip is an optional param, if missing will be filled out by beforeEnter
    path: '/desktop/marketplace/symbol-overview/:cusip?',
    name: 'marketplace.symbol-overview',
    component: () => import('@/modules/marketplace/views/MarketplaceSymbolOverview.vue'),
    meta: { requiresAuth: Role.TraderViewer },
    beforeEnter: function (to, _from, next) {
      if (to.params.cusip) {
        next();
      } else {
        // When there's no cusip in the route: get it from the store or use a default
        const cusip = store.state.lastVisitedSymbolOverview
          ? store.state.lastVisitedSymbolOverview.cusip
          : '037833100'; // AAPL
        next({
          name: 'marketplace.symbol-overview',
          params: { cusip },
          replace: true,
        });
      }
    } as NavigationGuard,
  },
  {
    path: '/desktop/marketplace/orders',
    name: 'marketplace.orders',
    component: () => import('@/modules/marketplace/views/MarketplaceOrders.vue'),
    meta: { requiresAuth: Role.TraderViewer },
  },

  {
    path: '/desktop/analytics/trading',
    name: 'analytics.trading',
    component: () => import('@/modules/analytics/views/TradingAnalyticsView.vue'),
    // meta: { requiresAuth: Role.TraderViewer },
  },
  {
    path: '/desktop/analytics/dashboard',
    name: 'analytics.dashboard',
    component: () => import('@/modules/analytics/views/DashboardView.vue'),
    meta: { requiresAuth: Role.TraderViewer },
  },

  // generic 404; 2 steps to make it callable from components and backend
  {
    path: '/404',
    name: 'error-400',
    component: () => import('@/modules/errors/views/PageNotFound.vue'),
    props: (route: Route): { redirectedFrom?: string } => ({
      redirectedFrom: route.redirectedFrom,
    }),
  },
  // generic 500
  {
    path: '/500',
    name: 'error-500',
    component: () => import('@/modules/errors/views/ServerOffline.vue'),
    beforeEnter: function (_to, from, next) {
      if (from.name === null) {
        // send the user back to the home page if browser is refreshed on this page
        // a refresh means there is no previous page (from.name === null)
        next({ name: 'home' });
      } else {
        next();
      }
    } as NavigationGuard,
  },
  { path: '*', redirect: '/404' },
];
