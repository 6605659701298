export enum TimeInForce {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Day = 'Day',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  GTC = 'GTC',
}

export interface TimeInForceOption {
  option: TimeInForce;
}

export const timeInForceOptions: TimeInForce[] = [TimeInForce.Day, TimeInForce.GTC];
