<template>
  <div class="wrapper d-flex flex-column fill-height">
    <div class="gap-1 d-flex align-center">
      <simple-equity-search
        class="equity ml-2 my-2"
        clear-after-select
        label="Security"
        :value="selectedEquity"
        @change="handleEquityChange"
      />
    </div>

    <div class="d-flex flex-column flex">
      <dashboard-panel title="Depth of Book">
        <marketplace-depth-of-book as-broker :cusip="$route.params.cusip" />
      </dashboard-panel>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import MarketplaceDepthOfBook from '@/modules/marketplace/components/MarketplaceDepthOfBook.vue';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import { Api, Equity } from '@/modules/common/types/api';
import { mapMutations } from 'vuex';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    DashboardPanel,
    MarketplaceDepthOfBook,
    SimpleEquitySearch,
  },
  methods: {
    ...mapMutations(['updateLastVisitedSymbolOverview']),
  },
})
export default class BrokerUserMarketplaceDepthOfBook extends Vue {
  // store methods
  protected updateLastVisitedSymbolOverview!: (equity: Equity) => void;

  protected selectedEquity: Equity | null = null;
  protected depthOfBook: Api.Marketplace.DepthOfBookResponse | null = null;

  protected get depthOfBookItems(): Api.Marketplace.DepthOfBookResponse['data'] {
    return this.depthOfBook ? this.depthOfBook.data : [];
  }

  @Watch('$route.params.cusip')
  protected updateEquity(): void {
    // fix until we move from "hash" router to "history" router
    // in case user manually edits URL
    if (this.$route.params.cusip === undefined) {
      window.location.reload();
      return;
    }
    void this.fetch();
  }

  protected mounted(): void {
    void this.fetch();
  }

  protected async fetch(): Promise<void> {
    await this.fetchEquity();
    this.updateLastVisitedSymbolOverview(this.selectedEquity as Equity);
  }

  protected async fetchEquity(): Promise<void> {
    if (this.selectedEquity === null || this.selectedEquity.cusip !== this.$route.params.cusip) {
      this.selectedEquity = await this.$api.manualLoans.fetchEquityByCusip(
        this.$route.params.cusip
      );
    }
  }

  protected handleEquityChange(equity: Equity | null): void {
    // Only change the page if user has selected a new equity
    // (i.e. do nothing if user unselects equity)
    if (equity) {
      void this.$router.push({
        name: 'broker-user.client-monitor.marketplace-depth-of-book',
        params: { cusip: equity.cusip },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}

.equity {
  max-width: 300px;
}

.gap-1 {
  gap: 1rem;
}
</style>
