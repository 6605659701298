<template>
  <items-batch-confirmation-dialog
    :execute="execute"
    item-id-key="displayId"
    :items="items"
    success-message="Returns successfully initiated."
    :table-headers="[
      { text: 'Counterparty' },
      { text: 'Ticker' },
      { text: 'CUSIP' },
      { text: 'Rebate', align: 'end' },
      { text: `Return Quantity`, value: 'toUpdate', align: 'end' },
      { text: 'Available To Return', value: 'openQuantityToReturn', align: 'end' },
    ]"
    title="Return Loans"
    v-on="$listeners"
  >
    <template #description>
      You are about to return <strong><pretty-number :value="totalQuantity" /></strong> shares
      across <strong><pretty-number :value="items.length" /></strong> loans:
    </template>
    <template #row="{ item }">
      <td>
        {{ item.counterpartyDisplay }}
      </td>
      <td>
        {{ item.equity.ticker }}
      </td>
      <td>
        {{ item.equity.cusip }}
      </td>
      <td class="text-end">
        <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
      </td>
      <td class="text-end">
        <pretty-number :value="newQuantities[item.id] || 0" />
      </td>
      <td class="text-end">
        <pretty-number :value="item.openQuantityToReturn" />
      </td>
    </template>
  </items-batch-confirmation-dialog>
</template>

<script lang="ts">
import type { Api } from '@/modules/borrower/types/borrower-loans';
import ItemsBatchConfirmationDialog from '@/modules/common/components/ItemsBatchConfirmationDialog.vue';
import { NewQuantities } from '@/modules/open-loans/helpers/multipleUpdates';
import { OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import { BorrowerOpenLoan } from '@/utils/api/borrower';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

type LoanReturnRequestItem = Api.LoanReturnRequestItem;

@Component({
  components: {
    ItemsBatchConfirmationDialog,
  },
  props: {
    items: Array as PropType<OpenLoanItem[]>,
    newQuantities: {
      type: Object as PropType<NewQuantities>,
      required: true,
      default: () => ({}),
    },
  },
})
export default class BatchReturnDialog extends Vue {
  // props
  protected readonly items!: BorrowerOpenLoan[];
  protected readonly newQuantities!: NewQuantities;

  protected get totalQuantity(): number {
    return Object.values(this.newQuantities).reduce((acc, val) => acc + val, 0);
  }

  private async execute(): Promise<{ failedIds?: number[] }> {
    const returns = this.items.map((loan) => {
      return {
        displayId: loan.displayId,
        quantity: this.newQuantities[loan.id],
      } as LoanReturnRequestItem;
    });
    const result = await this.$api.borrowerLoans.batchReturnBorrowerLoan(returns);
    const failedIds = result.failedItems.map((i) => i.requestId);

    // failing ids only, we are losing the error messages here
    return { failedIds };
  }
}
</script>
<style lang="scss" scoped></style>
