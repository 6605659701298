<template>
  <div>
    <loan-details-dialog
      v-if="detailLoanId"
      :as-broker="asBroker"
      :loan-id="detailLoanId"
      @close-modal="detailLoanId = null"
    />
    <v-dialog
      v-shortkey="['esc']"
      content-class="au-popup-dialog"
      max-width="1400"
      overlay-color="secondary"
      overlay-opacity="0.80"
      persistent
      :value="true"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      @shortkey.native="closeDialog()"
    >
      <marketplace-order-details-card
        :as-broker="asBroker"
        :order-ref="orderRef"
        :should-retry-fetch="shouldRetryFetch"
        v-on="$listeners"
        @open-loan-details="detailLoanId = $event"
      />
      <v-btn class="close-icon" icon @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import MarketplaceOrderDetailsCard from '@/modules/marketplace/components/MarketplaceOrderDetailsCard.vue';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';

@Component({
  components: {
    MarketplaceOrderDetailsCard,
    LoanDetailsDialog,
  },
  props: {
    orderRef: String,
    asBroker: {
      type: Boolean,
      default: false,
    },
    shouldRetryFetch: {
      type: Boolean,
      default: false,
    },
  },
})
export default class MarketplaceOrderDetailsDialog extends Vue {
  // props
  protected readonly orderRef!: string;
  protected readonly asBroker!: boolean;
  // useful when order has just been created
  // and the loan events are not available yet (but will be soon)
  protected readonly shouldRetryFetch!: boolean;

  protected detailLoanId: number | null = null;

  protected openLoanDetailsDialog(loanId: number | null): void {
    this.detailLoanId = loanId;
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>
<style lang="scss" scoped>
.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}
::v-deep {
  .v-card {
    min-height: 16vh;
  }

  .table-container {
    height: 30vh;
  }
}
</style>
