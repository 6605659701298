<template>
  <v-row class="d-flex align-start">
    <v-col class="border-right-divider" cols="6">
      <table class="summary">
        <tbody>
          <tr>
            <td>Side</td>
            <td>
              <format-side :side="contract.side" />
            </td>
          </tr>
          <tr>
            <td>Counterparty</td>
            <td>{{ formatCompanyName(contract.counterparty) }}</td>
          </tr>
          <tr>
            <td>Value</td>
            <td>
              ${{ formatPrice(contract.notionalValue) }} &#177;
              <rate-output :precision="2" :rate="contract.threshold" />
            </td>
          </tr>
          <tr>
            <td>Rate</td>
            <td>
              <rate-output :rate="contract.rate" :rate-modifier="contract.rateModifier" />
            </td>
          </tr>
          <tr>
            <td>Max Equity Percentage</td>
            <td><rate-output :precision="2" :rate="contract.maxEquityPercent" /></td>
          </tr>
        </tbody>
      </table>
    </v-col>
    <v-col class="pl-6" cols="6">
      <table class="summary">
        <tbody>
          <tr>
            <td>Start Date</td>
            <td>{{ formatDate(contract.startDate) }}</td>
          </tr>
          <tr>
            <td>End Date</td>
            <td>{{ formatDate(contract.endDate) }}</td>
          </tr>
          <tr>
            <td>Tracking Index</td>
            <td>Custom</td>
          </tr>
          <tr>
            <td>IA</td>
            <td>
              <rate-output :precision="2" :rate="contract.independentAmountRate" />
            </td>
          </tr>
          <tr>
            <td>Rounding Rule</td>
            <td>
              {{ roundingRuleToShortString(contract.roundingRule) }}
            </td>
          </tr>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import Decimal from 'decimal.js';
import { formatDate } from '@/utils/helpers/dates';
import { Api } from '@/modules/common/types/api';
import { formatCompanyName } from '@/modules/user-accounts/helpers/user-accounts';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import { roundingRuleToShortString } from '@/modules/sec-lending/helpers/contract-details';

@Component({
  methods: { roundingRuleToShortString },
  props: {
    contract: {
      type: Object as PropType<Api.TermLoans.ContractSummary>,
      required: true,
    },
  },
})
export default class TermLoansContractBodySummary extends Vue {
  // props
  protected readonly contract!: Api.TermLoans.ContractSummary;

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM dd Y');
  }

  protected formatCompanyName(counterparty: CompanyInfo): string {
    return formatCompanyName(counterparty);
  }
}
</script>
<style lang="scss" scoped>
.border-right-divider {
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.summary {
  width: 100%;
  line-height: 2;
}

.summary td:first-child {
  width: 50%;
  color: #888;
}

.summary td:last-child {
  width: 50%;
  font-weight: bold;
  color: white;
}
</style>
