<template>
  <span>{{ formattedNumber }}</span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import formatPrettyNumber from './formatPrettyNumber';

@Component({
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
})
export default class PrettyNumber extends Vue {
  public readonly value!: string | number;

  public get formattedNumber(): string {
    return formatPrettyNumber(this.value);
  }
}
</script>
