<template>
  <v-simple-table class="mt-4" dense>
    <template #default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t('auction-information') }}
          </th>
          <th class="text-left">
            {{ $t('Published') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="auction.equity">
          <td>{{ $t('security') }}</td>
          <td>{{ auction.equity.name }}</td>
        </tr>
        <tr>
          <td>{{ $t('ticker') }}</td>
          <td>{{ auction.equity.ticker }} [{{ auction.equity.cusip }}]</td>
        </tr>
        <tr>
          <td>
            <v-tooltip color="form-tooltip" max-width="600px" right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ $t('cut-off') }}
                  <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                </span>
              </template>
              <span>{{ endsAtTooltip }}</span>
            </v-tooltip>
          </td>
          <td>
            <pretty-date-time :when="auction.endsAt"></pretty-date-time>
          </td>
        </tr>
        <tr>
          <td>{{ $t('direction') }}</td>
          <td>{{ $t(auction.leakedDirection || $t('non-public')) }}</td>
        </tr>
        <tr>
          <td>{{ $t('volume') }}</td>
          <td v-if="auction.leakedQuantity">
            <pretty-number :value="auction.leakedQuantity" />
          </td>
          <td v-else>{{ $t('non-public') }}</td>
        </tr>
        <tr>
          <td>{{ $t('rate') }}</td>
          <td v-if="auction.leakedRate">
            <rate-output :rate="auction.leakedRate" />
          </td>
          <td v-else>{{ $t('non-public') }}</td>
        </tr>
        <tr>
          <td>
            <v-tooltip color="form-tooltip" max-width="600px" right>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ $t('Stacked-Order') }}
                  <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                </span>
              </template>
              <span>{{ stackedOrderTooltip }}</span>
            </v-tooltip>
          </td>
          <td v-if="auction.leakedIsStackedOrder">{{ $t('is-stacked') }}</td>
          <td v-else>{{ $t('non-public') }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { setSeconds } from 'date-fns';
import { formatDate } from '@/utils/helpers/dates';
import PrettyDateTime from '@/modules/common/components/format-date/PrettyDateTime.vue';
import { AuctionTimeManager } from '@/utils/helpers/auction-time-manager';
import i18n from '@/localisation/i18n';
import { BespokeAuction } from '@/utils/helpers/rest';

@Component({
  components: { PrettyDateTime },
  props: {
    auction: Object,
  },
})
export default class AuctionSummary extends Vue {
  private auction!: BespokeAuction;

  private timeManager = new AuctionTimeManager(this.$store);
  private now = setSeconds(new Date(), 0);
  private endsAtTooltip = '';
  private stackedOrderTooltip = '';

  private mounted(): void {
    this.endsAtTooltip = i18n.t('brokerAuctionTimeWindow.tip', {
      auctionOpens: this.timeManager.getBrokerMarketOpens(),
      auctionCloses: this.timeManager.getBrokerMarketCloses(),
      brokerTimeZone: this.timeManager.getBrokerTimeZone(),
    }) as string;
    if (this.timeManager.getBrokerOffsetMinutes(this.now) !== 0) {
      const endsAtLocal = i18n.t('localAuctionTimeWindow.tip', {
        auctionOpens: formatDate(this.timeManager.getMarketOpens(this.now), 'HH:mm'),
        auctionCloses: formatDate(this.timeManager.getMarketCloses(this.now), 'HH:mm'),
        localTimeZone: formatDate(this.now, 'O'),
      }) as string;
      this.endsAtTooltip = `${this.endsAtTooltip} ${endsAtLocal}`;
    }

    this.stackedOrderTooltip = i18n.t('stackedTicket.tip') as string;
  }
}
</script>
