<template>
  <v-card class="flex d-flex flex-column">
    <!-- Dialog boxes -->
    <create-lender-order-dialog
      v-if="showCreateDialog"
      :selected-items.sync="selectedItems"
      @close-modal="showCreateDialog = false"
    />

    <delete-lender-order-dialog
      v-if="showDeleteDialog"
      :selected-items.sync="selectedItems"
      @close-modal="showDeleteDialog = false"
    />

    <toggle-lendability-dialog
      v-if="showLendabilityDialog"
      :selected-items.sync="selectedItems"
      @close-modal="showLendabilityDialog = false"
    />

    <!-- File upload dialog box -->
    <upload-progress-popup
      :close-on-success="false"
      content-class="lender-orders-upload"
      :display-status.sync="isUploading"
      :progress-promise="uploadProgressPromise"
      :width="500"
      @uploadAgain="uploadAgain"
    >
      <!-- success display slot -->
      <template #success="{ uploadResult }">
        <div class="v-alert v-alert-dense success--text text-center">
          <b>{{ uploadResult.count }}</b> supply items have been created
        </div>
      </template>

      <!-- error display slot -->
      <template #error="{ errorMsg, uploadMsgs, showError, showWarning }">
        <div
          class="v-alert v-alert-dense text-center"
          :class="{ 'error--text': showError, 'warning--text': showWarning }"
        >
          {{ errorMsg }}
        </div>
        <div
          v-if="uploadMsgs"
          class="file-upload-summary col overflow-x-hidden overflow-y-auto text-center"
        >
          <v-row v-for="(err, idx) in uploadMsgs" :key="idx" class="pb-1">
            <v-col class="py-0">Row {{ err.row }}, Column {{ err.col }}: {{ err.errMsg }}</v-col>
          </v-row>
        </div>
      </template>
    </upload-progress-popup>

    <v-data-table
      ref="tableRef"
      v-model="selectedItems"
      class="d-flex flex flex-column"
      :custom-filter="searchableTableFilter"
      dense
      fixed-header
      :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
      :headers="tableHeaders"
      item-key="id"
      :items="formattedLenderOrders"
      :items-per-page="50"
      :search="search"
      :show-select="hasTraderUserRole"
      sort-asc
      sort-by="equity.ticker"
    >
      <!-- Top bar above table  -->
      <template #top>
        <div class="d-flex flex-row">
          <!-- Left-most button group -->
          <div class="col col-auto d-flex flex-column justify-space-between">
            <div class="d-flex">
              <div class="d-flex flex-row">
                <v-btn
                  class="new-order mr-6"
                  color="secondary"
                  :disabled="!hasTraderUserRole"
                  @click.stop="showCreateDialog = true"
                >
                  Add Supply
                </v-btn>

                <div class="d-flex flex-row align-center">
                  <!-- CSV File upload -->
                  <div class="d-flex" title="Upload Supply CSV">
                    <v-btn :disabled="!hasTraderUserRole" icon :ripple="false" small text>
                      <v-file-input
                        v-model="fileUpload"
                        :accept="uploadFileTypes"
                        class="pt-0 mt-0 justify-center"
                        color="primary"
                        :disabled="isUploading"
                        hide-input
                        prepend-icon="mdi-upload"
                        @change="onChangeFileUpload(fileUpload, 'change')"
                      />
                    </v-btn>
                  </div>

                  <!-- Batch actions -->
                  <v-btn
                    class="mr-1"
                    :disabled="!hasTraderUserRole || !selectedItems.length"
                    icon
                    small
                    :title="$t('orders.batchActions.toggleAvailability')"
                    @click="onBatchEditLendability(selectedItems)"
                  >
                    <v-icon>mdi-hand-right</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-1"
                    :disabled="!hasTraderUserRole || !selectedItems.length"
                    icon
                    small
                    :title="$t('orders.batchActions.deleteOrders')"
                    @click="onBatchDeleteOrders(selectedItems)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <div class="d-flex align-end selected-items-count">
              <transition name="fade">
                <small v-if="selectedItems.length">
                  {{
                    selectedItems.length
                      ? `${selectedItems.length} of ${formattedLenderOrders.length} selected`
                      : ''
                  }}
                </small>
              </transition>
            </div>
          </div>

          <!-- filter box -->
          <div class="d-flex col-md-2 ml-auto search">
            <v-text-field
              v-model="search"
              clearable
              dense
              label="Filter list"
              placeholder="filter..."
              prepend-inner-icon="mdi-magnify"
            />
          </div>
        </div>
      </template>

      <!-- Table rows -->
      <template #[`item.isLendableDisplay`]="{ item }">
        <v-chip v-if="item.expiredAt" class="justify-center" color="warning" small>
          expired
        </v-chip>
        <v-chip v-else :class="item.isLendable ? 'status-enabled' : 'status-disabled'" small>
          {{ item.isLendableDisplay }}
        </v-chip>
      </template>

      <template #[`item.quantity`]="{ item }">
        <pretty-number :value="item.quantity" />
      </template>

      <template #[`item.rate`]="{ item }">
        <rate-output :rate="item.rate" />
      </template>

      <template #[`item.timeInForce`]="{ item }">
        <span>{{ $tc(`orders.timeInForce.options.${item.timeInForce}`) }}</span>
      </template>

      <!-- Row item actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex flex-row justify-end row-actions">
          <v-btn
            v-if="!item.expiredAt"
            class="ml-1 action-toggle"
            :disabled="!hasTraderUserRole || isProcessing"
            icon
            small
            title="enable/disable order"
            @click="onToggleLendability(item, !item.isLendable)"
          >
            <!-- <v-icon>mdi-cancel</v-icon> -->
            <v-icon>mdi-hand-right</v-icon>
          </v-btn>
          <v-btn
            v-if="!item.expiredAt"
            class="ml-1 action-edit"
            :disabled="!hasTraderUserRole || isProcessing"
            icon
            small
            title="edit order"
            @click="onEditOrders(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="ml-1 action-delete"
            :disabled="!hasTraderUserRole || isProcessing"
            icon
            small
            title="delete order"
            @click="onBatchDeleteOrders([item])"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import UploadProgressPopup from '@/modules/common/components/UploadProgressPopup.vue';
import { Api } from '@/modules/common/types/api';
import CreateLenderOrderDialog from '@/modules/order-management/components/CreateLenderOrderDialog.vue';
import DeleteLenderOrderDialog from '@/modules/order-management/components/DeleteLenderOrderDialog.vue';
import ToggleLendabilityDialog from '@/modules/order-management/components/ToggleLendabilityDialog.vue';
import {
  LenderOrderListDisplayItem,
  LenderOrderListItem,
} from '@/modules/order-management/types/lender-orders';
import { AppState } from '@/store/store';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { searchableTableFilter } from '@/utils/helpers/searchable-fields';
import Vue from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapGetters, mapState } from 'vuex';

const tableHeaders: DataTableHeader[] = [
  {
    text: i18n.tc('Status'),
    value: 'isLendableDisplay',
    class: 'text-truncate row-icon status',
    cellClass: 'text-truncate row-icon status',
    align: 'center',
    width: '7rem',
  },
  {
    text: i18n.tc('Order ID'),
    value: 'displayId',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: 200,
  },
  {
    text: i18n.tc('Ticker'),
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('CUSIP'),
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Available'),
    value: 'quantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('Limit'),
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('orders.timeInForce.title'),
    value: 'timeInForce',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
  },
  // empty column to control space and alignment
  {
    text: '',
    value: '',
    sortable: false,
    width: 150,
  },
  {
    text: i18n.tc('Actions'),
    value: 'actions',
    align: 'end',
    sortable: false,
    filterable: false,
    width: 120,
  },
];

@Component({
  components: {
    CreateLenderOrderDialog,
    DeleteLenderOrderDialog,
    ToggleLendabilityDialog,
    UploadProgressPopup,
  },
  methods: {
    ...mapActions(['fetchLenderOrders']),
  },
  computed: {
    ...mapState(['lenderOrders']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class LenderOrdersList extends Vue {
  // store methods
  protected fetchLenderOrders!: () => Promise<void>;

  // store state
  protected lenderOrders!: AppState['lenderOrders'];
  protected hasTraderUserRole!: boolean;

  protected showCreateDialog = false;
  protected showEditDialog = false;
  protected showDeleteDialog = false;
  protected showLendabilityDialog = false;
  protected isProcessing = false;

  protected tableHeaders = tableHeaders;

  protected searchableTableFilter = searchableTableFilter;
  protected selectedItems: LenderOrderListItem[] = [];
  protected itemToEdit: LenderOrderListItem | null = null;
  protected search = '';

  /**
   * possible file types that can be uploaded
   */
  protected readonly uploadFileTypes = '.csv, .tsv, .xls, .xlsx, .dummy, text/*';
  protected isUploading = false;
  protected fileUpload: File | null = null;
  protected lastFileUpload: File | null = null;
  protected uploadProgressPromise: Promise<Api.LenderOrders.LenderOrderUploadResponse> | null =
    null;

  /**
   * Get a formatted version of lender orders list
   */
  protected get formattedLenderOrders(): LenderOrderListDisplayItem[] {
    return this.lenderOrders.map((item) => {
      const isLendableDisplay = item.isLendable ? 'active' : 'disabled';

      return {
        ...item,
        isLendableDisplay,
      };
    });
  }

  protected mounted(): void {
    // DO NOT REMOVE or EDIT unless you understand
    // https://provablelabs.atlassian.net/browse/AURORA-1480
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.tableRef as any).multipleSelect = () => {
      /* */
    };

    void this.fetchLenderOrders();
  }

  /**
   * Create a new Lender Orders via a dialog box
   */
  protected onCreateOrders(): void {
    this.showCreateDialog = true;
  }

  /**
   * Edit an existing Lender Orders via a dialog box
   */
  protected onEditOrders(item: LenderOrderListItem): void {
    this.selectedItems = [item];
    this.showCreateDialog = true;
  }

  /**
   * Delete multiple Lender Orders via a dialog box
   */
  protected onBatchDeleteOrders(items: LenderOrderListItem[]): void {
    this.selectedItems = items;
    this.showDeleteDialog = true;
  }

  /**
   * Toggle lendability of multiple Lender Orders via a dialog box
   */
  protected onBatchEditLendability(items: LenderOrderListItem[]): void {
    this.selectedItems = items.filter((item) => !item.expiredAt);
    if (this.selectedItems.length) {
      this.showLendabilityDialog = true;
    }
  }

  /**
   * Enable or disable lending of a Lender Orders
   */
  protected async onToggleLendability(item: LenderOrderListItem, state: boolean): Promise<void> {
    this.isProcessing = true;
    try {
      const batchChanges = { id: item.id, isLendable: state };
      await this.$api.orderManagement.batchUpdateLenderOrderLendability([batchChanges]);
      await this.fetchLenderOrders();
      this.$snackbar.confirm(`This supply item has been ${state ? 'enabled' : 'disabled'}`);
    } catch (err) {
      // show a popup if the action fails
      // @TODO better error message display
      this.$snackbar.error(i18nServerMessage(err as Error));
    } finally {
      this.isProcessing = false;
    }
  }

  /**
   * Handles file upload for batch creation of new Lender Orders
   */
  protected onChangeFileUpload(): void {
    if (!this.fileUpload) {
      return;
    }

    // only confirm if there are orders
    if (this.lenderOrders.length) {
      this.$dialog.confirm({
        title: 'Are you sure?',
        message: 'Uploading new supply replaces existing supply',
        isRejectable: true,
        rejectText: 'Cancel',
        acceptText: 'Replace',
        onAccept: this.uploadFile,
        onReject: () => {
          // clear file upload to allow for new input
          this.fileUpload = null;
          this.lastFileUpload = null;
        },
      });
    } else {
      void this.uploadFile();
    }
  }

  protected uploadAgain(): void {
    this.fileUpload = this.lastFileUpload;
    void this.uploadFile(true);
  }

  private async uploadFile(skipWarnings = false): Promise<void> {
    // post the file for parsing and show results in the progress dialog box
    const promise = this.$api.orderManagement.uploadLenderOrdersFile(
      this.fileUpload as File,
      skipWarnings
    );
    this.uploadProgressPromise = promise;

    try {
      await promise;
      void this.fetchLenderOrders();
    } catch (err) {
      // Do nothing; the upload dialog handles the promise
    }

    // clear file upload to allow for new input
    this.lastFileUpload = this.fileUpload;
    this.fileUpload = null;
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .selected-items-count {
    min-height: 2rem;

    small {
      font-size: 0.75rem;
    }
  }

  // remove extra margin on file input icon
  .v-btn .v-file-input .v-input__prepend-outer {
    margin: 0;
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }

    tr {
      .row-actions {
        opacity: 0.4;
        pointer-events: none;
        transition: opacity linear 0.15s;
      }

      &:hover .row-actions {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }

  // enhance status column
  th.row-icon.status {
    padding-left: 29px !important;
  }

  // @TODO use sass variables to store these colours
  .v-chip {
    &.status-enabled {
      padding: 0 16px;
      color: #fff;
      background: #4caf50;
    }

    &.status-disabled {
      color: #fff;
      background: #7a7a7a;
    }
  }
}
</style>

<style lang="scss" scoped>
.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>

    margin-top: auto;
  }
}

// Lender Orders CSV Upload dialog
.lender-orders-upload {
  .file-upload-summary {
    max-height: 20rem;
  }
}
</style>
