import { Api } from '@/modules/common/types/api';
import { Decimal } from 'decimal.js';
import {
  OrderEventType,
  OrderSide,
  OrderStatus,
  OrderType,
  SettlementType,
  TimeInForceType,
} from '@/modules/marketplace/types/marketplace';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import { sortBy } from 'lodash';

/**
 * Normalize functions used by api.service and mocks:
 *
 * a) Convert strings to Decimal
 * b) Convert strings to Date
 * c) Ensure string literals are asserted
 * d) Rename some fields to increase consistency
 */

export function normalizeTopOfBook(
  resp: Api.Marketplace.Raw.TopOfBookResponse
): Api.Marketplace.TopOfBookResponse {
  const mapped = resp.data.map((i) => {
    return {
      equity: { ...i.equity, lastClosePrice: new Decimal(i.equity.lastClosePrice) },
      borrow: i.borrow
        ? {
            ...i.borrow,
            counterparties: i.borrow.counterpartyIds.map((id) => resp.counterparties[id]),
            rate: new Decimal(i.borrow.rate),
          }
        : null,
      lend: i.lend
        ? {
            ...i.lend,
            counterparties: i.lend.counterpartyIds.map((id) => resp.counterparties[id]),
            rate: new Decimal(i.lend.rate),
          }
        : null,
    };
  });

  return mapped;
}

export function normalizeDepthOfBook(
  data: Api.Marketplace.Raw.DepthOfBookResponse
): Api.Marketplace.DepthOfBookResponse {
  const mapped = data.items.map((i) => {
    return {
      // rename "borrowOrder" to "borrow" (to be consistent with TopOfBook)
      borrow: i.borrowOrder
        ? {
            ...i.borrowOrder,
            rate: new Decimal(i.borrowOrder.rate),
            independentAmountRate: new Decimal(i.borrowOrder.independentAmountRate),
          }
        : null,
      // rename "lendOrder" to "lend" (to be consistent with TopOfBook)
      lend: i.lendOrder
        ? {
            ...i.lendOrder,
            rate: new Decimal(i.lendOrder.rate),
            independentAmountRate: new Decimal(i.lendOrder.independentAmountRate),
          }
        : null,
    };
  });

  return {
    // rename "items" to "data" (to be consistent with TopOfBook)
    data: mapped,
    equity: { ...data.equity, lastClosePrice: new Decimal(data.equity.lastClosePrice) },
  };
}

export function normalizeOrderDetails(
  data: Api.Marketplace.Raw.OrderDetails
): Api.Marketplace.OrderDetails {
  // first normalize the history
  const history: Api.Marketplace.OrderHistoryItem[] = data.items.reverse().map((i) => {
    const historyItem: Api.Marketplace.OrderHistoryItem = {
      ...i,
      eventType: i.eventType as OrderEventType,
      eventTimestamp: new Date(i.eventTimestamp),
      initiator: i.initiator,
      summary: {
        ...i.summary,
        status: normalizeStatus(i.summary.status),
        rate: new Decimal(i.summary.rate),
        avgExecutionRate:
          i.summary.avgExecutionRate === null ? null : new Decimal(i.summary.avgExecutionRate),
        orderType: i.summary.orderType as OrderType,
        settlementType: i.summary.settlementType as SettlementType,
        timeInForceType: i.summary.timeInForceType as TimeInForceType,
        independentAmountRate: new Decimal(i.summary.independentAmountRate),
        roundingRule: i.summary.roundingRule as RoundingRule,
        openQuantity: i.summary.quantity - i.summary.filled,
        totalValue: new Decimal(i.summary.quantity).times(data.equity.lastClosePrice),
      },
    };

    // when there is "execId" (i.e. eventType is "EXECUTED"), parse additional fields
    if ('execId' in i) {
      const execHistoryItem: Api.Marketplace.OrderHistoryExecutedItem = {
        ...historyItem,
        loanId: i.loanId,
        execId: i.execId,
        rate: new Decimal(i.rate),
        independentAmountRate: new Decimal(i.independentAmountRate),
        unitPrice: new Decimal(i.unitPrice),
        counterparty: i.counterparty,
      };
      return execHistoryItem;
    }

    return historyItem;
  });

  // combine most recent summary with the rest of the data
  const order: Api.Marketplace.Order = {
    orderRef: data.orderRef,
    side: data.side as OrderSide,
    createdAt: new Date(data.createdAt),
    equity: { ...data.equity, lastClosePrice: new Decimal(data.equity.lastClosePrice) },
    ...history[0].summary,
  };

  return { history, order };
}

export function normalizeAdminOrderDetails(
  data: Api.Marketplace.Raw.AdminOrderDetails
): Api.Marketplace.AdminOrderDetails {
  const normalizedOrderDetails = normalizeOrderDetails(data);

  const adminOrder: Api.Marketplace.AdminOrder = {
    ...normalizedOrderDetails.order,
    company: data.company,
  };

  const adminOrderDetails: Api.Marketplace.AdminOrderDetails = {
    ...normalizedOrderDetails,
    order: adminOrder,
  };

  return adminOrderDetails;
}

export function normalizeOrdersList(
  data: Api.Marketplace.Raw.OrdersResponse['data']
): Api.Marketplace.OrdersResponse {
  const mapped = data.map<Api.Marketplace.Order>(normalizeOrder);
  return sortBy(mapped, 'orderRef');
}

export function normalizeAdminOrdersList(
  data: Api.Marketplace.Raw.AdminOrdersResponse['data']
): Api.Marketplace.AdminOrdersResponse {
  const mapped = data.map<Api.Marketplace.AdminOrder>((i) => {
    const normalizedOrder = normalizeOrder(i);
    return {
      ...normalizedOrder,
      company: i.company,
    };
  });

  return sortBy(mapped, 'orderRef');
}

function normalizeOrder(i: Api.Marketplace.Raw.Order): Api.Marketplace.Order {
  return {
    ...i,
    status: normalizeStatus(i.status),
    side: i.side as OrderSide,
    rate: new Decimal(i.rate),
    avgExecutionRate: i.avgExecutionRate === null ? null : new Decimal(i.avgExecutionRate),
    createdAt: new Date(i.createdAt),
    orderType: i.orderType as OrderType,
    settlementType: i.settlementType as SettlementType,
    timeInForceType: i.timeInForceType as TimeInForceType,
    independentAmountRate: new Decimal(i.independentAmountRate),
    equity: { ...i.equity, lastClosePrice: new Decimal(i.equity.lastClosePrice) },
    roundingRule: i.roundingRule as RoundingRule,
    openQuantity: i.quantity - i.filled,
    totalValue: new Decimal(i.quantity).times(i.equity.lastClosePrice),
  };
}

function normalizeStatus(status: string): OrderStatus {
  switch (status) {
    case 'filled':
      return 'filled';
    case 'canceled':
      return 'canceled';
    case 'expired':
      return 'expired';
    default:
      return 'open';
  }
}

export function normalizeWatchlistWithInstruments(
  data: Api.Marketplace.Raw.TopOfBookWatchlistWithInstrumentsResponse
): Api.Marketplace.TopOfBookWatchlistWithInstrumentsResponse {
  const instruments = data.instruments.map((instrument) => ({
    ...instrument,
    lastClosePrice: new Decimal(instrument.lastClosePrice),
  }));

  return { ...data, instruments };
}

export function normalizeWatchlistUploadSecuritiesResponse(
  data: Api.Marketplace.Raw.TopOfBookWatchlistSecuritiesUploadResponse
): Api.Marketplace.TopOfBookWatchlistSecuritiesUploadResponse {
  return {
    ...data,
    instruments: data.instruments.map((instrument) => ({
      ...instrument,
      lastClosePrice: new Decimal(instrument.lastClosePrice),
    })),
  };
}
