<template>
  <v-card>
    <v-container fluid>
      <template v-if="isLoaded">
        <v-btn class="ma-6" color="primary" :disabled="!hasTraderUserRole" @click="initiateAuction">
          <v-icon left> mdi-plus</v-icon>
          {{ $t('startAuction') }}
        </v-btn>
      </template>

      <auctions-table
        :auctions="ownAuctions"
        :is-own="true"
        :label="$tc('yourAuctions.title')"
        no-open-auctions="yourAuctions.count"
        @addTicket="addTicket"
        @deleteTicket="deleteTicket"
        @editTicket="editTicket"
        @executeAuction="executeAuctionNow"
        @joinAuction="joinAuction"
      />

      <auctions-table
        :auctions="otherAuctions"
        :is-own="false"
        :label="$tc('openAuctions.title')"
        no-open-auctions="otherAuctions.count"
        @joinAuction="joinAuction"
      />

      <template v-if="auctionDialogOptions.isActive">
        <component
          :is="auctionDialog"
          :key="auctionRender"
          :auction="auctionBuffer"
          :staged-auction="stagedAuction"
          @change-auction="changeAuction"
          @clear-auction="clearAuction"
          @close-modal="closeAuctionModal"
          @stage-auction="stageAuction"
          @toggle-can-cancel="toggleCanCancelAuctionModal"
        ></component>
      </template>

      <template v-if="ticketDialogOptions.isActive">
        <component
          :is="ticketDialog"
          :key="ticketRender"
          :auction="joinedAuction"
          :crud-action="ticketDialogOptions.crudAction"
          :staged-ticket="stagedTicket"
          :ticket="ticketBuffer"
          @change-ticket="changeTicket"
          @clear-ticket="clearTicket"
          @close-modal="closeTicketModal"
          @stage-ticket="stageTicket"
          @toggle-can-cancel="toggleCanCancelTicketModal"
        ></component>
      </template>
    </v-container>

    <!-- [N]ew order -->
    <div v-shortkey="['ctrl', 'n']" @shortkey="initiateAuction"></div>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapState } from 'vuex';
import Decimal from 'decimal.js';
import { ModalOptions } from '@/utils/helpers/dialog';
import SystemBar from '@/modules/common/components/SystemBar.vue';
import AuctionInitiation from '@/modules/auction/components/AuctionInitiation.vue';
import AuctionConfirmation from '@/modules/auction/components/AuctionConfirmation.vue';
import AuctionOrderDialog from '@/modules/auction/components/AuctionOrderDialog.vue';
import AuctionOrderConfirmation from '@/modules/auction/components/AuctionOrderConfirmation.vue';
import AuctionsTable from '@/modules/auction/components/AuctionsTable.vue';

import {
  AuctionDialogBuffer,
  AuctionLeakage,
  BespokeAuction,
  ClientConfig,
  ClosedAuction,
  InvestorProfile,
  OrderTicket,
  RestOperation,
  auctionTotalFilledVolume,
  createAuctionDialogBuffer,
  createOrderTicket,
  getBestOrderFromTicket,
  leakNothing,
} from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { cloneDeep } from 'lodash';
import i18n from '@/localisation/i18n';

@Component({
  components: {
    SystemBar,
    AuctionsTable,
    AuctionInitiation,
    AuctionConfirmation,
    AuctionOrderDialog,
    AuctionOrderConfirmation,
  },
  props: {
    closedAuction: Object, // create a new auction for the remainder this closed auction
  },
  data: () => ({
    // auction lists
    ownAuctions: [],
    otherAuctions: [],
    isLoaded: false,

    // auction dialog
    auctionRender: 0,
    auctionDialog: 'auction-initiation',
    auctionDialogOptions: {
      isActive: false,
      crudAction: RestOperation.Noop,
      cancelOptions: defaultCancelOptions,
    },
    auctionBuffer: null,
    stagedAuction: null,

    // order dialog
    ticketRender: 0,
    ticketDialog: 'auction-order-dialog',
    ticketDialogOptions: {
      isActive: false,
      crudAction: RestOperation.Noop,
      cancelOptions: defaultCancelOptions,
    },
    joinedAuction: null,
    ticketBuffer: null,
    stagedTicket: null,
  }),
  methods: {
    ...mapActions([
      'fetchActiveAuctions',
      'fetchTraderInvestorProfiles',
      'executeAuction',
      'deleteAuctionTicket',
    ]),
  },
  computed: {
    ...mapState(['auctions', 'clientConfig', 'investorProfiles']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class Auctions extends Vue {
  protected hasTraderUserRole!: boolean;
  // store state refs
  private clientConfig!: ClientConfig;
  private investorProfiles!: InvestorProfile[];
  private fetchActiveAuctions!: () => Promise<null>;
  private fetchTraderInvestorProfiles!: () => Promise<null>;
  private executeAuction!: (id: string) => Promise<null>;
  private deleteAuctionTicket!: (payload: { id: string; leakage: AuctionLeakage }) => Promise<null>;
  private closedAuction!: ClosedAuction | null; //  re-initiate this closed auction!
  private isLoaded!: boolean;
  private ownAuctions!: BespokeAuction[];
  private otherAuctions!: BespokeAuction[];
  private auctionRender!: number;
  private auctionDialog!: string;
  private auctionDialogOptions!: ModalOptions;
  private auctionBuffer!: AuctionDialogBuffer | null;
  private stagedAuction!: BespokeAuction | null;
  private joinedAuction!: BespokeAuction | null;
  private ticketBuffer!: OrderTicket | null;
  private stagedTicket!: OrderTicket | null;
  private ticketRender!: number;
  private ticketDialog!: string;
  private ticketDialogOptions!: ModalOptions;

  @Watch('auctions')
  private onAuctions(newAuctions: BespokeAuction[]): void {
    this.ownAuctions = newAuctions.filter(
      (auction: BespokeAuction) => auction.companyOrderTickets.length > 0
    );
    this.otherAuctions = newAuctions.filter(
      (auction: BespokeAuction) =>
        auction.companyOrderTickets.length === 0 && auction.executedAt == null
    );
  }

  private async mounted() {
    try {
      await this.fetchActiveAuctions();
      await this.fetchTraderInvestorProfiles();

      this.isLoaded = true;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  private created() {
    if (this.closedAuction != null) {
      this.reinitiateAuction(this.closedAuction);
    }
  }

  /*
   * (re)initiate auction aka change auction aka create auction
   */
  private initiateAuction() {
    if (this.isMarketClosed()) {
      return;
    }

    if (this.auctionBuffer == null) {
      this.auctionBuffer = createAuctionDialogBuffer(this.investorProfiles.map((p) => p.id));
    }

    // edit this fresh auction
    this.openAuctionModal();
  }

  private reinitiateAuction(c: ClosedAuction) {
    if (this.isMarketClosed()) {
      return;
    }

    // start a new auction
    this.auctionDialog = 'auction-initiation';
    this.auctionBuffer = createAuctionDialogBuffer(this.investorProfiles.map((p) => p.id));
    this.stagedAuction = null;

    const quantity = c.companyOrderTickets[0].totalQuantity;
    const rate = c.companyOrderTickets[0].avgRate;

    // copy props from closed auction
    this.auctionBuffer.equity = c.equity;
    this.auctionBuffer.leakDirection = c.leakedDirection !== null;
    this.auctionBuffer.shareableDirection = c.companyOrderTickets[0].direction;

    this.auctionBuffer.leakQuantity = c.leakedQuantity !== null;
    this.auctionBuffer.shareableQuantity = quantity;

    const filledVolume = auctionTotalFilledVolume(c);
    this.auctionBuffer.shareableQuantity =
      filledVolume < this.auctionBuffer.shareableQuantity
        ? this.auctionBuffer.shareableQuantity - filledVolume
        : 0;
    this.auctionBuffer.firstOrderTicket.orders[0].quantity = this.auctionBuffer.shareableQuantity;

    this.auctionBuffer.leakRate = c.leakedRate !== null;
    this.auctionBuffer.shareableRate = rate;
    this.auctionBuffer.firstOrderTicket.orders[0].rate = rate;

    this.auctionBuffer.leakIsStackedOrder = c.leakedIsStackedOrder !== null;

    if (c.isOwnCompany) {
      this.auctionBuffer.participantList = c.participants;
    }

    // edit new auction
    this.openAuctionModal();
  }

  private clearAuction() {
    this.auctionBuffer = createAuctionDialogBuffer(this.investorProfiles.map((p) => p.id));
    this.auctionRender += 1;
    this.stagedAuction = null;
  }

  private changeAuction() {
    if (this.auctionBuffer == null) {
      // nothing to edit? close dialog
      this.closeAuctionModal();
      return;
    }
    this.auctionDialog = 'auction-initiation';
  }

  private stageAuction(stagedAuction: BespokeAuction) {
    this.auctionDialog = 'auction-confirmation';
    this.stagedAuction = stagedAuction;
  }

  private openAuctionModal() {
    this.toggleCanCancelAuctionModal(true); // default is cancelable
    this.auctionDialogOptions.isActive = true;
  }

  private closeAuctionModal() {
    // wipe auction data
    this.auctionDialogOptions.isActive = false;
    this.auctionDialog = 'auction-initiation';
    this.auctionBuffer = null;
    this.stagedAuction = null;
  }

  // clicked 'cancel', just hide the dialog (the user may continue later)
  private cancelAuctionModal() {
    this.auctionDialogOptions.isActive = false;
  }

  private toggleCanCancelAuctionModal(canCancel: boolean) {
    this.auctionDialogOptions.cancelOptions = canCancel ? defaultCancelOptions : false;
  }

  // clicked 'execute' on the auction row to execute the auction immediately (demomode only)
  private async executeAuctionNow(auctionId: string) {
    try {
      await this.executeAuction(auctionId);
    } catch (e) {
      this.$snackbar.error(errorString(e));
    }
  }

  // clicked 'add' on the order row
  private addTicket(auction: BespokeAuction) {
    if (this.isMarketClosed()) {
      return;
    }

    this.ticketDialogOptions.crudAction = RestOperation.Create;
    this.ticketDialogOptions.isActive = true;
    this.joinedAuction = auction;

    // continue to edit the current order?
    if (
      this.ticketBuffer &&
      this.ticketBuffer.auctionId === auction.id &&
      this.ticketBuffer.id === ''
    ) {
      return;
    }

    // create a fresh order
    this.ticketDialog = 'auction-order-dialog';
    this.ticketBuffer = createOrderTicket(auction.id);
    this.stagedTicket = null;
  }

  // clicked 'join' on the invitation
  private joinAuction(auction: BespokeAuction) {
    // the first order is no different from any other order
    this.addTicket(auction);
  }

  // clicked 'edit' on the order row
  private editTicket(auction: BespokeAuction, ticket: OrderTicket): void {
    if (this.isMarketClosed()) {
      return;
    }

    this.ticketDialogOptions.crudAction = RestOperation.Update;
    this.joinedAuction = auction;
    this.ticketDialog = 'auction-order-dialog';
    this.stagedTicket = null;

    // continue to edit the already decrypted order?
    if (
      this.ticketBuffer &&
      this.ticketBuffer.auctionId === auction.id &&
      this.ticketBuffer.id === ticket.id
    ) {
      this.ticketDialogOptions.isActive = true;
      return;
    }

    this.ticketBuffer = this.cloneOrderTicket(auction, ticket);
    this.ticketDialogOptions.isActive = true;
  }

  private cloneOrderTicket(auction: BespokeAuction, ticket: OrderTicket): OrderTicket {
    // clone! do not modify the price of the actual order
    const view = cloneDeep(ticket);

    view.auctionId = auction.id;
    view.orders.forEach((order) => {
      if (order.rate.isZero() && auction.equity && auction.equity.closePrice) {
        order.rate = new Decimal(0);
      }
    });

    return view;
  }

  // clicked 'delete' on the order row
  private async deleteTicket(auction: BespokeAuction, ticket: OrderTicket) {
    if (this.isMarketClosed()) {
      return;
    }

    let leakage = leakNothing();

    // the initiator of the auction may update leaked information if it still known what
    // the direction(bid/ask) was of the first order
    if (
      auction.isOwnCompany &&
      auction.originalDirection &&
      auction.companyOrderTickets.length > 1
    ) {
      // remove the deleted order from the company orders
      const orderList = auction.companyOrderTickets.filter((e) => {
        return e.id !== ticket.id;
      });

      leakage = getBestOrderFromTicket(auction, auction.originalDirection, orderList);
    }

    try {
      await this.deleteAuctionTicket({ id: ticket.id, leakage: leakage });
      this.$snackbar.confirm(i18n.t('OrderDeleted') as string);
    } catch (e) {
      this.$snackbar.error(errorString(e));
    }
  }

  // clicked 'clear' on the order entry
  private clearTicket() {
    if (this.ticketBuffer != null) {
      this.ticketBuffer = createOrderTicket(
        this.ticketBuffer.auctionId,
        this.ticketBuffer.id,
        this.ticketBuffer.direction
      );
      this.ticketRender += 1;
      this.stagedTicket = null;
    }
  }

  // clicked 'Back' on the order confirmation
  private changeTicket() {
    if (this.joinedAuction == null || this.ticketBuffer == null) {
      // nothing to edit? close dialog
      this.closeTicketModal();
      return;
    }
    this.ticketDialog = 'auction-order-dialog';
  }

  // clicked 'Next' on the order entry
  private stageTicket(orderTicket: OrderTicket) {
    this.ticketDialog = 'auction-order-confirmation';
    this.stagedTicket = orderTicket;
  }

  private closeTicketModal() {
    // wipe auction status
    this.ticketDialogOptions.isActive = false;
    this.ticketDialog = 'auction-order-dialog';
    this.ticketBuffer = null;
    this.stagedTicket = null;
  }

  private toggleCanCancelTicketModal(canCancel: boolean) {
    this.ticketDialogOptions.cancelOptions = canCancel ? defaultCancelOptions : false;
  }

  private cancelTicketModal() {
    this.ticketDialogOptions.isActive = false;
  }

  private isMarketClosed(): boolean {
    if (!this.clientConfig.createAuctionsEnabled) {
      this.$snackbar.confirm(i18n.t('market-closed') as string);

      return true;
    }
    return false;
  }
}

const defaultCancelOptions = ['escape', 'outside']; // no 'X' for real FORMs
</script>

<style lang="scss" scoped>
.open-auctions {
  margin-top: 32px;
}

::v-deep {
  .VueTables__child-row {
    border-bottom: 2px solid black;
  }

  .VueTables__no-results {
    display: none;
  }

  .VueTables__child-row-toggler {
    display: none;
  }
}
</style>
