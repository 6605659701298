<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <v-card-text>
      <v-container>
        <v-row class="mt-n8">
          <v-col cols="12">
            <counterparty-search
              v-model="contract.counterparty"
              clearable
              :disabled="contract.displayId !== null"
              :error-messages="errorMsgs['counterparty']"
              label="Counterparty"
              :side="contract.side === 'borrower' ? 'lender' : 'borrower'"
              @change="updateIaAndRoundingRule"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <text-date-picker v-model="contract.startDate" label="Start date" :min="minStartDate" />
          </v-col>
          <v-col cols="6">
            <text-date-picker
              v-model="contract.endDate"
              :error-messages="errorMsgs['endDate']"
              label="End date"
              :min="minEndDate"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <numeric-input
              v-model="contract.notionalValue"
              :error-messages="errorMsgs['notionalValue']"
              label="Value"
              :min="0"
              :precision="valuePrecision"
              :step="1"
              suffix="$"
              type="decimal"
              @blur="$v.contract?.notionalValue?.$touch()"
              @change="
                $v.$reset();
                $v.contract?.notionalValue?.$touch();
              "
            />
          </v-col>
          <v-col cols="6">
            <numeric-input
              v-model="contract.threshold"
              :error-messages="errorMsgs['threshold']"
              label="Threshold"
              :min="0"
              :precision="2"
              :step="1"
              suffix="%"
              type="decimal"
              @blur="$v.contract?.threshold?.$touch()"
              @change="
                $v.$reset();
                $v.contract?.threshold?.$touch();
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <numeric-input
              v-model="contract.rate"
              :error-messages="errorMsgs['rate']"
              label="Rate"
              :precision="ratePrecision"
              :step="0.25"
              suffix="%"
              type="decimal"
              @blur="$v.contract?.rate?.$touch()"
              @change="
                $v.$reset();
                $v.contract?.rate?.$touch();
              "
            />
          </v-col>
          <v-col cols="6">
            <benchmark-select v-model="contract.rateModifier" label="Benchmark" with-no-benchmark />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <numeric-input
              v-model="contract.maxEquityPercent"
              :error-messages="errorMsgs['maxEquityPercent']"
              label="Max Equity Percentage"
              :min="0"
              :precision="2"
              :step="1"
              suffix="%"
              type="decimal"
              @blur="$v.contract?.maxEquityPercent?.$touch()"
              @change="
                $v.$reset();
                $v.contract?.maxEquityPercent?.$touch();
              "
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="d-flex">
      <div class="d-flex flex-grow-1 justify-space-between align-end">
        <v-btn color="secondary" @click="$emit('close-modal')">Cancel</v-btn>
        <v-btn color="primary" type="submit" @click="goToNextStep()">Next</v-btn>
      </div>
    </v-card-actions>
  </span>
</template>

<script lang="ts">
import TextDatePicker from '@/modules/common/components/TextDatePicker.vue';
import {
  MAX_RATE,
  RATE_PRECISION,
  VALUE_MAX,
  VALUE_PRECISION,
} from '@/modules/common/constants/precision';
import { Api } from '@/modules/common/types/api';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import BenchmarkSelect from '@/modules/marketdata/components/BenchmarkSelect.vue';
import { RoundingRule } from '@/modules/sec-lending/helpers/contract-details';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { LoginState } from '@/store/store';
import { formatDecimalAsString } from '@/utils/helpers/auction-numbers';
import { notGreaterThanPrecision } from '@/utils/helpers/custom-validators';
import { addDays, isAfter } from 'date-fns';
import Decimal from 'decimal.js';
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { decimal, maxValue, minValue, required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';

interface FormErrors {
  notionalValue: string[];
  counterparty: string[];
  rate: string[];
  startDate: string[];
  endDate: string[];
  threshold: string[];
  maxEquityPercent: string[];
}

@Component({
  props: {
    contract: {
      type: Object as PropType<NullableAll<Api.TermLoans.ContractSummary>>,
      required: true,
    },
  },
  components: {
    SimpleEquitySearch,
    CounterpartySearch,
    TextDatePicker,
    BenchmarkSelect,
  },
  computed: {
    ...mapState(['loginState']),
  },
  mixins: [validationMixin],
  validations: function (this: TermLoansForm) {
    return {
      contract: {
        counterparty: {
          required,
        },
        notionalValue: {
          decimal,
          required,
          notGreaterThanPrecision: notGreaterThanPrecision(VALUE_PRECISION),
          minValue: minValue(0),
          maxValue: maxValue(VALUE_MAX),
        },
        rate: {
          decimal,
          required,
          notGreaterThanPrecision: notGreaterThanPrecision(RATE_PRECISION),
          minValue: minValue(-MAX_RATE),
          maxValue: maxValue(MAX_RATE),
        },
        threshold: {
          decimal,
          required,
          notGreaterThanPrecision: notGreaterThanPrecision(2),
          minValue: minValue(0),
          maxValue: maxValue(100),
        },
        maxEquityPercent: {
          decimal,
          required,
          notGreaterThanPrecision: notGreaterThanPrecision(2),
          minValue: minValue(0),
          maxValue: maxValue(100),
        },
        endDate: {
          required,
          isAfterStartDate: (value: string) => {
            return isAfter(new Date(value), new Date(this.contract.startDate as Date));
          },
        },
      },
    };
  },
})
export default class TermLoansForm extends mixins(validationMixin) {
  // props
  protected readonly contract!: NullableAll<Api.TermLoans.ContractSummary>;

  // store methods
  protected loginState!: NonNullableAll<LoginState>;

  protected minStartDate = new Date();
  protected ratePrecision = RATE_PRECISION;
  protected valuePrecision = VALUE_PRECISION;
  protected menuStartDate = false;
  protected menuEndDate = false;

  protected get minEndDate(): Date {
    return addDays(this.contract.startDate as Date, 1);
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      rate: [],
      threshold: [],
      maxEquityPercent: [],
      counterparty: [],
      startDate: [],
      endDate: [],
      notionalValue: [],
    };

    if (this.$v.contract?.counterparty?.$dirty) {
      if (!this.$v.contract.counterparty.required) {
        errors.counterparty.push('counterparty is required.');
      }
    }

    if (this.$v.contract?.notionalValue?.$dirty) {
      if (!this.$v.contract.notionalValue.required) {
        errors.notionalValue.push('value is required.');
      }
      if (this.contract.notionalValue && !this.$v.contract.notionalValue.notGreaterThanPrecision) {
        errors.notionalValue.push(`value must not be more than ${VALUE_PRECISION} decimal places.`);
      }
      if (!this.$v.contract.notionalValue.minValue) {
        errors.notionalValue.push(`value cannot be negative.`);
      }
      if (!this.$v.contract.notionalValue.maxValue) {
        errors.notionalValue.push(`value cannot be more than ${this.formatValue(VALUE_MAX)}.`);
      }
    }

    if (this.$v.contract?.rate?.$dirty) {
      if (!this.$v.contract.rate.required) {
        errors.rate.push('rate is required.');
      }
      if (this.contract.rate && !this.$v.contract.rate.notGreaterThanPrecision) {
        errors.rate.push(`rate must not be more than ${RATE_PRECISION} decimal places.`);
      }
      if (!this.$v.contract.rate.minValue) {
        errors.rate.push(`Rate cannot be lower than -${this.formatRate(MAX_RATE)}.`);
      }
      if (!this.$v.contract.rate.maxValue) {
        errors.rate.push(`Rate cannot be more than ${this.formatRate(MAX_RATE)}.`);
      }
    }

    if (this.$v.contract?.threshold?.$dirty) {
      if (!this.$v.contract.threshold.required) {
        errors.threshold.push(`threshold is required.`);
      }
      if (!this.$v.contract.threshold.minValue) {
        errors.threshold.push(`threshold cannot be negative.`);
      }
      if (!this.$v.contract.threshold.maxValue) {
        errors.threshold.push(`threshold cannot be more than 100.`);
      }
    }

    if (this.$v.contract?.maxEquityPercent?.$dirty) {
      if (!this.$v.contract.maxEquityPercent.required) {
        errors.maxEquityPercent.push(`max Equity Percentage is required.`);
      }
      if (!this.$v.contract.maxEquityPercent.minValue) {
        errors.maxEquityPercent.push(`max Equity Percentage cannot be negative.`);
      }
      if (!this.$v.contract.maxEquityPercent.maxValue) {
        errors.maxEquityPercent.push(`max Equity Percentage cannot be more than 100.`);
      }
    }

    if (this.$v.contract?.endDate?.$dirty) {
      if (!this.$v.contract.endDate.required) {
        errors.endDate.push('end date is required.');
      }
      if (!this.$v.contract.endDate.isAfterStartDate) {
        errors.endDate.push('end date must be after start date.');
      }
    }

    return errors;
  }

  protected created(): void {
    if (this.contract.side === 'lender') {
      this.contract.independentAmountRate =
        this.loginState.user.companyPreferredIndependentAmountRate;
      this.contract.roundingRule =
        this.loginState.user.companyPreferredRoundingRule === null
          ? RoundingRule.NoRounding
          : this.loginState.user.companyPreferredRoundingRule;
    }
  }

  protected updateIaAndRoundingRule(): void {
    if (this.contract.side === 'borrower') {
      if (this.contract.counterparty !== null) {
        this.contract.independentAmountRate =
          this.contract.counterparty.companyPreferredIndependentAmountRate || new Decimal(0);
        this.contract.roundingRule =
          this.contract.counterparty.companyPreferredRoundingRule || RoundingRule.NoRounding;
      } else {
        this.contract.independentAmountRate = new Decimal(0);
        this.contract.roundingRule = RoundingRule.NoRounding;
      }
    }
  }

  protected goToNextStep(): void {
    if (this.validateForm()) {
      this.$emit('next');
    }
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  protected formatRate(rate: number): string {
    return formatDecimalAsString(new Decimal(rate), RATE_PRECISION);
  }

  protected formatValue(value: number): string {
    return formatDecimalAsString(new Decimal(value), VALUE_PRECISION);
  }
}
</script>

<style lang="scss" scoped>
.col-6 + .col-6 {
  padding-left: 12px;
}
</style>
