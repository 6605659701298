<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
         with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <v-card-text>
      <v-container v-shortkey="['enter']" @shortkey="submitForm(false)">
        <v-row class="text--primary">
          <v-col class="py-0">
            <h2 class="text-h6 text--secondary">Continue?</h2>
            <term-loans-contract-full-summary :contract="contract" />
            <div class="text-end mt-2 mb-2 pr-2" cols="12">
              <strong>{{ contract.equities.length }}</strong> total securities
            </div>
          </v-col>
        </v-row>

        <v-row v-if="apiErrors.length">
          <v-col class="pa-0 px-1 col-6 offset-3">
            <div class="mt-8 v-alert v-alert--dense text--primary text-body-2 text-center error">
              {{ apiErrors.join('\n') }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions class="d-flex">
      <div class="d-flex flex-grow-1 justify-space-between align-end">
        <v-btn color="secondary" :disabled="formStatus !== 'idle'" @click="$emit('back')">
          Back
        </v-btn>
        <div>
          <v-btn color="secondary" type="submit" @click="submitForm(false)">Save draft</v-btn>
          <v-btn
            class="ml-4"
            color="primary"
            :disabled="formStatus === 'submitting'"
            :loading="formStatus === 'submitting'"
            type="submit"
            @click="submitForm(true)"
          >
            Propose
          </v-btn>
        </div>
      </div>
    </v-card-actions>
  </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { format as dateFormatter } from 'date-fns';
import Decimal from 'decimal.js';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import TermLoansContractFullSummary from '@/modules/termloans/components/TermLoansContractFullSummary.vue';
import { Api } from '@/modules/common/types/api';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';

@Component({
  props: {
    contract: {
      type: Object as PropType<Api.TermLoans.ContractSummary>,
      required: true,
    },
  },
  components: {
    TermLoansContractFullSummary,
  },
})
export default class TermLoansEditContractConfirmation extends Vue {
  // props
  protected readonly contract!: Api.TermLoans.ContractSummary;

  protected formStatus: DialogFormStatus = 'idle';
  protected apiErrors: string[] = [];

  protected async submitForm(isComplete: boolean): Promise<void> {
    if (this.formStatus !== 'idle') {
      return;
    }
    this.formStatus = 'submitting';

    try {
      const payload: Api.TermLoans.ContractRequest = {
        side: this.contract.side,
        cusips: this.contract.equities.map((e) => e.cusip),
        counterpartyId: (this.contract.counterparty as CompanyInfo).companyId,
        startDate: dateFormatter(this.contract.startDate, 'yyyy-MM-dd'),
        endDate: dateFormatter(this.contract.endDate as Date, 'yyyy-MM-dd'),
        isComplete,
        rate: this.contract.rate as Decimal,
        rateModifier: this.contract.rateModifier,
        threshold: this.contract.threshold as Decimal,
        notionalValue: this.contract.notionalValue as Decimal,
        maxEquityPercent: this.contract.maxEquityPercent as Decimal,
      };

      if (this.contract.displayId === null) {
        await this.$api.termLoans.create(payload);
      } else {
        await this.$api.termLoans.update(this.contract.displayId, payload);
      }

      this.$snackbar.show({
        color: 'primary',
        message: `Your contract has been ${isComplete ? 'proposed' : 'saved as draft'}.`,
        timeout: 3000,
      });

      this.$emit('close-modal');
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [`Operation Failed: ${errorMessage}`];
      this.formStatus = 'idle';
    }
  }
}
</script>

<style lang="scss" scoped></style>
