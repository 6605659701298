<template>
  <tr class="sticky-table-footer">
    <td class="text-right" :colspan="Math.min(aggregateColspan, getColspan('aggregate'))">
      <v-select v-model="sumOrAvg" class="text-body-2" dense hide-details :items="aggregateItems" />
    </td>

    <td :colspan="Math.max(0, getColspan('aggregate') - aggregateColspan)">
      <!-- dummy -->
    </td>

    <!-- no aggregate for `statusDisplay` as we need the space for the <v-select> -->

    <td
      :class="[
        { [agg.noun.sides[0] + '-color']: agg.noun.sides.length === 1 },
        columnClassByValue('side'),
        'text-capitalize',
      ]"
      :colspan="getColspan('side')"
      data-test="col-side"
    >
      {{ prettyNoun('sides') }}
    </td>

    <td
      :class="columnClassByValue('counterpartyDisplay')"
      :colspan="getColspan('counterpartyDisplay')"
      data-test="col-counterparty"
    >
      {{ prettyNoun('parties') }}
    </td>

    <td
      :class="columnClassByValue('lenderDisplay')"
      :colspan="getColspan('lenderDisplay')"
      data-test="col-counterparty"
    >
      {{ prettyNoun('lenders') }}
    </td>

    <td
      :class="columnClassByValue('borrowerDisplay')"
      :colspan="getColspan('borrowerDisplay')"
      data-test="col-counterparty"
    >
      {{ prettyNoun('borrowers') }}
    </td>

    <td
      :class="columnClassByValue('settlementType')"
      :colspan="getColspan('settlementType')"
      data-test="col-counterparty"
    >
      {{ prettyNoun('settlementType') }}
    </td>

    <td
      :class="columnClassByValue('equity.ticker')"
      :colspan="getColspan('equity.ticker')"
      data-test="col-security"
    >
      {{ prettyNoun('ticker') }}
    </td>

    <td
      :class="columnClassByValue('equity.cusip')"
      :colspan="getColspan('equity.cusip')"
      data-test="col-security"
    >
      {{ prettyNoun('cusip') }}
    </td>

    <td
      :class="columnClassByValue('createdAt')"
      :colspan="getColspan('createdAt')"
      data-test="col-startDate"
    ></td>

    <td
      :class="columnClassByValue('openQuantity')"
      :colspan="getColspan('openQuantity')"
      data-test="col-openQuantity"
    >
      <pretty-number :value="agg[sumOrAvg].openQuantity" />
    </td>

    <td
      :class="columnClassByValue('returnedQuantityToday')"
      :colspan="getColspan('returnedQuantityToday')"
      data-test="col-returnedQuantityToday"
    >
      <template v-if="agg[sumOrAvg].returnedQuantityToday">
        <pretty-number :value="agg[sumOrAvg].returnedQuantityToday" />
      </template>
    </td>

    <td :class="columnClassByValue('rate')" :colspan="getColspan('rate')" data-test="col-rate">
      <!-- Unique modifier (null counts as a modifier), display the average -->
      <rate-output
        v-if="agg.avg.rateModifiers.length === 1"
        :rate="agg.avg.rate"
        :rate-modifier="agg.avg.rateModifiers[0]"
      />
      <!-- Multiple modifiers, display the number of modifiers -->
      <span v-else>({{ agg.avg.rateModifiers.length }})</span>
    </td>

    <td
      :class="columnClassByValue('contractAmount')"
      :colspan="getColspan('contractAmount')"
      data-test="col-contractAmount"
    >
      <template v-if="agg[sumOrAvg].contractAmount">
        ${{ formatPrice(agg[sumOrAvg].contractAmount) }}
      </template>
    </td>

    <td
      :class="columnClassByValue('independentAmountRate')"
      :colspan="getColspan('independentAmountRate')"
      data-test="col-independentAmountRate"
    ></td>

    <td
      :class="columnClassByValue('settlementAmount')"
      :colspan="getColspan('settlementAmount')"
      data-test="col-settlementAmount"
    >
      <template v-if="agg[sumOrAvg].settlementAmount">
        ${{ formatPrice(agg[sumOrAvg].settlementAmount) }}
      </template>
    </td>

    <td
      :class="columnClassByValue('renegotiateRate')"
      :colspan="getColspan('renegotiateRate')"
      data-test="col-suggestedRate"
    >
      <!-- Only render if loan has a renegotiation -->
      <template v-if="agg.avg.renegotiateRate !== null">
        <rate-output
          v-if="agg.avg.renegotiateRateModifiers.length === 1"
          :rate="agg.avg.renegotiateRate"
          :rate-modifier="agg.avg.renegotiateRateModifiers[0]"
        />
        <span v-else>({{ agg.avg.renegotiateRateModifiers.length }})</span>
      </template>
    </td>

    <td
      :class="columnClassByValue('recalledQuantity')"
      :colspan="getColspan('recalledQuantity')"
      data-test="col-recalledQuantity"
    >
      <template v-if="agg[sumOrAvg].recalledQuantity">
        <pretty-number :value="agg[sumOrAvg].recalledQuantity" />
      </template>
    </td>

    <td
      :class="columnClassByValue('nextAllowedBuyInExecutionDate')"
      :colspan="getColspan('nextAllowedBuyInExecutionDate')"
      data-test="col-due"
    ></td>

    <td
      :class="columnClassByValue('actions')"
      :colspan="getColspan('actions')"
      data-test="col-actions"
    ></td>
  </tr>
</template>

<script lang="ts">
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import { OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import { BrokerOpenLoan } from '@/utils/api/broker';
import { Benchmark } from '@/utils/api/loans';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { ClientConfig } from '@/utils/helpers/rest';
import Decimal from 'decimal.js';
import { cloneDeep } from 'lodash';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapState } from 'vuex';

const zero = new Decimal(0);
const AGGREGATE_COLSPAN = 3;
const COLUMN_NAMES_FOOTER = [
  'aggregate',
  'side',
  'counterpartyDisplay',
  'lenderDisplay',
  'borrowerDisplay',
  'settlementType',
  'equity.ticker',
  'equity.cusip',
  'createdAt',
  'openQuantity',
  'returnedQuantity',
  'returnedQuantityToday',
  'rate',
  'contractAmount',
  'independentAmountRate',
  'settlementAmount',
  'renegotiateRate',
  'recalledQuantity',
  'nextAllowedBuyInExecutionDate',
  'actions',
] as const;
type ColumnNamesFooter = (typeof COLUMN_NAMES_FOOTER)[number];

interface AggregateSum {
  // total/avg quantities
  openQuantity: number;
  recalledQuantity: number;
  returnedQuantity: number;
  returnedQuantityToday: number;
  renegotiateQuantity: number; // only loans under renegotiation!
  contractAmount: Decimal;
  settlementAmount: Decimal;
}

interface AggregateAvg extends AggregateSum {
  // weighted avg rates
  rate: Decimal;
  rateModifiers: Benchmark[];

  // weighted avg rates under renegotiation
  renegotiateRate: Decimal | null;
  renegotiateRateModifiers: Benchmark[];
}

interface AggregateNoun {
  sides: Array<'lender' | 'borrower'>;
  parties: string[];
  lenders: string[];
  borrowers: string[];
  settlementType: string[];
  ticker: string[];
  cusip: string[];
}

const initialSum: AggregateSum = {
  openQuantity: 0,
  recalledQuantity: 0,
  returnedQuantity: 0,
  returnedQuantityToday: 0,
  renegotiateQuantity: 0,
  contractAmount: new Decimal(0),
  settlementAmount: new Decimal(0),
};

const initialAvg: AggregateAvg = {
  ...initialSum,
  rate: zero,
  rateModifiers: [],
  renegotiateRate: null,
  renegotiateRateModifiers: [],
};

const initialNoun: AggregateNoun = {
  sides: [],
  parties: [],
  lenders: [],
  borrowers: [],
  settlementType: [],
  ticker: [],
  cusip: [],
};

// only push if item is not already in array
function pushIfUnique<T>(arr: T[], item: T) {
  if (!arr.includes(item)) {
    arr.push(item);
  }
}

@Component({
  components: {
    BtnDropdown,
    LoanStatusChip,
  },
  props: {
    items: Array as PropType<OpenLoanItem[] | BrokerOpenLoan[]>,
    tableColumns: {
      type: Array as PropType<DataTableHeader[]>,
      default: () => [],
    },
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class OpenLoansAggregates extends Vue {
  // props
  protected readonly items!: OpenLoanItem[] | BrokerOpenLoan[];
  protected readonly tableColumns!: DataTableHeader[];

  protected readonly pricePrecision = PRICE_PRECISION;
  protected readonly aggregateColspan = AGGREGATE_COLSPAN;

  // store state
  protected clientConfig!: ClientConfig;

  protected sumOrAvg: 'sum' | 'avg' = 'sum';
  protected aggregateItems = [
    { text: 'Summary', value: 'sum' },
    { text: 'Average', value: 'avg' },
  ];

  protected get columnClassMap(): Map<string, string> {
    return new Map(
      this.tableColumns.map((col) => {
        // use col.align to build text-* css class (mimic <v-data-table> behaviour)
        // and merge with col.cellClass
        return [col.value, `text-${col.align ? col.align : 'start'} ${col.cellClass || ''}`];
      })
    );
  }

  protected get agg(): {
    sum: AggregateSum;
    avg: AggregateAvg;
    noun: AggregateNoun;
  } {
    const sum = cloneDeep(initialSum);
    const avg = cloneDeep(initialAvg);
    const noun = cloneDeep(initialNoun);

    let cntOpenQuantity = 0;
    let cntRenegotiateQuantity = 0;
    let sumRates = zero;
    let sumRenegotiateRates = zero;

    this.items.forEach((loan) => {
      sum.openQuantity += loan.openQuantity;
      sum.recalledQuantity += loan.recalledQuantity;
      sum.returnedQuantity += loan.returnedQuantity;
      sum.returnedQuantityToday += loan.returnedQuantityToday;
      sum.contractAmount = sum.contractAmount.add(loan.contractAmount);
      sum.settlementAmount = sum.settlementAmount.add(loan.settlementAmount);

      cntOpenQuantity++;

      pushIfUnique(noun.settlementType, loan.settlementType);

      // loan can be either BrokerOpenLoan or OpenLoanItem
      // depending on that push the respective nouns
      if (loan.side) {
        pushIfUnique(noun.sides, loan.side);
        pushIfUnique(noun.parties, loan.counterpartyDisplay);
      } else {
        pushIfUnique(noun.lenders, loan.lenderDisplay);
        pushIfUnique(noun.borrowers, loan.borrowerDisplay);
      }
      pushIfUnique(noun.ticker, loan.equity.ticker);
      pushIfUnique(noun.cusip, loan.equity.cusip);
      pushIfUnique(avg.rateModifiers, loan.rateModifier);

      sumRates = sumRates.add(loan.rate.mul(loan.openQuantity));

      if (loan.renegotiation !== null) {
        pushIfUnique(avg.renegotiateRateModifiers, loan.renegotiation.rateModifier);
        sumRenegotiateRates = sumRenegotiateRates.add(
          loan.renegotiation.rate.mul(loan.openQuantity)
        );
        sum.renegotiateQuantity += loan.openQuantity;
        cntRenegotiateQuantity++;
      }
    });

    avg.openQuantity = cntOpenQuantity ? Math.round(sum.openQuantity / cntOpenQuantity) : 0;
    avg.recalledQuantity = cntOpenQuantity ? Math.round(sum.recalledQuantity / cntOpenQuantity) : 0;
    avg.returnedQuantity = cntOpenQuantity ? Math.round(sum.returnedQuantity / cntOpenQuantity) : 0;
    avg.returnedQuantityToday = cntOpenQuantity
      ? Math.round(sum.returnedQuantityToday / cntOpenQuantity)
      : 0;
    avg.renegotiateQuantity = cntRenegotiateQuantity
      ? Math.round(sum.renegotiateQuantity / cntRenegotiateQuantity)
      : 0;
    avg.contractAmount = cntOpenQuantity ? sum.contractAmount.div(cntOpenQuantity) : new Decimal(0);
    avg.settlementAmount = cntOpenQuantity
      ? sum.settlementAmount.div(cntOpenQuantity)
      : new Decimal(0);

    // NOTE: we only display the average if the `rateModifier` is the same for all selected loans
    avg.rate = sum.openQuantity ? sumRates.div(sum.openQuantity) : zero;

    // NOTE: we only display the average if the `renegotiateRateModifier` is the same for all selected loans
    avg.renegotiateRate = sum.renegotiateQuantity
      ? sumRenegotiateRates.div(sum.renegotiateQuantity)
      : null;

    return { sum, avg, noun };
  }

  protected get columnNamesHeader(): string[] {
    return this.tableColumns.map((column) => column.value);
  }

  protected get columnNamesCommon(): string[] {
    return this.columnNamesHeader.filter((columnName) =>
      COLUMN_NAMES_FOOTER.includes(columnName as ColumnNamesFooter)
    );
  }

  protected getColspan(columnName: ColumnNamesFooter): number {
    const indexHeaderThis = this.columnNamesHeader.indexOf(columnName);
    const indexCommon = this.columnNamesCommon.indexOf(columnName);

    // columns that do not appear in the columns of the parent will have colspan 0 to hide them
    if (columnName !== 'aggregate' && indexCommon < 0) return 0;

    let indexHeaderNext = this.columnNamesHeader.findIndex(
      (name, index) => index > indexHeaderThis && this.columnNamesCommon.includes(name)
    );
    if (indexHeaderNext < 0) indexHeaderNext = this.columnNamesHeader.length;
    return indexHeaderNext - indexHeaderThis;
  }

  protected columnClassByValue(colValue: string): string {
    return this.columnClassMap.get(colValue) || '';
  }

  protected prettyNoun(name: string): string {
    return this.agg.noun[name].length === 1
      ? this.agg.noun[name][0]
      : `(${this.agg.noun[name].length})`;
  }

  protected formatPrice(price: Decimal): string {
    return getPriceAsString(price, this.pricePrecision);
  }
}
</script>

<style lang="scss" scoped>
[colspan='0'] {
  display: none;
}
.sticky-table-footer {
  td {
    // use padding to make the aggregate-footer row exactly the same height as the page-footer
    // this way the table won't resize if you toggle the selected items
    padding-top: 13px !important;
    padding-bottom: 14px !important;
    position: sticky;
    bottom: 0;
    &:last-child {
      // override last-col-sticky from parent container
      border-left: 0 !important;
      right: auto !important;
      width: auto !important;
    }
  }
}
.theme--light {
  .sticky-table-footer td {
    border-top: solid rgba(0, 0, 0, 0.12) 1px;
    color: #1e1e1e;
    background-color: white;
  }
}
.theme--dark {
  .sticky-table-footer td {
    border-top: solid #393939 1px;
    color: rgba(255, 255, 255, 0.7);
    background-color: #1e1e1e;
  }
}
</style>
