<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    not-persistent
    overlay-color="secondary"
    overlay-opacity="0.80"
    :value="true"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ shouldCounterOffer ? 'Reject & Counter Rerate' : ' Rerate' }}
          </span>
          <v-spacer></v-spacer>
          <span class="text--primary headline-2">{{ equityDescription }}</span>
        </v-card-title>

        <v-card-text>
          <!-- Input fields -->
          <v-container class="pl-12">
            <v-row v-if="shouldCounterOffer" align="center">
              <v-col class="col-4 py-1 text-capitalize"> Suggested Rate by Contra</v-col>
              <v-col class="py-0">
                <rate-output
                  :rate="loan.renegotiation?.rate"
                  :rate-modifier="loan.renegotiation?.rateModifier"
                />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize"> Counterparty</v-col>
              <v-col class="py-0">{{ loan.counterpartyDisplay }}</v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize">
                {{ $t('Open Quantity') }}
              </v-col>
              <v-col class="py-0">
                <pretty-number :value="loan.openQuantity" />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize"> Current Rate</v-col>
              <v-col class="py-0">
                <rate-output :rate="loan.rate" :rate-modifier="loan.rateModifier" />
              </v-col>
            </v-row>

            <v-row align="center" class="pt-6">
              <v-col class="col-4 py-1 text-capitalize">Rate Type</v-col>
              <v-col class="py-0">
                <v-select
                  ref="data-test-rate-type"
                  v-model="rateType"
                  :items="['Fixed', 'Floating']"
                  @change="onChangeRateType($event)"
                />
              </v-col>
            </v-row>

            <v-row v-if="rateType === 'Floating'" align="center">
              <v-col class="col-4 py-1 text-capitalize">Benchmark</v-col>
              <v-col class="py-0">
                <benchmark-select
                  v-model="newRateModifier"
                  :error-messages="errorMsgs.newRateModifier"
                  @blur="$v.newRateModifier.$touch()"
                  @change="onChangeRateModifier($event)"
                  @input="$v.newRateModifier.$touch()"
                />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize">
                Suggest new {{ rateType === 'Floating' ? 'offset' : 'rate' }}
              </v-col>
              <v-col class="py-0">
                <numeric-input
                  v-model="newRate"
                  autofocus
                  :error-messages="errorMsgs['newRate']"
                  :label="rateType === 'Floating' ? 'offset' : 'rate'"
                  placeholder="0.0000"
                  :precision="{ min: 4 }"
                  :step="0.25"
                  suffix="%"
                  type="decimal"
                  @change="onChangeRate()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0 px-1" offset="4">
                <div
                  class="v-alert v-alert--dense text-center"
                  :class="{ error: errorMsgs.apiErrors.length, success: showSuccess }"
                >
                  <div v-if="errorMsgs.apiErrors.length">
                    {{ errorMsgs.apiErrors.join('\n') }}
                  </div>
                  <div v-if="showSuccess">Your rate change has been sent.</div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Dialog box actions -->
        <v-card-actions>
          <v-btn
            color="secondary"
            data-test="cancel-btn"
            :disabled="formStatus !== 'idle'"
            @click="closeDialog"
          >
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <aurora-btn
            color="primary"
            :disabled="formStatus !== 'idle'"
            :loading="formStatus === 'submitting'"
            timeframe="rateModLoans"
            type="submit"
            @click="submitForm"
          >
            Submit
          </aurora-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import Decimal from 'decimal.js';
import { MAX_RATE, RATE_PRECISION } from '@/modules/common/constants/precision';
import { Benchmark, RenegotiateSide } from '@/utils/api/loans';
import { validationMixin } from 'vuelidate';
import {
  and,
  decimal,
  maxValue,
  minValue,
  not,
  required,
  requiredIf,
} from 'vuelidate/lib/validators';
import wait from '@/modules/common/services/wait';
import {
  notGreaterThanPrecision,
  sameDecimals,
  sameValues,
} from '@/utils/helpers/custom-validators';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import { LenderOpenLoan } from '@/utils/api/lender';
import { BorrowerOpenLoan } from '@/utils/api/borrower';
import BenchmarkSelect from '@/modules/marketdata/components/BenchmarkSelect.vue';
import { RateType } from '@/modules/marketdata/types/marketdata';
import { PropType } from 'vue';
import { ApiError } from '@/utils/errors';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { formatDecimalAsString } from '@/utils/helpers/auction-numbers';

interface FormErrors {
  apiErrors: string[];
  newRate: string[];
  newRateModifier: string[];
}

@Component({
  components: {
    BenchmarkSelect,
  },
  props: {
    loan: Object as PropType<LenderOpenLoan | BorrowerOpenLoan | null>,
    shouldCounterOffer: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [validationMixin],
  validations: {
    newRateModifier: {
      required: requiredIf((vm: RenegotiateDialog) => vm.rateType === 'Floating'),
    },
    newRate: {
      decimal,
      required,
      notSameAsPreviousRate: not(
        and(sameDecimals('oldRate'), sameValues('oldRateModifier', 'newRateModifier'))
      ),
      notSameAsProposedRate: not(sameDecimals('proposedRate')),
      notGreaterThanPrecision: notGreaterThanPrecision(RATE_PRECISION),
      minValue: minValue(-MAX_RATE),
      maxValue: maxValue(MAX_RATE),
    },
  },
})
export default class RenegotiateDialog extends mixins(validationMixin) {
  // props
  protected readonly loan!: LenderOpenLoan | BorrowerOpenLoan;
  protected readonly shouldCounterOffer!: boolean;

  protected ratePrecision = RATE_PRECISION;
  protected newRate: Decimal | null = null;
  protected rateType: RateType = 'Fixed';
  protected newRateModifier = Benchmark.NoBenchmark;
  protected formStatus: DialogFormStatus = 'idle';
  protected apiErrors: string[] = [];
  protected showSuccess = false;

  protected get oldRate(): Decimal {
    return this.loan.rate;
  }

  protected get oldRateModifier(): Benchmark {
    return this.loan.rateModifier;
  }

  protected get proposedRate(): Decimal | null {
    if (this.loan === null || this.loan.renegotiation === null) {
      return null;
    }
    return this.loan.renegotiation.rate;
  }

  protected get equityDescription(): string {
    return `${this.loan.equity.name} / ${this.loan.equity.ticker} [${this.loan.equity.cusip}]`;
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      newRate: [],
      newRateModifier: [],
    };

    // rate errors
    if (this.$v.newRate.$dirty) {
      if (!this.$v.newRate.required) {
        errors.newRate.push(`${this.rateOrOffset()} is required.`);
      }
      if (!this.$v.newRate.notGreaterThanPrecision) {
        errors.newRate.push(`Rate must not be more than ${RATE_PRECISION} decimal places.`);
      }
      if (!this.$v.newRate.notSameAsPreviousRate) {
        errors.newRate.push(this.$tc('rest.renegotiate.sameRateErr'));
      }
      if (!this.$v.newRate.notSameAsProposedRate) {
        errors.newRate.push(this.$tc('rest.renegotiate.sameProposedRateErr'));
      }
      if (!this.$v.newRate.minValue) {
        errors.newRate.push(`Rate cannot be lower than -${this.formatRate(MAX_RATE)}%.`);
      }
      if (!this.$v.newRate.maxValue) {
        errors.newRate.push(`Rate cannot be more than ${this.formatRate(MAX_RATE)}%.`);
      }
    }

    // validate rateModifier
    if (this.$v.newRateModifier.$dirty) {
      if (!this.$v.newRateModifier.required) errors.newRateModifier.push('Benchmark is required.');
    }

    return errors;
  }

  protected created(): void {
    // clone the oldRate and oldRateModifier to populagte the form
    this.newRate = new Decimal(this.oldRate);
    this.newRateModifier = this.oldRateModifier;
    this.rateType = this.newRateModifier === '' ? 'Fixed' : 'Floating';
  }

  protected onChangeRate(): void {
    // clear any previous API errors
    this.apiErrors = [];
    this.$v.newRate.$touch();
  }

  protected onChangeRateModifier(rateModifier: Benchmark): void {
    this.newRateModifier = rateModifier;
  }

  protected onChangeRateType(rateType: RateType): void {
    this.rateType = rateType;
    if (this.rateType === 'Fixed') {
      this.newRate = this.oldRate;
      this.newRateModifier = Benchmark.NoBenchmark;
    } else {
      this.newRate = null;
      this.$v.newRate.$reset();
    }
  }

  protected async submitForm(): Promise<void> {
    // run validation
    if (this.formStatus !== 'idle' || !this.validateForm()) {
      return;
    }

    this.formStatus = 'submitting';

    try {
      const payload = {
        side: this.loan.side as RenegotiateSide,
        loanId: this.loan.id,
        rate: this.newRate as Decimal,
        rateModifier: this.newRateModifier,
      };

      if (this.shouldCounterOffer) {
        await this.$api.openLoans.rejectRenegotiateAndCounter(this.loan, payload);
      } else {
        await this.$api.openLoans.postRenegotiate(payload);
      }

      // success, alert user and then close form
      this.showSuccess = true;
      this.formStatus = 'closing';

      await wait(1200);
      this.closeDialog();
    } catch (err) {
      const errorMessage = new ApiError(i18nServerMessage(err as Error)).message;
      this.apiErrors = [errorMessage];
    } finally {
      this.formStatus = 'idle';
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected formatRate(rate: number): string {
    return formatDecimalAsString(new Decimal(rate), this.ratePrecision);
  }

  private rateOrOffset(): string {
    return this.rateType === 'Fixed' ? 'Rate' : 'Offset';
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }
}
</script>
<style lang="scss" scoped></style>
