/*
 *  price-alignment.ts
 *
 *  describe the max length of the integral- and the max length of the fractional part for a set of prices
 */
import { BespokeAuction, ClosedAuction } from '@/utils/helpers/rest';
import { formatDecimalAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { RATE_PRECISION } from '@/modules/common/constants/precision';

export class PriceAlignment {
  public integralLen: number;
  public fractionalLen: number;

  public constructor(integralLen: number, fractionalLen: number) {
    this.integralLen = integralLen;
    this.fractionalLen = fractionalLen;
  }
}

export function getMaxPriceAlignment(pas: PriceAlignment[]): PriceAlignment {
  const res = new PriceAlignment(0, 0);

  pas.forEach((p) => {
    res.integralLen = Math.max(res.integralLen, p.integralLen);
    res.fractionalLen = Math.max(res.fractionalLen, p.fractionalLen);
  });

  return res;
}

export function getPriceFractionalLength(tickSize: number): number {
  const d = new Decimal(tickSize);
  return d.decimalPlaces();
}

export function getDecimalIntegralLength(d: Decimal): number {
  return formatDecimalAsString(d, 0).length;
}

// for 'open' auctions, the display format is enforced by the max of all 'leaked' auction prices
export function getOpenAuctionRateAlignment(auctions: BespokeAuction[]): PriceAlignment {
  // max leaked-price determines the size of the integrals in the limit column
  // @TODO what happens when leakedRate is null? case is not tested
  const maxLeaked = auctions.reduce(
    (max: Decimal, a: BespokeAuction) =>
      a.isLeakedRate && a.leakedRate?.greaterThan(max) ? a.leakedRate : max,
    new Decimal(0)
  );

  // return the max length for each decimal part of the order prices
  return new PriceAlignment(getDecimalIntegralLength(maxLeaked), RATE_PRECISION);
}

// for 'closed' auctions, the display format is enforced by the max of our closed orders and all crossing prices
export function getClosedAuctionRateAlignment(auctions: ClosedAuction[]): PriceAlignment {
  // max crossing-price determines the size of the integrals in the crossing column
  const maxCrossing = auctions.reduce(
    (max: Decimal, a: ClosedAuction) =>
      a.crossingRate && a.crossingRate.greaterThan(max) ? a.crossingRate : max,
    new Decimal(0)
  );

  // max crossing-price determines the size of the integrals in the rate column
  const maxRate = auctions.reduce(
    (max1, a) =>
      a.companyOrderTickets.reduce(
        (max2, t) => t.orders.reduce((max3, o) => (o.rate.greaterThan(max3) ? o.rate : max3), max2),
        max1
      ),
    new Decimal(0)
  );

  return new PriceAlignment(
    Math.max(getDecimalIntegralLength(maxCrossing), getDecimalIntegralLength(maxRate)),
    RATE_PRECISION
  );
}
