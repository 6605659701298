<template>
  <v-card class="flex d-flex flex-column">
    <!-- Dialog boxes -->
    <create-borrower-order-dialog
      v-if="showCreateDialog"
      :selected-items.sync="selectedItems"
      @close-modal="showCreateDialog = false"
    />

    <delete-borrower-order-dialog
      v-if="showDeleteDialog"
      :selected-items.sync="selectedItems"
      @close-modal="showDeleteDialog = false"
    />

    <toggle-borrowability-dialog
      v-if="showBorrowabilityDialog"
      :selected-items.sync="selectedItems"
      @close-modal="showBorrowabilityDialog = false"
    />

    <new-loan-from-demand-dialog
      v-if="showBorrowDialog"
      :demand-list-item="selectedItems[0]"
      @close-modal="
        showBorrowDialog = false;
        selectedItems = [];
      "
    />

    <!-- File upload dialog box -->
    <upload-progress-popup
      :close-on-success="false"
      content-class="borrower-orders-upload"
      :display-status.sync="isUploading"
      :progress-promise="uploadProgressPromise"
      :width="500"
      @uploadAgain="uploadAgain"
    >
      <!-- success display slot -->
      <template #success="{ uploadResult }">
        <div class="v-alert v-alert-dense success--text text-center">
          <b>{{ uploadResult.count }}</b> demand items have been created
        </div>
      </template>

      <!-- error display slot -->
      <template #error="{ errorMsg, uploadMsgs, showError, showWarning }">
        <div
          class="v-alert v-alert-dense text-center"
          :class="{ 'error--text': showError, 'warning--text': showWarning }"
        >
          {{ errorMsg }}
        </div>
        <div
          v-if="uploadMsgs"
          class="file-upload-summary col overflow-x-hidden overflow-y-auto text-center"
        >
          <v-row v-for="(err, idx) in uploadMsgs" :key="idx" class="pb-1">
            <v-col class="py-0">Row {{ err.row }}, Column {{ err.col }}: {{ err.errMsg }}</v-col>
          </v-row>
        </div>
      </template>
    </upload-progress-popup>

    <v-data-table
      ref="tableRef"
      v-model="selectedItems"
      class="d-flex flex flex-column"
      :custom-filter="searchableTableFilter"
      dense
      fixed-header
      :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
      :headers="tableHeaders"
      item-key="id"
      :items="formattedBorrowerOrders"
      :items-per-page="50"
      :search="search"
      :show-select="hasTraderUserRole"
      sort-asc
      sort-by="equity.ticker"
    >
      <!-- Top bar above table  -->
      <template #top>
        <div class="d-flex flex-row">
          <!-- Left-most button group -->
          <div class="col col-auto d-flex flex-column justify-space-between">
            <div class="d-flex">
              <div class="d-flex flex-row">
                <v-btn
                  class="new-order mr-6"
                  color="secondary"
                  :disabled="!hasTraderUserRole"
                  @click.stop="onCreateOrder()"
                >
                  Add Demand
                </v-btn>

                <div class="d-flex flex-row align-center">
                  <!-- CSV File upload -->
                  <div class="d-flex" title="Upload Demand CSV">
                    <v-btn :disabled="!hasTraderUserRole" icon :ripple="false" small text>
                      <v-file-input
                        v-model="fileUpload"
                        :accept="uploadFileTypes"
                        class="pt-0 mt-0 justify-center"
                        color="primary"
                        :disabled="isUploading"
                        hide-input
                        prepend-icon="mdi-upload"
                        @change="onChangeFileUpload(fileUpload, 'change')"
                      />
                    </v-btn>
                  </div>

                  <!-- Batch actions -->
                  <v-btn
                    v-if="clientConfig.demoMode"
                    class="mr-1"
                    :disabled="!hasTraderUserRole || !selectedItems.length"
                    icon
                    small
                    :title="$t('orders.batchActions.toggleAvailability')"
                    @click="onBatchEditBorrowability(selectedItems)"
                  >
                    <v-icon>mdi-hand-right</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-1"
                    :disabled="!hasTraderUserRole || !selectedItems.length"
                    icon
                    small
                    :title="$t('orders.batchActions.deleteOrders')"
                    @click="onBatchDeleteOrders(selectedItems)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <div class="d-flex align-end selected-items-count">
              <transition name="fade">
                <small v-if="selectedItems.length">
                  {{
                    selectedItems.length
                      ? `${selectedItems.length} of ${formattedBorrowerOrders.length} selected`
                      : ''
                  }}
                </small>
              </transition>
            </div>
          </div>

          <!-- filter box -->
          <div class="d-flex col-md-2 ml-auto search">
            <v-text-field
              v-model="search"
              clearable
              dense
              label="Filter list"
              placeholder="filter..."
              prepend-inner-icon="mdi-magnify"
            />
          </div>
        </div>
      </template>

      <!-- Table rows -->
      <template #[`item.isBorrowableDisplay`]="{ item }">
        <v-chip v-if="item.expiredAt" class="justify-center" color="warning" small>
          expired
        </v-chip>
        <v-chip v-else :class="item.isBorrowable ? 'status-enabled' : 'status-disabled'" small>
          {{ item.isBorrowableDisplay }}
        </v-chip>
      </template>

      <template #[`item.quantity`]="{ item }">
        <pretty-number :value="item.quantity" />
      </template>

      <template #[`item.filledQuantity`]="{ item }">
        <pretty-number :value="item.filledQuantity" />
      </template>

      <template #[`item.rate`]="{ item }">
        <rate-output :rate="item.rate" />
      </template>

      <template #[`item.timeInForce`]="{ item }">
        <span>{{ $tc(`orders.timeInForce.options.${item.timeInForce}`) }}</span>
      </template>

      <!-- Row item actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex flex-row justify-end align-center row-actions">
          <aurora-btn
            v-if="!item.expiredAt"
            class="ml-1 mr-2 action-borrow"
            color="secondary"
            :disabled="!hasTraderUserRole || isProcessing || !item.isBorrowable"
            timeframe="createLoans"
            title="create a new loan"
            x-small
            @click="onCreateLoan(item)"
          >
            Borrow
          </aurora-btn>
          <v-btn
            v-if="clientConfig.demoMode && !item.expiredAt"
            class="ml-1 action-toggle"
            :disabled="!hasTraderUserRole || isProcessing"
            icon
            small
            title="enable/disable order"
            @click="onToggleBorrowability(item, !item.isBorrowable)"
          >
            <!-- <v-icon>mdi-cancel</v-icon> -->
            <v-icon>mdi-hand-right</v-icon>
          </v-btn>
          <v-btn
            v-if="!item.expiredAt"
            class="ml-1 action-edit"
            :disabled="!hasTraderUserRole || isProcessing"
            icon
            small
            title="edit order"
            @click="onEditOrder(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="ml-1 action-delete"
            :disabled="!hasTraderUserRole || isProcessing"
            icon
            small
            title="delete order"
            @click="onBatchDeleteOrders([item])"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script lang="ts">
import i18n from '@/localisation/i18n';
import UploadProgressPopup from '@/modules/common/components/UploadProgressPopup.vue';
import { Api } from '@/modules/common/types/api';
import CreateBorrowerOrderDialog from '@/modules/order-management/components/CreateBorrowerOrderDialog.vue';
import DeleteBorrowerOrderDialog from '@/modules/order-management/components/DeleteBorrowerOrderDialog.vue';
import NewLoanFromDemandDialog from '@/modules/order-management/components/NewLoanFromDemandDialog.vue';
import ToggleBorrowabilityDialog from '@/modules/order-management/components/ToggleBorrowabilityDialog.vue';
import {
  BorrowerOrderListDisplayItem,
  BorrowerOrderListItem,
} from '@/modules/order-management/types/borrower-orders';
import { AppState } from '@/store/store';
import { searchableTableFilter } from '@/utils/helpers/searchable-fields';
import Vue from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapGetters, mapState } from 'vuex';

const tableHeaders: DataTableHeader[] = [
  {
    text: i18n.tc('Status'),
    value: 'isBorrowableDisplay',
    class: 'text-truncate row-icon status',
    cellClass: 'text-truncate row-icon status',
  },
  {
    text: i18n.tc('Order ID'),
    value: 'displayId',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: 200,
  },
  {
    text: i18n.tc('Ticker'),
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('CUSIP'),
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('Demand Quantity'),
    value: 'quantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('Filled'),
    value: 'filledQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('Limit'),
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
  },
  {
    text: i18n.tc('orders.timeInForce.title'),
    value: 'timeInForce',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
  },
  // empty column to control space and alignment
  {
    text: '',
    value: '',
    sortable: false,
    width: 150,
  },
  {
    text: i18n.tc('Actions'),
    value: 'actions',
    align: 'end',
    sortable: false,
    filterable: false,
    width: 120,
  },
];

@Component({
  components: {
    CreateBorrowerOrderDialog,
    DeleteBorrowerOrderDialog,
    ToggleBorrowabilityDialog,
    UploadProgressPopup,
    NewLoanFromDemandDialog,
  },
  methods: {
    ...mapActions(['fetchBorrowerOrders']),
  },
  computed: {
    ...mapState(['borrowerOrders', 'clientConfig']),
    ...mapGetters(['hasTraderUserRole']),
  },
})
export default class BorrowerOrdersList extends Vue {
  // store methods
  protected fetchBorrowerOrders!: () => Promise<void>;

  // store state
  protected hasTraderUserRole!: boolean;

  protected borrowerOrders!: AppState['borrowerOrders'];
  protected readonly clientConfig!: AppState['clientConfig'];

  protected showCreateDialog = false;
  protected showEditDialog = false;
  protected showDeleteDialog = false;
  protected showBorrowDialog = false;
  protected showBorrowabilityDialog = false;
  protected isProcessing = false;
  protected tableHeaders = tableHeaders;
  protected searchableTableFilter = searchableTableFilter;
  protected selectedItems: BorrowerOrderListItem[] = [];
  protected itemToEdit: BorrowerOrderListItem | null = null;
  protected search = '';
  /**
   * possible file types that can be uploaded
   */
  protected readonly uploadFileTypes = '.csv, .tsv, .xls, .xlsx, .dummy, text/*';
  protected isUploading = false;
  protected fileUpload: File | null = null;
  protected lastFileUpload: File | null = null;
  protected uploadProgressPromise: Promise<Api.BorrowerOrders.BorrowerOrderUploadResponse> | null =
    null;

  /**
   * Get a formatted version of borrower Orders list
   */
  protected get formattedBorrowerOrders(): BorrowerOrderListDisplayItem[] {
    return this.borrowerOrders.map((item) => {
      const isBorrowableDisplay = item.isBorrowable ? 'active' : 'disabled';

      return {
        ...item,
        isBorrowableDisplay,
      };
    });
  }

  protected mounted(): void {
    // DO NOT REMOVE or EDIT unless you understand
    // https://provablelabs.atlassian.net/browse/AURORA-1480
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.tableRef as any).multipleSelect = () => {
      /* */
    };

    void this.fetchBorrowerOrders();
  }

  /**
   * Create a new Borrower Order via a dialog box
   */
  protected onCreateOrder(): void {
    this.selectedItems = [];
    this.showCreateDialog = true;
  }

  /**
   * Edit an existing Borrower Order via a dialog box
   */
  protected onEditOrder(item: BorrowerOrderListItem): void {
    this.selectedItems = [item];
    this.showCreateDialog = true;
  }

  /**
   * Create a new loan for the given Borrower Order via a dialog box
   */
  protected onCreateLoan(item: BorrowerOrderListItem): void {
    this.selectedItems = [item];
    this.showBorrowDialog = true;
  }

  /**
   * Delete multiple Borrower Orders via a dialog box
   */
  protected onBatchDeleteOrders(items: BorrowerOrderListItem[]): void {
    this.selectedItems = items;
    this.showDeleteDialog = true;
  }

  /**
   * Toggle borrowability of multiple Borrower Orders via a dialog box
   */
  protected onBatchEditBorrowability(items: BorrowerOrderListItem[]): void {
    this.selectedItems = items.filter((item) => !item.expiredAt);
    if (this.selectedItems.length) {
      this.showBorrowabilityDialog = true;
    }
  }

  /**
   * Enable or disable borrowing of a Borrow Orders
   */
  protected async onToggleBorrowability(
    item: BorrowerOrderListItem,
    state: boolean
  ): Promise<void> {
    this.isProcessing = true;
    try {
      const batchChanges = { id: item.id, isBorrowable: state };
      await this.$api.orderManagement.batchUpdateBorrowerOrderBorrowability([batchChanges]);
      await this.fetchBorrowerOrders();
      this.$snackbar.confirm(`This demand item has been ${state ? 'enabled' : 'disabled'}`);
    } catch (err) {
      // show a popup if the action fails
      this.$snackbar.error(`${err}`);
    } finally {
      this.isProcessing = false;
    }
  }

  /**
   * Handles file upload for batch creation of new Borrower Orders
   */
  protected onChangeFileUpload(): void {
    if (!this.fileUpload) {
      return;
    }

    // only confirm if there are orders
    if (this.borrowerOrders.length) {
      this.$dialog.confirm({
        title: 'Are you sure?',
        message: 'Uploading new demand replaces existing demand',
        isRejectable: true,
        rejectText: 'Cancel',
        acceptText: 'Replace',
        onAccept: this.uploadFile,
        onReject: () => {
          // clear file upload to allow for new input
          this.fileUpload = null;
          this.lastFileUpload = null;
        },
      });
    } else {
      void this.uploadFile();
    }
  }

  protected uploadAgain(): void {
    this.fileUpload = this.lastFileUpload;
    void this.uploadFile(true);
  }

  private async uploadFile(skipWarnings = false): Promise<void> {
    // post the file for parsing and show results in the progress dialog box
    const promise = this.$api.orderManagement.uploadBorrowerOrdersFile(
      this.fileUpload as File,
      skipWarnings
    );
    this.uploadProgressPromise = promise;

    try {
      await promise;
      void this.fetchBorrowerOrders();
    } catch (err) {
      // Do nothing; the upload dialog handles the promise
    }

    // clear file upload to allow for new input
    this.lastFileUpload = this.fileUpload;
    this.fileUpload = null;
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .selected-items-count {
    min-height: 2rem;

    small {
      font-size: 0.75rem;
    }
  }

  // remove extra margin on file input icon
  .v-btn .v-file-input .v-input__prepend-outer {
    margin: 0;
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }

    tr {
      .row-actions button:not(.action-borrow) {
        opacity: 0.4;
        pointer-events: none;
        transition: opacity linear 0.15s;
      }

      &:hover .row-actions button {
        opacity: 1;
        pointer-events: inherit;
      }
    }
  }

  // enhance status column
  th.row-icon.status {
    padding-left: 29px !important;
  }

  // @TODO use sass variables to store these colours
  .v-chip {
    &.status-enabled {
      padding: 0 16px;
      color: #fff;
      background: #4caf50;
    }

    &.status-disabled {
      color: #fff;
      background: #7a7a7a;
    }
  }
}
</style>

<style lang="scss" scoped>
.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }
}

// Borrower Orders CSV Upload dialog
.borrower-orders-upload {
  .file-upload-summary {
    max-height: 20rem;
  }
}
</style>
