<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="800"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistents
    :value="true"
    @click:outside="closeDialog()"
    @keydown.esc="closeDialog()"
    @shortkey.native="closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card v-shortkey="['enter']" class="form-summary" @shortkey="submitForm()">
        <v-card-title class="mb-0">
          <div class="d-flex align-center">
            <span class="headline"> Modify {{ itemsToBeModified.length }} orders </span>
          </div>
          <v-spacer />
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-alert v-if="failedOrderRefs.length" dense type="error">
            {{ itemsToBeModified.length - failedOrderRefs.length }}
            {{ itemsToBeModified.length - failedOrderRefs.length > 1 ? 'actions' : 'action' }}
            succeeded / {{ failedOrderRefs.length }} failed (marked in red below)
          </v-alert>
          <v-card-text v-else>
            <div class="p-4">
              <v-row dense>
                <v-col cols="2">
                  <numeric-input
                    v-model="newRate"
                    :error-messages="errorMsgs['rate']"
                    label="Rate"
                    :precision="ratePrecision"
                    :step="0.25"
                    suffix="%"
                    type="decimal"
                  />
                </v-col>
                <v-col cols="5">
                  <counterparty-search
                    v-model="newCounterparty"
                    clearable
                    label="Counterparty"
                    :prepend-item="prependCounterparty"
                  />
                </v-col>
                <v-col cols="2">
                  <numeric-input
                    v-model="newMinQty"
                    :error-messages="errorMsgs.newMinQty"
                    label="Min. Quantity"
                    :max="smallestRemainingQuantityInBatchSelection"
                    :min="1"
                    :step="100"
                    type="integer"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="newRoutingStatus"
                    item-text="label"
                    item-value="value"
                    :items="routedOptions"
                    label="Activate/Deactivate"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-container>
            <v-divider />
            <div class="table-container">
              <v-data-table
                disable-filtering
                disable-pagination
                disable-sort
                fixed-header
                :headers="[
                  { text: 'Side' },
                  { text: 'Ticker' },
                  { text: 'CUSIP' },
                  { text: 'Counterparty', align: 'center' },
                  { text: 'Executed', align: 'end' },
                  { text: 'Quantity', align: 'end' },
                  { text: 'Rate', align: 'end' },
                ]"
                hide-default-footer
                item-key="key"
                :items="itemsToBeModified"
                width="100%"
              >
                <template #item="{ item }">
                  <tr :class="{ 'error--text': failedOrderRefs.includes(item.orderRef) }">
                    <td>
                      <format-side :side="item.side" />
                    </td>
                    <td>{{ item.equity.ticker }}</td>
                    <td>{{ item.equity.cusip }}</td>
                    <td class="text-center">
                      <span v-if="!item.counterparties">-</span>
                      <span v-else>{{
                        item.counterparties.map(formatCompanyBoxId).join(', ')
                      }}</span>
                    </td>
                    <td class="text-end">
                      <span v-if="item.filled === 0">–</span>
                      <span v-else>
                        <pretty-number :value="item.filled" />
                      </span>
                    </td>
                    <td class="text-end">
                      <pretty-number :value="item.quantity" />
                    </td>
                    <td class="text-end">
                      <rate-output :rate="item.rate" />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-container>

          <v-row v-if="showError || showSuccess || formError">
            <v-col class="pa-0 px-1 pt-4 col-6 offset-3">
              <div
                class="v-alert v-alert--dense text--primary text-body-2 text-center"
                :class="{ error: showError || formError, success: showSuccess }"
              >
                <div v-if="formError">
                  {{ formError }}
                </div>
                <div v-if="showError">
                  {{ errorMsgs.apiErrors.join('\n') }}
                </div>
                <div v-if="showSuccess">Modify all successfully initiated.</div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex px-8 py-4">
          <div v-if="failedOrderRefs.length" class="d-flex flex-grow-1 justify-end align-end">
            <v-btn color="primary" data-test="close-btn" @click="closeDialog()">Back</v-btn>
          </div>
          <div v-else class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" data-test="cancel-btn" @click="closeDialog">Back</v-btn>
            <v-btn
              color="primary"
              :disabled="formStatus !== 'idle'"
              :loading="formStatus === 'submitting'"
              type="submit"
              @click="submitForm()"
            >
              Confirm
            </v-btn>
          </div>
        </v-card-actions>
        <v-progress-linear v-if="formStatus !== 'idle'" color="primary" indeterminate />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import wait from '@/modules/common/services/wait';
import { Api } from '@/modules/common/types/api';
import { DialogFormStatus } from '@/modules/common/types/dialog';
import { RoutingStatus } from '@/modules/marketplace/types/marketplace';
import CounterpartySearch from '@/modules/user-accounts/components/CounterpartySearch.vue';
import { formatCompanyBoxId } from '@/modules/user-accounts/helpers/user-accounts';
import { CompanyInfo } from '@/modules/user-accounts/types/user-accounts';
import Decimal from 'decimal.js';
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { integer, maxValue } from 'vuelidate/lib/validators';

interface FormErrors {
  apiErrors: string[];
  newMinQty: string[];
}

@Component({
  props: {
    items: Array as PropType<Api.Marketplace.Order[]>,
    execute: Function,
  },
  components: {
    CounterpartySearch,
  },
  mixins: [validationMixin],
  validations: function (this: MarketplaceBatchModifyAllDialog) {
    return {
      newMinQty: {
        integer,
        maxValue: maxValue(this.smallestRemainingQuantityInBatchSelection),
      },
    };
  },
})
export default class MarketplaceBatchModifyAllDialog extends mixins(validationMixin) {
  protected routedOptions: Array<{ label: string; value: RoutingStatus }> = [
    { label: 'Activate', value: 'routed' },
    { label: 'Deactivate', value: 'unrouted' },
  ];

  protected newRate: Decimal | null = null;

  // We want to `lock in` the items list once we are in the modify flow in case something changes it in the parent
  // component, so we disconnect it from the incoming prop. See mounted().
  protected readonly items!: Api.Marketplace.Order[];
  protected itemsToBeModified: Api.Marketplace.Order[] = [];

  protected readonly successMessage!: string;
  protected ratePrecision = RATE_PRECISION;
  protected newMinQty: number | null = null;
  protected newRoutingStatus: RoutingStatus | null = null;
  protected showSuccess = false;
  protected apiErrors: string[] = [];
  protected formStatus: DialogFormStatus = 'idle';
  protected formError: string | null = null;
  protected failedOrderRefs: string[] = [];
  protected newCounterparty: CompanyInfo | null = null;
  protected prependCounterparty: CompanyInfo = {
    companyName: 'Clear/Remove Counterparty',
    companyId: 0, // special id instructs backend to remove the counterparty
    displayBoxId: null,
  };
  protected formatCompanyBoxId = formatCompanyBoxId;

  protected get canSubmit(): boolean {
    if (!this.newMinQty && !this.newRate && !this.newCounterparty && !this.newRoutingStatus) {
      return false;
    }

    return true;
  }

  protected get smallestRemainingQuantityInBatchSelection(): number {
    const batchRemainingQty = this.itemsToBeModified.map((item) => item.quantity - item.filled);
    return Math.min(...batchRemainingQty);
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      newMinQty: [],
    };

    if (this.$v.newMinQty.$dirty) {
      if (!this.$v.newMinQty.maxValue) {
        errors.newMinQty.push(
          `must be smaller than or equal to the smallest remaining quantity in the batch (${this.smallestRemainingQuantityInBatchSelection}).`
        );
      }
    }
    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  protected mounted(): void {
    this.itemsToBeModified = [...this.items];
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }

  protected async submitForm(): Promise<void> {
    this.formError = '';
    this.apiErrors = [];

    if (!this.validateForm()) {
      return;
    }

    if (!this.canSubmit) {
      this.formError = 'Please fill in at least one of the fields';
      return;
    }

    if (this.formStatus !== 'idle') {
      return;
    }

    this.formStatus = 'submitting';

    try {
      const payload: Api.Marketplace.BatchModifyPayload = {
        orderRefs: this.itemsToBeModified.map((item) => item.orderRef),
      };

      if (this.newCounterparty) {
        payload.counterparties = [this.newCounterparty.companyId];
      }
      if (this.newMinQty) {
        payload.minQuantity = this.newMinQty;
      }
      if (this.newRate) {
        payload.rate = this.newRate;
      }
      if (this.newRoutingStatus) {
        payload.routingStatus = this.newRoutingStatus;
      }

      const res = await this.$api.marketplace.batchUpdate(payload);

      if (res.failedOrderRefs) {
        this.failedOrderRefs = res.failedOrderRefs;
        this.formStatus = 'idle';
      } else {
        this.showSuccess = true;
        this.formStatus = 'closing';
        await wait(1200);
        this.$emit('success');
        this.closeDialog();
      }
    } catch (err) {
      this.apiErrors = [`${err}`];
      this.formStatus = 'idle';
    }
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 30vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
