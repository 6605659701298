<template>
  <div>
    <dashboard-panel no-collapse title="lender orders">
      <broker-lend-orders-list />
    </dashboard-panel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import BrokerLendOrdersList from '@/modules/broker-admin/components/BrokerLendOrdersList.vue';

@Component({
  components: {
    DashboardPanel,
    BrokerLendOrdersList,
  },
})
export default class BrokerLendOrdersListView extends Vue {}
</script>

<style lang="scss"></style>
