<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('startAuction') }}</span>
          <v-spacer></v-spacer>
          <span class="text--primary headline-2">{{ securityTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- find security to auction -->
            <v-row>
              <v-col>
                <security-selector
                  v-model="eqSelected"
                  color="form-field"
                  :error-messages="clErrors.equityID"
                  :label="$t('security')"
                  :placeholder="$t('securityAuction.tip')"
                ></security-selector>
              </v-col>
            </v-row>

            <!-- auction ends at -->
            <v-row align="center">
              <v-col class="py-3" cols="4">
                <v-tooltip v-if="endsAtTooltip !== ''" color="form-tooltip" max-width="600px" right>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <span>{{ $t('ends-at') }}</span>
                      <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>{{ endsAtTooltip }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="py-3">
                <v-menu
                  v-model="isVisibleDatePicker"
                  :close-on-content-click="false"
                  min-width="auto"
                  :nudge-right="40"
                  offset-y
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      ref="auctionDay"
                      v-model="auction.auctionDay"
                      v-bind="attrs"
                      color="form-field"
                      dense
                      :label="$t('On')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="auction.auctionDay"
                    :allowed-dates="isAllowedAuctionDay"
                    color="secondary"
                    first-day-of-week="1"
                    :max="pickerMaxDate"
                    :min="pickerMinDate"
                    no-title
                    scrollable
                    @input="isVisibleDatePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="py-3">
                <v-menu
                  ref="menu"
                  v-model="isVisibleTimePicker"
                  :close-on-content-click="false"
                  max-width="290px"
                  min-width="290px"
                  :nudge-right="40"
                  offset-y
                  :return-value.sync="times"
                  transition="scale-transition"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="auction.auctionTime"
                      v-bind="attrs"
                      color="form-field"
                      dense
                      :label="$t('At')"
                      prepend-icon="mdi-clock-outline"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="isVisibleTimePicker"
                    v-model="auction.auctionTime"
                    :allowed-hours="isAllowedAuctionHour"
                    :allowed-minutes="isAllowedAuctionMinute"
                    format="24hr"
                    full-width
                    :max="pickerMaxTime"
                    :min="pickerMinTime"
                    @click:minute="$refs.menu.save(times)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="clErrors.auctionTime">
              <v-col class="py-0" offset="4">
                <v-alert color="error" dense text>{{ clErrors.auctionTime }}</v-alert>
              </v-col>
            </v-row>

            <!-- participants to invite -->
            <v-row align="center">
              <v-col class="py-3" cols="4">
                <v-tooltip color="form-tooltip" max-width="600px" right>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <span>{{ $t('participants') }}</span>
                      <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>{{ $t('participantAuction.tip') }}</span>
                </v-tooltip>
              </v-col>
              <v-col v-for="p in investorProfiles" :key="p.id">
                <v-switch
                  ref="participantList"
                  v-model="auction.participantList"
                  color="form-field"
                  dense
                  hide-details
                  :label="p.label"
                  multiple
                  :value="p.id"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="clErrors.participantList">
              <v-col class="py-0" offset="4">
                <v-alert color="error" dense text>{{ clErrors.participantList }}</v-alert>
              </v-col>
            </v-row>

            <!-- first order -->
            <v-row align="center">
              <v-col class="pt-6 pb-0">
                <div class="subtitle-2">First Order</div>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col class="py-3" cols="4">
                {{ $t('direction') }}
              </v-col>
              <v-col class="py-3" cols="3">
                <v-radio-group v-model="auction.shareableDirection" dense hide-details row>
                  <v-radio color="form-field" :label="$t('borrow')" :value="Borrow"></v-radio>
                  <v-radio color="form-field" :label="$t('lend')" :value="Lend"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="py-0" cols="5">
                <v-chip
                  v-if="rateToolTip != ''"
                  class="ma-0"
                  color="form-tooltip"
                  label
                  text-color="white"
                >
                  <v-icon left> mdi-information</v-icon>
                  {{ rateToolTip }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row align="end">
              <v-col class="py-3" cols="4"></v-col>
              <v-col class="py-3">
                <template v-for="(row, lineNo) in auction.firstOrderTicket.orders">
                  <v-row :key="row.rowID" align="center" dense>
                    <v-col cols="5">
                      <volume-input
                        :ref="`quantity-${row.rowID}`"
                        :label="$t('quantity')"
                        :step="1"
                        tabindex
                        :ticket="row"
                        :volume="row.quantity"
                        @changed-error="onQuantityError"
                        @changed-volume="onQuantityChange"
                      ></volume-input>
                    </v-col>
                    <v-col cols="5">
                      <rate-input
                        :ref="`rate-${row.rowID}`"
                        :label="$t('rate')"
                        :max-rate="clientConfig.maxRate"
                        :precision="RATE_PRECISION"
                        :rate="row.rate"
                        tabindex
                        :ticket="row"
                        @changed-error="onRateError"
                        @changed-rate="onRateChange"
                      ></rate-input>
                    </v-col>
                    <v-col cols="2">
                      <v-tooltip color="form-tooltip" top>
                        <template #activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-btn color="primary" icon x-small @click="onCopyOrder(row)">
                              <v-icon> mdi-plus-circle </v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>{{ $t('add') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        v-if="auction.firstOrderTicket.orders.length > 1"
                        color="form-tooltip"
                        top
                      >
                        <template #activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-btn color="primary" icon x-small @click="onDeleteOrder(row)">
                              <v-icon> mdi-trash-can-outline </v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>{{ $t('delete') }}</span>
                      </v-tooltip>

                      <v-tooltip
                        v-if="auction.firstOrderTicket.orders.length > 1 && lineNo === 0"
                        class="pl-10"
                        color="form-tooltip"
                        top
                      >
                        <template #activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-btn
                              :class="['ml-2', { 'is-disabled': stackIsSorted }]"
                              color="primary"
                              :disabled="stackIsSorted"
                              icon
                              x-small
                              @click="onSortOrders"
                            >
                              <v-icon>
                                {{ isLend() ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}
                              </v-icon>
                            </v-btn>
                          </span>
                        </template>
                        <span>{{ $t('sortOrders.tip') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>

                <!-- order errors -->
                <v-row v-if="quantityError !== '' || rateError !== ''" align="center">
                  <v-col class="pa-0 pr-1" cols="5">
                    <v-alert v-if="quantityError !== ''" class="py-1" color="error" dense text
                      >{{ quantityError }}
                    </v-alert>
                  </v-col>
                  <v-col class="pa-0 pl-1" cols="5">
                    <v-alert v-if="rateError !== ''" class="py-1" color="error" dense text
                      >{{ rateError }}
                    </v-alert>
                  </v-col>
                </v-row>

                <!-- order summary -->
                <v-row v-if="auction.firstOrderTicket.orders.length > 1">
                  <v-col
                    >{{ $t('total-quantity') }}:
                    <pretty-number :value="totalQuantity" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- order notes, free text -->
            <v-row align="start">
              <v-col class="py-4" cols="4">
                <v-tooltip color="form-tooltip" max-width="600px" right>
                  <template #activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <span>{{ $t('notes') }}</span>
                      <v-icon class="ml-1 text--secondary" small> mdi-help-circle </v-icon>
                    </span>
                  </template>
                  <span>{{ $t('privateNotes.tip') }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="py-0">
                <v-textarea
                  v-model="auction.firstOrderTicket.notes"
                  color="form-field"
                  counter
                  dense
                  :hint="$t('privateNotes.label')"
                  persistent-hint
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="closeModalDialog">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="clearForm">
            {{ $t('clearAuctionButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="120" type="submit" @click="onSubmit">
            <v-icon left>mdi-arrow-right-bold</v-icon>
            {{ $t('verifyAuctionButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import { isEmpty } from 'lodash';
import { formatDate } from '@/utils/helpers/dates';
import {
  addBusinessDays,
  addDays,
  addHours,
  addMinutes,
  differenceInMilliseconds,
  getMinutes,
  isSameDay,
  isWeekend,
  parseISO as parseDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns';
import {
  formatDecimalAsCurrencyString,
  getCurrencySymbol,
  getVolumeAsInteger,
} from '@/utils/helpers/auction-numbers';
import { getPriceFractionalLength } from '@/utils/helpers/price-alignment';

import i18n from '@/localisation/i18n';
import SecuritySelector from '@/modules/orders/components/SecuritySelector.vue';
import VolumeInput from '@/modules/common/components/format-volume/VolumeInput.vue';
import PriceInput from '@/modules/common/components/format-price/PriceInput.vue';

import {
  AuctionDialogBuffer,
  AuctionEquity,
  ClientConfig,
  Direction,
  InvestorProfile,
  Order,
  createBespokeAuction,
  createBlankOrder,
  createOrderTicket,
} from '@/utils/helpers/rest';
import { FormValidator, setInputFocus } from '@/utils/helpers/input-form';
import { AuctionTimeManager } from '@/utils/helpers/auction-time-manager';
import Decimal from 'decimal.js';
import RateInput from '@/modules/common/components/format-rate/RateInput.vue';
import {
  PRICE_PRECISION,
  RATE_PRECISION,
  RATE_TICKSIZE,
} from '@/modules/common/constants/precision';
import { formatEquityDescription } from '@/utils/helpers/equities';

@Component({
  components: {
    RateInput,
    SecuritySelector,
    VolumeInput,
    PriceInput,
  },
  props: {
    auction: Object,
  },
  data: () => ({
    RATE_PRECISION,

    eqSelected: null,

    Borrow: Direction.Borrow,
    Lend: Direction.Lend,

    pickerSelectedDate: null,

    clErrors: {},
    isLoading: false,
    isVisibleDatePicker: false,
    isVisibleTimePicker: false,
    times: null,
    securityName: '',
    securityTitle: '',
    securitySymbol: '',

    // stacked order
    totalQuantity: 0,
    quantityError: '',
    rateError: '',
    stackIsSorted: false,
  }),
  methods: {
    ...mapActions(['fetchTraderInvestorProfiles']),
  },
  computed: {
    ...mapState(['investorProfiles', 'clientConfig']),
  },
})
export default class AuctionInitiation extends Vue {
  public $refs!: {
    equityID: HTMLFormElement;
    volume: HTMLFormElement;
    rate: HTMLFormElement;
    auctionDay: HTMLFormElement;
    participantList: HTMLFormElement;
  };

  // store state refs
  private investorProfiles!: InvestorProfile[];
  private clientConfig!: ClientConfig;

  private auction!: AuctionDialogBuffer;

  private processing = false;
  private isLoading!: boolean;
  private eqDescriptionLimit = 60;
  private eqSelected!: AuctionEquity | null;
  private securityName = '';
  private securityTitle = '';
  private priceCurrencySymbol = '';
  private rateTickSize = RATE_TICKSIZE;
  private ratePrecision = 2;

  // dynamic tooltips
  private endsAtTooltip = '';
  private rateToolTip = '';

  private now = setSeconds(new Date(), 0);
  private pickerMinStamp = addDays(this.now, -1);
  private pickerMaxStamp = addDays(this.now, 10);

  private pickerMinutesIncrement = 15;
  private pickerSelectedDate: string = formatDate(this.now, 'yyyy-MM-dd');
  private pickerMinDate = formatDate(addDays(this.now, -1), 'yyyy-MM-dd');
  private pickerMaxDate = formatDate(addDays(this.now, 10), 'yyyy-MM-dd');
  private pickerSelectedTime = this.now;
  private pickerMinTime: string | null = '10:00';
  private pickerMaxTime: string | null = '18:00';
  private auctionTimeoutHandle?: ReturnType<typeof setTimeout>;
  private auctionTimerHandle = 0;

  private fetchTraderInvestorProfiles!: () => Promise<null>;
  private clErrors: { [index: string]: string } = {};

  // stacked order
  private lastRowId = 0;
  private totalQuantity!: number;
  private totalValue!: Decimal;
  private quantityError!: string;
  private rateError!: string;
  private stackIsSorted!: boolean;

  private timeManager = new AuctionTimeManager(this.$store);

  @Watch('auction.direction')
  protected onChangedDirection(): void {
    this.onSortOrders();
  }

  // selected equity from dropdown
  @Watch('eqSelected')
  protected onChangedSecurity(selected: AuctionEquity): void {
    if (selected) {
      if (selected.id !== this.auction.equityID) {
        // create the first order of the auction
        this.auction.shareableRate = new Decimal(0);
        const firstOrder = createBlankOrder(0, this.auction.shareableRate);
        this.auction.firstOrderTicket = createOrderTicket('', '', Direction.Borrow, [firstOrder]);
      }
      this.auction.equityID = selected.id;
      this.auction.equity = selected;
      this.renderSelectedEquity(selected);
      this.clErrors.equityID = '';
    }
  }

  protected beforeMount(): void {
    // render equity if the 'back' button was used to re-edit the auction
    if (this.auction.equity) {
      this.eqSelected = this.auction.equity;
      this.auction.equityID = this.eqSelected.id;
      this.renderSelectedEquity(this.eqSelected);
    }

    this.updateTotals();
  }

  protected async mounted(): Promise<void> {
    this.prepareAuction();

    try {
      await this.fetchTraderInvestorProfiles();
    } catch (e) {
      this.$log.warn(e);
    }

    // preload dynamic  tooltips
    this.endsAtTooltip = i18n.t('brokerAuctionTimeWindow.tip', {
      auctionOpens: this.timeManager.getBrokerMarketOpens(),
      auctionCloses: this.timeManager.getBrokerMarketCloses(),
      brokerTimeZone: this.timeManager.getBrokerTimeZone(),
    }) as string;
    if (this.timeManager.getBrokerOffsetMinutes(this.now) !== 0) {
      const endsAtLocal = i18n.t('localAuctionTimeWindow.tip', {
        auctionOpens: formatDate(this.timeManager.getMarketOpens(this.now), 'HH:mm'),
        auctionCloses: formatDate(this.timeManager.getMarketCloses(this.now), 'HH:mm'),
        localTimeZone: formatDate(this.now, 'O'),
      }) as string;
      this.endsAtTooltip = `${this.endsAtTooltip} ${endsAtLocal}`;
    }

    // prepare stacked order
    this.lastRowId = this.auction.firstOrderTicket.orders.reduce(
      (max, order) => (order.rowID > max ? order.rowID : max),
      0
    );
    this.stackIsSorted = this.isOrdersSorted();
  }

  protected destroyed(): void {
    if (this.auctionTimeoutHandle) {
      clearTimeout(this.auctionTimeoutHandle);
      this.auctionTimeoutHandle = undefined;
    }
    if (this.auctionTimerHandle) {
      clearInterval(this.auctionTimerHandle);
      this.auctionTimerHandle = 0;
    }
  }

  protected isAllowedAuctionDay(day: string): boolean {
    return !isWeekend(parseDate(day));
  }

  protected isAllowedAuctionHour(_hours: number): boolean {
    // no special hours, office hours are already enforced by min and max
    return true;
  }

  protected isAllowedAuctionMinute(m: number): boolean {
    return m % this.pickerMinutesIncrement === 0;
  }

  protected isLend(): boolean {
    return this.auction.shareableDirection === Direction.Lend;
  }

  protected isOrdersSorted(): boolean {
    // sort a clone of the .orders and compare with the original
    return this.sortOrders(this.auction.firstOrderTicket.orders.slice(0)).every(
      (el, i) => el === this.auction.firstOrderTicket.orders[i]
    );
  }

  protected onSelectedTime(): void {
    // map selected time to nearest increment
    const roundedTime = this.roundDate2NearestIncrement(this.pickerSelectedTime);
    this.pickerSelectedTime = roundedTime;
    this.auction.auctionTime = formatDate(roundedTime, 'HH:mm');
  }

  // clone current row
  protected onCopyOrder(row: Order): void {
    const newRow = createBlankOrder(0, row.rate);
    newRow.rowID = ++this.lastRowId;

    // use splice to insert the new row as this will not disturb the sort order
    const idx = this.auction.firstOrderTicket.orders.indexOf(row) + 1; // append after current
    this.auction.firstOrderTicket.orders.splice(idx, 0, newRow);
    this.updateTotals();

    // set focus on the new row (after it has been rendered)
    Vue.nextTick(() => {
      this.$refs[`quantity-${this.lastRowId}`][0].$el.focus();
    });
  }

  protected onDeleteOrder(row: Order): void {
    this.auction.firstOrderTicket.orders = this.auction.firstOrderTicket.orders.filter((e) => {
      return e.rowID !== row.rowID;
    });
    this.stackIsSorted = this.isOrdersSorted();
    this.updateTotals();
    this.quantityError = this.reduceQuantityErrors();
    this.rateError = this.reduceRateErrors();
  }

  protected onSortOrders(): void {
    this.auction.firstOrderTicket.orders = this.sortOrders(this.auction.firstOrderTicket.orders);
    this.stackIsSorted = true;
  }

  protected onQuantityChange(qty: number, row: Order): void {
    row.quantity = qty;
    row.quantityError = '';

    this.updateTotals();
    this.quantityError = this.reduceQuantityErrors();
  }

  protected onQuantityError(errorStr: string, row: Order): void {
    row.quantityError = errorStr;
    this.quantityError = errorStr;
  }

  // the order that is published is the most attractive one (price-wise)
  protected onRateChange(rate: Decimal, row: Order): void {
    row.rate = rate;
    row.rateError = '';

    this.stackIsSorted = this.isOrdersSorted();
    this.rateError = this.reduceRateErrors();
  }

  protected onRateError(errorStr: string, row: Order): void {
    row.rateError = errorStr;
    this.rateError = errorStr;
  }

  protected onSubmit(): void {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      this.clErrors = new FormValidator(this.auction, [
        'equityID',
        'participantList',
        'auctionTime',
        'auctionDay',
      ]).check();

      if (
        this.getAuctionDateAndTime(this.auction.auctionDay, this.auction.auctionTime) < new Date()
      ) {
        this.clErrors.auctionTime = this.$t('expired.auctionTime') as string;
      }

      if (!isEmpty(this.clErrors)) {
        // jump to the first error and abort
        const focusRef = this.$refs[Object.keys(this.clErrors)[0]];
        if (focusRef) {
          setInputFocus(focusRef);
        }
        return;
      }

      // check all orders before submitting anything
      this.quantityError = '';
      this.rateError = '';
      const canSubmit = this.auction.firstOrderTicket.orders.every((row) => {
        // check if volume and price are entered
        const errors = new FormValidator(row, ['quantity', 'rate']).check();

        // report volume error
        this.quantityError = errors.quantity || row.quantityError;
        if (this.quantityError) {
          setInputFocus(this.$refs[`quantity-${row.rowID}`]);
          return false;
        }

        // also check that the price is a multiple of the ticksize
        if (!errors.rate) {
          if (row.rate.abs().greaterThan(this.clientConfig.maxRate)) {
            errors.rate = i18n.t('max.rate', {
              max: new Decimal(this.clientConfig.maxRate).toFixed(this.ratePrecision),
            }) as string;
          } else if (this.auction.equity) {
            // check that the price is a multiple of the ticksize
            if (!row.rate.mod(RATE_TICKSIZE).eq(0)) {
              errors.rate = this.$t('ticksize.rate') as string;
            }
          }
        }

        // report price error
        this.rateError = errors.rate || row.rateError;
        if (this.rateError) {
          setInputFocus(this.$refs[`rate-${row.rowID}`]);
          this.$refs[`rate-${row.rowID}`][0].$el.focus();
          return false;
        }

        // this order row is fine
        return true;
      });

      // bail out on hard errors
      if (!canSubmit) {
        return;
      }

      // check for suspicious limit prices
      //   if found: confirm or review order
      this.$emit('toggle-can-cancel', false);

      this.submitAuction();
    } finally {
      this.processing = false;
    }
  }

  protected formatPickerDate(dt: Date): string {
    return i18n.d(dt, 'short');
  }

  protected formatPickerTime(t: Date): string {
    return formatDate(t, 'HH:mm O');
  }

  protected clearForm(): void {
    this.$emit('clear-auction');
  }

  protected closeModalDialog(): void {
    this.$emit('close-modal');
  }

  private prepareAuction(): void {
    // only 'round' times are selectable (08:10, 08:20, ...)
    const [, incMinutes] = this.timeManager.getAuctionMarketTimeIncrements();
    this.pickerMinutesIncrement = +incMinutes;

    // initialize the date and clock pickers
    const [hours, minutes] = this.auction.auctionTime.split(':');
    this.pickerSelectedDate = this.auction.auctionDay;
    this.pickerSelectedTime = setHours(
      setMinutes(parseDate(this.pickerSelectedDate), +minutes),
      +hours
    );

    // Check- and adjust requested auction moment and re-check every minute
    this.checkRequestedAuctionStamp();

    // re-check the next minute
    // determine how much longer until the next minute
    const now = new Date();
    const nextMinute = addMinutes(setSeconds(setMilliseconds(now, 0), 0), 1);
    const timeTillNextMinute = differenceInMilliseconds(nextMinute, now);
    this.auctionTimeoutHandle = setTimeout(() => {
      // update the auction times again
      this.checkRequestedAuctionStamp();

      // re-check every minute
      this.auctionTimerHandle = window.setInterval(() => {
        this.checkRequestedAuctionStamp();
      }, 60 * 1000);
    }, timeTillNextMinute);
  }

  private checkRequestedAuctionStamp(): void {
    // 1. calculate the first possible auction start
    // 2. move requested auction start if out of range
    this.applyDatePickerRange();

    // which timestamp would we *like* to show?
    let auctionStamp = parseDate(this.auction.auctionDay);
    const [auctionHour, auctionMinute] = this.auction.auctionTime.split(':');
    auctionStamp = setHours(setMinutes(setSeconds(auctionStamp, 0), +auctionMinute), +auctionHour);

    if (auctionStamp < this.pickerMinStamp) {
      auctionStamp = this.pickerMinStamp;
    }
    if (auctionStamp > this.pickerMaxStamp) {
      // too far ahead... schedule auction at 1 hour before absolute closing
      auctionStamp = addHours(this.pickerMaxStamp, -1);
    }

    // date/time may have changed by applying the limits
    this.auction.auctionDay = formatDate(auctionStamp, 'yyyy-MM-dd');
    this.auction.auctionTime = formatDate(auctionStamp, 'HH:mm');

    // limit clock-picker for the selected day
    this.applyClockPickerRange();
  }

  private applyDatePickerRange(): void {
    const now = new Date();
    const marketOpens = this.timeManager.getMarketOpens(now);
    const marketCloses = this.timeManager.getMarketCloses(now);
    let firstPick = this.timeManager.getFirstAuctionMoment();

    // we add 1 minute to the firstPick, because it will rounded (up) to nearest increment
    //  and we want to make sure 15:30 will result in 16:40 and not 16:30
    firstPick = addMinutes(firstPick, 1);

    // if the market not open yet, skip to opening of the market
    // @TODO: skip weekends and bank holidays
    if (firstPick < marketOpens) {
      firstPick = setSeconds(marketOpens, 0);
    }
    if (isWeekend(firstPick)) {
      firstPick = addBusinessDays(firstPick, 1);
    }

    // if the market closed for the day, skip to opening of the market tomorrow
    if (firstPick > marketCloses) {
      firstPick = this.timeManager.getMarketOpens(addBusinessDays(now, 1));
    }

    // stamp fields contains date- and time; date-fields have zero-d time portion.
    // the zero-d time portion is needed to make the date picker work correctly!
    this.pickerMinStamp = setSeconds(firstPick, 0);
    this.pickerMaxStamp = this.timeManager.getMarketCloses(
      addBusinessDays(firstPick, this.timeManager.getAuctionMarketDaysMaxOpen())
    );

    const min = new Date(firstPick.getFullYear(), firstPick.getMonth(), firstPick.getDate());
    const max = addBusinessDays(now, this.timeManager.getAuctionMarketDaysMaxOpen());
    this.pickerMinDate = formatDate(min, 'yyyy-MM-dd');
    this.pickerMaxDate = formatDate(max, 'yyyy-MM-dd');
  }

  private applyClockPickerRange(): void {
    const marketOpensAt = this.timeManager.getMarketOpens(this.pickerSelectedTime);

    let minTime = setSeconds(marketOpensAt, 0);
    if (minTime < this.pickerMinStamp) {
      minTime = setSeconds(this.pickerMinStamp, 0);
    }
    minTime = this.roundDate2NearestIncrement(minTime);
    if (this.pickerSelectedTime < minTime) {
      this.pickerSelectedTime = setSeconds(minTime, 0);
      this.onSelectedTime();
    }

    const marketClosesAt = this.timeManager.getMarketCloses(this.pickerSelectedTime);
    const maxTime = this.roundDate2NearestIncrement(marketClosesAt);

    // if min and max are not on the same day the time-picker goes bananas
    // remove restriction and rely on the server to through an error
    if (!isSameDay(minTime, maxTime)) {
      this.pickerMinTime = null;
      this.pickerMaxTime = null;
      return;
    }

    this.pickerMinTime = formatDate(minTime, 'HH:mm');
    this.pickerMaxTime = formatDate(maxTime, 'HH:mm');
  }

  private roundDate2NearestIncrement(d: Date): Date {
    // auction time should be a multiple of 'increment' minutes
    let roundedDate = d;
    const minutes = getMinutes(d);
    if (minutes % this.pickerMinutesIncrement !== 0) {
      roundedDate = addMinutes(
        d,
        this.pickerMinutesIncrement - (minutes % this.pickerMinutesIncrement)
      );
    }
    return roundedDate;
  }

  private sortOrders(orders: Order[]): Order[] {
    return orders.sort((o1, o2) =>
      this.isLend() ? o1.rate.comparedTo(o2.rate) : o2.rate.comparedTo(o1.rate)
    );
  }

  private updateTotals(): void {
    this.totalQuantity = this.auction.firstOrderTicket.orders.reduce(
      (sum, r) => sum + r.quantity,
      0
    );
    this.totalValue = this.auction.firstOrderTicket.orders.reduce(
      (sum, r) => sum.add(r.rate.mul(r.quantity)),
      new Decimal(0)
    );
  }

  private reduceQuantityErrors(): string {
    return this.auction.firstOrderTicket.orders.reduce((err, r) => {
      if (r.quantityError && r.quantityError !== '') {
        return r.quantityError;
      }
      return err;
    }, '');
  }

  private reduceRateErrors(): string {
    return this.auction.firstOrderTicket.orders.reduce((err, r) => {
      if (r.rateError && r.rateError !== '') {
        return r.rateError;
      }
      return err;
    }, '');
  }

  // sort the orders (taking direction into account) and return the 1st
  private getBestOrder(): [number, Decimal] {
    const sortedOrders = this.sortOrders(this.auction.firstOrderTicket.orders);
    return [sortedOrders[0].quantity, sortedOrders[0].rate];
  }

  private renderSelectedEquity(equity: AuctionEquity): void {
    this.securityName = equity.name;
    this.securityTitle = this.formatEquityDescription(equity);

    // additional equity info for the trader (last price, ticksize, ...)
    this.priceCurrencySymbol = getCurrencySymbol(equity.currencyCode);
    this.ratePrecision = getPriceFractionalLength(this.rateTickSize);
    this.rateToolTip = `Last Price: ${formatDecimalAsCurrencyString(
      equity.currencyCode,
      equity.closePrice,
      PRICE_PRECISION
    )}`;
  }

  private formatEquityDescription(equity: AuctionEquity): string {
    return formatEquityDescription(equity, this.eqDescriptionLimit);
  }

  private submitAuction(): void {
    // all order rows are fine, validation has already been done
    const orders = this.auction.firstOrderTicket.orders.map((order) => {
      return {
        quantity: getVolumeAsInteger(order.quantity),
        rate: order.rate,
      } as Order;
    });

    // share only the most-attractive order of a stacked order with the participants
    // assigned to the original auction, not the staged auction as the user decides what to share on the next page
    [this.auction.shareableQuantity, this.auction.shareableRate] = this.getBestOrder();

    const orderTicket = createOrderTicket(
      '',
      '',
      this.auction.shareableDirection,
      orders,
      this.auction.firstOrderTicket.notes
    );
    const bespokeAuction = createBespokeAuction(
      this.auction as NonNullableAll<AuctionDialogBuffer>,
      this.endsAt(),
      orderTicket
    );

    // all well, forward auction to confirmation dialog
    this.$emit('stage-auction', bespokeAuction);
  }

  private endsAt(): Date {
    return this.roundDate2NearestIncrement(
      this.getAuctionDateAndTime(this.auction.auctionDay, this.auction.auctionTime)
    );
  }

  // convert day+time strings to real datetime
  private getAuctionDateAndTime(auctionDay: string, auctionTime: string): Date {
    const dt = parseDate(auctionDay);
    const [hours, minutes] = auctionTime.split(':');
    return setSeconds(setHours(setMinutes(dt, +minutes), +hours), 0);
  }
}
</script>

<style lang="scss" scoped>
// make the dropdown of <v-autocomplete> stick out
.v-autocomplete__content.v-menu__content .v-select-list {
  background-color: #485c66;
}
</style>
