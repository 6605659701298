<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="800px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(title) }}</span>
          <v-spacer></v-spacer>
          <span class="text--primary headline-2">{{ user.name }}</span>
          <span v-if="user.name && user.emailAddress" class="text--primary headline-2"> | </span>
          <span class="text--primary text-lowercase headline-2">{{ user.emailAddress }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="showError">
            <v-alert dense type="error">{{ errorMsgs.apiErrors.join('\n') }}</v-alert>
          </v-container>
          <v-tabs v-model="tabIndex">
            <v-tab>Profile</v-tab>
            <v-tab :disabled="isTraderViewer">Risk Limits</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabIndex">
            <v-tab-item class="user-tab pa-4">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.emailAddress"
                      autocomplete="email"
                      autofocus
                      :error-messages="clErrors.emailAddress"
                      :label="$t('loginEmail')"
                      required
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="crudAction === RestOperation.Create">
                  <v-col>
                    <v-alert border="left" dense type="info">
                      The user will receive an invitation by email to activate the account and set a
                      password.
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.name"
                      autocomplete="username"
                      :error-messages="clErrors.name"
                      :label="$t('Full Name')"
                      placeholder="e.g. Susan Smith"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <!-- If there are roles pending approval, we show the pending role not the actual role in the role
                      dropdown. This way the trader-admin can cancel the pending role assignment or switch it to a
                      different role assignment -->
                    <v-select
                      v-if="user.accountStatus === 'pending-approval-for-roles'"
                      ref="roles"
                      v-model="user.rolesPendingApproval[0]"
                      :error-messages="clErrors.roles"
                      :items="roles"
                      :label="$t('Role')"
                    />
                    <v-select
                      v-else
                      ref="roles"
                      v-model="user.roles[0]"
                      :error-messages="clErrors.roles"
                      :items="roles"
                      :label="$t('Role')"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      ref="tradingPermissions"
                      v-model="user.tradingPermissions"
                      :error-messages="clErrors.tradingPermissions"
                      :item-text="formatTradingPermissionsLabel"
                      item-value="permission"
                      :items="allowedPermissions"
                      :label="$t(`tradingPermissions.title`)"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="user.tradingPermissions === null">
                  <v-col cols="12">
                    <v-alert border="left" dense icon="mdi-information" type="info">
                      <span
                        >{{ $t('tradingPermissions.title') }} for the company are:
                        <em>
                          {{ $t(`tradingPermissions.options.${user.companyTradingPermissions}`) }}
                        </em>
                      </span>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row
                  v-else-if="
                    // no need to explain 'disabled'
                    user.tradingPermissions !== 0 &&
                    // and no need to explain when the permissions are the same
                    effectiveTradingPermissions !== user.tradingPermissions
                  "
                >
                  <v-col cols="12">
                    <v-alert border="left" dense icon="mdi-information" type="info">
                      <div>User permissions may be limited by permissions set on the company.</div>
                      <div>
                        Trading permissions for {{ user.companyName }} are:
                        <em>
                          {{ $t(`tradingPermissions.options.${user.companyTradingPermissions}`) }}.
                        </em>
                      </div>
                      <div>
                        As a result, the effective trading permissions of this user are:
                        <em>
                          {{ $t(`tradingPermissions.options.${effectiveTradingPermissions}`) }}.
                        </em>
                      </div>
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row v-if="crudAction === RestOperation.Update">
                  <v-col>
                    <v-checkbox
                      v-model="user.resetPassword"
                      label="Send a Reset Password Link to the email address above"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item class="user-tab pa-4">
              <v-container>
                <v-row dense>
                  <v-col class="pt-6 pb-0 subtitle-1"> Per Order Soft Limit</v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    Fat-finger warning will trigger when {{ user.name }} attempts to enter an order
                    exceeding this notional value.
                  </v-col>
                </v-row>
                <v-row align="center" dense>
                  <v-col>Individual Per Order Soft Limit:</v-col>
                  <v-col>
                    <numeric-input
                      ref="softLimitInput"
                      v-model="userOrderSoftLimit"
                      :clearable="true"
                      :error-messages="errorMsgs.userOrderSoftLimit"
                      :messages="softLimitMessage"
                      :min="1"
                      :precision="0"
                      prefix="$"
                      type="decimal"
                      @blur="$v.userOrderSoftLimit.$touch()"
                      @input="$v.userOrderSoftLimit.$touch()"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-container>
                <v-row dense>
                  <v-col class="pt-12 pb-0 subtitle-1"> Per Order Hard Limit</v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    Hard limit will be enforced when {{ user.name }} attempts to enter an order
                    exceeding this notional value, preventing order entry.
                  </v-col>
                </v-row>
                <v-row align="center" dense>
                  <v-col>Individual Per Order Hard Limit:</v-col>
                  <v-col>
                    <numeric-input
                      ref="hardLimitInput"
                      v-model="userOrderHardLimit"
                      :clearable="true"
                      :error-messages="errorMsgs.userOrderHardLimit"
                      :messages="hardLimitMessage"
                      :min="1"
                      :precision="0"
                      prefix="$"
                      type="decimal"
                      @blur="$v.userOrderHardLimit.$touch()"
                      @input="$v.userOrderHardLimit.$touch()"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions class="pt-16">
          <v-btn class="d-none d-sm-flex" color="secondary" text @click="closeModalDialog">
            {{ $t('cancelButton') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="processing"
            :loading="processing"
            min-width="120"
            type="submit"
            @click="onSubmit"
          >
            {{ $t('adminSaveUser') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { isEmpty, isNull } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  RestOperation,
  TradingPermissionOption,
  UpdateTraderUserResponse,
} from '@/utils/helpers/rest';
import { FormValidator } from '@/utils/helpers/input-form';
import i18n from '@/localisation/i18n';
import { LoginState } from '@/store/store';
import { formatTradingPermissionOption } from '@/modules/broker-admin/utils';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';
import NumericInput from '@/modules/common/components/NumericInput.vue';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { validationMixin } from 'vuelidate';
import { numeric } from 'vuelidate/lib/validators';
import { minValueIf } from '@/utils/helpers/risk-limit-validator';
import Decimal from 'decimal.js';

interface FormErrors {
  apiErrors: string[];
  userOrderSoftLimit: string[];
  userOrderHardLimit: string[];
}

@Component({
  components: {},
  props: {
    crudAction: Number,
    title: String,
    user: Object as PropType<UserAccount>,
    adminCount: Number,
  },
  data: () => ({
    emailRules: [(v: string) => /.+@.+/.test(v) || v === '' || i18n.t('formfield.InvalidEmail')],
    clErrors: {},
    RestOperation,
    roles: [
      { text: i18n.tc('trader-user'), value: 'trader-user' },
      { text: i18n.tc('trader-viewer'), value: 'trader-viewer' },
      { text: i18n.tc('trader-admin'), value: 'trader-admin' },
      { text: i18n.tc('ops-user'), value: 'ops-user' },
      { text: i18n.tc('config-admin'), value: 'config-admin' },
    ],
    allowedPermissions: [],
  }),
  mixins: [validationMixin],
  validations: function (this: TraderUserDialog) {
    return {
      userOrderSoftLimit: {
        numeric,
        minValue: minValueIf(!this.isTraderViewer, new Decimal(1)),
      },
      userOrderHardLimit: {
        numeric,
        minValue: minValueIf(!this.isTraderViewer, new Decimal(1)),
        minOrderSoftLimitValue: minValueIf(
          !isNull(this.userOrderSoftLimit) && !this.isTraderViewer,
          this.userOrderSoftLimit
        ),
      },
    };
  },
  methods: {
    ...mapActions([
      'fetchTradingPermissionOptions',
      'createTraderAdminUser',
      'updateTraderAdminUser',
    ]),
  },
  computed: {
    ...mapState(['loginState', 'tradingPermissionOptions']),
    ...mapGetters(['hasTraderAdminRole']),
  },
})
export default class TraderUserDialog extends mixins(validationMixin) {
  public $refs!: {
    softLimitInput: NumericInput;
    hardLimitInput: NumericInput;
  };
  protected apiErrors: string[] = [];
  private crudAction!: RestOperation;
  private user!: UserAccount;
  private userOrderSoftLimit: Decimal | null = null;
  private userOrderHardLimit: Decimal | null = null;
  private tabIndex = 0;
  private tradingPermissionOptions!: TradingPermissionOption[];
  private allowedPermissions!: TradingPermissionOption[];
  private adminCount!: number;
  private fetchTradingPermissionOptions!: () => void;
  private createTraderAdminUser!: (user: UserAccount) => Promise<null>;
  private updateTraderAdminUser!: (payload: {
    user: UserAccount;
    isAdministrator: boolean;
  }) => Promise<UpdateTraderUserResponse>;
  private loginState!: NonNullableAll<LoginState>;
  private hasTraderAdminRole!: boolean;
  private processing = false;
  private lostAdminRole = false;
  private clErrors: { [index: string]: string } = {};

  protected get effectiveTradingPermissions(): number {
    if (this.user.tradingPermissions === null) {
      return this.user.companyTradingPermissions;
    }
    return this.user.companyTradingPermissions & this.user.tradingPermissions;
  }

  protected get isTraderViewer(): boolean {
    return this.user.roles.length === 0 || this.user.roles.includes('trader-viewer');
  }

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      userOrderSoftLimit: [],
      userOrderHardLimit: [],
    };

    // soft limit errors
    if (!isNull(this.userOrderSoftLimit) && this.$v.userOrderSoftLimit.$dirty) {
      if (!this.$v.userOrderSoftLimit.minValue) {
        this.tabIndex = 1;
        errors.userOrderSoftLimit.push('please enter a soft limit');
      }
    }

    // hard limit errors
    if (!isNull(this.userOrderHardLimit) && this.$v.userOrderHardLimit.$dirty) {
      if (!this.$v.userOrderHardLimit.minValue) {
        this.tabIndex = 1;
        errors.userOrderHardLimit.push('please enter a hard limit');
      }

      if (!this.$v.userOrderHardLimit.minOrderSoftLimitValue) {
        this.tabIndex = 1;
        errors.userOrderHardLimit.push('hard limit should always exceed the soft limit');
      }
    }

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  // softLimitMessage - what is the company default if we leave the field open?
  private get softLimitMessage(): string {
    return !isNull(this.userOrderSoftLimit) || isNull(this.user.defaultOrderSoftLimit)
      ? ''
      : `Inheriting company order soft limit value of $${getPriceAsString(
          this.user.defaultOrderSoftLimit,
          0
        )}`;
  }

  // hardLimitMessage - what is the company default if we leave the field open?
  private get hardLimitMessage(): string {
    return !isNull(this.userOrderHardLimit) || isNull(this.user.defaultOrderHardLimit)
      ? ''
      : `Inheriting company order hard limit value of $${getPriceAsString(
          this.user.defaultOrderHardLimit,
          0
        )}`;
  }

  protected validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  private async mounted() {
    try {
      await this.fetchTradingPermissionOptions();

      // add `null` as option for permissions for 'inherit from company'
      this.allowedPermissions = this.tradingPermissionOptions.concat({ permission: null });
      this.userOrderSoftLimit = this.user.orderSoftLimit;
      this.userOrderHardLimit = this.user.orderHardLimit;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  private async onSubmit() {
    if (this.processing) {
      return;
    }
    this.processing = true;

    try {
      // validate 'profile' tab
      this.clErrors = new FormValidator(this.user, ['name', 'emailAddress', 'roles']).check();
      if (!isEmpty(this.clErrors)) {
        this.tabIndex = 0;
        return;
      }

      this.apiErrors = [];
      if (this.user.id === this.loginState.user.id) {
        if (!this.hasTraderAdminRole) {
          // current user lost admin rights
          if (this.adminCount === 1) {
            // not allowed if it is the only admin
            this.apiErrors.push(i18n.t('adminChangeLastAdminAccount') as string);
            this.tabIndex = 0;
            return;
          } else {
            // allowed (more than 1 admin) but this user cannot manage users anymore
            this.lostAdminRole = true;
          }
        }
      }

      if (!this.validateForm()) {
        return;
      }

      // if updating a user with pending role approvals, they should be submitted as roles or
      // they will be reset.
      if (
        this.user.accountStatus === 'pending-approval-for-roles' &&
        this.user.rolesPendingApproval !== null
      ) {
        this.user.roles = this.user.rolesPendingApproval;
      }

      // clear risk-limits for read-only users
      //   limits were not validated because we should not error on a disabled tab
      if (this.isTraderViewer) {
        this.userOrderSoftLimit = null;
        this.userOrderHardLimit = null;
      }

      this.user.orderSoftLimit = this.userOrderSoftLimit;
      this.user.orderHardLimit = this.userOrderHardLimit;
      await this.doSubmit(this.user);
    } finally {
      this.processing = false;
    }
  }

  private async doSubmit(userRecord: UserAccount) {
    try {
      if (this.crudAction === RestOperation.Create) {
        await this.createTraderAdminUser(userRecord);
      } else {
        const updateResponse = await this.updateTraderAdminUser({
          user: userRecord,
          isAdministrator: !this.lostAdminRole,
        });

        if (updateResponse.approvalRequired) {
          this.$snackbar.confirm(i18n.tc('adminUpdateUserApprovalRequired'));
        }
      }

      if (this.lostAdminRole) {
        // jump to the generic settings page if you are no longer admin
        await this.$router.replace({ name: 'settings' });
        this.$snackbar.confirm('You are no longer administrator.');
      }
      this.closeModalDialog();
    } catch (err) {
      this.apiErrors = [`${err}`];
    }
  }

  private closeModalDialog() {
    // allow parent to clean up
    this.$emit('close-modal');
  }

  private formatTradingPermissionsLabel(option: TradingPermissionOption): string {
    return this.$i18n.tc(formatTradingPermissionOption(option));
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .user-tab {
    min-height: 575px;
  }
}
</style>
