<!-- eslint-disable vue/no-v-html -->
<template>
  <v-dialog
    content-class="au-popup-dialog"
    max-width="600px"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
  >
    <v-form novalidate @submit.prevent="onSubmit">
      <v-card>
        <v-card-title v-if="clientConfig">
          <span class="headline">
            {{ $t('loginTitle', { title: clientConfig.systemTitleShort }) }}
          </span>
          <span v-if="clientConfig.systemEnv !== 'prod'" class="system-env text--secondary">
            {{ clientConfig.systemEnv }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-if="browserIsSupported" class="message-spacer" cols="12">
                <v-alert v-if="loginError" dense type="error">
                  {{ loginError }}
                </v-alert>
                <v-alert v-else-if="loginMessage" dense type="success">
                  {{ $t(loginMessage, { emailAddress: email }) }}
                </v-alert>
              </v-col>
              <v-col v-else>
                <v-alert dense type="error">
                  <span v-html="browserError"></span>
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="browserIsSupported">
              <v-col cols="12">
                <v-text-field
                  v-model="emailAddress"
                  autocomplete="username email"
                  autofocus
                  :error-messages="errorMsgs['emailAddress']"
                  :label="$t('loginEmail')"
                  @blur="$v.emailAddress.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :append-icon="revealPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  autocomplete="current-password"
                  counter
                  :error-messages="errorMsgs['password']"
                  :label="$t('loginPassword')"
                  required
                  :type="revealPassword ? 'text' : 'password'"
                  @blur="$v.emailAddress.$touch()"
                  @click:append="revealPassword = !revealPassword"
                  @input="$v.emailAddress.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="browserIsSupported">
          <v-btn color="secondary" text @click="lostPassword">
            {{ $t('loginForgotPassword') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="isProcessing"
            :loading="isProcessing"
            min-width="150"
            type="submit"
          >
            <v-icon left>mdi-login</v-icon>
            {{ $t('loginButton') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="120" to="/">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { SymmetricEncrypter } from '@/modules/common/services/crypto/symmetric-encrypter';
import { AppState } from '@/store/store';
import { StoreAuth, useStoreAuth } from '@/store/store-auth';
import { LoginSalt, MyAccount } from '@/utils/helpers/rest';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import axios, { AxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import Component, { mixins } from 'vue-class-component';
import type { Dictionary } from 'vue-router/types/router';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState } from 'vuex';

interface FormErrors {
  emailAddress: string[];
  password: string[];
}

@Component({
  props: {
    email: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['clientConfig']),
    ...mapGetters(['tfaEnabled']),
  },
  methods: {
    ...mapMutations(['updateCurrentUser']),
  },
  mixins: [validationMixin],
  validations: {
    emailAddress: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  setup() {
    return storeToRefs(useStoreAuth());
  },
})
export default class Login extends mixins(validationMixin) {
  // props
  protected readonly email!: string;
  protected readonly message!: string;
  protected readonly error!: string;

  // store state refs
  protected readonly clientConfig!: AppState['clientConfig'];
  // store getters
  protected tfaEnabled!: boolean;
  // store mutations (sync)
  protected readonly updateCurrentUser!: (a: MyAccount) => void;

  // pinia
  protected readonly login!: StoreAuth['login'];

  // form fields
  protected emailAddress = '';
  protected password = '';

  protected isProcessing = false;
  protected revealPassword = false;
  protected loginError = '';
  protected loginMessage = '';
  protected browserIsSupported = true;
  protected browserError = '';

  private symmetricEncrypter!: SymmetricEncrypter;

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      emailAddress: [],
      password: [],
    };

    // Email errors
    if (this.$v.emailAddress.$dirty) {
      if (!this.$v.emailAddress.required) errors.emailAddress.push('please enter an email.');
      if (!this.$v.emailAddress.email) errors.emailAddress.push('please enter a valid email.');
    }

    // Password errors
    if (this.$v.password.$dirty) {
      if (!this.$v.password.required) errors.password.push('please enter a password.');
    }

    return errors;
  }

  protected async mounted(): Promise<void> {
    this.symmetricEncrypter = this.$es.getSymmetricEncrypter();
    this.browserIsSupported = await this.$es.isCryptoSupported();

    // Modern browsers have support for windows.crypto.subtle
    // NOTE: website must be running secured! (localhost or https with valid certificate)
    if (!this.browserIsSupported) {
      this.browserError = this.$tc('encrypt-function-missing');
    }

    this.emailAddress = this.email;
    this.loginMessage = this.message;
    this.loginError = this.$t(this.error) as string;
  }

  protected async onSubmit(): Promise<void> {
    // run validation
    if (this.isProcessing || !this.validateForm()) {
      return;
    }

    this.isProcessing = true;

    try {
      // get salt (for the email entered) from the server
      let loginSalt: Buffer;
      try {
        const { data } = await axios.get(`/api/1/login-salt/${this.emailAddress}`);
        loginSalt = Buffer.from((data as LoginSalt).salt, 'base64');
      } catch (e) {
        this.loginError = i18nServerMessage(e as AxiosError);
        return;
      }

      // hash password with login salt to submit to server
      const hashedPassword = await this.symmetricEncrypter.hashPassword(this.password, loginSalt);

      const result = await this.login({
        email: this.emailAddress,
        password: hashedPassword,
      });

      // already handled within login
      if (result === undefined) return;

      if (result.success) {
        this.loginMessage = 'Success';
        this.advise2FA();

        await this.$router.replace({ name: 'dashboard' });
      } else {
        this.loginError = this.$t(result.error, result.details) as string;
      }
    } finally {
      this.isProcessing = false;
    }
  }

  protected lostPassword(): void {
    this.redirectLogin('lost-password', { lostEmail: this.emailAddress });
  }

  private validateForm(): boolean {
    this.loginError = '';
    this.loginMessage = '';
    this.$v.$reset();
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  private redirectLogin(pageName: string, pageParams: Dictionary<string>): void {
    void this.$router.push({
      name: pageName,
      params: pageParams,
    });
  }

  private advise2FA() {
    if (!this.tfaEnabled) {
      this.$snackbar.show({
        message: this.$t('loginRegisterDevice.text') as string,
        actionText: this.$t('loginRegisterDevice.button') as string,
        timeout: 3000,
        onAction: () => this.$router.push({ name: 'settings.register2fa' }),
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.system-env {
  text-transform: uppercase;
  margin-top: 2px;
  margin-left: 4px;
}
</style>
