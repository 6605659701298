<!-- eslint-disable vue/no-v-html -->
<template>
  <v-app-bar app clipped-left dense>
    <router-link class="text-capitalize text-decoration-none pr-16" :to="{ name: 'dashboard' }">
      <span class="pl-gradient-colors">
        {{ clientConfig.systemTitleLong }}
      </span>
      <span v-if="clientConfig.systemEnv !== 'prod'" class="system-env text--secondary">
        {{ clientConfig.systemEnv }}
      </span>
    </router-link>

    <v-spacer />

    <!-- global search bar -->
    <v-toolbar v-if="searchEnabled" v-shortkey="['/']" dense elevation="0" @shortkey="onSearchKey">
      <v-autocomplete
        ref="search"
        v-model="searchSelected"
        attach=".global-search"
        auto-select-first
        class="global-search"
        color="form-field"
        dense
        :filter="searchFilter"
        hide-details="auto"
        hide-selected
        item-text="description"
        :items="searchResults"
        :no-data-text="$t('searchPlatform.not-found')"
        :placeholder="$t('searchPlatform.tip', { platform: clientConfig.systemTitleShort })"
        prepend-inner-icon="mdi-magnify"
        return-object
        solo-inverted
        style="width: 40vw"
        @input="onSelectSearchResult"
      >
        <template #selection="data">
          {{ data.item.title }}
        </template>
        <template #item="data">
          <v-list-item-content style="width: 40vw">
            <v-list-item-title class="text-capitalize">
              {{ data.item.category }}
              <span v-if="data.item.title !== data.item.category">
                &mdash; {{ data.item.title }}</span
              >
            </v-list-item-title>
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-toolbar>

    <v-spacer />

    <!-- notifications -->
    <browser-notification v-if="isLoggedIn" :notification-title="clientConfig.systemTitleLong" />

    <!-- user menu -->
    <v-menu v-if="isLoggedIn" bottom left transition="slide-x-transition">
      <template #activator="{ on, attrs }">
        <v-btn
          class="aurora-keep-case"
          color="primary darken-2"
          fab
          v-bind="attrs"
          x-small
          v-on="on"
        >
          {{ nameInitials }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-btn class="aurora-keep-case" color="primary darken-2" fab x-large>
              {{ nameInitials }}
            </v-btn>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ loginState.user.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ loginState.user.companyName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'settings', params: { returnPath: currentPath } }">
          <v-list-item-title class="text-capitalize">settings</v-list-item-title>
          <v-list-item-action>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-action>
        </v-list-item>

        <!-- @TODO: some kind of help or documentation ref -->
        <!-- @TODO: temporarily disabled until FAQ content is updated -->
        <!-- <v-list-item @click="showFaqDialog = true">
          <v-list-item-title class="text-capitalize">help</v-list-item-title>
          <v-list-item-action>
            <v-icon>mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item> -->

        <v-list-item @click="showFeedbackDialog = true">
          <v-list-item-title class="text-capitalize">send feedback</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item to="/logout">
          <v-list-item-title class="text-capitalize">log out</v-list-item-title>
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- various dialog box popups -->
    <!-- @TODO: temporarily disabled until FAQ content is updated -->
    <!-- <faq-popup v-if="showFaqDialog" v-model="showFaqDialog" /> -->
    <feedback-dialog v-if="showFeedbackDialog" v-model="showFeedbackDialog" />
  </v-app-bar>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Role } from '@/utils/helpers/permissions';
import { ClientConfig, DesktopFeature, UXConfig } from '@/utils/helpers/rest';
import BrowserNotification from '@/modules/notifications/components/BrowserNotification.vue';
import { LoginState } from '@/store/store';
// @TODO: temporarily disabled until FAQ content is updated
// import FaqPopup from '@/modules/faq/components/FaqPopup.vue';
import FeedbackDialog from '@/modules/feedback/components/FeedbackDialog.vue';
import { noop } from 'lodash';

@Component({
  components: {
    BrowserNotification,
    FeedbackDialog,
    // @TODO: temporarily disabled until FAQ content is updated
    // FaqPopup,
  },
  computed: {
    ...mapState(['loginState', 'clientConfig', 'desktopFeatures', 'uxConfig']),
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapActions(['fetchDesktopFeatures']),
  },
})
export default class Header extends Vue {
  public $refs!: {
    search: HTMLFormElement;
  };

  // @TODO: temporarily disabled until FAQ content is updated -->
  // protected showFaqDialog = false;
  protected showFeedbackDialog = false;
  protected roles = Role;
  protected searchSelected: DesktopFeature | null = null;
  protected searchResults: DesktopFeature[] = [];

  // store state/getters
  protected loginState!: LoginState;
  protected isLoggedIn!: boolean;
  protected clientConfig!: ClientConfig;
  private desktopFeatures!: DesktopFeature[];
  private uxConfig!: UXConfig;

  // store state mutations
  private fetchDesktopFeatures!: () => Promise<void>;

  protected get searchEnabled(): boolean {
    return this.uxConfig.hasGlobalSearch;
  }

  protected get currentPath(): string {
    return this.$route.fullPath;
  }

  protected get nameInitials(): string {
    if (!this.loginState.user) {
      return '';
    }
    return this.getInitials(this.loginState.user.name);
  }

  protected async mounted(): Promise<void> {
    try {
      await this.fetchDesktopFeatures();
      this.searchResults = this.desktopFeatures;
    } catch (e) {
      this.$log.warn(e);
    }
  }

  /**
   * Handles selection of a search suggestion from the dropdown
   */
  protected onSelectSearchResult(selected: DesktopFeature): void {
    if (this.$route.fullPath !== selected.link) {
      // suppress errors from redirection or duplicate navigation
      void this.$router.push(selected.link).catch(noop);
    }
    // clear input
    this.$nextTick(() => (this.searchSelected = null));
  }

  /**
   * Sets the focus on the search input
   */
  protected onSearchKey(): void {
    this.$nextTick(() => this.$refs.search.focus());
  }

  /**
   * The filtering algorithm used by autocomplete when searching
   */
  protected searchFilter(item: DesktopFeature, queryText: string, _itemText: string): boolean {
    const q = queryText.toLowerCase();
    return (
      item.category.toLocaleLowerCase().indexOf(q) > -1 ||
      item.title.toLocaleLowerCase().indexOf(q) > -1 ||
      item.description.toLocaleLowerCase().indexOf(q) > -1
    );
  }

  protected getInitials(name: string): string {
    let nameParts = name.split(' ');

    // don't risk butchering somebody's name if we can't make it fit,
    //  in that case just stick to the first initial.
    if (nameParts.length > 3) {
      nameParts = [nameParts[0]];
    }

    return nameParts.map((namePart) => namePart.substring(0, 1)).join('');
  }
}
</script>

<style lang="scss" scoped>
.global-search {
  // make the global search dropdown stand out
  .v-autocomplete__content.v-menu__content .v-select-list {
    background-color: #485c66;
  }
}

.system-env {
  text-transform: uppercase;
  margin-top: 2px;
}
</style>
