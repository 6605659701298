<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- add company account -->
    <v-btn class="ma-6" color="primary" @click="createCompany">
      <v-icon left> mdi-plus</v-icon>
      {{ $t('addBrokerCompany') }}
    </v-btn>

    <!-- search bar -->
    <v-text-field
      v-model="tableSearch"
      class="mx-6"
      clearable
      :label="$t('searchBrokerCompany')"
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>

    <v-data-table
      class="elevation-0 pb-8"
      dense
      disable-pagination
      fixed-header
      :headers="tableColumns"
      height="100%"
      hide-default-footer
      :items="companies"
      :no-data-text="$tc('no companies', companies.length, { count: companies.length })"
      :search="tableSearch"
      sort-by="name"
    >
      <!-- company icon -->
      <!-- eslint-disable-next-line vue/no-lone-template -->
      <template>
        <v-icon disabled small> mdi-briefcase</v-icon>
      </template>

      <!-- NSCC account -->
      <template #[`item.displayBoxID`]="{ item }">
        {{ item.displayBoxID != null ? item.displayBoxID : '*' }}
      </template>

      <!-- trading allowed by this company -->
      <template #[`item.tradingPermissions`]="{ item }">
        {{ $t(`tradingPermissions.options.${item.tradingPermissions}`) }}
      </template>

      <!-- Loanet integration -->
      <template #[`item.loanetIntegration`]="{ item }">
        <v-icon v-if="item.hasLoanetIntegration" color="grey" small>mdi-checkbox-marked</v-icon>
        <v-icon v-else color="grey" small>mdi-checkbox-blank-off-outline</v-icon>
      </template>

      <!-- Loanet integration -->
      <template #[`item.deliverOrderSender`]="{ item }">
        <span>{{ deliverOrderSenderDisplay[item.deliverOrderSender] }}</span>
      </template>

      <template #[`item.preferredIndependentAmountRate`]="{ item }">
        <rate-output :precision="2" :rate="item.preferredIndependentAmountRate" />
      </template>
      <template #[`item.preferredRoundingRule`]="{ item }">
        {{ roundingRuleToShortString(item.preferredRoundingRule) }}
      </template>

      <!-- action buttons -->
      <template #[`item.actions`]="{ item }">
        <v-tooltip color="primary" top>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                class="ma-1 mr-10"
                color="primary"
                icon
                :to="{ name: 'broker-admin.accounts.users', query: { filter: item.name } }"
                x-small
              >
                <v-icon> mdi-account-search </v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{ $t('adminSelectUsers') }}</span>
        </v-tooltip>

        <v-tooltip color="primary" top>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn class="ma-1" color="primary" icon x-small @click="editCompany(item)">
                <v-icon> mdi-briefcase-edit </v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{ $t('editBrokerCompany') }}</span>
        </v-tooltip>

        <v-tooltip color="primary" top>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn class="ma-1" color="primary" icon x-small @click="deleteCompany(item)">
                <v-icon> mdi-briefcase-minus </v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{ $t('deleteBrokerCompany') }}</span>
        </v-tooltip>

        <v-btn
          v-if="clientConfig.demoMode"
          class="demo-button"
          color="error"
          outlined
          x-small
          @click="deleteCompanyLoans(item)"
          >Wipe loans and orders
        </v-btn>
      </template>
    </v-data-table>

    <!-- at least 1 company before adding users! -->
    <v-alert
      v-if="!companies.length"
      border="left"
      colored-border
      elevation="2"
      text
      type="warning"
    >
      <span v-html="$t('noTraderCompanies')"></span>
    </v-alert>

    <!-- edit company popup -->
    <broker-company-dialog
      v-if="dialogOptions.isActive"
      :company="dialogBuffer"
      :crud-action="dialogOptions.crudAction"
      :title="dialogOptions.title"
      @close-modal="closeModal"
    ></broker-company-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import i18n from '@/localisation/i18n';
import { mapActions, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import BrokerCompanyDialog from '@/modules/broker-admin/components/BrokerCompanyDialog.vue';
import { RestOperation } from '@/utils/helpers/rest';
import { errorString } from '@/utils/helpers/rest-response';
import { getCurrencySymbol } from '@/utils/helpers/auction-numbers';
import { roundingRuleToShortString } from '@/modules/sec-lending/helpers/contract-details';
import { CompanyAccount, DeliverOrderSender } from '@/utils/api/broker';
import { DataTableHeader } from 'vuetify';
import { AppState } from '@/store/store';

const defaultCompany = {
  id: 0,
  name: '',
  kind: 'client',
  profileID: '',
  profileLabel: '',
  nsccAccounts: [],
  deliverOrderSender: 'self' as DeliverOrderSender,
  bilateralCounterparties: [],
};

const tableColumns: DataTableHeader[] = [
  { text: '', value: 'icon', sortable: false, width: 16, class: 'row-icon', cellClass: 'row-icon' },
  { text: i18n.tc('traderCompany'), value: 'name', class: 'text-no-wrap text-truncate' },
  { text: i18n.tc('nsccAccount'), value: 'displayBoxID', class: 'text-no-wrap text-truncate' },
  {
    text: i18n.tc('companyLoanet.table'),
    value: 'loanetIntegration',
    class: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('deliverOrderSender'),
    value: 'deliverOrderSender',
    class: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('companyCategory'),
    value: 'profileLabel',
    class: 'text-no-wrap text-truncate',
  },
  {
    text: i18n.tc('tradingPermissions.title'),
    value: 'tradingPermissions',
    class: 'text-no-wrap text-truncate',
  },
  {
    text: 'IA',
    value: 'preferredIndependentAmountRate',
    class: 'text-no-wrap text-truncate',
  },
  {
    text: 'RR',
    value: 'preferredRoundingRule',
    class: 'text-no-wrap text-truncate',
  },
  { text: '', value: 'actions', sortable: false },
];

@Component({
  components: {
    BrokerCompanyDialog,
  },
  methods: {
    ...mapActions(['fetchBrokerCompanies', 'deleteBrokerCompany', 'deleteBrokerCompanyLoans']),
  },
  computed: {
    ...mapState(['companies', 'clientConfig']),
  },
})
export default class BrokerCompanies extends Vue {
  // display text for deliver order sender values
  public deliverOrderSenderDisplay: { [key: string]: string } = {
    provable: i18n.tc('deliverOrderSender.options.provable'),
    loanet: i18n.tc('deliverOrderSender.options.loanet'),
    self: i18n.tc('deliverOrderSender.options.self'),
  };

  // Store state
  protected readonly companies!: AppState['companies'];
  protected readonly clientConfig!: NonNullable<AppState['clientConfig']>;

  // Store actions
  protected readonly fetchBrokerCompanies!: () => void;
  protected readonly deleteBrokerCompany!: (companyId: number) => void;
  protected readonly deleteBrokerCompanyLoans!: (companyId: number) => void;

  protected readonly roundingRuleToShortString = roundingRuleToShortString;

  protected baseCurrency = '';

  protected tableSearch = '';
  protected tableColumns = tableColumns;

  protected addBuffer: Partial<NullableAll<CompanyAccount>> | null = null;
  protected dialogBuffer: Partial<NullableAll<CompanyAccount>> | null = null;
  protected dialogOptions = {
    isActive: false,
    crudAction: RestOperation.Noop,
    title: 'Company',
    cancelOptions: ['escape', 'outside'], // no 'X' for real FORMs
  };

  protected async mounted(): Promise<void> {
    try {
      await this.fetchBrokerCompanies();
      this.baseCurrency = getCurrencySymbol(this.clientConfig.auctionMarketBaseCurrency);
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected createCompany(): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Create;
    this.dialogOptions.title = 'Add Company';

    // addBuffer is a 2nd buffer behind the dialog so that the admin
    // can escape from `Create New Company` and return with partially filled record
    // browsing the company list will not destroy the partially filled record
    if (this.addBuffer == null) {
      this.addBuffer = cloneDeep(defaultCompany);
    }
    this.dialogBuffer = this.addBuffer;
  }

  protected editCompany(rowCompany: CompanyAccount): void {
    this.dialogOptions.isActive = true;
    this.dialogOptions.crudAction = RestOperation.Update;
    this.dialogOptions.title = 'Change Company';
    // do not modify the row while typing
    this.dialogBuffer = cloneDeep(rowCompany);
  }

  protected closeModal(): void {
    // wipe company data
    this.dialogOptions.isActive = false;
    this.dialogOptions.crudAction = RestOperation.Noop;
    this.dialogOptions.title = '';
    this.addBuffer = null;
    this.dialogBuffer = null;
  }

  protected deleteCompany(rowCompany: CompanyAccount): void {
    this.$dialog.ask({
      title: i18n.t('deleteBrokerCompany'),
      color: 'error',
      icon: 'mdi-alert',
      message: i18n.t('deleteBrokerCompanyMessage', {
        companyName: rowCompany.name,
      }),
      acceptText: i18n.t('deleteBrokerCompany'),
      onAccept: () => this.doDeleteCompany(rowCompany),
      rejectText: i18n.t('cancelButton'),
    });
  }

  protected deleteCompanyLoans(rowCompany: CompanyAccount): void {
    this.$dialog.ask({
      title: i18n.t('deleteBrokerCompanyLoans'),
      color: 'error',
      icon: 'mdi-alert',
      message: i18n.t('deleteBrokerCompanyLoansMessage', {
        companyName: rowCompany.name,
      }),
      acceptText: i18n.t('deleteBrokerCompanyLoans'),
      onAccept: () => this.doDeleteCompanyLoans(rowCompany),
      rejectText: i18n.t('cancelButton'),
    });
  }

  private async doDeleteCompanyLoans(rowCompany: CompanyAccount) {
    try {
      await this.deleteBrokerCompanyLoans(rowCompany.id);

      this.deleteConfirmation('deletedBrokerCompanyLoans');
    } catch (e) {
      this.handleError(e);
    }
  }

  private async doDeleteCompany(rowCompany: CompanyAccount) {
    try {
      await this.deleteBrokerCompany(rowCompany.id);

      this.deleteConfirmation('deletedBrokerCompany');
    } catch (e) {
      this.handleError(e);
    }
  }

  private deleteConfirmation(msgKey: string) {
    this.$snackbar.confirm(i18n.tc(msgKey));
  }

  private handleError(err: unknown) {
    this.$snackbar.error(errorString(err as Error));
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .demo-button {
    margin-left: 32px;
  }
}
</style>
