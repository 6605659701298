<!--
  Parse and format a rate (available globally, no need to import)

  For flexibility, the rate prop can be a Decimal, string or number.
  However, normal usage is to pass a Decimal.

  Normal rate:
  ------------
      <rate-output rate="-1.234" /> => -1.2344%
      <rate-output rate="0.452" />  =>  0.4520%
      (no + sign for positive rates)

  With modifier:
  --------------
    <rate-output rate="-1.234" rate-modifier="OFBR" /> => OFBR - 1.2344%
    <rate-output rate="0.452" rate-modifier="OFBR" />  => OFBR + 0.4520%
    (it's an expression, adding or subtracting the offset from the modifier)

-->
<template>
  <span v-if="rateModifier !== Benchmark.NoBenchmark">
    <span :class="rateModifierSign === -1 ? 'modifier-minus' : 'modifier-plus'">
      {{ rateModifier }}
    </span>
    <span class="sign">
      {{ rateModifierSign === -1 ? '–' : '+' }}
    </span>
    <span>{{ floatingRate }}%</span>
  </span>
  <span v-else>{{ fixedRate }}% </span>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import Decimal from 'decimal.js';
import { RATE_PRECISION } from '@/modules/common/constants/precision';
import { Benchmark } from '@/utils/api/loans';

@Component({
  computed: {
    Benchmark() {
      return Benchmark;
    },
  },
  props: {
    rate: {
      type: [Number, String, Decimal],
      required: true,
    },
    rateModifier: {
      type: String as PropType<Benchmark>,
      default: Benchmark.NoBenchmark,
    },
    precision: {
      type: Number as PropType<number>,
      default: RATE_PRECISION,
    },
    trimZeros: {
      type: Boolean,
      default: false,
    },
  },
})
export default class RateOutput extends Vue {
  // props
  protected readonly rate!: Decimal | string | number;
  protected readonly rateModifier!: Benchmark;
  protected readonly precision!: number;
  protected readonly trimZeros!: boolean;

  protected get rateModifierSign(): number {
    return Math.sign(this.localRate.toNumber());
  }

  protected get precisionForRate(): number {
    return this.trimZeros
      ? Math.min(this.localRate.decimalPlaces(), this.precision)
      : this.precision;
  }

  protected get floatingRate(): string {
    return this.localRate.abs().toFixed(this.precisionForRate);
  }

  protected get fixedRate(): string {
    return this.localRate.toFixed(this.precisionForRate);
  }

  protected get localRate(): Decimal {
    return this.rate instanceof Decimal ? this.rate : new Decimal(this.rate);
  }

  protected created(): void {
    if (typeof this.rate === 'undefined') {
      throw new Error(
        'Component requires a rate prop of type Decimal | string | number. Did you forget to pass it?'
      );
    }
  }
}
</script>
<style lang="scss" scoped>
.modifier-plus {
  margin-right: 6px;
}

.modifier-minus {
  margin-right: 5px;
}

.sign {
  margin: 0 4px 0 0;
}
</style>
