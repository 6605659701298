<template>
  <v-container class="py-0" fluid>
    <div class="mt-4">
      <v-btn
        color="secondary"
        data-test-watchlist-tabs="show-watchlist-manager"
        :disabled="!hasTraderViewerRole"
        @click="showWatchlistManager = true"
      >
        Watchlists
      </v-btn>
    </div>
    <v-row class="mt-2 mb-4 d-flex align-center" no-gutters>
      <v-tabs background-color="transparent" show-arrows :value="selectedTabIndex">
        <v-tab data-test-watchlist-tab-name="all" @change="$emit('change', null)"> All</v-tab>

        <v-tab
          v-for="watchlist in watchlists"
          :key="watchlist.displayId"
          :data-test-watchlist-tab-name="watchlist.name"
          :value="watchlist.displayId"
          @change="$emit('change', watchlist.displayId)"
        >
          {{ watchlist.name }}
        </v-tab>
      </v-tabs>
    </v-row>

    <marketplace-top-of-book-watchlist-manager
      v-if="showWatchlistManager"
      data-test-watchlist-manager="manager-dialog"
      :watchlists="watchlists"
      @close-modal="showWatchlistManager = false"
      @refresh-watchlists="fetchWatchlists"
    />
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import Component from 'vue-class-component';
import MarketplaceTopOfBookWatchlistManager from '@/modules/marketplace/components/MarketplaceTopOfBookWatchlistManager.vue';

import { Api } from '@/modules/common/types/api';

@Component({
  props: {
    selectedDisplayId: String as PropType<string | null>,
  },
  components: {
    MarketplaceTopOfBookWatchlistManager,
  },
  computed: {
    ...mapGetters(['hasTraderViewerRole']),
  },
})
export default class MarketplaceTopOfBookWatchlistTabs extends Vue {
  // store
  protected hasTraderViewerRole!: boolean;

  // props
  protected readonly selectedDisplayId!: string | null;

  protected watchlists: Api.Marketplace.TopOfBookWatchlist[] = [];
  protected showWatchlistManager = false;

  protected get selectedTabIndex(): number {
    if (this.selectedDisplayId === null) {
      return 0;
    }
    return (
      // because first tab (All) is always present, subsequent tabs are at index + 1
      this.watchlists.findIndex((watchlist) => watchlist.displayId === this.selectedDisplayId) + 1
    );
  }

  protected mounted(): void {
    void this.fetchWatchlists();
  }

  protected async fetchWatchlists(): Promise<void> {
    this.watchlists = await this.$api.marketplace.fetchWatchlists();

    // if selected watchlist is no longer present, reset to "All"
    if (!this.watchlists.find((watchlist) => watchlist.displayId === this.selectedDisplayId)) {
      this.$emit('change', null);
    }
  }
}
</script>

<style lang="scss" scoped></style>
