<template>
  <div>
    <v-row align="center">
      <v-col class="col-4 py-1 text-capitalize">Counterparty</v-col>
      <v-col class="py-0">
        {{ liquidity.lender.name }}
      </v-col>
    </v-row>
    <v-row v-if="liquidity.roundingRule !== RoundingRule.NoRounding" align="center">
      <v-col class="col-4 py-1 text-capitalize">Rounding rule</v-col>
      <v-col class="py-0">
        {{ formatRoundingRule(liquidity.roundingRule) }}
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col class="col-4 py-1 text-capitalize">Quantity</v-col>
      <v-col class="py-0"><pretty-number :value="quantity" /></v-col>
    </v-row>

    <v-row align="center">
      <v-col class="col-4 py-1 text-capitalize">Rate</v-col>
      <v-col class="col-3 py-0"><rate-output :rate="liquidity.rate" /></v-col>
      <v-col v-if="equity.lastClosePrice && !liquidity.rate.isZero()" class="col-5 py-0">
        {{ formatPrice(contractDetails.dailyInterest) }}/day
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>

    <template v-if="equity.lastClosePrice && !equity.lastClosePrice.isZero()">
      <v-row align="center">
        <v-col class="col-4 py-1 text-capitalize">Last close price</v-col>
        <v-col class="py-0">{{ formatPrice(equity.lastClosePrice) }}</v-col>
      </v-row>

      <v-row align="center">
        <v-col class="col-4 py-1 text-capitalize">Contract amount</v-col>
        <v-col class="py-0"> {{ formatPrice(contractDetails.contractAmount) }}</v-col>
      </v-row>

      <template v-if="!liquidity.independentAmountRate.isZero()">
        <v-row align="center">
          <v-col class="col-4 py-1 text-capitalize">Independent amount rate</v-col>
          <v-col class="py-0">
            <rate-output :precision="2" :rate="liquidity.independentAmountRate" />
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col class="col-4 py-1 text-capitalize">Independent amount</v-col>
          <v-col class="py-0">
            {{ formatPrice(contractDetails.independentAmount) }}
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="col-4 py-1 text-capitalize">Settlement amount</v-col>
          <v-col class="py-0">
            {{ formatPrice(contractDetails.settlementAmount) }}
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-else>
      <v-row align="center">
        <v-col class="col-4 py-1 text-capitalize">Last close price</v-col>
        <v-col class="py-0">Unknown...</v-col>
      </v-row>
      <v-row v-if="!liquidity.independentAmountRate.isZero()" align="center">
        <v-col class="col-4 py-1 text-capitalize">Independent amount rate</v-col>
        <v-col class="py-0">
          <rate-output :precision="2" :rate="liquidity.independentAmountRate" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { BorrowLiquidityWithKey } from '@/utils/api/borrower';
import { formatDecimalAsCurrencyString } from '@/utils/helpers/auction-numbers';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import Decimal from 'decimal.js';
import {
  ContractDetails,
  RoundingRule,
  calculateContractDetailsForLiquidity,
  roundingRuleToString,
} from '@/modules/sec-lending/helpers/contract-details';
import { Equity } from '@/modules/common/types/api';

@Component({
  props: {
    equity: Object as PropType<Equity>,
    liquidity: Object as PropType<BorrowLiquidityWithKey>,
    quantity: Number,
  },
})
export default class BorrowDialogContractDetails extends Vue {
  protected equity!: Equity | null;
  protected liquidity!: BorrowLiquidityWithKey | null;
  protected quantity!: number | null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected RoundingRule = RoundingRule;

  protected get contractDetails(): ContractDetails {
    return calculateContractDetailsForLiquidity(this.quantity, this.equity, this.liquidity);
  }
  protected formatPrice(price: Decimal): string {
    return formatDecimalAsCurrencyString('USD', price, PRICE_PRECISION);
  }
  protected formatRoundingRule(roundingRule: RoundingRule): string {
    return roundingRuleToString(roundingRule);
  }
}
</script>
