<template>
  <!-- v-on="$listeners" binds the click event if it's passed -->
  <!-- useful because v-chip has different css:hover when @click is passed (or not) -->

  <v-chip
    v-if="loan.status === 'Open' && loan.pendingReturnQuantity > 0"
    color="warning"
    small
    v-on="$listeners"
  >
    Returning / <pretty-number :value="loan.pendingReturnQuantity" />
  </v-chip>

  <v-chip v-else-if="loan.pendingBuyInQuantity > 0" color="orange darken-2" small v-on="$listeners">
    Buying-in / <pretty-number :value="loan.pendingBuyInQuantity" />
  </v-chip>

  <v-chip v-else :color="getStatusColor(loan.status)" small v-on="$listeners">
    {{ $t(`loan-status-${loan.status}`) }}
  </v-chip>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { OpenLoan, statusColor } from '@/utils/api/loans';

@Component({
  props: {
    loan: Object as PropType<OpenLoan>,
  },
})
export default class LoanStatusChip extends Vue {
  protected readonly loan!: OpenLoan;

  protected getStatusColor = statusColor;
}
</script>
<style lang="scss" scoped>
.v-chip {
  text-transform: lowercase;
}
</style>
