<template>
  <v-dialog
    v-shortkey="['esc']"
    content-class="au-popup-dialog"
    max-width="700"
    overlay-color="secondary"
    overlay-opacity="0.80"
    persistent
    :value="true"
    @click:outside="!showSummary && closeDialog()"
    @keydown.esc="showSummary ? goBack() : closeDialog()"
    @shortkey.native="showSummary ? goBack() : closeDialog()"
  >
    <v-form novalidate @submit.prevent>
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ isEditMode ? 'Edit Demand' : 'Add Demand' }}
          </span>
          <v-spacer />
          <span class="text--primary headline-2">{{ equityDescription }}</span>
        </v-card-title>

        <v-card-text :class="{ 'show-summary': showSummary }">
          <!-- Input fields -->
          <v-container v-if="!showSummary" class="form-input pl-12">
            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize"> security</v-col>
              <v-col class="py-0">
                <simple-equity-search
                  v-model="equity"
                  :autofocus="!equity"
                  clearable
                  :disabled="isEditMode"
                  :error-messages="errorMsgs['equity']"
                  label="security"
                  @change="$v.equity.$touch()"
                  @input="$v.equity.$touch()"
                />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize"> quantity</v-col>
              <v-col class="py-0">
                <numeric-input
                  v-model="quantity"
                  :autofocus="!!equity"
                  data-test="quantity-input"
                  :error-messages="errorMsgs['quantity']"
                  label="quantity"
                  :min="0"
                  placeholder="0"
                  :step="100"
                  type="integer"
                  @blur="$v.quantity.$touch()"
                  @input="$v.quantity.$touch()"
                />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize"> limit</v-col>
              <v-col class="py-0">
                <numeric-input
                  v-model="rate"
                  data-test="rate-input"
                  :error-messages="errorMsgs['rate']"
                  label="limit"
                  placeholder="0.0000"
                  :precision="ratePrecision"
                  :step="0.25"
                  suffix="%"
                  type="decimal"
                  @blur="$v.rate.$touch()"
                  @change="onChangeRate()"
                  @input="$v.rate.$touch()"
                />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col class="col-4 py-1 text-capitalize"
                >{{ $tc('orders.timeInForce.title') }}
              </v-col>
              <v-col class="py-0">
                <v-select
                  v-model="timeInForce"
                  :error-messages="errorMsgs['timeInForce']"
                  item-text="option"
                  :items="timeInForceOptions"
                  :label="$t('orders.timeInForce.label')"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="clientConfig.demoMode" align="center">
              <v-col class="col-4 py-1 text-capitalize">Status</v-col>
              <v-col class="py-0">
                <v-checkbox v-model="isBorrowable" label="Activate this demand item" />
              </v-col>
            </v-row>
          </v-container>

          <!-- Form summary -->
          <v-container v-if="showSummary" v-shortkey="['enter']" @shortkey="submitForm()">
            <v-row class="text--primary">
              <v-col class="py-0">
                <div v-if="isEditMode">Make these changes to your demand item?</div>
                <div v-else>Create this new demand item?</div>
                <div v-if="isDuplicateDemand" class="orange--text" data-test="duplicate-warning">
                  A demand item with these details already exists
                </div>
              </v-col>
            </v-row>

            <div class="py-8 pl-10">
              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Security</v-col>
                <v-col class="py-0">
                  {{ equityDescription }}
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Quantity</v-col>
                <v-col class="py-0">
                  <pretty-number :value="quantity" />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Limit</v-col>
                <v-col class="py-0">
                  <rate-output :rate="rate" />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize">
                  {{ $tc('orders.timeInForce.title') }}
                </v-col>
                <v-col class="py-0">
                  {{ $tc(`orders.timeInForce.options.${timeInForce}`) }}
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col class="col-4 py-1 text-capitalize"> Status</v-col>
                <v-col class="py-0">
                  <v-chip :class="isBorrowable ? 'status-enabled' : 'status-disabled'" small>
                    {{ isBorrowableDisplay }}
                  </v-chip>
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row v-if="showError || showSuccess">
              <v-col class="pa-0 px-1 col-6 offset-3">
                <div
                  class="v-alert v-alert--dense text--primary text-body-2 text-center"
                  :class="{ error: showError, success: showSuccess }"
                >
                  <div v-if="showError">
                    {{ errorMsgs.apiErrors.join('\n') }}
                  </div>
                  <div v-if="showSuccess">
                    Your demand item has been {{ isEditMode ? 'updated' : 'created' }}.
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- Dialog box actions -->
        <v-card-actions class="d-flex">
          <div v-if="!showSummary" class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn color="secondary" data-test="cancel-btn" @click="closeDialog">
              {{ backButtonText }}
            </v-btn>
            <v-btn color="primary" type="submit" @click="goToSummary()">
              {{ isEditMode ? $t('dialogs.updateButton') : $t('dialogs.submitButton') }}
            </v-btn>
          </div>
          <div v-if="showSummary" class="d-flex flex-grow-1 justify-space-between align-end">
            <v-btn
              color="secondary"
              data-test="back-btn"
              :disabled="isProcessing"
              @click="goBack()"
            >
              {{ backButtonText }}
            </v-btn>

            <v-btn color="primary" :disabled="isProcessing" type="submit" @click="submitForm()">
              {{ $t('dialogs.confirmButton') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { decimal, integer, maxValue, minValue, required } from 'vuelidate/lib/validators';
import wait from '@/modules/common/services/wait';
import SimpleEquitySearch from '@/modules/manual-loan/components/SimpleEquitySearch.vue';
import { notGreaterThanPrecision } from '@/utils/helpers/custom-validators';
import { MAX_RATE, RATE_PRECISION } from '@/modules/common/constants/precision';
import { calculateOrderNotional, formatDecimalAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { PropType } from 'vue';
import { BorrowerOrderListItem } from '@/modules/order-management/types/borrower-orders';
import { Equity } from '@/modules/common/types/api';
import { TimeInForce, timeInForceOptions } from '@/utils/helpers/tif_options';
import { RiskLimitValidator } from '@/utils/helpers/risk-limit-validator';
import { LoginState } from '@/store/store';
import { ClientConfig } from '@/utils/helpers/rest';
import { canTradeEquity } from '@/utils/helpers/equity-validators';
import i18n from '@/localisation/i18n';

interface FormErrors {
  apiErrors: string[];
  equity: string[];
  quantity: string[];
  rate: string[];
  timeInForce: string[];
}

@Component({
  components: {
    SimpleEquitySearch,
  },
  props: {
    selectedItems: Array as PropType<BorrowerOrderListItem[]>,
  },
  methods: {
    ...mapActions(['fetchBorrowerOrders']),
  },
  computed: {
    ...mapState(['clientConfig', 'loginState']),
  },
  mixins: [validationMixin],
  validations: function (this: CreateBorrowerOrderDialog) {
    return {
      equity: {
        required,
        canTrade: canTradeEquity(),
      },
      quantity: {
        integer,
        required,
        minValue: minValue(1),
      },
      rate: {
        decimal,
        required,
        notGreaterThanPrecision: notGreaterThanPrecision(RATE_PRECISION),
        minValue: minValue(-MAX_RATE),
        maxValue: maxValue(MAX_RATE),
      },
      timeInForce: {
        required,
      },
    };
  },
})
export default class CreateBorrowerOrderDialog extends mixins(validationMixin) {
  // props
  protected readonly selectedItems?: BorrowerOrderListItem[];

  // store state/actions/etc
  protected fetchBorrowerOrders!: () => Promise<void>;
  protected clientConfig!: ClientConfig;
  protected loginState!: NonNullableAll<LoginState>;

  // form input
  protected equity: Equity | null = null;
  protected quantity: number | null = null;
  protected rate: Decimal | null = null;
  protected timeInForce = TimeInForce.Day;
  protected isBorrowable = true;

  protected showSummary = false;
  protected isProcessing = false;
  protected showSuccess = false;
  protected apiErrors: string[] = [];
  protected timeInForceOptions: TimeInForce[] = timeInForceOptions;
  protected isDuplicateDemand = false;
  protected ratePrecision = RATE_PRECISION;

  protected get errorMsgs(): FormErrors {
    const errors: FormErrors = {
      apiErrors: this.apiErrors,
      equity: [],
      quantity: [],
      rate: [],
      timeInForce: [],
    };

    // equity errors
    if (this.$v.equity.$dirty) {
      if (!this.$v.equity.required) errors.equity.push('please select a security.');
      if (this.equity && this.equity.cannotTradeMessage) {
        errors.equity.push(i18n.t(this.equity.cannotTradeMessage) as string);
      }
    }

    // quantity errors
    if (this.$v.quantity.$dirty) {
      if (!this.$v.quantity.required) errors.quantity.push('please enter a quantity.');
      if (!this.$v.quantity.integer) errors.quantity.push('please enter a valid quantity.');
      if (!this.$v.quantity.minValue) errors.quantity.push('must be greater than 0.');
    }

    // rate errors
    if (this.$v.rate.$dirty) {
      if (!this.$v.rate.required) errors.rate.push('please enter a rate.');
      if (!this.$v.rate.notGreaterThanPrecision) {
        errors.rate.push(`Rate must not be more than ${RATE_PRECISION} decimal places.`);
      }
      if (!this.$v.rate.minValue) {
        errors.rate.push(`Rate cannot be lower than -${this.formatRate(MAX_RATE)}.`);
      }
      if (!this.$v.rate.maxValue) {
        errors.rate.push(`Rate cannot be more than ${this.formatRate(MAX_RATE)}.`);
      }
    }

    // time-in-force errors
    if (this.$v.timeInForce.$dirty) {
      if (!this.$v.timeInForce.required) errors.timeInForce.push('please select time-in-force.');
    }

    return errors;
  }

  protected get showError(): boolean {
    return !!this.errorMsgs.apiErrors.length;
  }

  /**
   * the Borrower Order in the current list selection to edit
   */
  protected get orderToEdit(): BorrowerOrderListItem | null {
    return this.selectedItems?.[0] ? this.selectedItems[0] : null;
  }

  /**
   * indicates if we are editing an existing order or creating a new one
   */
  protected get isEditMode(): boolean {
    return !!this.orderToEdit;
  }

  protected get equityDescription(): string {
    return this.equity ? `${this.equity.ticker} [${this.equity.cusip}]` : '';
  }

  protected get isBorrowableDisplay(): string {
    return this.isBorrowable ? 'active' : 'disabled';
  }

  /**
   * get the text to display in the back button
   */
  protected get backButtonText(): string {
    if (this.showSummary) {
      return this.$tc('dialogs.goBackButton');
    } else {
      return this.$tc('dialogs.cancelButton');
    }
  }

  protected created(): void {
    // fill in existing order details to edit
    if (this.orderToEdit) {
      this.equity = this.orderToEdit.equity;
      this.quantity = this.orderToEdit.quantity;
      this.rate = this.orderToEdit.rate;
      this.timeInForce = this.orderToEdit.timeInForce;
      this.isBorrowable = this.orderToEdit.isBorrowable;
    }
  }

  protected onChangeRate(): void {
    // clear any previous API errors
    this.apiErrors = [];
  }

  protected goToSummary(): void {
    if (this.validateForm()) {
      // check for suspicious quantities
      //   if found: confirm or review order
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const notional = calculateOrderNotional(this.quantity, this.equity!.lastClosePrice);
      new RiskLimitValidator(this.$dialog, this.loginState.user).checkAndConfirmRiskLimits(
        notional,
        () => {
          this.$emit('toggle-can-cancel', false);
          this.showSummary = true;
          void this.checkForDuplicates();
        }
      );
    }
  }

  protected goBack(): void {
    this.showSummary = false;
    this.isDuplicateDemand = false;
  }

  protected async submitForm(): Promise<void> {
    // run validation
    if (this.isProcessing || !this.validateForm()) {
      return;
    }
    this.isProcessing = true;

    try {
      // validation has passed; safe to assert types
      const rate = this.rate as Decimal;
      const quantity = this.quantity as number;
      const timeInForce = this.timeInForce;
      const isBorrowable = this.isBorrowable;

      if (this.isEditMode) {
        const id = this.orderToEdit?.id as string;
        await this.$api.orderManagement.updateBorrowerOrder(
          id,
          quantity,
          rate,
          timeInForce,
          isBorrowable
        );
      } else {
        const equityId = this.equity?.id as number;
        await this.$api.orderManagement.createBorrowerOrder(
          equityId,
          quantity,
          rate,
          timeInForce,
          isBorrowable
        );
      }

      // success, alert user and then close form
      this.showSuccess = true;

      // reload lender orders in the background
      void this.fetchBorrowerOrders();

      await wait(1200);
      await this.closeDialog();
    } catch (err) {
      this.apiErrors = [`${err}`];
    } finally {
      this.isProcessing = false;
    }
  }

  protected closeDialog(): void {
    // deselect item(s) in parent list and close modal
    this.$emit('update:selectedItems', []);
    this.$emit('close-modal');

    // close risk-validator popups
    this.$dialog.close();
  }

  protected formatRate(rate: number): string {
    return formatDecimalAsString(new Decimal(rate), this.ratePrecision);
  }

  private validateForm(): boolean {
    this.$v.$reset();
    this.apiErrors = [];
    this.$v.$touch();
    return !this.$v.$anyError;
  }

  /**
   * Checks to see if a Borrower Order with these details already exists
   */
  private async checkForDuplicates(): Promise<void> {
    if (!this.validateForm()) {
      return;
    }

    // validation has passed; safe to assert types
    const rate = this.rate as Decimal;
    const equityId = this.equity?.id as number;

    this.isProcessing = true;
    try {
      const result = await this.$api.orderManagement.checkBorrowerOrderExistence(equityId, rate);

      this.isDuplicateDemand =
        result.exists && (this.orderToEdit?.id ? !result.ids.includes(this.orderToEdit?.id) : true);
    } finally {
      this.isProcessing = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  min-height: 22rem;
  max-height: 24rem;
  transition:
    min-height 250ms linear,
    max-height 250ms linear;

  &.show-summary {
    min-height: 23rem;
    max-height: 25rem;
  }
}

// @TODO use sass variables to store these colours
.v-chip {
  &.status-enabled {
    color: #fff;
    background: #4caf50;
  }

  &.status-disabled {
    color: #fff;
    background: #7a7a7a;
  }
}
</style>
