<template>
  <div>
    <div class="table-container">
      <div>
        <v-data-table
          disable-filtering
          disable-pagination
          disable-sort
          fixed-header
          :headers="tableHeaders"
          hide-default-footer
          item-key="key"
          :items="filteredHistory"
          width="100%"
        >
          <template #item="{ item, index }">
            <tr>
              <td class="highlight">
                {{ formatDate(item.eventTimestamp) }}
              </td>
              <td>
                <v-chip
                  class="text-uppercase mr-2"
                  :color="getEventTypeColor(item.eventType)"
                  x-small
                >
                  <span> {{ $tc(`orderHistory.eventType.${item.eventType}`) }} </span>
                </v-chip>
                <v-btn
                  v-if="item.eventType === 'EXECUTED' && item.loanId"
                  class="icon-action"
                  data-test="open-loans-details"
                  icon
                  size="24"
                  @click="$emit('open-loan-details', item.loanId)"
                >
                  <v-icon size="24">mdi-eye</v-icon>
                </v-btn>

                <v-tooltip v-else-if="item.eventType === 'EXECUTED' && item.loanId === null" bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="icon-action tooltip-icon"
                      data-test="open-loans-details"
                      icon
                      size="24"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Processing execution, details not available yet</span>
                </v-tooltip>
              </td>
              <td :class="cellHighlight('routingStatus', index)">
                {{
                  item.summary.routingStatus === 'routed'
                    ? 'Active'
                    : item.summary.routingStatus === 'pending'
                      ? 'Pending'
                      : '–'
                }}
              </td>
              <td :class="cellHighlight('quantity', index)">
                <pretty-number :value="item.summary.quantity" />
              </td>
              <td :class="cellHighlight('openQuantity', index)">
                <pretty-number :value="item.summary.quantity - item.summary.filled" />
              </td>

              <td :class="cellHighlight('filled', index)">
                <pretty-number :value="item.summary.filled" />
              </td>
              <td class="text-end" :class="cellHighlight('rate', index)">
                <rate-output :rate="item.summary.rate" />
              </td>
              <td class="text-end" :class="cellHighlight('avgExecutionRate', index)">
                <span v-if="item.summary.avgExecutionRate === null">–</span>
                <rate-output v-else :rate="item.summary.avgExecutionRate" />
              </td>
              <td :class="cellHighlight('counterparties', index)">
                <template v-if="item.summary.counterparties">
                  <span
                    v-for="(counterparty, ctptyIndex) in item.summary.counterparties"
                    :key="counterparty.companyId"
                  >
                    <span v-if="ctptyIndex !== 0">, </span>
                    <span :title="formatCompanyName(counterparty)">
                      {{ formatCompanyBoxId(counterparty) }}
                    </span>
                  </span>
                </template>
                <template v-else> Any </template>
              </td>
              <td v-if="visibleCols['minQuantity']" :class="cellHighlight('minQuantity', index)">
                <pretty-number :value="item.summary.minQuantity" />
              </td>
              <td v-if="visibleCols['orderType']" :class="cellHighlight('orderType', index)">
                <span class="text-capitalize">{{ item.summary.orderType }}</span>
              </td>
              <td
                v-if="visibleCols['timeInForceType']"
                :class="cellHighlight('timeInForceType', index)"
              >
                {{ timeInForceAbbr(item.summary.timeInForceType) }}
              </td>
              <td
                v-if="visibleCols['independentAmountRate']"
                :class="cellHighlight('independentAmountRate', index)"
              >
                <RateOutput :precision="2" :rate="item.summary.independentAmountRate" />
              </td>
              <td v-if="visibleCols['roundingRule']" :class="cellHighlight('roundingRule', index)">
                {{ roundingRuleToShortString(item.summary.roundingRule) }}
              </td>
              <td>
                <format-event-initiator :initiator="item.initiator" />
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- Filter by eventType -->
    <v-row class="mt-4 filter-by">
      <v-col class="col-12 d-flex align-center justify-end">
        <span v-if="filterableEvents.length" class="text--primary mr-2">Filter by</span>
        <v-chip-group v-model="selectedEvents" column multiple>
          <v-chip
            v-for="eventType in filterableEvents"
            :key="eventType"
            :color="getEventTypeColor(eventType)"
            filter
            outlined
            small
            :value="eventType"
          >
            {{ $t(`orderHistory.eventType.${eventType}`) }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import {
  getEventTypeColor,
  getStatus,
  timeInForceAbbr,
  timeInForceLabel,
} from '@/modules/marketplace/helpers/marketplace';
import { Api } from '@/modules/common/types/api';
import { formatDate } from '@/utils/helpers/dates';
import Decimal from 'decimal.js';
import { OrderEventType } from '@/modules/marketplace/types/marketplace';
import FormatEventInitiator from '@/modules/common/components/FormatEventInitiator.vue';
import { Watch } from 'vue-property-decorator';
import { roundingRuleToShortString } from '@/modules/sec-lending/helpers/contract-details';
import {
  formatCompanyBoxId,
  formatCompanyName,
} from '@/modules/user-accounts/helpers/user-accounts';

const defaultSelectedEvents: OrderEventType[] = [
  'CREATED',
  'MODIFIED',
  'CANCELED',
  'EXPIRED',
  'EXECUTED',
  'CLOSED',
];

const allTableHeaders: DataTableHeader[] = [
  { text: 'When', width: '10rem', value: 'eventTimestamp' },
  { text: 'Event', width: '10rem', value: 'eventType' },
  { text: 'Active', value: 'routingStatus' },
  { text: 'Order Qty.', value: 'quantity' },
  { text: 'Open Qty.', value: 'openQuantity' },
  { text: 'Exec Qty.', value: 'filled' },
  { text: 'Rate Limit', align: 'end', value: 'rate' },
  { text: 'Avg Exec Rate', value: 'avgExecutionRate', align: 'end' },
  { text: 'Counterparty', value: 'counterparty' },
  { text: 'Min Qty.', value: 'minQuantity' },
  { text: 'Type', value: 'orderType' },
  { text: 'Time In Force', value: 'timeInForceType' },
  { text: 'IA Limit', value: 'independentAmountRate' },
  { text: 'Rounding Rule', value: 'roundingRule' },
  { text: 'Initiator', value: 'initiator' },
];

@Component({
  methods: { formatCompanyName, formatCompanyBoxId, roundingRuleToShortString },
  props: {
    details: Object as PropType<Api.Marketplace.OrderDetails>,
  },
  components: {
    FormatEventInitiator,
  },
})
export default class MarketplaceOrderHistory extends Vue {
  // props
  protected readonly details!: Api.Marketplace.OrderDetails;

  protected getStatus = getStatus;
  protected getEventTypeColor = getEventTypeColor;
  protected timeInForceLabel = timeInForceLabel;
  protected timeInForceAbbr = timeInForceAbbr;

  protected selectedEvents = [...defaultSelectedEvents];

  protected readonly unfilterableEventTypes: OrderEventType[] = ['CREATED'];

  protected get tableHeaders(): DataTableHeader[] {
    return allTableHeaders.filter((header) => this.visibleCols[header.value]);
  }

  protected get filteredHistory(): Api.Marketplace.OrderHistoryItem[] {
    return this.details.history.filter((i) => {
      return this.selectedEvents.includes(i.eventType);
    });
  }

  /*
   * UI only displays eventType that can be filtered
   * Return a list of unique eventType based on the fetched history data
   */
  protected get filterableEvents(): OrderEventType[] {
    return this.details.history.reduce<OrderEventType[]>((filterable, event) => {
      if (
        !filterable.includes(event.eventType) &&
        !this.unfilterableEventTypes.includes(event.eventType)
      ) {
        filterable.push(event.eventType);
      }
      return filterable;
    }, []);
  }

  protected get visibleCols(): Record<string, boolean> {
    // certain columns are always displayed
    const visibleCols = {
      eventTimestamp: true,
      eventType: true,
      routingStatus: true,
      quantity: true,
      openQuantity: true,
      filled: true,
      rate: true,
      avgExecutionRate: true,
      counterparty: true,
      initiator: true,
    };

    // other columns are only displayed if there there are multiple values between rows
    const colsToCheck = allTableHeaders.map((i) => i.value).filter((i) => !visibleCols[i]);
    for (const name of colsToCheck) {
      const uniqueCount = new Set(
        this.filteredHistory.map((i) => {
          return i.summary[name] instanceof Decimal ? i.summary[name].toString() : i.summary[name];
        })
      ).size;
      visibleCols[name] = uniqueCount > 1;
    }

    return visibleCols;
  }

  /*
   * Because the component stays mounted, we need to reset the "selectedEvents"
   * (v-chip-group automatically unselects items not present in "filterableEvents",
   * resulting in  stale "selectedEvents" when the parent component changes the "details")
   */
  @Watch('details')
  protected onDetailsChange(): void {
    this.selectedEvents = [...defaultSelectedEvents];
  }

  /*
   * 1) Always highlight all cells of the first event (length - 1)
   * 2) Highlight if current event cell value is different from previous event
   */
  protected cellHighlight(name: string, index: number): 'highlight' | '' {
    if (index === this.filteredHistory.length - 1) {
      return 'highlight';
    }

    // previous item is index + 1 (list is reversed)
    const previousRow = this.filteredHistory[index + 1].summary;
    const currentRow = this.filteredHistory[index].summary;

    if (name === 'openQuantity') {
      // calculate openQuantity and compare
      return currentRow.quantity - currentRow.filled === previousRow.quantity - previousRow.filled
        ? ''
        : 'highlight';
    }

    const previousCell = previousRow[name];
    const currentCell = currentRow[name];

    if (previousCell instanceof Decimal) {
      return previousCell.eq(currentCell) ? '' : 'highlight';
    }

    if (typeof previousCell === 'object') {
      return previousCell?.companyId === currentCell?.companyId ? '' : 'highlight';
    }

    return previousCell === currentCell ? '' : 'highlight';
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MMM d h:mm a');
  }
}
</script>
<style lang="scss" scoped>
.filter-by {
  background-color: #1e1e1e;
  position: sticky;
  bottom: 0;
  .theme--light & {
    background: white;
  }
}

.tooltip-icon {
  margin-left: 6px;
}

.table-container tr,
.summary {
  /* Tone down grey to increase contrast between bold and non-bold text */
  color: #888;
}

.highlight,
.summary strong {
  color: white;
  font-weight: bold;
}

.theme--light .table-container tr,
.theme--light .summary {
  color: #777;
}

.theme--light .highlight,
.theme--light .summary strong {
  color: black;
}

::v-deep {
  .v-card {
    min-height: 60vh;
  }

  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}
</style>
