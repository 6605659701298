<template>
  <span>
    <marketplace-top-of-book-watchlist-form
      v-if="createFormStatus !== 'closed' || editDisplayId"
      :display-id="editDisplayId"
      :initial-step="createFormStatus === 'upload' ? 'upload' : 'form'"
      @close-modal="
        editDisplayId = null;
        createFormStatus = 'closed';
      "
      @refresh-watchlists="$emit('refresh-watchlists')"
    />

    <v-dialog
      v-shortkey="['esc']"
      content-class="au-popup-dialog"
      max-width="600"
      overlay-color="secondary"
      overlay-opacity="0.80"
      persistent
      :value="true"
      @click:outside="closeDialog()"
      @keydown.esc="closeDialog()"
      @shortkey.native="closeDialog()"
    >
      <v-form novalidate @submit.prevent>
        <v-card class="d-flex flex-column">
          <v-card-title>
            <span class="headline">Watchlists</span>
            <v-btn
              class="icon-create ml-4"
              color="primary"
              data-test-watchlist-manager="create"
              split
              @click="createFormStatus = 'form'"
            >
              Create New
            </v-btn>
            <v-btn
              class="icon-upload"
              color="primary"
              data-test-watchlist-manager="upload"
              @click="createFormStatus = 'upload'"
            >
              <v-icon size="24"> mdi-cloud-upload </v-icon>
            </v-btn>
            <v-btn class="close-icon" icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
              <div class="table-container">
                <div>
                  <v-data-table
                    disable-filtering
                    disable-pagination
                    disable-sort
                    fixed-header
                    hide-default-footer
                    item-key="key"
                    :items="watchlists"
                    no-data-text="You haven't created any watchlists yet."
                    width="100%"
                  >
                    <template #item="{ item }">
                      <tr>
                        <td>
                          {{ item.name }}
                        </td>
                        <td class="text-right">
                          <v-btn
                            class="icon-action"
                            data-test-watchlist-manager="edit"
                            icon
                            @click="editDisplayId = item.displayId"
                          >
                            <v-icon size="24">mdi-pencil</v-icon>
                          </v-btn>
                          <confirm-dialog-wrapper
                            :options="{
                              message: `You are about to permanently delete watchlist ${item.name}.`,
                              title: 'Delete Watchlist',
                              rejectText: 'Back',
                              acceptText: 'Delete',
                            }"
                          >
                            <template #default="{ confirm }">
                              <v-btn
                                class="icon-action"
                                data-test-watchlist-manager="remove"
                                icon
                                @click="confirm(() => remove(item))"
                              >
                                <v-icon size="24">mdi-close-circle</v-icon>
                              </v-btn>
                            </template>
                          </confirm-dialog-wrapper>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </span>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import MarketplaceTopOfBookWatchlistForm from '@/modules/marketplace/components/MarketplaceTopOfBookWatchlistForm.vue';
import { Api } from '@/modules/common/types/api';

@Component({
  props: {
    watchlists: Array as PropType<Api.Marketplace.TopOfBookWatchlist[]>,
  },
  components: {
    MarketplaceTopOfBookWatchlistForm,
  },
})
export default class MarketplaceTopOfBookWatchlistManager extends Vue {
  // props
  protected readonly watchlists!: Api.Marketplace.TopOfBookWatchlist[];

  protected createFormStatus: 'closed' | 'form' | 'upload' = 'closed';
  protected editDisplayId: string | null = null;

  protected async remove(watchlist: Api.Marketplace.TopOfBookWatchlist): Promise<void> {
    try {
      await this.$api.marketplace.deleteWatchlist(watchlist.displayId);
      this.$emit('refresh-watchlists');
      this.$snackbar.confirm(`Watchlist ${watchlist.name} successfully removed`);
    } catch (err) {
      this.$snackbar.error(`Failed to remove watchlist ${watchlist.name}`);
    }
  }

  protected closeDialog(): void {
    this.$emit('close-modal');
  }
}
</script>

<style lang="scss" scoped>
.close-icon {
  top: 0.4rem;
  right: 0.4rem;
  position: absolute;
}

::v-deep {
  .v-data-table,
  .v-data-table > div {
    width: 100%;
    display: flex;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 50vh;
}

.table-container > div {
  display: flex;
  overflow: hidden;
}

.icon-action {
  opacity: 0.4;
  transition: opacity linear 0.15s;
}

.icon-action:hover {
  opacity: 1;
}

.icon-create.primary {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0 1px 0 0;
}

.icon-upload.primary {
  width: 24px;
  min-width: 20px;
  padding-left: 24px;
  padding-right: 24px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
