<template>
  <v-data-table
    disable-filtering
    disable-pagination
    fixed-header
    :headers="tableHeaders"
    hide-default-footer
    item-key="id"
    :items="items"
    must-sort
    :show-select="false"
    width="100%"
    v-on="$listeners"
  >
    <template #item="{ item }">
      <!-- it was difficult to make the highlight class work in the <tr>,
           so instead they appear many times in the <td>s -->
      <tr>
        <td :class="getHighlightClass(item)">
          {{ item.ticker }}
        </td>
        <td :class="getHighlightClass(item)">
          {{ item.cusip }}
        </td>
        <td :class="getHighlightClass(item)">
          {{ prettyNumber(item.currentQuantity) }}
        </td>
        <td class="text-end" :class="getHighlightClass(item)">
          ${{ formatPrice(item.lastClosePrice) }}
        </td>
        <td class="text-end" :class="getHighlightClass(item)">
          <rate-output
            :class="{ 'text-red': item.hasExceeded }"
            :precision="2"
            :rate="item.position"
          />
        </td>
        <td v-if="side === 'borrower'" class="d-flex text-start" :class="getHighlightClass(item)">
          <div class="pa-2x single-input d-flex align-center" data-test="single-input">
            <numeric-input
              class="total-quantity"
              data-test="total-quantity"
              dense
              :error-messages="item.hasExceeded ? ['hidden error, triggers border color only'] : []"
              :min="0"
              outlined
              placeholder="0"
              small
              :step="100"
              type="integer"
              :value="item.newQuantity"
              @input="
                $emit('update-single-quantity', { item, quantity: $event === null ? 0 : $event })
              "
            />
          </div>
        </td>
        <td class="text-end" :class="getHighlightClass(item)">
          ${{ formatPrice(item.newQuantity * item.lastClosePrice) }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { formatPrettyNumber } from '@/modules/common/components/pretty-number';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { Api } from '@/modules/common/types/api';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';

const allTableHeaders: DataTableHeader[] = [
  {
    text: 'Ticker',
    value: 'equity.ticker',
    align: 'start',
    width: '6rem',
    sortable: false,
  },
  {
    text: 'CUSIP',
    value: 'equity.cusip',
    align: 'start',
    width: '5rem',
    sortable: false,
  },
  {
    text: 'Current Quantity',
    value: 'currentQuantity',
    align: 'start',
    width: '8rem',
    sortable: false,
  },
  {
    text: 'Price',
    value: 'lastClosePrice',
    align: 'end',
    sortable: false,
  },
  {
    text: 'Position',
    value: 'position',
    align: 'end',
    sortable: false,
  },
  {
    text: 'New Quantity',
    value: 'newQuantity',
    align: 'start',
    width: '8rem',
    sortable: false,
  },
  {
    text: 'Value',
    value: 'totalValue',
    align: 'end',
    sortable: false,
  },
];

@Component({
  props: {
    side: {
      type: String as PropType<'borrower' | 'lender'>,
      required: true,
    },
    items: {
      type: Array as PropType<Api.TermLoans.UpdatedItem[]>,
      required: true,
    },
  },
})
export default class TermLoansManageContractEditTable extends Vue {
  protected readonly side!: 'borrower' | 'lender';
  protected readonly items!: Api.TermLoans.UpdatedItem[];

  protected get tableHeaders(): DataTableHeader[] {
    return allTableHeaders.filter((header) => {
      return (
        (header.value !== 'newQuantity' && header.value !== 'newQuantityError') ||
        this.side === 'borrower'
      );
    });
  }

  protected getHighlightClass(item: Api.TermLoans.UpdatedItem): string {
    return item.currentQuantity !== item.newQuantity ? 'highlight' : '';
  }

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }

  protected prettyNumber(value: number): string {
    return formatPrettyNumber(value);
  }
}
</script>
<style lang="scss" scoped>
.single-input {
  scale: 0.75;
  margin: 3px 0 0 -20px;
}

::v-deep {
  .v-data-table__wrapper {
    width: 100%;
  }

  input[type='text'] {
    color: #888;
  }

  .highlight input[type='text'] {
    color: white;
  }
}

.table-container tr,
.summary {
  /* Tone down grey to increase contrast between bold and non-bold text */
  color: #888;
}

.highlight,
.summary strong {
  color: white;
}

.theme--light .table-container tr,
.theme--light .summary {
  color: #777;
}

.theme--light .highlight,
.theme--light .summary strong {
  color: black;
}

::v-deep {
  .v-text-field__details {
    display: none;
  }
}

.text-red {
  color: #ff5252;
}
</style>
