<template>
  <!-- template must have 1 direct child, we wrap the contents in a <span>
       with "display: contents", making sure layout rendering is not affected -->
  <span style="display: contents">
    <open-loans-action-manager ref="actionManager" />
    <v-data-table
      ref="tableRef"
      v-model="localSelectedItems"
      class="d-flex flex flex-column last-col-sticky"
      dense
      fixed-header
      :footer-props="{ itemsPerPageOptions: [10, 50, 100, 200] }"
      :headers="tableColumns"
      height="100%"
      :hide-default-footer="selectedItems && selectedItems.length > 0"
      item-key="id"
      :items="items"
      :items-per-page="itemsPerPage"
      must-sort
      :page="page"
      :server-items-length="serverItemsLength"
      :show-select="showSelect"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      v-on="$listeners"
      @current-items="onChangeItems"
      @toggle-select-all="toggleOpenLoans"
    >
      <template #[`header.data-table-select`]="{ props, on }">
        <v-simple-checkbox
          :indeterminate-icon="hasSelectedAllOpenLoans ? 'mdi-checkbox-marked' : 'mdi-minus-box'"
          v-bind="props"
          :ripple="false"
          v-on="on"
        />
      </template>
      <template #[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          :disabled="isTerminalLoanStatus(item.status)"
          :off-icon="
            isTerminalLoanStatus(item.status) ? 'mdi-checkbox-blank-off-outline' : '$checkboxOff'
          "
          :ripple="false"
          :value="isSelected && !isTerminalLoanStatus(item.status)"
          @input="select($event)"
        />
      </template>

      <!-- Table rows -->
      <template #[`item.status`]="{ item }">
        <loan-status-chip class="justify-center" :loan="item" @click="$emit('view-loan', item)" />
      </template>

      <template #[`item.updatedAt`]="{ item }">
        {{ formatTimestamp(item.updatedAt) }}
      </template>

      <template #[`item.side`]="{ item }">
        <format-side :side="item.side" />
      </template>

      <template #[`item.settlementType`]="{ item }">
        {{ settlementTypeDisplayText[item.settlementType] }}
      </template>

      <template #[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template #[`item.equity.ticker`]="{ item }">
        <div class="d-flex">
          <span>{{ item.equity.ticker }}</span>
          <v-tooltip v-if="clientConfig.corpActionsEnabled" data-test="corp-actions-button" top>
            <template #activator="{ on, attrs }">
              <button
                v-if="corporateActions[item.equity.cusip]"
                class="corp-actions-button"
                v-bind="attrs"
                small
                v-on="on"
                @click="$emit('view-loan', item, 'corporateActions')"
              >
                <v-icon color="purple darken-1" small> mdi-flash</v-icon>
              </button>
            </template>
            <span>Upcoming events</span>
          </v-tooltip>
        </div>
      </template>

      <template #[`item.openQuantity`]="{ item }">
        <span :title="`Original: ${item.openQuantity + item.returnedQuantity}`">
          <pretty-number :value="item.openQuantity" />
        </span>
      </template>

      <template #[`item.returnedQuantityToday`]="{ item }">
        <pretty-number :value="item.returnedQuantityToday" />
      </template>

      <template #[`item.contractAmount`]="{ item }">
        ${{ formatPrice(item.contractAmount) }}
      </template>

      <template #[`item.settlementAmount`]="{ item }">
        ${{ formatPrice(item.settlementAmount) }}
      </template>

      <template #[`item.rate`]="{ item }">
        <rate-output :rate="item.rate" :rate-modifier="item.rateModifier" />
      </template>

      <template #[`item.independentAmountRate`]="{ item }">
        <rate-output :precision="2" :rate="item.independentAmountRate" />
      </template>

      <template #[`item.renegotiateRate`]="{ item }">
        <div v-if="item.renegotiation && !isTerminalLoanStatus(item.status)">
          <v-chip class="justify-center text-uppercase mr-2" x-small>
            {{ item.renegotiation.side === item.side ? 'you' : 'contra' }}
          </v-chip>
          <rate-output
            :rate="item.renegotiation.rate"
            :rate-modifier="item.renegotiation.rateModifier"
          />
        </div>
      </template>

      <template #[`item.recalledQuantity`]="{ item }">
        <template v-if="item.recalledQuantity > 0">
          <pretty-number :value="item.recalledQuantity" />
        </template>
      </template>

      <template #[`item.nextAllowedBuyInExecutionDate`]="{ item }">
        <template v-if="item.recalledQuantity > 0">
          {{ formatDate(item.nextAllowedBuyInExecutionDate) }}
        </template>
      </template>

      <template #[`item.sponsorshipSide`]="{ item }">
        <span class="text-capitalize">
          {{ item.sponsorshipSide !== null ? item.sponsorshipSide : '' }}
        </span>
      </template>

      <template #[`item.termContractDisplayId`]="{ item }">
        <span>
          {{ item.termContractDisplayId !== null ? item.termContractDisplayId : '' }}
        </span>
      </template>

      <!-- Dropdown actions -->
      <template #[`item.actions`]="{ item }">
        <div class="actions d-flex justify-end">
          <v-btn
            class="icon-action"
            data-test="view-loan"
            icon
            x-small
            @click="$emit('view-loan', item)"
          >
            <v-icon size="24">mdi-eye</v-icon>
          </v-btn>
          <btn-dropdown
            v-if="showDropdownActions"
            color="secondary"
            data-test="actions-dropdown"
            :disabled="!hasOpsUserRole || Object.keys(item.availableActions).length === 0"
            main-text="Actions"
            x-small
          >
            <v-list ref="dropdown-actions" dense>
              <aurora-list-item
                v-if="item.availableActions.cancelPending"
                data-test="cancel-pending-menu-item"
                timeframe="cancelLoans"
                @click="$refs.actionManager.trigger({ name: 'cancelPendingLoan', loan: item })"
              >
                <v-list-item-title>Cancel</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="item.availableActions.return"
                data-test="return-menu-item"
                timeframe="settleLoans"
                @click="$refs.actionManager.trigger({ name: 'return', loan: item })"
              >
                <v-list-item-title>Return</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="item.availableActions.cancelReturn"
                data-test="cancel-return-menu-item"
                timeframe="settleLoans"
                @click="$refs.actionManager.trigger({ name: 'cancelPendingReturns', loan: item })"
              >
                <v-list-item-title>Cancel return</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="item.availableActions.recall"
                data-test="recall-menu-item"
                timeframe="recallLoans"
                @click="$refs.actionManager.trigger({ name: 'recall', loan: item })"
              >
                <v-list-item-title> Add recall</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="item.availableActions.updateRecall"
                data-test="update-recall-menu-item"
                timeframe="recallLoans"
                @click="$emit('view-loan', item, 'recalls')"
              >
                <v-list-item-title> Manage recalls</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="item.availableActions.buyIn"
                data-test="buy-in-menu-item"
                timeframe="buyInLoans"
                @click="$refs.actionManager.trigger({ name: 'buyIn', loan: item })"
              >
                <v-list-item-title> Buy-in</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="
                  item.availableActions.renegotiateFixed ||
                  item.availableActions.renegotiateFloating
                "
                data-test="renegotiate-menu-item"
                :disabled="!hasTraderUserRole"
                timeframe="rateModLoans"
                @click="$refs.actionManager.trigger({ name: 'renegotiate', loan: item })"
              >
                <v-list-item-title> Rerate</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="item.availableActions.cancelRenegotiate"
                data-test="cancel-renegotiate-menu-item"
                :disabled="!hasTraderUserRole"
                @click="$refs.actionManager.trigger({ name: 'cancelRenegotiate', loan: item })"
              >
                <v-list-item-title> Cancel rerate</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="item.availableActions.acceptRenegotiate"
                data-test="accept-renegotiate-menu-item"
                :disabled="!hasTraderUserRole"
                timeframe="rateModLoans"
                @click="$refs.actionManager.trigger({ name: 'acceptRenegotiate', loan: item })"
              >
                <v-list-item-title> Accept rerate</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="item.availableActions.rejectRenegotiate"
                data-test="reject-renegotiate-menu-item"
                :disabled="!hasTraderUserRole"
                timeframe="rateModLoans"
                @click="$refs.actionManager.trigger({ name: 'rejectRenegotiate', loan: item })"
              >
                <v-list-item-title> Reject rerate</v-list-item-title>
              </aurora-list-item>

              <aurora-list-item
                v-if="
                  item.availableActions.acceptRenegotiate || item.availableActions.rejectRenegotiate
                "
                data-test="reject-and-counter-renegotiate-menu-item"
                :disabled="!hasTraderUserRole"
                timeframe="rateModLoans"
                @click="
                  $refs.actionManager.trigger({
                    name: 'rejectRenegotiateAndCounterOffer',
                    loan: item,
                  })
                "
              >
                <v-list-item-title> Reject & Counter Rerate</v-list-item-title>
              </aurora-list-item>
            </v-list>
          </btn-dropdown>
        </div>
      </template>

      <!-- summary row -->
      <template v-if="selectedItems && selectedItems.length > 0" #[`body.append`]>
        <open-loans-aggregates :items="selectedItems" :table-columns="tableColumns" />
      </template>
    </v-data-table>
  </span>
</template>

<script lang="ts">
import BtnDropdown from '@/modules/common/components/BtnDropdown.vue';
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { settlementTypeDisplayText } from '@/modules/marketplace/helpers/marketplace';
import LoanStatusChip from '@/modules/open-loans/components/LoanStatusChip.vue';
import OpenLoansActionManager from '@/modules/open-loans/components/OpenLoansActionManager.vue';
import OpenLoansAggregates from '@/modules/open-loans/components/OpenLoansAggregates.vue';
import { OpenLoanDisplayItem, OpenLoanItem } from '@/modules/open-loans/types/open-loans';
import { LoanStatus, isTerminalLoanStatus } from '@/utils/api/loans';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import { formatDate } from '@/utils/helpers/dates';
import { ClientConfig } from '@/utils/helpers/rest';
import { Decimal } from 'decimal.js';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { DataTableHeader } from 'vuetify';
import { mapGetters, mapState } from 'vuex';

// Note: if you add a column here make sure to update OpenLoansAggregates as well!
const allTableHeaders: DataTableHeader[] = [
  {
    text: 'Status',
    value: 'status',
    class: 'text-truncate row-icon status',
    cellClass: 'text-truncate row-icon status',
    align: 'center',
    width: '7rem',
  },
  {
    text: 'Update Time',
    value: 'updatedAt',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '6.5rem',
  },
  {
    text: 'Side',
    value: 'side',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '4rem',
  },
  {
    text: 'Counterparty',
    value: 'counterpartyDisplay',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    width: '12rem',
  },
  {
    text: 'Sett Type',
    value: 'settlementType',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Ticker',
    value: 'equity.ticker',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'CUSIP',
    value: 'equity.cusip',
    class: 'text-truncate',
    cellClass: 'text-truncate',
  },
  {
    text: 'Start Date',
    value: 'createdAt',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '6.5rem',
  },

  {
    text: 'Open',
    value: 'openQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: "Today's Returned",
    value: 'returnedQuantityToday',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: 'Rate',
    value: 'rate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: 'Contract Amount',
    value: 'contractAmount',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: 'IA',
    value: 'independentAmountRate',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: 'Settlement Amount',
    value: 'settlementAmount',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'end',
    width: '5rem',
  },
  {
    text: 'Suggested rate',
    value: 'renegotiateRate',
    class: 'text-truncate',
    cellClass: 'text-truncate col-renegotiate',
    align: 'end',
    width: '10rem',
  },
  {
    text: 'Recalled',
    value: 'recalledQuantity',
    class: 'text-truncate',
    cellClass: 'text-truncate col-recalled',
    align: 'end',
    width: '5rem',
  },
  {
    text: 'Due Date',
    value: 'nextAllowedBuyInExecutionDate',
    class: 'text-truncate',
    cellClass: 'text-truncate col-due-date',
    align: 'end',
    width: '5rem',
  },
  {
    text: 'Sponsorship',
    value: 'sponsorshipSide',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
    width: '2rem',
    sortable: false,
  },
  {
    text: 'Term Contract',
    value: 'termContractDisplayId',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'start',
    width: '2rem',
    sortable: false,
  },
  // "spacer" column before "actions" is required for .last-col-sticky class to work nicely
  {
    text: '',
    value: 'spacer',
    width: '100%',
    sortable: false,
  },
  {
    text: '', // No label (less is more!)
    value: 'actions',
    class: 'text-truncate',
    cellClass: 'text-truncate',
    align: 'center',
    sortable: false,
    width: '100%',
  },
];

const demModeOnlyCols = ['termContractDisplayId'];
const bilateralOnlyCols = ['settlementType'];

@Component({
  components: {
    OpenLoansAggregates,
    BtnDropdown,
    LoanStatusChip,
    OpenLoansActionManager,
  },
  props: {
    items: {
      type: Array as PropType<OpenLoanItem[]>,
      required: true,
    },
    corporateActions: {
      type: Object as PropType<Record<string, true>>,
      required: true,
    },
    selectedItems: {
      type: Array as PropType<OpenLoanItem[]>,
      default: () => [],
    },
    omitHeaders: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showDropdownActions: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    serverItemsLength: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(['clientConfig']),
    ...mapGetters(['hasOpsUserRole', 'hasTraderUserRole']),
  },
})
export default class OpenLoansTable extends Vue {
  public $refs!: {
    actionManager: OpenLoansActionManager;
  };

  // props
  protected readonly items!: OpenLoanDisplayItem[];
  protected readonly corporateActions!: Record<string, true>;
  protected readonly selectedItems!: OpenLoanItem[];
  protected readonly omitHeaders!: string[];
  protected readonly showDropdownActions!: boolean;
  protected readonly showSelect!: boolean;

  protected readonly pricePrecision = PRICE_PRECISION;
  protected readonly settlementTypeDisplayText = settlementTypeDisplayText;

  // store state
  protected readonly hasOpsUserRole!: boolean;
  protected readonly hasTraderUserRole!: boolean;
  protected readonly clientConfig!: ClientConfig;

  protected openLoanCount = 0;

  protected get localSelectedItems(): OpenLoanItem[] {
    return this.selectedItems;
  }

  protected set localSelectedItems(items: OpenLoanItem[]) {
    this.$emit('update:selected-items', items);
  }

  protected get tableColumns(): DataTableHeader[] {
    return allTableHeaders
      .filter((h) => !this.omitHeaders.includes(h.value))
      .filter((h) => this.clientConfig.demoMode || !demModeOnlyCols.includes(h.value))
      .filter(
        (h) => this.clientConfig.bilateralLoansEnabled || !bilateralOnlyCols.includes(h.value)
      );
  }

  protected get hasSelectedAllOpenLoans(): boolean {
    return this.selectedItems && this.selectedItems.length === this.openLoanCount;
  }

  protected mounted(): void {
    // DO NOT REMOVE or EDIT unless you understand
    // https://provablelabs.atlassian.net/browse/AURORA-1480
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs as any).tableRef.multipleSelect = () => {
      /* */
    };
  }

  protected filterOpenLoanItems(items: OpenLoanItem[]): OpenLoanItem[] {
    return items.filter((item) => !this.isTerminalLoanStatus(item.status));
  }

  // everytime the page is changed we need to count how many open-loans are on it
  protected onChangeItems(items: OpenLoanDisplayItem[]): void {
    this.openLoanCount = this.filterOpenLoanItems(items).length;
  }

  // toggle selected open-loans
  // ignore `payload.value` as it takes closed-loans into account
  protected toggleOpenLoans(payload: { items: OpenLoanItem[]; value: boolean }): void {
    this.$emit(
      'update:selected-items',
      this.hasSelectedAllOpenLoans ? [] : this.filterOpenLoanItems(payload.items)
    );
  }

  protected isTerminalLoanStatus(s: LoanStatus): boolean {
    return isTerminalLoanStatus(s);
  }

  protected formatDate(value: Date): string {
    return formatDate(value, 'MM/dd/yy');
  }

  protected formatTimestamp(value: Date): string {
    return formatDate(value, 'MMM d h:mm a');
  }

  protected formatPrice(price: Decimal): string {
    return getPriceAsString(price, this.pricePrecision);
  }
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 0.5rem;
}

.corp-actions-button {
  width: 24px;
  height: 24px;
}

.corp-actions-button i {
  margin-top: -4px;
}

.v-data-table.flex {
  // give the table a height, allowing flexbox to stretch to fill a available space
  // when tables have footer (pagination), this should be used instead of adding height="100%" to <v-data-table>
  height: 0rem;
}

::v-deep {
  .v-data-footer {
    // stick the footer to the bottom of the table
    // adjustment NOT required if "hide-default-footer" props is passed to <v-data-table>
    margin-top: auto;
  }

  table {
    thead th {
      text-transform: capitalize;
    }

    pre {
      // @TODO replace with Roboto Mono font, or similar monospace sans-serif font
      font-family: inherit;
      letter-spacing: 0.03em;
    }
  }

  .v-chip {
    min-width: 4rem;
  }

  // enhance status column
  th.row-icon.status {
    padding-left: 29px !important;
  }

  // @TODO use sass variables to store these colours
  .v-chip {
    &.status {
      padding: 0 16px;
    }
  }
}
</style>
