<template>
  <v-hover v-slot="{ hover }">
    <v-data-table
      :class="{ 'on-hover': hover }"
      dense
      disable-pagination
      fixed-header
      :headers="tableColumns"
      hide-default-footer
      :items="borrowerLocates"
      sort-by="id"
      sort-desc
    >
      <template #[`item.checkoff`]="{ item }">
        <v-checkbox color="secondary" dense hide-details @click="checkOff(item)"></v-checkbox>
      </template>

      <template #[`item.createdAt`]="{ item }">
        <pretty-date-time :when="item.createdAt"></pretty-date-time>
      </template>

      <template #[`item.quantity`]="{ item }">
        <pretty-number :value="item.quantity" />
      </template>

      <template #[`item.approvedQuantity`]="{ item }">
        <pretty-number :value="item.approvedQuantity" />
      </template>

      <template #[`item.category`]="{ item }">
        <span>{{ item.equity.id % 2 == 0 ? 'GC' : 'HTB' }}</span>
      </template>

      <template #[`item.refresh`]="{ item }">
        <v-btn color="secondary" x-small @click="locateAgain(item)">
          <v-icon left x-small> mdi-refresh</v-icon>
          Locate
        </v-btn>
      </template>
    </v-data-table>
  </v-hover>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import i18n from '@/localisation/i18n';
import { AuctionEquity, ClientConfig } from '@/utils/helpers/rest';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { BorrowIntent, BorrowerLocate } from '@/utils/api/locates';
import { mapActions, mapState } from 'vuex';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
import { DataTableHeader } from 'vuetify';
import axios from 'axios';
import PrettyDateTime from '@/modules/common/components/format-date/PrettyDateTime.vue';

@Component({
  components: {
    RateOutput,
    PrettyDateTime,
  },
  props: {
    borrowerLocates: Array,
  },
  data: () => ({
    tableColumns: [
      {
        text: i18n.t('Counterparty'),
        value: 'equity.name',
        class: 'text-truncate',
        cellClass: 'text-truncate',
      },
      {
        text: i18n.t('Date'),
        value: 'createdAt',
        class: 'text-truncate',
        cellClass: 'text-truncate',
      },
      {
        text: i18n.t('Requested Qty'),
        value: 'quantity',
        class: 'text-truncate',
        cellClass: 'text-truncate is-numeric',
        align: 'end',
      },
      {
        text: i18n.t('Approved Qty'),
        value: 'approvedQuantity',
        class: 'text-truncate',
        cellClass: 'text-truncate is-numeric',
        align: 'end',
      },

      {
        text: 'Liquidity Cat.',
        value: 'category',
        class: 'text-truncate',
        cellClass: 'text-truncate category',
        align: 'center',
      },
      {
        text: i18n.t('Refresh'),
        value: 'refresh',
        class: 'text-truncate',
        cellClass: 'text-truncate',
        align: 'center',
      },
    ] as DataTableHeader[],
  }),
  methods: {
    ...mapActions(['fetchBorrowerLocates']),
  },
  computed: {
    ...mapState(['clientConfig']),
  },
})
export default class BorrowerLocatesTable extends Vue {
  protected borrowerLocates!: BorrowerLocate[];

  protected tableColumns!: string[];

  protected fetchBorrowerLocates!: () => Promise<null>;

  protected clientConfig!: ClientConfig;

  protected async checkOff(locate: BorrowerLocate): Promise<void> {
    if (locate.equity == null) {
      return;
    }

    try {
      await axios.put(`/api/1/borrower/locate/${locate.equity.id}/check-off`);

      // reload auctions, the auction should move to the other list
      await this.fetchBorrowerLocates();

      this.$snackbar.confirm(i18n.t('checkedOffLocate') as string);
    } catch (e) {
      this.$snackbar.error(
        i18nServerMessage(e, `Locate ${locate.equity.id}' cannot be checked off`)
      );
    }
  }

  protected locateAgain(locate: BorrowerLocate): void {
    this.$emit('locate-intent', {
      equity: locate.equity as unknown as AuctionEquity,
      quantity: locate.quantity,
    } as BorrowIntent);
  }
}
</script>

<style lang="scss" scoped></style>
