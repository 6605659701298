import { ApiService } from '@/modules/common/services/api.service';
import {
  FinraTransactions,
  FinraTransactionsParams,
  FinraTransactionsRequest,
  normalizeFinraTransaction,
} from '@/utils/api/finra-transactions';
import { ApiError } from '@/utils/errors';
import axios from 'axios';
import Vue from 'vue';

export class FinraTransactionsApiService extends ApiService {
  private featureUrl = '/finra/transactions';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.finraTransactions = singleton;
    } else {
      v.prototype.$api = {
        finraTransactions: singleton,
      };
    }
  }

  // About the fetch methods below:
  //
  // 1. Overload to support request cancellation
  // 2. Build request params using provided params + default values
  // 3. Make request
  // 4. Normalize response data
  // 5. Return normalized data (or void if request was cancelled)

  public async fetchFinraTransactions(
    finraTransactionsState: FinraTransactionsParams
  ): Promise<FinraTransactions>;
  public async fetchFinraTransactions(
    finraTransactionsState: FinraTransactionsParams,
    abortSignal: AbortSignal
  ): Promise<FinraTransactions | void>;
  public async fetchFinraTransactions(
    finraTransactionsState: FinraTransactionsParams,
    abortSignal?: AbortSignal
  ): Promise<FinraTransactions | void> {
    const defaultFinraTransactionsState: FinraTransactionsRequest = {
      filters: {
        counterpartyCompanyId: null,
        cusip: null,
        side: null,
        showAll: false,
      },
      sort: 'createdAt',
      pagination: {
        page: 1,
        limit: 50,
      },
    };
    finraTransactionsState = { ...defaultFinraTransactionsState, ...finraTransactionsState };
    const params = {
      sort: finraTransactionsState.sort,
      ...finraTransactionsState.filters,
      ...finraTransactionsState.pagination,
    };
    const url = this.baseUrl + this.featureUrl;

    try {
      const { data } = await this.axios.get<FinraTransactions>(url, {
        params,
        signal: abortSignal,
      });
      data.items.forEach((transaction) => {
        normalizeFinraTransaction(transaction);
      });
      return data;
    } catch (e) {
      if (axios.isCancel(e)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }
      throw new ApiError(`${e}`);
    }
  }
}
